import React, { useEffect } from 'react';
import { SearchInput } from '../../../../../../components/search-input';
import { Label } from '../../../../../../ui/label';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import * as actions from './actions';
import { IAdvisor } from '../../../../../../api/advisors';
import { UserAvatar } from '../../../../../../components/avatar';
import { debounce } from 'lodash';

export const Recipient: React.FC = () => {
  const dispatch = useDispatch();

  const { user, list, selected, error } = useSelector(
    (state) => ({
      user: state.common.user.info,
      list: state.common.drawers.messages.panels.create.recipient.list,
      selected: state.common.drawers.messages.panels.create.recipient.selected,
      error: state.common.drawers.messages.panels.create.recipient.error,
    }),
    shallowEqual
  );

  const handleOnChange = (e: any) => {
    dispatch(actions.onSearch(e.target.value));
  };

  return (
    <div className={'w-full mb-6'}>
      <SearchInput
        handleOnFocus={() => dispatch(actions.fetch())}
        name="recipient_id"
        error={error}
        handleClearSelection={() => dispatch(actions.clear())}
        selected={{
          label: selected?.name,
          id: selected?.id,
          imageUrl: selected?.avatarUrl,
        }}
        renderLabel={() => (
          <Label
            required={false}
            name={'Recipient'}
            classNames={'font-semibold text-baby-blue text-sm mb-2 block'}
          />
        )}
        handleOnChange={debounce(handleOnChange, 250)}
        placeholder={'Search for a recipient'}
        iconClassNames={'fill-baby-blue absolute top-3 left-3'}
        inputClassNames={
          'bg-neutral-lighter rounded-lg pt-3 pb-3 pr-2 pl-8 text-xs w-full text-neutral-dark'
        }
      />
      <div
        className={'bg-white rounded-md shadow overflow-y-auto'}
        style={{ maxHeight: 300 }}
      >
        <ul>
          {selected == null &&
            list.map((advisor: IAdvisor) => (
              <li
                className={
                  'flex items-center py-2 px-2 hover:bg-neutral-lightest'
                }
                onClick={() => dispatch(actions.select(advisor))}
              >
                <UserAvatar size={20} imageUrl={advisor.avatarUrl} />
                <span className={'text-neutral text-sm'}>{advisor.name}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
