import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import GoogleAnalytics from '../../../../../analytics';

const fetchOnUpdate: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.done),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      return from(
        api.admin.getStudentSnapshot(state$.value.common.match.params.id)
      ).pipe(
        mergeMap(({ data }) => {
          GoogleAnalytics.Schedule.viewedStudentSchedule({
            scheduleId: data.schedule.id,
          });
          return of(actions.load.done(data));
        })
      )
    }
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(({ payload }) => {
      const { schedule } = state$.value.pages.admin.dashboard.student.schedule;

      const seasons = schedule.configuration.availableSeasons;

      return from(
        api.schedules.updateSchedule(
          schedule.id,
          schedule.name,
          schedule.majors,
          payload,
          seasons
        )
      ).pipe(
        mergeMap(({ data }) => {
          GoogleAnalytics.Schedule.addScheduleUpdateEvent({
            scheduleId: data.id,
          });

          return of(actions.update.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.update.error(error)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(fetch, load, update, fetchOnUpdate);
