import React, { useEffect, useState } from 'react';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { Button } from '../../../../../ui';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../ui/button';
import {
  Modal,
  ModalPosition,
  ModalVariant,
} from '../../../../../components/modal';
import { AddStudent } from './add-student';
import { IPreppedStudentAccountInfo } from '../../../../../api/admin/invites';
import { UploadCsv } from './upload-csv';
import { StudentItem } from './student';
import { AiOutlineDownload } from '@react-icons/all-files/ai/AiOutlineDownload';
import * as integrationActions from "../../../../../common/configs/integrations/actions";
import querystring from "query-string";

export const InviteStudentsModal: React.FC = () => {
  const dispatch = useDispatch();
  const [isAddingStudent, setIsAddingStudent] = useState<boolean>(false);
  const [students, setStudents] = useState<IPreppedStudentAccountInfo[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { isOpen, isLoading, error, studentInviteTemplateUrl, blackBaudAuthUrl } = useSelector(
    (state) => ({
      isOpen: state.pages.admin.invitations.modals.studentInvitesModal.isOpen,
      isLoading:
        state.pages.admin.invitations.modals.studentInvitesModal.isLoading,
      error: state.pages.admin.invitations.modals.studentInvitesModal.error,
      studentInviteTemplateUrl:
        state.common.configs.configuration.studentInviteTemplateUrl,
      blackBaudAuthUrl: state.common.configs.integrations.blackbaud.authorizeUrl
    }),
    shallowEqual
  );

  const handleEditStudent = (
    editedStudent: IPreppedStudentAccountInfo,
    editedStudentIndex: number
  ) => {
    let updatedStudents = students.map((student, index) => {
      if (editedStudentIndex == index) {
        return editedStudent;
      }
      return student;
    });

    setStudents(updatedStudents);
    setIsEditing(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setStudents([]);
    }
  }, [isOpen]);

  useEffect(() => {
    let params = querystring.parse(window.location.search);
    if(params.import) {
      dispatch(actions.open());
      dispatch(actions.loadBlackBaudStudents.start());
    }
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        position={ModalPosition.Top}
        variant={ModalVariant.FullWidth}
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderBody={() => <></>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-10'}>
              <div className="mr-2">
                <Button
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Secondary}
                  handleOnClick={() => dispatch(actions.close())}
                >
                  Cancel
                </Button>
              </div>
              {
                <Button
                  disabled={!students.length || isEditing}
                  isLoading={isLoading}
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Filled}
                  variant={ButtonVariant.Secondary}
                  handleOnClick={() => dispatch(actions.send.start(students))}
                >
                  Send Invites
                </Button>
              }
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
          <div
            className={
              'flex items-center justify-between mb-6 border-b border-neutral-light'
            }
          >
            <div>
              <h2 className={'text-4xl text-black font-semibold mb-2'}>
                Invite Students
              </h2>
            </div>
          </div>
        </div>

        <section>
          <section className={'flex items-center mt-4 mb-2 border-b pb-3'}>
            <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
              Name
            </div>
            <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
              ID Number
            </div>
            <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
              Email
            </div>
            <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
              Program
            </div>
            <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
              Start Term
            </div>
            <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
              Start Year
            </div>
            <div
              className={
                'w-1/12 font-medium text-neutral-darkest text-xs text-right'
              }
            ></div>
          </section>

          <section className={'overflow-y-scroll'} style={{ maxHeight: 250 }}>
            {students.map(
              (student: IPreppedStudentAccountInfo, index: number) => (
                <StudentItem
                  student={student}
                  index={index}
                  onEditing={(editing: boolean) => setIsEditing(true)}
                  handleEditStudent={handleEditStudent}
                  handleRemoveStudent={() =>
                    setStudents([...students.filter((s, i) => index !== i)])
                  }
                />
              )
            )}
          </section>

          {isAddingStudent && (
            <AddStudent
              handleAddStudent={(student) => {
                setStudents([...students, student]);
                setIsAddingStudent(false);
              }}
            />
          )}

          <div className={'flex items-center mt-8'}>
            {/*<Button*/}
            {/*    size={ButtonSize.Small}*/}
            {/*    classes={"mr-2"}*/}
            {/*    variant={ButtonVariant.Secondary}*/}
            {/*    isLoading={false}*/}
            {/*    handleOnClick={() => dispatch(integrationActions.authorize.start())}*/}
            {/*    radius={ButtonBorderRadius.XL}*/}
            {/*>*/}
            {/*  Import from Blackbaud*/}
            {/*</Button>*/}
            <UploadCsv
              handleAddStudents={(uploadedStudents: []) =>
                setStudents([...students, ...uploadedStudents])
              }
            />
            {isAddingStudent && (
              <Button
                classes={'mr-2'}
                size={ButtonSize.Small}
                style={ButtonStyle.Outline}
                variant={ButtonVariant.Secondary}
                isLoading={false}
                handleOnClick={() => setIsAddingStudent(false)}
                radius={ButtonBorderRadius.XL}
              >
                Cancel
              </Button>
            )}
            {!isAddingStudent && (
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Secondary}
                isLoading={false}
                handleOnClick={() => setIsAddingStudent(true)}
                radius={ButtonBorderRadius.XL}
              >
                Manually Add Student
              </Button>
            )}
          </div>
          <div className={'mt-3 flex items-center'}>
            <a
              className={'text-xs text-baby-blue underline flex items-center'}
              href={studentInviteTemplateUrl}
              download={true}
            >
              <AiOutlineDownload className={'fill-baby-blue mr-1 text-xl'} />
              Download Template
            </a>
            <span className={'ml-2 block text-xs text-neutral'}>
              If you upload a CSV, include the name, student id, and email
              fields and we can edit the rest in Degreely.
            </span>
          </div>
        </section>
      </Modal>
    </>
  );
};
