import React from 'react';
import { useSelector } from '../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';

interface IAuthHeaderProps {
  includeLogo?: boolean;
  title: string;
  subtitle?: string;
}

export const AuthHeader: React.FC<IAuthHeaderProps> = ({
  title,
  subtitle,
  includeLogo,
}) => {
  const { domain } = useSelector(
    (state) => ({
      domain: state.common.configs.domain,
    }),
    shallowEqual
  );

  return (
    <div
      className={
        'mb-6 w-5/6 md:1/2 lg:w-full flex align-items flex-col text-center'
      }
    >
      {includeLogo && (
        <div
          className={
            'text-center flex items-center justify-center mb-6 lg:mb-8 xl:mb-12'
          }
        >
          {domain.universityDomain == 'usc' && (
            <img
              src="https://customsitesmedia.usc.edu/wp-content/uploads/sites/55/2019/01/15234738/PrimShield-Mono_SmallUse_CardOnTrans_RGB.png"
              style={{ width: 250 }}
              alt=""
            />
          )}
          {domain.universityDomain == 'app' && (
            <img
              style={{ width: 250 }}
              src="/assets/logos/degreely-logo.png"
              alt=""
            />
          )}
          {domain.universityDomain == 'sa' && (
            <img
              style={{ width: 250 }}
              src="/assets/logos/sonoma-academy-logo.png"
              alt=""
            />
          )}
        </div>
      )}
      <h1
        className={
          'text-4xl text-blue-darkest mb-2 lg:mb-2 xl:pb-3 font-bold'
        }
      >
        {title}
      </h1>
      {!!subtitle && (
        <span className={'text-black font-light'}>{subtitle}</span>
      )}
    </div>
  );
};

AuthHeader.defaultProps = {
  includeLogo: true,
};
