import { createAction } from 'redux-act';
import { IDegreeRequirement } from '../../../../../../../api/admin/degrees';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/degrees/edit/degree/requirements/list] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/edit/degree/requirements/list] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/edit/degree/requirements/list] load.error'
  ),
  done: createAction<IDegreeRequirement[]>(
    '[pages/admin/dashboard/settings/degrees/edit/degree/requirements/list] load.done'
  ),
};
