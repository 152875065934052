import React from 'react';
import { ISVGProps } from '../index';

export const GraduationCap: React.FC<ISVGProps> = ({ classes }) => {
  return (
    <svg width="23" height="19" viewBox="0 0 23 19" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path fill={"currentColor"} stroke={"none"} d="M17.7666 8.19155C18.0736 8.71933 18.3019 9.2691 18.4517 9.84086C18.6014 10.4126 18.68 11.0027 18.6875 11.6111V12.7436L11.5 16.2731L4.3125 12.7436C4.3125 12.3038 4.31624 11.8933 4.32373 11.5122C4.33122 11.131 4.36491 10.7608 4.4248 10.4016C4.4847 10.0424 4.57454 9.68326 4.69434 9.32407C4.81413 8.96489 4.99381 8.58738 5.2334 8.19155L2.875 7.03704V13.4913C3.09212 13.5646 3.28678 13.6672 3.45898 13.7992C3.63118 13.9311 3.78467 14.0851 3.91943 14.261C4.0542 14.4369 4.15153 14.6275 4.21143 14.8328C4.27132 15.038 4.30501 15.2542 4.3125 15.4815V19H0V15.4815C0 15.2616 0.0336914 15.049 0.101074 14.8438C0.168457 14.6385 0.265788 14.4443 0.393066 14.261C0.520345 14.0777 0.670085 13.9238 0.842285 13.7992C1.01449 13.6746 1.21289 13.572 1.4375 13.4913V6.33333L0 5.62963L11.5 0L23 5.62963L17.7666 8.19155ZM2.875 15.4815C2.875 15.2909 2.80387 15.126 2.66162 14.9867C2.51937 14.8474 2.35091 14.7778 2.15625 14.7778C1.96159 14.7778 1.79313 14.8474 1.65088 14.9867C1.50863 15.126 1.4375 15.2909 1.4375 15.4815V17.5926H2.875V15.4815ZM3.21191 5.62963L11.5 9.68692L19.7881 5.62963L11.5 1.57234L3.21191 5.62963ZM17.25 11.875C17.25 11.3106 17.1938 10.7865 17.0815 10.3027C16.9692 9.81887 16.7708 9.32407 16.4863 8.81829L11.5 11.2593L6.51367 8.81829C6.2666 9.24344 6.07943 9.69059 5.95215 10.1597C5.82487 10.6289 5.75749 11.1127 5.75 11.6111V11.875L11.5 14.6898L17.25 11.875Z"/>
    </svg>

  )
}

GraduationCap.defaultProps = {
  classes: ''
}
