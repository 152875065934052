import React from 'react';
import { ISVGProps } from '../index';

export const InviteStudent: React.FC<ISVGProps> = ({ classes }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <path fill={"currentColor"} stroke={"none"} d="M18.7666 11.1916C19.0736 11.7193 19.3019 12.2691 19.4517 12.8409C19.6014 13.4126 19.68 14.0027 19.6875 14.6111V15.7436L12.5 19.2731L5.3125 15.7436C5.3125 15.3038 5.31624 14.8933 5.32373 14.5122C5.33122 14.131 5.36491 13.7608 5.4248 13.4016C5.4847 13.0424 5.57454 12.6833 5.69434 12.3241C5.81413 11.9649 5.99381 11.5874 6.2334 11.1916L3.875 10.037V16.4913C4.09212 16.5646 4.28678 16.6672 4.45898 16.7992C4.63118 16.9311 4.78467 17.0851 4.91943 17.261C5.0542 17.4369 5.15153 17.6275 5.21143 17.8328C5.27132 18.038 5.30501 18.2542 5.3125 18.4815V22H1V18.4815C1 18.2616 1.03369 18.049 1.10107 17.8438C1.16846 17.6385 1.26579 17.4443 1.39307 17.261C1.52035 17.0777 1.67008 16.9238 1.84229 16.7992C2.01449 16.6746 2.21289 16.572 2.4375 16.4913V9.33333L1 8.62963L12.5 3L24 8.62963L18.7666 11.1916ZM3.875 18.4815C3.875 18.2909 3.80387 18.126 3.66162 17.9867C3.51937 17.8474 3.35091 17.7778 3.15625 17.7778C2.96159 17.7778 2.79313 17.8474 2.65088 17.9867C2.50863 18.126 2.4375 18.2909 2.4375 18.4815V20.5926H3.875V18.4815ZM4.21191 8.62963L12.5 12.6869L20.7881 8.62963L12.5 4.57234L4.21191 8.62963ZM18.25 14.875C18.25 14.3106 18.1938 13.7865 18.0815 13.3027C17.9692 12.8189 17.7708 12.3241 17.4863 11.8183L12.5 14.2593L7.51367 11.8183C7.2666 12.2434 7.07943 12.6906 6.95215 13.1597C6.82487 13.6289 6.75749 14.1127 6.75 14.6111V14.875L12.5 17.6898L18.25 14.875Z" />
            <circle stroke="none" cx="19" cy="16" r="3" fill="#2563EB"/>
            <path fill={"currentColor"} stroke={"none"} d="M19 13C18.2073 13.0096 17.4498 13.3287 16.8893 13.8893C16.3287 14.4498 16.0096 15.2073 16 16C16.0096 16.7927 16.3287 17.5502 16.8893 18.1107C17.4498 18.6713 18.2073 18.9904 19 19C19.7927 18.9904 20.5502 18.6713 21.1107 18.1107C21.6713 17.5502 21.9904 16.7927 22 16C21.9904 15.2073 21.6713 14.4498 21.1107 13.8893C20.5502 13.3287 19.7927 13.0096 19 13ZM20.7143 16.2143H19.2143V17.7143H18.7857V16.2143H17.2857V15.7857H18.7857V14.2857H19.2143V15.7857H20.7143V16.2143Z"/>
        </svg>
    )
}

InviteStudent.defaultProps = {
    classes: ''
}
