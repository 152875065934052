import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import * as actions from './actions';
import * as studentFilterActions from '../../../common/filters/students/actions';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../common/routes';

export const Overview: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, overview, user, studentFilters } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isLoading: state.pages.admin.dashboard.overview.courses.isLoading,
      overview: state.pages.admin.dashboard.overview.overview,
      studentFilters: state.pages.admin.common.filters.students.filters,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  if (isLoading) {
    return null;
  }

  return (
    <section className={'flex justify-between mb-8'}>
      <div
        className="w-1/5 bg-neutral-lightest p-6 rounded-md mr-4 cursor-pointer hover:bg-neutral-lighter"
        onClick={() => {
          dispatch(
            studentFilterActions.apply({
              ...studentFilters,
              onlyFlaggedStudents: 0,
            })
          );
          navigate(Routes.admin.dashboard.students.index);
        }}
      >
        <div className={'text-baby-blue text-4xl font-bold'}>
          {overview.activeStudents}
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          Active <br /> Students
        </span>
      </div>
      <div
        className="w-1/5 bg-neutral-lightest p-6 rounded-md mr-4 cursor-pointer hover:bg-neutral-lighter"
        onClick={() => {
          navigate(Routes.admin.dashboard.courses.index);
        }}
      >
        <div className={'text-baby-blue text-4xl font-bold'}>
          {overview.activeCourses}
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          Active <br /> Courses
        </span>
      </div>
      <div
        className="w-1/5 bg-neutral-lightest p-6 rounded-md mr-4 hover:bg-neutral-lighter cursor-pointer"
        onClick={() => {
          dispatch(
            studentFilterActions.apply({
              ...studentFilters,
              percentPlanned: { min: 0, max: 0 },
            })
          );
          navigate(Routes.admin.dashboard.students.index);
        }}
      >
        <div className={'text-baby-blue text-4xl font-bold'}>
          {overview.emptySchedules}
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          Empty <br /> Schedules
        </span>
      </div>
      {/*<div className="w-1/5 bg-neutral-lightest p-6 rounded-md mr-4">*/}
      {/*    <div className={"text-baby-blue text-4xl font-bold"}>{overview.pendingRegistrations}</div>*/}
      {/*    <span className={"text-neutral-darkest text-md font-semibold leading-none mt-2 block"}>Pending <br/> Registrations</span>*/}
      {/*</div>*/}
      <div
        className="w-1/5 bg-neutral-lightest p-6 rounded-md mr-4 cursor-pointer hover:bg-neutral-lighter"
        onClick={() => {
          navigate(Routes.admin.invitations.student);
        }}
      >
        <div className={'text-baby-blue text-4xl font-bold'}>
          {overview.pendingStudentInvitations}
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          Pending <br /> Invitations
        </span>
      </div>
      <div
        className="w-1/5 bg-neutral-lightest p-6 rounded-md mr-4 cursor-pointer hover:bg-neutral-lighter"
        onClick={() => {
          dispatch(
            studentFilterActions.apply({
              ...studentFilters,
              onlyFlaggedStudents: 1,
            })
          );
          navigate(Routes.admin.dashboard.students.index);
        }}
      >
        <div className={'text-baby-blue text-4xl font-bold'}>
          {overview.studentFlags}
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          Student <br /> Flags
        </span>
      </div>
      {/*<div className="w-2/12 bg-neutral-lightest p-6 rounded-md">*/}
      {/*  <div className={'text-baby-blue text-4xl font-bold'}>*/}
      {/*    {overview.courseConflicts}*/}
      {/*  </div>*/}
      {/*  <span*/}
      {/*    className={*/}
      {/*      'text-neutral-darkest text-md font-semibold leading-none mt-2 block'*/}
      {/*    }*/}
      {/*  >*/}
      {/*    Course <br /> Conflicts*/}
      {/*  </span>*/}
      {/*</div>*/}
    </section>
  );
};
