import React from 'react';
import { Modal, ModalPosition, ModalVariant } from '../../../../components/modal';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../infrastructure/selector';
import { Button } from '../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../ui/button';
import { UserProfileAccountSettingFields } from '../fields';
import { UserRoleType } from '../../../../constants';

export const AccountSettingsModal: React.FC = () => {

  const dispatch = useDispatch();

  const { isOpen, isLoading, error, user, legalName, preferredName, email, studentId, degree, intendedGraduationSeason, intendedGraduationYear } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isOpen: state.pages.studentProfile.accountSettings.modal.isOpen,
      error: state.pages.studentProfile.accountSettings.modal.error,
      isLoading: state.pages.studentProfile.accountSettings.modal.isLoading,
      legalName: state.pages.studentProfile.accountSettings.modal.legalName,
      preferredName: state.pages.studentProfile.accountSettings.modal.preferredName,
      email: state.pages.studentProfile.accountSettings.modal.email,
      studentId: state.pages.studentProfile.accountSettings.modal.studentId,
      degree: state.pages.studentProfile.accountSettings.modal.degree,
      intendedGraduationSeason: state.pages.studentProfile.accountSettings.modal.intendedGraduation.season,
      intendedGraduationYear: state.pages.studentProfile.accountSettings.modal.intendedGraduation.year,
    }),
    shallowEqual
  );

  return (
    <Modal
      position={ModalPosition.Centered}
      variant={ModalVariant.Centered}
      handleClose={() => dispatch(actions.close())}
      isOpen={isOpen}
      renderBody={() => <></>}
      renderFooter={() => <>
        <div className={"flex items-center mt-16 justify-end"}>
          <Button
            classes={"mr-2"}
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            style={ButtonStyle.Outline}
            handleOnClick={() => dispatch(actions.close())}
          >
            Cancel
          </Button>
          <Button
            size={ButtonSize.Large}
            variant={ButtonVariant.Secondary}
            isLoading={isLoading}
            handleOnClick={() => dispatch(actions.save.start())}
          >
            Save
          </Button>
        </div>
      </>}
    >
      <div>
        <h2 className={"text-3xl pb-4 text-baby-blue border-b border-b-neutral-light font-semibold"}>Update Your Account Settings</h2>
      </div>

      <UserProfileAccountSettingFields
        onChange={(value) => dispatch(actions.onChange(value))}
        isEditable={true}
        values={{
          legalName,
          preferredName,
          email,
          studentId,
          degree: degree.label,
          intendedGraduationSeason,
          intendedGraduationYear,
        }}
        isAdmin={user?.roleId == UserRoleType.Administrator}
        isStudent={user?.roleId == UserRoleType.Student}
      />
    </Modal>
  )
}
