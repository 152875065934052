import { combineReducers } from 'redux';

import { predictiveCourseDataReducer as predictiveCourseData } from './predictive-course-data/reducer';
import { studentStatusDataReducer as studentStatusData } from './student-status-data/reducer';
import { overviewReducer as overview } from './overview-stats/reducer';

export const studentsAdminDashboardReducer = combineReducers({
  predictiveCourseData,
  studentStatusData,
  overview,
});
