import { AxiosPromise } from 'axios';
import client from '../axios.client';

export interface IIntegration {
    name: string;
    key: string;
    logoUrl: string;
    authorizationUrl: string;
}

export interface IIntegrationsAPI {
    getIntegrations(): AxiosPromise<IIntegration[]>;
}

class IntegrationsAPI implements IIntegrationsAPI {

    getIntegrations(): AxiosPromise<IIntegration[]> {
        return client.get(`/api/support/integrations`);
    }
}

export const integrations = new IntegrationsAPI();
