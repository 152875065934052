import { generatePath, Link } from 'react-router-dom';
import * as actions from '../actions';
import { Routes as DegreelyRoutes } from '../../../../../../common/routes';
import { Button } from '../../../../../../ui';
import { ButtonBorderRadius, ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { RequiresMessagingAccess } from '../../../../../../common/guards/messaging-access';
import React from 'react';
import { useAppSelector } from '../../../../../../app/hooks';
import { shallowEqual, useDispatch } from 'react-redux';

export const Actions: React.FC = () => {

  const dispatch = useDispatch();

  const { name, major, avatarUrl, isLoading, email, studentId, flagCount, hasActiveSchedule, activePreview } =
    useAppSelector(
      (state) => ({
        activePreview: state.pages.admin.dashboard.drawers.studentDetail.activePreview,
        isLoading: state.pages.admin.dashboard.drawers.studentDetail.isLoading,
        hasActiveSchedule: state.pages.admin.dashboard.drawers.studentDetail.hasActiveSchedule,
        name: state.pages.admin.dashboard.drawers.studentDetail.name,
        studentId: state.pages.admin.dashboard.drawers.studentDetail.studentId,
        email: state.pages.admin.dashboard.drawers.studentDetail.email,
        avatarUrl: state.pages.admin.dashboard.drawers.studentDetail.avatarUrl,
        major: state.pages.admin.dashboard.drawers.studentDetail.major,
        flagCount: state.pages.admin.dashboard.drawers.studentDetail.flags,
      }),
      shallowEqual
    );

  return (
    <div className={'mt-1 flex items-center'}>
      {hasActiveSchedule && (
        <Link
          className={'mr-2'}
          onClick={() => dispatch(actions.close())}
          to={generatePath(DegreelyRoutes.admin.dashboard.students.show, {
            id: studentId,
          })}
        >
          <Button
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Secondary}
            size={ButtonSize.XXXSmall}
            radius={ButtonBorderRadius.MD}
          >
            {!!name && `View ${name.split(' ')[0]}'s Plan`}
          </Button>
        </Link>
      )}
      <RequiresMessagingAccess>
        <Button
          style={ButtonStyle.Filled}
          variant={ButtonVariant.Secondary}
          size={ButtonSize.XXXSmall}
          radius={ButtonBorderRadius.MD}
          handleOnClick={() => {
            dispatch(actions.openMessageDrawerWithRecipient({
              id: studentId,
              name,
              avatarUrl,
            }));
          }}
        >
          {!!name && `Message ${name.split(' ')[0]}`}
        </Button>
      </RequiresMessagingAccess>
    </div>
  )
}
