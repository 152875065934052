import React from "react";
import { IValidationErrorResponse } from '../../../../../types/error';
import { Input } from '../../../../../components/input';

export interface IAdminAccountSettingFieldProps {
  isEditable?: boolean;
  errors?: IValidationErrorResponse;
  onChange?: (payload: {key: string; value: string}) => any;
  isStudent: boolean;
  isAdmin: boolean;
  values: {
    legalName: string;
    preferredName: string;
    email: string;
    role: string;
  }
}

export const AdminAccountSettingFields: React.FC<IAdminAccountSettingFieldProps> = ({ values, errors, isStudent, isAdmin, isEditable, onChange }) => {
  return (
    <div className={"mt-4"}>
      <div className={"mb-6"}>
        <div className={"flex items-center justify-between"}>
          <div className={"w-1/2 mr-2"}>
            <Input
              value={values.legalName}
              disabled={true}
              type={"text"}
              required={false}
              name={"legalName"}
              label={"Legal Name"}
              placeholder={"John Doe"}
              onChange={(e) => onChange && onChange({
                key: "legalName",
                value: e.target.value
              })}
            />
          </div>
          <div className={"w-1/2 ml-2"}>
            <Input
              value={values.preferredName}
              disabled={!isEditable}
              type={"text"}
              required={false}
              name={"preferredName"}
              label={"Preferred Name"}
              placeholder={"John Doe"}
              onChange={(e) => onChange && onChange({
                key: "preferredName",
                value: e.target.value
              })}
            />
          </div>
        </div>
      </div>
      <div className={"mb-6"}>
        <div className={"flex items-center justify-between"}>
          <div className={"w-1/2 mr-2"}>
            <Input
              value={values.email}
              disabled={true}
              onChange={(e) => onChange && onChange({
                key: "email",
                value: e.target.value
              })}
              type={"text"}
              required={false}
              name={"email"}
              label={"Email"}
              placeholder={"johndoe@edu.com"}
            />
          </div>
          <div className={"w-1/2 ml-2"}>
            <Input
              value={values.role}
              disabled={true}
              onChange={(e) => onChange && onChange({
                key: "role",
                value: e.target.value
              })}
              type={"text"}
              required={false}
              name={"role"}
              label={"Role"}
              placeholder={"Advisor"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

AdminAccountSettingFields.defaultProps = {
  isEditable: false
}
