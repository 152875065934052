import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/course/occupancies] fetch'
);

export const load = {
  start: createAction('[pages/admin/dashboard/course/occupancies] load.start'),
  error: createAction<any>(
    '[pages/admin/dashboard/course/occupancies] load.error'
  ),
  done: createAction<any>(
    '[pages/admin/dashboard/course/occupancies] load.done'
  ),
};

export const select = createAction<number>(
  '[pages/admin/dashboard/course/occupancies] select'
);
