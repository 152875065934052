import React from 'react';
import { UserProfileAboutYouFields } from '../../../../pages/student-profile/about-you/fields';
import * as actions from "../actions";
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
export const CompleteProfileAbout: React.FC = () => {

  const dispatch = useDispatch();

  const { favoriteThingToDo, dreamVocationAsKid, dreamFutureVocation, error } = useSelector(
    (state) => ({
      error: state.common.modals.completeProfile.error,
      favoriteThingToDo: state.common.modals.completeProfile.about.favoriteThingToDo,
      dreamVocationAsKid: state.common.modals.completeProfile.about.dreamVocationAsKid,
      dreamFutureVocation: state.common.modals.completeProfile.about.dreamFutureVocation,
    }),
    shallowEqual
  );

  return (
    <>
      <div>
        <h3 className={"text-neutral-darkest font-semibold text-2xl"}>Tell us about yourself!</h3>
        <UserProfileAboutYouFields
          isEditable={true}
          errors={error}
          onVocationAsKidChange={(value) => dispatch(actions.onAboutChange({
            key: "dreamVocationAsKid",
            value
          }))}
          futureVocationChange={(value) => dispatch(actions.onAboutChange({
            key: "dreamFutureVocation",
            value
          }))}
          favoriteThingToDoChange={(value) => dispatch(actions.onAboutChange({
            key: "favoriteThingToDo",
            value
          }))}
          futureVocation={dreamFutureVocation}
          vocationAsKid={dreamVocationAsKid}
          favoriteThingToDo={favoriteThingToDo}
        />
      </div>
    </>
  )
}
