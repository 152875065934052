const Constants = {
  localStorageKeys: {
    degree: 'degree',
    lastUpdated: 'degree-saved-updated-at',
  },
  grid: 4,
};

export enum StudentGradeLevel {
  Freshman,
  Sophomore,
  Junior,
  Senior,
  SuperSenior,
}

export enum TermType {
  Term,
  Bin,
}

export enum UserRoleType {
  Student = 1,
  Administrator,
}

export enum Season {
  Spring,
  Summer,
  Fall,
  Winter,
}

export enum CourseStatus {
  Completed,
  Planned,
  InProgress,
  NotPlanned,
  NotRequired,
}

export const ProgressionPercentageThresholds = {
  Danger: 33,
  Warning: 66,
  Success: 100,
};

export const REQUIREMENT_COLORS = [
  '#3498DB',
  '#9B59B6',
  '#2C3E50',
  '#F1C40F',
  '#E67E22',
  '#410093',
  '#A4C400',
  '#D80073',
  '#C0392B',
  '#1ABC9C',
];

export default Constants;
