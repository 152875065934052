import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import { api } from '../../../../../../api';

const upload: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.upload.start),
    switchMap(({ payload }) =>
      from(api.admin.invites.uploadStudentInviteFile(payload)).pipe(
        mergeMap(({ data }) => {
          return of(actions.upload.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.upload.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(upload);
