import React, { useEffect, useState } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import { Login } from './pages/auth/login';
import { Register } from './pages/auth/register';
import { Schedules } from './pages/schedules';
import { DashboardPage } from './pages/dashboard';
import { DashboardTracks } from './pages/dashboard/tracks';
import { AdminDashboard } from './pages/admin/dashboard';
import { Page404 } from './pages/404';
import { Routes as DegreelyRoutes } from './common/routes';
import { NoSchedules } from './pages/dashboard/no-schedules';
import { shallowEqual, useDispatch } from 'react-redux';
import * as userActions from './common/user/actions';
import * as configActions from './common/configs/domain/actions';
import * as configurationActions from "./common/configs/configuration/actions";
import { applyTheme } from './infrastructure/themes/utils';
import { themeDetectorService } from './services/theme-detector';
import { AdminStudentData } from './pages/admin/dashboard/students';
import { AdminCoursesData } from './pages/admin/dashboard/courses';
import { AdminCourseData } from './pages/admin/dashboard/course';
import { AdminStudent } from './pages/admin/dashboard/student';
import { AdminSettings } from './pages/admin/settings';
import { AdminSettingsCourses } from './pages/admin/settings/courses';
import { AdminSettingsCourse } from './pages/admin/settings/course';
import { AdminDegreesPage } from './pages/admin/settings/degrees/list';
import { AdminEditDegreePage } from './pages/admin/settings/degrees/edit';
import { AdminSettingsCreateCourse } from './pages/admin/settings/course/create';
import { AdminDashboardMain } from './pages/admin/dashboard/main';
import { AdminStudentInvitationsPage } from './pages/admin/student-invitations';
import { InvitationsIndex } from './pages/admin/student-invitations/list';
import { AlertToast } from './common/alerts';
import { MessagesDrawer } from './common/drawers/messages';
import {DegreelyIndex} from "./pages/index";
import {HelpScout} from "./support/help-scout";
import { UserProfile } from './pages/profile';
import { AdminStudentProfile } from './pages/admin/dashboard/student/profile';
import { AdminStudentProgression } from './pages/admin/dashboard/student/progression';
import * as matchActions from "./common/match/actions";
import { CommonModals } from './common/modals';
import { useSelector } from './infrastructure/selector';

export const App: React.FC = () => {

  let location = window.location;
  let themeKey = themeDetectorService.detectTheme(location.host);

  const dispatch = useDispatch();
  dispatch(matchActions.route.change(window.location));

  const [theme, setTheme] = useState(themeKey);

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(userActions.fetch());
    dispatch(configActions.fetch());
    dispatch(configurationActions.fetch());
    // dispatch(integrationActions.fetch());
  }, []);

  useEffect(() => {
    if(user?.name) {
      dispatch(configurationActions.fetch());
    }
  }, [user?.id]);

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  // TODO: This need to be cleaned up
  return (
    <React.Fragment>
      <HelpScout />
      <Routes>
        <Route
          path={'/'}
          element={<DegreelyIndex />}
        />
        <Route path={DegreelyRoutes.auth.login} element={<Login />} />
        <Route path={DegreelyRoutes.auth.register} element={<Register />} />
        //TODO: Put this behind auth
        <Route
          path={DegreelyRoutes.admin.dashboard.index}
          element={<AdminDashboard />}
        >
          <Route
            path={DegreelyRoutes.admin.dashboard.index}
            element={<AdminDashboardMain />}
          />
          <Route
            path={DegreelyRoutes.admin.dashboard.students.index}
            element={<AdminStudentData />}
          />
          <Route
            path={DegreelyRoutes.admin.dashboard.students.show}
            element={<AdminStudent />}
          >
            <Route path={DegreelyRoutes.admin.dashboard.students.show} element={<AdminStudentProgression />} />
            <Route path={DegreelyRoutes.admin.dashboard.students.profile} element={<AdminStudentProfile />} />
          </Route>
          <Route
            path={DegreelyRoutes.admin.dashboard.courses.index}
            element={<AdminCoursesData />}
          />
          <Route
            path={DegreelyRoutes.admin.dashboard.courses.show}
            element={<AdminCourseData />}
          />
        </Route>
        <Route
          path={DegreelyRoutes.admin.settings.index}
          element={<AdminSettings />}
        >
          <Route
            path={DegreelyRoutes.admin.settings.courses.index}
            element={<AdminSettingsCourses />}
          />
          <Route
            path={DegreelyRoutes.admin.settings.courses.create}
            element={<AdminSettingsCreateCourse />}
          />
          <Route
            path={DegreelyRoutes.admin.settings.courses.show}
            element={<AdminSettingsCourse />}
          />
          <Route
            path={DegreelyRoutes.admin.settings.degrees.index}
            element={<AdminDegreesPage />}
          />
          <Route
            path={DegreelyRoutes.admin.settings.degrees.show}
            element={<AdminEditDegreePage />}
          />
        </Route>
        <Route
          path={DegreelyRoutes.admin.invitations.student}
          element={<AdminStudentInvitationsPage />}
        >
          <Route
            path={DegreelyRoutes.admin.invitations.student}
            element={<InvitationsIndex />}
          />
        </Route>
        <Route
          path={DegreelyRoutes.student.dashboard}
          element={<DashboardPage />}
        >
          <Route
            path={DegreelyRoutes.student.noSchedules}
            element={<NoSchedules />}
          />

          <Route
            path={DegreelyRoutes.student.schedule}
            element={<DashboardTracks />}
          />

          <Route
            path={DegreelyRoutes.student.schedules}
            element={<Schedules />}
          />
        </Route>
        <Route path={DegreelyRoutes.user.profile} element={<UserProfile />} />
        <Route path={'/404'} element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <CommonModals />
      <MessagesDrawer />
      <AlertToast />
    </React.Fragment>
  );
};
