import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourse } from '../../../../types/course';
import { ICourseStatusInformation } from '../../../../api/courses';
import { IValidationErrorResponse } from '../../../../types/error';

interface IState {
  isOpen: boolean;
  isEditMode: boolean;
  isLoading: boolean;
  status: ICourseStatusInformation;
  course: ICourse;
  customCourseDetails: {
    isLoading: boolean;
    name: string;
    code: string;
    units: number;
    error: IValidationErrorResponse;
  };
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  isEditMode: false,
  status: {
    courseId: -1,
    status: -1,
    termSeason: -1,
    termYear: -1,
  },
  customCourseDetails: {
    isLoading: false,
    name: '',
    code: '',
    units: 0,
    error: {
      message: '',
      errors: {},
    },
  },
  course: {
    isLocked: false,
    code: '',
    name: '',
    content: '',
    id: 0,
    difficulty: 0,
    credits: 0,
    category: '',
    termsOffered: [],
    gradeLevel: [],
    customDetailsEnabled: false,
    isRepeatable: false,
    isUserGenerated: false,
    requirementCategories: [],
    requirementCategory: {
      id: 0,
      color: '',
      major_id: 0,
      name: '',
      number_required: 0,
      planned: 0,
      unit: '',
      completed: 0,
      required: 0,
      updated_at: '',
      created_at: '',
    },
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  course: payload,
}));

reducer.on(actions.open, (state) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
  isEditMode: false,
  isLoading: false,
}));

reducer.on(actions.loadStatus.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.loadStatus.error, (state) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.loadStatus.done, (state, payload) => ({
  ...state,
  isLoading: false,
  status: payload,
}));

reducer.on(actions.addCustomCourseDetails.start, (state) => ({
  ...state,
  customCourseDetails: {
    ...state.customCourseDetails,
    isLoading: true,
  },
}));

reducer.on(actions.addCustomCourseDetails.error, (state, payload) => ({
  ...state,
  customCourseDetails: {
    ...state.customCourseDetails,
    isLoading: false,
    error: payload,
  },
}));

reducer.on(actions.addCustomCourseDetails.done, (state, payload) => ({
  ...state,
  isLoading: false,
  isEditMode: false,
  course: {
    ...state.course,
    name: payload.name,
    credits: payload.credits,
    code: payload.code,
    isLoading: false,
  },
}));

reducer.on(actions.updateCustomCourseDetails.done, (state, payload) => ({
  ...state,
  isLoading: false,
  isEditMode: false,
  course: {
    ...state.course,
    name: payload.name,
    credits: payload.credits,
    code: payload.code,
    isLoading: false,
  },
}));

reducer.on(actions.toggleEdit, (state) => ({
  ...state,
  isEditMode: !state.isEditMode,
}));

reducer.on(actions.onNameChange, (state, payload) => ({
  ...state,
  customCourseDetails: {
    ...state.customCourseDetails,
    name: payload,
    error: {
      errors: {},
      message: '',
    },
  },
}));

reducer.on(actions.onCourseCodeChange, (state, payload) => ({
  ...state,
  customCourseDetails: {
    ...state.customCourseDetails,
    code: payload,
    error: {
      errors: {},
      message: '',
    },
  },
}));

reducer.on(actions.onUnitChange, (state, payload) => ({
  ...state,
  customCourseDetails: {
    ...state.customCourseDetails,
    units: payload,
    error: {
      errors: {},
      message: '',
    },
  },
}));

export const courseDetailModalReducer = reducer;
