import { createAction } from 'redux-act';
import { IStudentAccountInvitation } from '../../../../api/admin/invites';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[pages/admin/student-invitations] fetch');

export const load = {
  start: createAction('[pages/admin/student-invitations] load.start'),
  error: createAction<any>('[pages/admin/student-invitations] load.error'),
  done: createAction<IStudentAccountInvitation[]>(
    '[pages/admin/student-invitations] load.done'
  ),
};

export const resend = {
  start: createAction<number>('[pages/admin/student-invitations] resend.start'),
  error: createAction<any>('[pages/admin/student-invitations] resend.error'),
  done: createAction('[pages/admin/student-invitations] resend.done'),
};

export const destroy = {
  start: createAction<number>(
    '[pages/admin/student-invitations] destroy.start'
  ),
  error: createAction<any>('[pages/admin/student-invitations] destroy.error'),
  done: createAction('[pages/admin/student-invitations] destroy.done'),
};
