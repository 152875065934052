import { AxiosPromise } from 'axios';
import { ICourse } from '../../types/course';
import client from '../axios.client';
import {courseFiltersAPI} from "./filters";
import {courseAvailabilityAPI} from "./availbility";
import { IPaginatedRequestParams, IPaginatedResponse } from '../common';

export interface ICourseFutureOccupancy {
  id: number;
  courseId: number;
  isCurrent: boolean;
  termSeason: number;
  termYear: number;
  plannedSlots: number;
  updatedAt: string;
  createdAt: string;
}

export interface ICourseOccupancyResponse {
  headers: {label: string; season: number; year: number;}[];
  data: ICourseOccupancyInfo[];
}

export interface ICourseOccupancyInfo {
  code: string;
  courseId: number;
  name: string;
  occupancies: ICourseOccupancy[];
}

export interface ICourseOccupancy {
  season: number;
  slots: number;
  year: number;
}

export interface ICourseStatusInformation {
  courseId: number;
  status: number;
  termSeason: number;
  termYear: number;
}

export interface ICreateCustomCourseDetailsPayload {
  scheduleId: number;
  courseId: number;
  name: string;
  units: number;
  code: string;
}

export interface IUserGeneratedCourseDetails {
  name: string;
  credits: number;
  code: string;
}

export interface ICourseInfoResponse {
  data: {
    course: {
      name: string;
      code: string;
      units: number;
      prerequisiteRequirements: {
        id: number;
        code: string;
      }[];
      concurrentRequirements: {
        id: number;
        code: string;
      }[];
    };
    majors: {
      id: number;
      abbreviatedName: string;
      name: string;
    }[];
    requirementCategory: string;
  };
}

export interface ICoursesBySchoolParams {
  schoolId: number;
  majorIds: number[];
  minorIds: number[];
  filters?: {
    grade: number[];
  };
  scheduleId?: number;
  search?: string;
  limit?: number;
}

export interface ICourseBinCoursesParams {
  schoolId: number;
  majorIds: number[];
  minorIds: number[];
  filters?: {
    grade: number[];
  };
  scheduleId?: number;
  search?: string;
  limit?: number;
}

export interface ICourseOccupanciesParams extends IPaginatedRequestParams {
  schoolId: number;
  filters?: {
    name: string;
    grades: number[];
    requirementCategories: number[];
    availableTerms: number[];
    availableYears: number[];
    sort: string;
    sortDirection: string;
  }
}

export interface IPaginatedCourseOccupanciesResponse extends IPaginatedResponse<ICourseOccupancyInfo> {
  headers: {label: string; season: number; year: number;}[];
}

export interface ICourses {
  getCourses(termId: number): AxiosPromise<ICourse[]>;
  getCourseById(courseId: number, scheduleId: number): AxiosPromise<ICourse>;
  getCourseInfo(courseId: number): AxiosPromise<ICourseInfoResponse>;
  getCoursesBySchool(params: ICoursesBySchoolParams): AxiosPromise<ICourse[]>;
  getCoursesByMajorId(majorId: number): AxiosPromise<ICourse[]>;
  getFutureOccupanciesByCourseId(
    courseId: number
  ): AxiosPromise<ICourseFutureOccupancy[]>;
  getCourseOccupanciesByMajorId(
    majorId: number
  ): AxiosPromise<ICourseOccupancyResponse>;
  getCourseOccupanciesBySchoolId(
    params: ICourseOccupanciesParams
  ): AxiosPromise<IPaginatedCourseOccupanciesResponse>;
  getCourseStatus(
    courseId: number,
    termId: number
  ): AxiosPromise<ICourseStatusInformation>;
  createCustomCourseDetails(
    payload: ICreateCustomCourseDetailsPayload
  ): AxiosPromise<IUserGeneratedCourseDetails>;
  updateCustomCourseDetails(
    payload: ICreateCustomCourseDetailsPayload
  ): AxiosPromise<IUserGeneratedCourseDetails>;
  getCourseBinCourses(params: ICourseBinCoursesParams): AxiosPromise<ICourse[]>;
}

class CoursesAPI implements ICourses {

  filters = courseFiltersAPI;
  availability = courseAvailabilityAPI;

  public getCourses(termId: number = 1): AxiosPromise<ICourse[]> {
    return client.get(`/api/courses`, {
      params: {
        termId,
      },
    });
  }

  getCourseById(courseId: number, scheduleId?: number): AxiosPromise<ICourse> {
    return client.get(`/api/courses/${courseId}`, {
      params: {
        schedule_id: scheduleId,
      },
    });
  }

  public getCoursesByMajorId(
    majorId: number = 1,
    search: string = ''
  ): AxiosPromise<ICourse[]> {
    return client.get(`/api/majors/${majorId}/courses`, {
      params: {
        majorId,
        search,
      },
    });
  }

  getFutureOccupanciesByCourseId(
    courseId: number
  ): AxiosPromise<ICourseFutureOccupancy[]> {
    return client.get(`/api/courses/${courseId}/future-occupancy`);
  }

  getCourseOccupanciesByMajorId(
    majorId: number
  ): AxiosPromise<ICourseOccupancyResponse> {
    return client.get(`/api/majors/${majorId}/courses/occupancies`);
  }

  getCourseOccupanciesBySchoolId(
    params: ICourseOccupanciesParams
  ): AxiosPromise<IPaginatedCourseOccupanciesResponse> {
    return client.get(`/api/schools/${params.schoolId}/courses/occupancies`, {
      params: {
        page: params.page,
        ...params.filters
      }
    });

  }

  getCoursesBySchool(params: ICoursesBySchoolParams): AxiosPromise<ICourse[]> {
    return client.get(`/api/schools/${params.schoolId}/courses`, {
      params: {
        majorIds: params.majorIds,
        minorIds: params.minorIds,
        scheduleId: params.scheduleId ?? 0,
        search: params.search ?? '',
        limit: params.limit ?? 100,
        grade: params?.filters?.grade ?? [],
      },
    });
  }

  getCourseStatus(
    courseId: number,
    termId: number
  ): AxiosPromise<ICourseStatusInformation> {
    return client.get('/api/terms/courses/status', {
      params: {
        courseId,
        termId,
      },
    });
  }

  createCustomCourseDetails(
    payload: ICreateCustomCourseDetailsPayload
  ): AxiosPromise<IUserGeneratedCourseDetails> {
    return client.post(`/api/courses/${payload.courseId}/details`, {
      schedule_id: payload.scheduleId,
      name: payload.name,
      units: payload.units,
      code: payload.code,
    });
  }

  updateCustomCourseDetails(
    payload: ICreateCustomCourseDetailsPayload
  ): AxiosPromise<IUserGeneratedCourseDetails> {
    return client.put(`/api/courses/${payload.courseId}/details`, {
      schedule_id: payload.scheduleId,
      name: payload.name,
      units: payload.units,
      code: payload.code,
    });
  }

  getCourseInfo(courseId: number): AxiosPromise<ICourseInfoResponse> {
    return client.get(`/api/admin/courses/${courseId}/info`);
  }

  getCourseBinCourses(
    params: ICourseBinCoursesParams
  ): AxiosPromise<ICourse[]> {
    return client.get(`/api/courses/bin`, {
      params: {
        majorIds: params.majorIds,
        minorIds: params.minorIds,
        scheduleId: params.scheduleId ?? 0,
        search: params?.search ?? '',
        limit: params.limit ?? -1,
        grade: params?.filters?.grade ?? [],
      },
    });
  }
}

export const courses = new CoursesAPI();
