import { combineEpics, ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import * as actions from './actions';
import { IEpic } from '../../infrastructure/selector';
import { ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router';
import { parse } from 'query-string';
import { of } from 'rxjs';
import * as paths from '../routes/paths';
import { matchPath } from 'react-router-dom';
import GoogleAnalytics from '../../analytics/index';


const setOnClientSideRoute: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(ROUTER_ON_LOCATION_CHANGED),
    map(( { payload }) => actions.route.change(payload)),
  );
const set: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.route.change),
    mergeMap((action) => {
      const {
        router: { location },
      } = state$.value;

      const matches: any = Object.keys(paths)
        .map((key: string) => {
          // @ts-ignore
          let pattern = paths[key];
          return matchPath(pattern, location.pathname);
        })
        .filter(Boolean);

      let match = matches[0];

      if (!match) {
        try {
          match = {
            params: {},
            url: window.location.pathname,
          };
        } catch {}
      }

      let hash = window.location.hash;
      let href = window.location.href;
      let hostname = window.location.hostname;
      let path = window.location.pathname;
      let query = window.location.search;

      GoogleAnalytics.PageView.addPageViewEvent({
        url: href,
        hostname: hostname,
        path: path,
        query: query,
      });

      return of(
        actions.route.set({
          ...match,
          query: parse(location.search),
          hash,
          href,
          hostname,
        })
      );
    })
  );

export const epic = combineEpics(set, setOnClientSideRoute);
