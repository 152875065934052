export default {
  blueDarkest: '#DC2626',
  blue: '#144FC2',
  green: '#16A34A',
  'green-lighter': '#BBF7D0',
  'green-lightest': '#F0FFF2',
  'purple-light': '#E5E7F4',
  purple: '#782BD9',
  babyBlueLightest: '#FFE6EE',
  babyBlueLighter: '#FECACA',
  babyBlue: '#DC2626',
  red: '#DC2626',
  'red-lighter': '#FECACA',
  'red-lightest': '#FFE6EE',
  'neutral-lightest': '#F8F8F8',
  'neutral-lighter': '#F1F1F1',
  'neutral-light': '#CFCFCF',
  neutral: '#8F8F8F',
  'neutral-dark': '#676767',
  'neutral-darker': '#F1F2F4',
  'neutral-darkest': '#040404',
  'yellow-dark': '#CA8A04',
  yellow: '#FFBF08',
  'yellow-lighter': '#FEF08A',
  'yellow-lightest': '#FFF6DC',
  primary: '#DC2626',
  secondary: '#0F2D4D',
  tertiary: '#040404',
};
