import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ISchedule } from '../../../api/schedules';

interface IState {
  unitsRequired: number;
  planned: number;
  completed: number;
  unplanned: number;
}

const initialState: IState = {
  planned: 0,
  completed: 0,
  unplanned: 0,
  unitsRequired: 0,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.update, (state, payload) => ({
  ...state,
  ...payload,
}));

export const progressReducer = reducer;
