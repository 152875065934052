import { AxiosPromise } from 'axios';
import client from '../axios.client';

export interface IUserAPI {
  getUser(): AxiosPromise<IUser>;
  uploadAvatar(file: any): AxiosPromise<string>;
  updateAvatarUrl(avatarUrl: string): AxiosPromise<IUser>;
}

export interface IUser {
  id: number;
  schoolId: number;
  name: string;
  email: string;
  emailVerifiedAt: string;
  startYear: number;
  startSeason: number;
  studentId: string;
  cohortNumber: string;
  roleId: number;
  createdAt: string;
  updatedAt: string;
  loggedInAt: string;
  activeScheduleId: number | null;
  avatarUrl: string;
  completedProfile: boolean;
  isStudent: boolean;
  isAdmin: boolean;
}

class UserAPI implements IUserAPI {
  public getUser(): AxiosPromise<IUser> {
    return client.get(`/api/user`);
  }

  public uploadAvatar(file: any): AxiosPromise<string> {
    return client.post(
      '/api/avatar/upload',
      {
        file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  updateAvatarUrl(avatarUrl: string): AxiosPromise<IUser> {
    return client.put(`/api/user/avatar`, {
      avatarUrl,
    });
  }
}

export const user = new UserAPI();
