import React from 'react';
import { BiUser } from '@react-icons/all-files/bi/BiUser';

export interface IUserAvatarProps {
  size: number;
  imageUrl: string;
  countableIdentifier?: number;
  includeNotificationIdentifier?: boolean;
  renderEmptyAvatar?: () => React.ReactNode;
  handleOnClick?: () => any
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({
  imageUrl,
  size,
  countableIdentifier,
  includeNotificationIdentifier,
  renderEmptyAvatar,
  handleOnClick,
}) => {
  return (
    <>
      {!!imageUrl && (
        <div
          onClick={() => handleOnClick && handleOnClick()}
          style={{ height: size, width: size }}
          className={
            'cursor-pointer rounded-full bg-neutral-lighter flex items-center justify-center relative'
          }
        >
          <img
            height={size}
            width={size}
            className={'rounded-full'}
            src={imageUrl}
            alt=""
          />
          {includeNotificationIdentifier && !countableIdentifier && (
            <div
              className={
                'absolute top-0 right-0 bg-red rounded-full w-3 h-3 shadow flex items-center justify-center text-baby-blue text-sm font-semibold'
              }
            ></div>
          )}
        </div>
      )}
      {!imageUrl && !renderEmptyAvatar && (
        <div
          onClick={() => handleOnClick && handleOnClick()}
          style={{ height: size, width: size }}
          className={
            'rounded-full bg-neutral-lighter flex items-center justify-center relative'
          }
        >
          <BiUser className={'text-xl fill-neutral'} />
          {!!countableIdentifier && (
            <div
              className={
                'absolute -top-1.5 -right-0 bg-white rounded-full w-6 h-6 shadow flex items-center justify-center text-baby-blue text-sm font-semibold'
              }
            >
              {countableIdentifier}
            </div>
          )}
          {includeNotificationIdentifier && !countableIdentifier && (
            <div
              className={
                'absolute -top-1 -right-1 bg-red rounded-full w-2 h-2 shadow flex items-center justify-center text-baby-blue text-sm font-semibold'
              }
            ></div>
          )}
        </div>
      )}
      {!imageUrl && !!renderEmptyAvatar && renderEmptyAvatar()}
    </>
  );
};

export interface INavUserAvatarProps {
  name: string;
  imageUrl: string;
  size: number;
}

export const NavUserAvatar: React.FC<INavUserAvatarProps> = ({
  name,
  imageUrl,
  size,
}) => {
  let firstNameFirstLetter = '';
  let lastNameFirstLetter = '';
  let splitName = name.split(' ');

  if (splitName.length == 1) {
    let firstName = splitName[0];
    firstNameFirstLetter = firstName.charAt(0);
  }
  if (splitName.length == 2) {
    let firstName = splitName[0];
    let lastName = splitName[1];

    firstNameFirstLetter = firstName.charAt(0);
    lastNameFirstLetter = lastName.charAt(0);
  }

  return (
    <>
      {!!imageUrl && (
        <img
          height={size}
          width={size}
          className={'rounded-full'}
          src={imageUrl}
          alt=""
        />
      )}
      {!imageUrl && (
        <div
          className={
            'bg-neutral-lighter text-neutral py-3 px-3 text-xxs rounded-lg font-medium tracking-wider'
          }
        >
          {firstNameFirstLetter}
          {lastNameFirstLetter}
        </div>
      )}
    </>
  );
};

UserAvatar.defaultProps = {
  countableIdentifier: 0,
  includeNotificationIdentifier: false,
};
