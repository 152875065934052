import React from 'react';

export enum ProgressStatus {
  Danger,
  Warning,
  Success,
}

interface IProgressBarProps {
  label: string;
  progressionPercentage: number;
  status: ProgressStatus;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({
  progressionPercentage,
  label,
  status,
}) => {
  let barStyles =
    'absolute h-1 top-0 left-0 bg-baby-blue rounded-full transition-width ease-in-out duration-200';

  if (label.toLowerCase() == 'planned' && status == ProgressStatus.Danger) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-red-lightest rounded-full transition-width ease-in-out duration-200';
  }

  if (label.toLowerCase() == 'planned' && status == ProgressStatus.Warning) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-yellow-lightest rounded-full transition-width ease-in-out duration-200';
  }

  if (label.toLowerCase() == 'planned' && status == ProgressStatus.Success) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-green-lightest rounded-full transition-width ease-in-out duration-200';
  }

  if (label.toLowerCase() == 'completed' && status == ProgressStatus.Danger) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-red rounded-full transition-width ease-in-out duration-200';
  }

  if (label.toLowerCase() == 'completed' && status == ProgressStatus.Warning) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-yellow rounded-full transition-width ease-in-out duration-200';
  }

  if (label.toLowerCase() == 'completed' && status == ProgressStatus.Success) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-green rounded-full transition-width ease-in-out duration-200';
  }

  if (label.toLowerCase() == 'in progress' && status == ProgressStatus.Danger) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-red-lighter rounded-full transition-width ease-in-out duration-200';
  }

  if (
    label.toLowerCase() == 'in progress' &&
    status == ProgressStatus.Warning
  ) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-yellow-lighter rounded-full transition-width ease-in-out duration-200';
  }

  if (
    label.toLowerCase() == 'in progress' &&
    status == ProgressStatus.Success
  ) {
    barStyles =
      'absolute h-1 top-0 left-0 bg-green-lighter rounded-full transition-width ease-in-out duration-200';
  }

  if (progressionPercentage > 100) {
    progressionPercentage = 100;
  }

  return (
    <div className={barStyles} style={{ width: progressionPercentage + '%' }}>
      <div className={'relative w-full h-full'}></div>
    </div>
  );
};
