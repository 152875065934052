import React from "react";
import {Edit} from "../../../icons/edit";
import { InterestsModal } from './modal';
import { UserProfileInterestFields } from './fields';
import * as interestModalActions from './modal/actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../infrastructure/selector';
import Skeleton from 'react-loading-skeleton';

export const UserProfileInterests: React.FC = () => {

    const dispatch = useDispatch();

  const { english, math, performingArts, studioArts, history, science, athletics, isLoading } = useSelector(
    (state) => ({
      isLoading: state.pages.studentProfile.interests.modal.isLoading,
      athletics: state.pages.studentProfile.interests.modal.athletics,
      english: state.pages.studentProfile.interests.modal.english,
      history: state.pages.studentProfile.interests.modal.history,
      math: state.pages.studentProfile.interests.modal.math,
      performingArts: state.pages.studentProfile.interests.modal.performingArts,
      science: state.pages.studentProfile.interests.modal.science,
      studioArts: state.pages.studentProfile.interests.modal.studioArts,
    }),
    shallowEqual
  );

  if(isLoading) {
    return null;
  }

    return (
        <div className={"mt-10"}>
            <div className={"flex items-center"}>
                <h3 className={"text-neutral-darkest text-2xl mr-2 font-medium"}>Your Interests</h3>
                <Edit classes={"text-baby-blue cursor-pointer"} onClick={() => dispatch(interestModalActions.open())} />
            </div>
            <UserProfileInterestFields
              isEditable={false}
              values={{
                english,
                math,
                performingArts,
                studioArts,
                history,
                science,
                athletics
            }} />
            <InterestsModal />
        </div>
    )
}
