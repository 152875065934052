import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../infrastructure/selector';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const CourseInfo: React.FC = () => {
  const dispatch = useDispatch();

  const { course, isLoading, majors, requirementCategory, url } = useSelector(
    (state) => ({
      url: state.common.match.url,
      course: state.pages.admin.dashboard.course.info.course,
      majors: state.pages.admin.dashboard.course.info.majors,
      requirementCategory:
        state.pages.admin.dashboard.course.info.requirementCategory,
      isLoading: state.pages.admin.dashboard.course.info.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [url]);

  if (isLoading) {
    return (
      <>
        <Skeleton height={30} width={'30%'} />
        <Skeleton className={'mt-2'} width={'10%'} />
        <Skeleton width={'10%'} />
        <div>
          <Skeleton inline={true} width={'5%'} className={'mt-8'} />
          <Skeleton inline={true} width={'10%'} className={'ml-3'} />
          <Skeleton inline={true} width={'5%'} className={'ml-3'} />
          <Skeleton inline={true} width={'20%'} className={'ml-3'} />
        </div>
      </>
    );
  }

  return (
    <>
      <section className={'flex justify-between'}>
        <div>
          <h1 className={'text-3xl text-neutral-darkest'}>{course.name}</h1>
          <div className={'flex items-center mt-1'}>
            <div
              className={
                'bg-neutral-lighter w-fit rounded-sm mr-3 flex items-center px-3 py-1'
              }
            >
              <span
                className={
                  'text-neutral-darkest text-xxs font-semibold relative'
                }
              >
                {course.code}
              </span>
            </div>
            <div
              className={
                'bg-neutral-lighter w-fit rounded-sm mr-3 flex items-center px-3 py-1'
              }
            >
              <span
                className={
                  'text-neutral-darkest text-xxs font-semibold relative'
                }
              >
                {course.units} Units
              </span>
            </div>
            {!!majors.length &&
              majors.map((major: any) => (
                <div
                  className={
                    'bg-baby-blue-lightest w-fit rounded-sm mr-3 flex items-center px-3 py-1'
                  }
                >
                  {/*<span className={"text-neutral-darkest text-xxs font-semibold relative pr-1"}>*/}
                  {/*    {major.abbreviatedName}*/}
                  {/*</span>*/}
                  <span
                    className={
                      'text-neutral-darkest text-xxs font-semibold relative'
                    }
                  >
                    {major.requirementCategory}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className={'flex flex-col border-dashed border p-4 rounded-lg'}>
          <div className={'flex items-center'}>
            <span className={'font-bold text-neutral-light text-sm mr-2'}>
              Pre Reqs:
            </span>
            <span className={'text-neutral-light text-sm'}>
              {!!course.prerequisiteRequirements.length
                ? course.prerequisiteRequirements.map((req: any) => (
                    <Link
                      className={'underline ml-2'}
                      to={`/admin/dashboard/courses/${req.id}`}
                    >
                      {req.code}
                    </Link>
                  ))
                : 'N/A'}
            </span>
          </div>
          <div className={'flex items-center'}>
            <span className={'font-bold text-neutral-light text-sm mr-2'}>
              Co Reqs:
            </span>
            <span className={'text-neutral-light text-sm'}>
              {!!course.concurrentRequirements.length
                ? course.concurrentRequirements.map((req: any) => (
                    <Link
                      className={'underline ml-2'}
                      to={`/admin/dashboard/courses/${req.id}`}
                    >
                      {req.code}
                    </Link>
                  ))
                : 'N/A'}
            </span>
          </div>
          <div className={'flex items-center'}>
            <span className={'font-bold text-neutral-light text-sm mr-2'}>
              Notes:
            </span>
            <span className={'text-neutral-light text-sm'}>
              Enrollment in this class is limited to only{' '}
              {majors.map((major: any) => major.abbreviatedName)} students
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
