import { AxiosPromise } from 'axios';
import client from '../axios.client';

export interface IModulesAPI {
  getModules(section: string): AxiosPromise<any[]>; // TODO: Type this
}

class ModulesAPI implements IModulesAPI {
  public getModules(section: string): AxiosPromise<any[]> {
    return client.get(`/api/modules`, {
      params: {
        section,
      },
    });
  }
}

export const modules = new ModulesAPI();
