import React, { useEffect, useState } from 'react';
import { IScheduleTermGroup } from '../../../api/schedules';
import { IScheduleTerm } from '../../../api/schedule-terms';
import { termSeasonService } from '../../../services/term-season/TermSeasonService';
import { YearPillar } from './year-pillar';
import { useSpring, animated } from 'react-spring';
import { Group } from './group';

interface ITermGroupProps {
    groupIndex: number;
    group: IScheduleTermGroup;
    isDraggable: boolean;
    displayTermCost: boolean;
    handleOnRemoveTerm: (termId: number) => any;
}

export const TermGroup: React.FC<ITermGroupProps> = ({ displayTermCost, group, groupIndex, isDraggable, handleOnRemoveTerm }) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(group.isCompleted);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    let containerWidth =
      document.getElementById(`term-container-${groupIndex}`)?.clientWidth ?? 0;
    if (!!containerWidth) {
      setWidth(containerWidth);
    }
  }, [group]);

  const seasonAndYearLabels = group.list.map((term: IScheduleTerm) => {
    return termSeasonService.getSeasonAsString(term.season) + ' ' + term.year;
  });

  let containerAnimation = useSpring({
    display: 'flex',
    width: !isCollapsed ? width : 0,
  });
  let termContainerAnimation = useSpring({
    display: 'flex',
    overflow: 'hidden',
    width: !isCollapsed ? width : 0,
    onRest: {
      width: (width) => setIsHidden(!width.value),
    },
  });

  useEffect(() => {
    setWidth(
      document.getElementById(`term-container-${groupIndex}`)?.clientWidth ?? 0
    );
    setIsHidden(!width);
    if (!!width) {
      setIsHidden(group.isCompleted);
    }
  }, []);

  return (
    <>
      <YearPillar
        isCompleted={group.isCompleted}
        isCollapsed={isCollapsed}
        title={group.name}
        labels={seasonAndYearLabels}
        onClick={() => {
          if (isCollapsed) {
            setIsHidden(false);
          }
          if (!isCollapsed) {
            setWidth(
              document.getElementById(`term-container-${groupIndex}`)?.clientWidth ??
                0
            );
          }
          setIsCollapsed(!isCollapsed);
        }}
      />

            <animated.div style={containerAnimation}>
                {!isHidden && <animated.div style={termContainerAnimation}>
                    <div className={"flex w-auto"} id={`term-container-${groupIndex}`}>
                        {group.list.map((term: IScheduleTerm, termIndex: number) =>
                            <Group
                                groupIndex={groupIndex}
                                handleOnRemoveTerm={handleOnRemoveTerm}
                                displayTermCost={displayTermCost}
                                isDraggable={isDraggable}
                                termIndex={termIndex}
                                isLastInGroup={termIndex == group.list.length - 1}
                                term={term}
                            />)}
                    </div>
                </animated.div>}
            </animated.div>

        </>
    )
}

TermGroup.defaultProps = {};
