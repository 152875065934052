import React from 'react';
import { AuthHeader } from '../../../../shared/header';
import { Input } from '../../../../../../components/input';
import * as actions from '../actions';
import { Button } from '../../../../../../components/button';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';

export const ForgotPasswordEmailInput: React.FC = () => {
  const { isLoading, email, error } = useSelector(
    (state) => ({
      error: state.pages.auth.modals.forgotPassword.error,
      email: state.pages.auth.modals.forgotPassword.email,
      isLoading: state.pages.auth.modals.forgotPassword.isLoading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  return (
    <>
      <div className={'w-full'}>
        <AuthHeader
          title={'Forgot your password?'}
          subtitle={
            'If you have an account a password reset link will be sent to your email.'
          }
        />
      </div>
      <div className="flex items-center justify-center">
        <div className={'w-10/12'}>
          <Input
            onChange={(e) => dispatch(actions.onChange(e.target.value))}
            name={'email'}
            label={'Email'}
            type={'text'}
            error={error}
            value={email}
            required={true}
            placeholder={'enter email'}
          />
          <div className={'flex justify-end text-right w-full mt-4'}>
            <Button
              className={
                'text-sm block bg-blue-darkest font-semibold rounded-md px-14 py-3'
              }
              handleOnClick={(e) => dispatch(actions.fetch())}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
