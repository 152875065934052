import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IUserState {
  isLoading: boolean;
  universityDomain: string;
}

const initialState: IUserState = {
  isLoading: false,
  universityDomain: 'app',
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  universityDomain: payload,
}));

export const domainConfigReducer = reducer;
