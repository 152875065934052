import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof onChange>

export const onChange = createAction<string>(
  '[pages/admin/dashboard/settings/courses/search] onChange'
);

export const reset = createAction('[pages/admin/dashboard/settings/courses/search] reset');
