import { THEME_KEYS } from '../../infrastructure/themes';

export interface IThemeDetectorService {
  detectTheme(host: string): string;
}

class ThemeDetectorService implements IThemeDetectorService {
  detectTheme(host: string): string {
    const splitHost = host.split('.');

    const subdomain = splitHost[0];

    let themeKey: string = THEME_KEYS.base;

    // TODO: Remove usc once we are legally allowed to use
    if (
      subdomain != 'app' &&
      subdomain != 'usc' &&
      THEME_KEYS.hasOwnProperty(subdomain)
    ) {
      themeKey = THEME_KEYS[subdomain];
    }

    return themeKey;
  }
}

export const themeDetectorService = new ThemeDetectorService();
