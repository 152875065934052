import { createAction } from "redux-act";
import {IValidationErrorResponse} from "../../../../../types/error";
import {ISchedule} from "../../../../../api/schedules";

export type IActions =
    | ReturnType<typeof load.start>
    | ReturnType<typeof load.error>
    | ReturnType<typeof load.done>
    | ReturnType<typeof removeTerm.start>
    | ReturnType<typeof removeTerm.error>
    | ReturnType<typeof removeTerm.done>
    | ReturnType<typeof addTerm.start>
    | ReturnType<typeof addTerm.error>
    | ReturnType<typeof addTerm.done>;

export const load = {
    start: createAction("[pages/admin/dashboard/student/schedule] load.start"),
    error: createAction<any>("[pages/admin/dashboard/student/schedule] load.error"),
    done: createAction<ISchedule>("[ppages/admin/dashboard/student/schedule] load.done"),
}

export const addTerm = {
    start: createAction<number>("[pages/admin/dashboard/student/schedule] addTerm.start"),
    error: createAction<any>("[pages/admin/dashboard/student/schedule] addTerm.error"),
    done: createAction("[ppages/admin/dashboard/student/schedule] addTerm.done"),
}

export const removeTerm = {
    start: createAction<number|string>("[pages/admin/dashboard/student/schedule] removeTerm.start"),
    error: createAction<IValidationErrorResponse>("[pages/admin/dashboard/student/schedule] removeTerm.error"),
    done: createAction("[pages/admin/dashboard/student/schedule] removeTerm.done"),
}
