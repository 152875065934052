import React from 'react';

export enum BadgeVariants {
  Success,
  Warning,
  Danger,
  Neutral,
  Info,
}

interface IBadgeProps {
  variant: BadgeVariants;
  children: any;
}

export const Badge: React.FC<IBadgeProps> = ({ variant, children }) => {
  let classNames = 'py-1 px-2 rounded-md font-semibold text-xs';

  if (variant == BadgeVariants.Success) {
    classNames += ' bg-green-lighter text-green';
  }

  if (variant == BadgeVariants.Warning) {
    classNames += ' bg-yellow-lighter text-yellow-dark';
  }

  if (variant == BadgeVariants.Danger) {
    classNames += ' bg-red-lighter text-red';
  }

  if (variant == BadgeVariants.Info) {
    classNames += ' bg-baby-blue-lightest text-baby-blue';
  }

  if (variant == BadgeVariants.Neutral) {
    classNames += ' bg-neutral-dark text-white';
  }

  return <div className={classNames}>{children}</div>;
};
