import React from "react";
import {useSelector} from "../../../../infrastructure/selector";
import {shallowEqual} from "react-redux";

export const UserProfileName: React.FC = () => {

    const { user } = useSelector(
        (state) => ({
            user: state.common.user.info
        }),
        shallowEqual
    );

    return (
        <div className={"text-center mt-6"}>
            <h1 className={"text-baby-blue text-4xl font-semibold"}>{user?.name}</h1>
        </div>
    )
}