import React, { useEffect } from 'react';
import { UserProfileInterestFields } from '../../../../../student-profile/interests/fields';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from "./actions";

export const AdminStudentProfileInterests: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, english, history, math, science, performingArts, athletics, studioArts, studentName } = useSelector(
    (state) => ({
      isLoading: state.pages.admin.dashboard.student.profile.interests.isLoading,
      studentName: state.pages.admin.dashboard.student.info.details.name,
      english: state.pages.admin.dashboard.student.profile.interests.english,
      history: state.pages.admin.dashboard.student.profile.interests.history,
      math: state.pages.admin.dashboard.student.profile.interests.math,
      science: state.pages.admin.dashboard.student.profile.interests.science,
      athletics: state.pages.admin.dashboard.student.profile.interests.athletics,
      performingArts: state.pages.admin.dashboard.student.profile.interests.performingArts,
      studioArts: state.pages.admin.dashboard.student.profile.interests.studioArts,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [studentName]);

  if(isLoading) {
    return null;
  }

  return (
    <div className={"mt-8"}>
      <h2 className={"text-2xl text-neutral-dark font-semibold mb-1"}>{studentName}'s Interests</h2>
      <hr className={"border-b border-baby-blue mb-10"} />

      <UserProfileInterestFields values={{
        english,
        history,
        math,
        science,
        performingArts,
        athletics,
        studioArts
      }} isEditable={false} />
    </div>
  )
}
