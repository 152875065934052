import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { IUser } from '../user';

export interface IMessage {
  id: number;
  body: string;
  createdAt: string;
  sender: IUser;
  recipient: IUser;
  readAt: string;
}

export interface IMessageThread {
  id: number;
  name: string;
  recipient: IUser;
  sender: IUser;
  hasUnreadMessages: boolean;
  latestMessage: {
    body: string;
    sentAt: string;
  };
}

export interface ICreateMessageThreadPayload {
  recipientId: number;
  scheduleId: number;
  body: string;
}

export interface IMessagesAPI {
  getAllMessageThreads(search: string): AxiosPromise<IMessageThread[]>;
  createMessageThread(
    payload: ICreateMessageThreadPayload
  ): AxiosPromise<IMessageThread>;
  getMessageThreadMessages(threadId: number): AxiosPromise<IMessageThread>;
  sendMessage(threadId: number, body: string): AxiosPromise<IMessageThread>;
  markMessageThreadAsRead(threadId: number): AxiosPromise<IMessageThread>;
  getAllUnreadMessages(): AxiosPromise<IMessage[]>;
}

class MessagesAPI implements IMessagesAPI {
  getAllMessageThreads(search: string = ""): AxiosPromise<IMessageThread[]> {
    return client.get(`/api/message-threads`, {
      params: {
        name: search,
      }
    });
  }
  createMessageThread(
    payload: ICreateMessageThreadPayload
  ): AxiosPromise<IMessageThread> {
    return client.post('/api/message-threads', {
      recipient_id: payload.recipientId,
      schedule_id: payload.scheduleId,
      body: payload.body,
    });
  }

  getMessageThreadMessages(threadId: number): AxiosPromise<IMessageThread> {
    return client.get(`/api/message-threads/${threadId}/messages`);
  }

  sendMessage(threadId: number, body: string): AxiosPromise<IMessageThread> {
    return client.post(`/api/message-threads/${threadId}/messages`, {
      body,
    });
  }

  markMessageThreadAsRead(threadId: number): AxiosPromise<IMessageThread> {
    return client.put(`/api/message-threads/${threadId}/read`);
  }

  getAllUnreadMessages(): AxiosPromise<IMessage[]> {
    return client.get(`/api/messages/unread`);
  }
}

export const messages = new MessagesAPI();
