import React, {useEffect} from "react";
import {api} from "../../api";
import {Helpers} from "../../helpers";
import {useParams} from "react-router-dom";
import querystring from "query-string";

export const DegreelyIndex: React.FC = () => {

    useEffect(() => {

        const params = querystring.parse(window.location.search);
        if(params.state) {
            //@ts-ignore
            let url = localStorage.getItem(params.state) ?? "";
            //@ts-ignore
            localStorage.removeItem(params.state);
            window.location.href = url;
        } else {
            api.user.getUser().then(({ data }) => {
                Helpers.redirectToDashboard(data);
            });
        }

    }, []);

    return (
    <></>
  );
}
