import { createAction } from 'redux-act';
import { ICourseFilters } from './reducer';
import {IFilter} from "../../../../../types/filter";
import {SortDirection} from "../../../../../components/sort-by-direction";

export type IActions = ReturnType<typeof apply>;

export const apply = createAction<ICourseFilters>(
  '[pages/admin/common/filters/courses] apply'
);

export const fetch = createAction(
  '[pages/admin/common/filters/courses] fetch'
);

export const load = {
  start: createAction('[pages/admin/common/filters/courses] load.start'),
  error: createAction<any>('[pages/admin/common/filters/courses] load.error'),
  done: createAction<any>('[pages/admin/common/filters/courses] load.done'),
};

export const handleFilter = createAction<{ key: string; filter: IFilter }>(
  '[pages/admin/common/filters/courses] handleFilter'
);

export const handleSortDirection = createAction<SortDirection>(
    '[pages/admin/common/filters/courses] handleSortDirection'
);
