import base from './base';
import usc from './schools/usc';
import sonoma from './schools/sonoma';
import { IThemes } from './utils';

// @key subdomain
// @value theme object
export const THEME_KEYS: { [key: string]: string } = {
  base: 'base',
  usc: 'usc',
  sa: 'sonoma', // Sonoma Academy
};

export const themes: IThemes = {
  base,
  usc,
  sonoma,
};
