import React, { useEffect } from 'react';
import { Layout } from '../../shared/layout';
import { shallowEqual, useDispatch } from 'react-redux';
import {UserProfileAvatar} from "../profile/shared/avatar";
import {UserProfileName} from "../profile/shared/name";
import {UserProfileAboutYou} from "./about-you";
import {UserProfileInterests} from "./interests";
import {UserProfileAccountSettings} from "./account-settings";
import * as actions from "./actions";
import { useSelector } from '../../infrastructure/selector';
export const StudentProfile: React.FC = () => {

  const dispatch = useDispatch();

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info
    }),
    shallowEqual
  );

  useEffect(() => {
    if(user?.id) {
      dispatch(actions.fetch());
    }
  }, [user?.id]);

  return (
    <>
      <Layout>
        <section className={'py-8 px-16 relative '}>
          <div className={'bg-white rounded-sm p-10'}>
            <h3 className={'text-2xl text-neutral-darkest font-semibold mb-3'}>
              Profile & Settings
            </h3>
            <hr />
            <UserProfileAvatar />
            <UserProfileName />
            <div className={"w-2/3 mt-8 mx-auto"}>
              <UserProfileAboutYou />
              <UserProfileInterests />
              <UserProfileAccountSettings />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
