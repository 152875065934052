import React from 'react';
import { ProgressBar, ProgressStatus } from './progress-bar';
import { IProgression } from '../../../api/students';
import { ProgressionPercentageThresholds } from '../../../constants';

export interface IStudentProgressionProps {
  progression: IProgression;
}

export const StudentProgression: React.FC<IStudentProgressionProps> = ({
  progression,
}) => {
  let baseStyles = 'bg-neutral w-full h-1 rounded-full relative mt-3';
  let progressStatus = ProgressStatus.Danger;

  if (progression.percentCompleted <= ProgressionPercentageThresholds.Danger) {
    progressStatus = ProgressStatus.Danger;
    baseStyles = 'bg-red-lightest w-full h-1 rounded-full relative mt-3';
  }
  if (
    progression.percentCompleted > ProgressionPercentageThresholds.Danger &&
    progression.percentCompleted <= ProgressionPercentageThresholds.Warning
  ) {
    progressStatus = ProgressStatus.Warning;
    baseStyles = 'bg-yellow-lightest w-full h-1 rounded-full relative mt-3';
  }
  if (progression.percentCompleted > ProgressionPercentageThresholds.Warning) {
    progressStatus = ProgressStatus.Success;
    baseStyles = 'bg-green-lightest w-full h-1 rounded-full relative mt-3';
  }

  return (
    <>
      <div className={'flex items-center justify-between'}>
        <span
          className={'font-medium text-xxs text-neutral-dark -mb-2'}
        >{`${progression.percentCompleted}% Completed`}</span>
        <span
          className={'font-medium text-xxs text-neutral-dark -mb-2'}
        >{`${progression.percentInProgress}% In Progress`}</span>
        <span
          className={'font-medium text-xxs text-neutral-dark -mb-2'}
        >{`${progression.percentPlanned}% Planned`}</span>
      </div>
      <div className={baseStyles}>
        {progression.percentPlanned > 0 &&
          progression.percentPlanned > progression.percentCompleted && (
            <ProgressBar
              status={progressStatus}
              label={'Planned'}
              progressionPercentage={progression.percentPlanned}
            />
          )}
        {progression.percentInProgress > 0 && (
          <ProgressBar
            status={progressStatus}
            label={'In Progress'}
            progressionPercentage={
              progression.percentCompleted + progression.percentInProgress
            }
          />
        )}
        {progression.percentCompleted > 0 && (
          <ProgressBar
            status={progressStatus}
            label={'Completed'}
            progressionPercentage={progression.percentCompleted}
          />
        )}
      </div>
    </>
  );
};
