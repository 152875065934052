import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IAvailableSeason, IGradeLevel } from '../../../api/schools';
import { ISelectOption } from '../../../ui/select';

interface IState {
  account: {
    key: string;
    name: string;
    tier: {
      degreeManagementModuleAccess: boolean;
      messagingModuleAccess: boolean;
      termPlanningModuleAccess: boolean;
      registrationModuleAccess: boolean;
    }
  },
  multipleStudentSchedulesEnabled: boolean;
  isLoading: boolean;
  availableSeasons: IAvailableSeason[];
  schoolConfigurationId: number;
  schoolId: number;
  displayTermCostOnSchedule: boolean;
  availableRequirementUnitTypes: ISelectOption[];
  gradeLevels: IGradeLevel[];
  studentInviteTemplateUrl: string;
}

const initialState: IState = {
  account: {
    key: "",
    name: "",
    tier: {
      degreeManagementModuleAccess: true,
      messagingModuleAccess: false,
      termPlanningModuleAccess: false,
      registrationModuleAccess: false,
    }
  },
  multipleStudentSchedulesEnabled: false,
  isLoading: false,
  availableSeasons: [],
  schoolConfigurationId: 0,
  schoolId: 0,
  displayTermCostOnSchedule: false,
  availableRequirementUnitTypes: [],
  gradeLevels: [],
  studentInviteTemplateUrl: '',
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    account: payload.account,
    availableSeasons: payload.availableSeasons,
    schoolConfigurationId: payload.schoolConfigurationId,
    schoolId: payload.schoolId,
    displayTermCostOnSchedule: payload.displayTermCostOnSchedule,
    gradeLevels: payload.gradeLevels,
    availableRequirementUnitTypes: payload.availableRequirementUnitTypes,
    studentInviteTemplateUrl: payload.studentInviteTemplateUrl,
    multipleStudentSchedulesEnabled: payload.multipleStudentSchedulesEnabled,
  };
});

export const configurationReducer = reducer;
