import React, { useEffect } from 'react';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { termSeasonService } from '../../../../../services/term-season/TermSeasonService';
import * as actions from './actions';
import Skeleton from 'react-loading-skeleton';

export const OverviewStats: React.FC = () => {
  const dispatch = useDispatch();

  const {
    studentsNotFullyPlanned,
    isLoading,
    averageUnitLoad,
    plannedToGraduate,
    currentYear,
    currentSeason,
    user,
  } = useSelector(
    (state) => ({
      user: state.common.user.info,
      studentsNotFullyPlanned:
        state.pages.admin.dashboard.students.overview.studentsNotFullyPlanned,
      averageUnitLoad:
        state.pages.admin.dashboard.students.overview.averageUnitLoad,
      plannedToGraduate:
        state.pages.admin.dashboard.students.overview.plannedToGraduate,
      currentYear: state.pages.admin.dashboard.students.overview.currentYear,
      currentSeason:
        state.pages.admin.dashboard.students.overview.currentSeason,
      isLoading: state.pages.admin.dashboard.students.overview.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  if (isLoading) {
    return (
      <>
        <div className={'flex items-center justify-between'}>
          <div className={'w-1/3 text-center'}>
            <Skeleton height={15} width={'20%'} />
            <Skeleton height={30} width={'10%'} />
            <Skeleton height={20} width={'25%'} />
          </div>
          <div className={'w-1/3 text-center'}>
            <Skeleton height={15} width={'20%'} />
            <Skeleton height={30} width={'10%'} />
          </div>
          <div className={'w-1/3 text-center'}>
            <Skeleton height={15} width={'5%'} />
            <Skeleton height={30} width={'20%'} />
            <Skeleton height={20} width={'15%'} />
          </div>
        </div>
      </>
    );
  }

  return (
    <section className={'flex items-center justify-between'}>
      <div className="w-4/12 bg-neutral-lightest p-6 rounded-md mr-4">
        <div className={'text-baby-blue text-4xl font-bold'}>
          {averageUnitLoad} Units
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          average unit load
        </span>
      </div>
      <div className="w-4/12 bg-neutral-lightest p-6 rounded-md mr-4">
        <div className={'text-baby-blue text-4xl font-bold'}>
          {studentsNotFullyPlanned}{' '}
          {studentsNotFullyPlanned > 1 ? 'Students' : 'Student'}
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          {studentsNotFullyPlanned > 1
            ? 'are not 100% planned'
            : 'is not 100% planned'}
        </span>
      </div>
      <div className="w-4/12 bg-neutral-lightest p-6 rounded-md mr-4">
        <div className={'text-baby-blue text-4xl font-bold'}>
          {plannedToGraduate}{' '}
          {studentsNotFullyPlanned > 1 ? 'Students' : 'Student'}
        </div>
        <span
          className={
            'text-neutral-darkest text-md font-semibold leading-none mt-2 block'
          }
        >
          to graduate in {termSeasonService.getSeasonAsString(currentSeason)}{' '}
          {currentYear}
        </span>
      </div>
    </section>
  );
};
