import React, { useEffect } from 'react';
import { Greeting } from '../../../shared/greeting';
import { Outlet } from 'react-router-dom';
import { Layout } from '../../../shared/layout';
import { AdminPageTabs } from '../dashboard/page-tabs';
import { InviteStudentsModal } from './modals/invite-students';
import { shallowEqual, useDispatch } from 'react-redux';
import * as degreeActions from '../common/config/degrees/actions';
import { useSelector } from '../../../infrastructure/selector';

export const AdminStudentInvitationsPage: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(degreeActions.fetch());
  }, [user?.schoolId]);

  return (
    <>
      <Layout>
        <div className={'flex w-full'}>
          <section className={'w-full py-8 px-16'}>
            <Greeting />
            {/*<AdminPageTabs />*/}
            <section className={'bg-white rounded-sm p-10'}>
              <Outlet />
            </section>
          </section>
          <InviteStudentsModal />
        </div>
      </Layout>
    </>
  );
};
