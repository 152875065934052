import {createReducer} from "redux-act";
import * as actions from "./actions";
import {ICourse} from "../../../../../../types/course";

interface IState {
  list: ICourse[];
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
  list: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  list: payload,
}));

reducer.on(actions.update, (state, payload) => ({
  ...state,
  list: payload,
}));

export const courseBinReducer = reducer;

