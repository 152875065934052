import React from 'react';
import { HiOutlineTrash } from '@react-icons/all-files/hi/HiOutlineTrash';
import * as deleteTermModalActions from '../../../../pages/dashboard/modals/delete-term/actions';
import { IScheduleTerm } from '../../../../api/schedule-terms';
import { useDispatch } from 'react-redux';

interface ICreditTotalProps {
    total: number;
    term: IScheduleTerm;
    displayTermCost: boolean;
    onDeleteTerm: (termId: number) => any;
}


export const CreditTotalAndPrice: React.FC<ICreditTotalProps> = ({ total, term, displayTermCost, onDeleteTerm }) => {

    const dispatch = useDispatch();

  // TODO: This price will change per semester :(
  // TODO: This needs to be in a config on the school
  const price = 2714;

    return (
        <div className={"flex items-center justify-between"}>
             <div className={"flex flex-col"}>
                <div className="text-black uppercase text-xxs font-bold tracking-wide">{total} Units</div>
                 {displayTermCost && <div className="text-black font-medium text-xxs">
                    {(total * price).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}
                </div>}
            </div>
            <HiOutlineTrash
                className={"mr-2 text-md stroke-black cursor-pointer"}
                onClick={() => onDeleteTerm(term.id)}
            />
        </div>
    )
}
