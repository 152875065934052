const StudentRoutes = {
  student: {
    dashboard: '/student/dashboard',
    schedules: '/student/dashboard/schedules',
    noSchedules: '/student/dashboard/schedules/create',
    schedule: '/student/dashboard/schedules/:id',
  },
};

const AdminRoutes = {
  admin: {
    invitations: {
      student: '/admin/invitations/student',
    },
    settings: {
      index: '/admin/settings',
      courses: {
        create: '/admin/settings/courses/create',
        index: '/admin/settings/courses',
        show: '/admin/settings/courses/:id',
      },
      degrees: {
        index: '/admin/settings/degrees',
        show: '/admin/settings/degrees/:id',
      },
    },
    dashboard: {
      index: '/admin/dashboard',
      courses: {
        index: '/admin/dashboard/courses',
        show: '/admin/dashboard/courses/:id',
      },
      students: {
        profile: '/admin/dashboard/students/:id/profile',
        index: '/admin/dashboard/students',
        show: '/admin/dashboard/students/:id',
      },
      reports: '/admin/dashboard/reports',
    },
  },
};

const AuthRoutes = {
  auth: {
    login: '/login',
    register: '/register',
  },
};

const UserRoutes = {
  user: {
    profile: '/user/profile',
  },
};

export const Routes = {
  ...AdminRoutes,
  ...AuthRoutes,
  ...StudentRoutes,
  ...UserRoutes,
};

export const buildRoute = (route: string, param: string | number) => {
  return route.replace(':id', param.toString());
};
