import { AxiosPromise } from 'axios';
import client from '../axios.client';

export interface IUserProfile {

}

export interface IUserProfileAboutYou {
    dreamVocationAsKid?: string;
    futureDreamVocation?: string;
    favoriteThingToDo?: string;
}

export interface IUserProfileAboutYouPayload {
    dreamVocationAsKid?: string;
    futureVocation?: string;
    favoriteThingToDo?: string;
}

export interface IUserProfileInterests {
    english: number;
    history: number;
    math: number;
    science: number;
    performingArts: number;
    athletics: number;
    studioArts: number;
}

export interface IUserAccountSettings {
    legalName: string;
    preferredName: string;
    email: string;
    studentId: string;
    degree: {
        label: string;
        value: number;
    };
    intendedGraduation: {
        season: number;
        year: number;
    }
}

export interface IAdminAccountSettings {
    legalName: string;
    preferredName: string;
    pronouns: string;
    email: string;
    role: string;
}

export interface IAdminAccountSettingsPayload {
    legalName: string;
    preferredName: string;
    email: string;
    role: string;
}

export interface IUserProfileAPI {
    getUserProfile(id: number): AxiosPromise<IUserProfile>;
    getAboutYou(id: number): AxiosPromise<IUserProfileAboutYou>;
    updateAboutYou(id: number, payload: IUserProfileAboutYouPayload): AxiosPromise<IUserProfileAboutYou>;

    getInterests(id: number): AxiosPromise<IUserProfileInterests>;
    updateInterests(id: number, payload: IUserProfileInterests): AxiosPromise<IUserProfileInterests>;

    getAccountSettings(id: number): AxiosPromise<IUserAccountSettings>;
    updateAccountSettings(id: number, payload: IUserAccountSettings): AxiosPromise<IUserAccountSettings>;

    getAdminAccountSettings(id: number): AxiosPromise<IAdminAccountSettings>;
    updateAdminAccountSettings(id: number, payload: IAdminAccountSettingsPayload): AxiosPromise<IAdminAccountSettings>;

    completeProfile(userId: number, payload: ICompleteProfilePayload): AxiosPromise<any>
}


export interface ICompleteProfilePayload {
    aboutYou: IUserProfileAboutYouPayload;
    interests: IUserProfileInterests;
}

class UserProfileAPI implements IUserProfileAPI {

    getAboutYou(id: number): AxiosPromise<IUserProfileAboutYou> {
        return client.get(`/api/user/${id}/profile/about-you`);
    }
    public getUserProfile(): AxiosPromise<IUserProfile> {
        return client.get(`/api/user`);
    }

    updateAboutYou(id: number, payload: IUserProfileAboutYouPayload): AxiosPromise<IUserProfileAboutYou> {
        return client.put(`/api/user/${id}/profile/about-you`, {
            dreamVocationAsKid: payload.dreamVocationAsKid,
            futureDreamVocation: payload.futureVocation,
            favoriteThingToDo: payload.favoriteThingToDo,
        });
    }

    getInterests(id: number): AxiosPromise<IUserProfileInterests> {
        return client.get(`/api/user/${id}/profile/interests`);
    }

    updateInterests(id: number, payload: IUserProfileInterests): AxiosPromise<IUserProfileInterests> {
        return client.put(`/api/user/${id}/profile/interests`, {
            english: payload.english,
            history: payload.history,
            math: payload.math,
            science: payload.science,
            performingArts: payload.performingArts,
            athletics: payload.athletics,
            studioArts: payload.studioArts,
        });
    }

    getAccountSettings(id: number): AxiosPromise<IUserAccountSettings> {
        return client.get(`/api/user/${id}/profile/settings`);
    }

    updateAccountSettings(id: number, payload: IUserAccountSettings): AxiosPromise<IUserAccountSettings> {
        return client.put(`/api/user/${id}/profile/settings`, {
            legalName: payload.legalName,
            preferredName: payload.preferredName,
            email: payload.email,
            studentId: payload.studentId,
            degree: payload.degree,
            intendedGraduation: payload.intendedGraduation,
        });
    }

    getAdminAccountSettings(id: number): AxiosPromise<IAdminAccountSettings> {
        return client.get(`/api/admin/profile/${id}/settings`);
    }
    updateAdminAccountSettings(id: number, payload: IAdminAccountSettingsPayload): AxiosPromise<IAdminAccountSettings> {
        return client.put(`/api/admin/profile/${id}/settings`, {
            legalName: payload.legalName,
            preferredName: payload.preferredName,
            email: payload.email,
            role: payload.role,
        });
    }

    completeProfile(userId: number, payload: ICompleteProfilePayload): AxiosPromise<any> {
        return client.put(`/api/student/${userId}/profile`, payload);
    }

}

export const userProfile = new UserProfileAPI();
