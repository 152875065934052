import { createAction } from 'redux-act';
import { ICourse } from '../../../../../../types/course';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>
  | ReturnType<typeof onCourseChange>;

export const fetch = createAction(
  '[pages/admin/dashboard/predictive-course-data/search] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/predictive-course-data/search] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/predictive-course-data/search] load.error'
  ),
  done: createAction<ICourse[]>(
    '[pages/admin/dashboard/predictive-course-data/search] load.done'
  ),
};

export const onCourseChange = createAction<string>(
  '[pages/admin/dashboard/predictive-course-data/search] onCourseChange'
);
export const select = createAction<number>(
  '[pages/admin/dashboard/predictive-course-data/search] select'
);
