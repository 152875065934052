import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof destroy.start>
  | ReturnType<typeof destroy.done>
  | ReturnType<typeof destroy.error>;

export const destroy = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/destroy] destroy.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/destroy] destroy.error'
  ),
  done: createAction<boolean>(
    '[pages/admin/dashboard/settings/course/destroy] destroy.done'
  ),
};
