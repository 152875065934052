import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { IUser } from '../user';

export interface IRegisterPayload {
  name: string;
  avatarUrl: string;
  pronouns: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  schoolId: number;
  startYear: string;
  startSeason: string;
  marketingEmailConsent: boolean;
  marketingSMSConsent: boolean;
  legalConsent: boolean;
  userRoleType: number;
  intendedGraduationYear: number;
  intendedGraduationSeason: number;
  majorId: number;
  studentId: string;
  phoneNumber: string;
}

export interface IAuth {
  setSession(): AxiosPromise<any>;
  login(email: string, password: string): AxiosPromise<any>;
  register(payload: IRegisterPayload): AxiosPromise<IUser>;
  logout(): AxiosPromise<any>;
  getEmailAvailability(email: string, password: string): AxiosPromise<any>;
  forgotPassword(email: string): AxiosPromise<any>;
  validateForgotPasswordToken(token: string): AxiosPromise<any>;
  resetPassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ): AxiosPromise<any>;
}

class AuthAPI implements IAuth {
  validateForgotPasswordToken(token: string): AxiosPromise<any> {
    return client.get('/api/forgot-password', {
      params: {
        token,
      },
    });
  }

  forgotPassword(email: string): AxiosPromise<any> {
    return client.post('/api/forgot-password', {
      email,
    });
  }

  public setSession(): AxiosPromise<any> {
    return client.get('/sanctum/csrf-cookie');
  }

  public login(email: string, password: string): AxiosPromise<any> {
    return client.post('/login', {
      email,
      password,
    });
  }

  public register(payload: IRegisterPayload): AxiosPromise<IUser> {
    return client.post('/register', {
      name: payload.name,
      avatar_url: payload.avatarUrl,
      pronouns: payload.pronouns,
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password,
      school_id: payload.schoolId,
      start_year: payload.startYear,
      start_season: payload.startSeason,
      marketing_email_consent: payload.marketingEmailConsent,
      marketing_sms_consent: payload.marketingSMSConsent,
      legal_consent: payload.legalConsent,
      user_role_type: payload.userRoleType,
      major_id: payload.majorId,
      intended_graduation_year: payload.intendedGraduationYear,
      intended_graduation_season: payload.intendedGraduationSeason,
      student_id: payload.studentId,
      phone_number: payload.phoneNumber.replaceAll('-', ''),
    });
  }

  public logout(): AxiosPromise<any> {
    return client.post('/logout');
  }

  public getEmailAvailability(
    email: string,
    password: string
  ): AxiosPromise<any> {
    return client.post('/api/email/availability', {
      email,
      password,
    });
  }

  resetPassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ): AxiosPromise<any> {
    return client.put('/api/reset-password', {
      token,
      password,
      password_confirmation: passwordConfirmation,
    });
  }
}

export const auth = new AuthAPI();
