import { combineReducers } from 'redux';

import { registerReducer as register } from './register/reducer';
import { loginReducer as login } from './login/reducer';
import { modalsReducer as modals } from './login/modals/reducer';

export const authReducer = combineReducers({
  register,
  login,
  modals,
});
