import { createAction } from 'redux-act';
import { ICourseResponse } from '../../../../../api/admin/courses';
import { ISelectOption } from '../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof create.start>
  | ReturnType<typeof create.done>
  | ReturnType<typeof create.error>;

export const create = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/create] create.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/admin/dashboard/settings/course/create] create.error'
  ),
  done: createAction<ICourseResponse>(
    '[pages/admin/dashboard/settings/course/create] create.done'
  ),
};

export const onChange = createAction<{
  [key: string]: string | boolean | undefined | number;
}>('[pages/admin/dashboard/settings/course/create] onChange');
export const onTermsOfferedChange = createAction<ISelectOption[]>(
  '[pages/admin/dashboard/settings/course/create] onTermsOfferedChange'
);
export const onGradeLevelChange = createAction<ISelectOption[]>(
  '[pages/admin/dashboard/settings/course/create] onGradeLevelChange'
);
