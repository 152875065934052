import React, { useEffect, useRef, useState } from 'react';

interface IRenderTargetProps {
  position: DOMRect | undefined;
  handleToggle: () => any;
  isOpen: boolean;
}

interface IRenderChildrenProps {
  handleToggle: () => any;
}

interface IDialogBoxProps {
  useDynamicPositioning?: boolean;
  containerClasses?: string;
  dialogClasses?: string;
  position?: IDialogPosition;
  renderTarget: React.FC<IRenderTargetProps>;
  children: React.FC<IRenderChildrenProps>;
}

interface IDialogPosition {
  top: number | string;
  left: number | string;
  bottom: number | string;
  right: number | string;
}

export const DialogBox: React.FC<IDialogBoxProps> = ({
  renderTarget: Target,
  containerClasses,
  children: Children,
  position,
  dialogClasses,
  useDynamicPositioning,
}) => {
  let dialogStyles = 'absolute';

  if (dialogClasses) {
    dialogStyles += ' ' + dialogClasses;
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const [targetPosition, setTargetPosition] = useState<DOMRect>();
  const [dialogPosition, setDialogPosition] = useState<IDialogPosition>({
    top: position?.top ?? 'auto',
    bottom: position?.bottom ?? 'auto',
    right: position?.right ?? 'auto',
    left: position?.left ?? 'auto',
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  let containerStyles = 'relative';

  if (containerClasses) {
    containerStyles += ' ' + containerClasses;
  }

  useEffect(() => {
    if (containerRef.current && useDynamicPositioning) {
      setTargetPosition(containerRef.current.getBoundingClientRect());
    }
  }, [containerRef.current]);

  const handleDocumentClick = (e: any) => {

    if(e.target.localName === "textarea" || e.target.localName === "button" || e.target.localName === "svg") {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (useDynamicPositioning) {
      setDialogPosition({
        top: targetPosition?.top ?? 'auto',
        bottom: 'auto',
        right: 'auto',
        left: targetPosition?.left ?? 'auto',
      });
    } else {
      setDialogPosition({
        top: position?.top ?? 'auto',
        bottom: position?.bottom ?? 'auto',
        right: position?.right ?? 'auto',
        left: position?.left ?? 'auto',
      });
    }
  }, [targetPosition]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick);
      }, 500);
    }

    return () => document.removeEventListener('click', handleDocumentClick);
  }, [isOpen]);

  return (
    <>
      <div className={containerStyles} ref={containerRef}>
        <Target
          isOpen={isOpen}
          handleToggle={() => setIsOpen(!isOpen)}
          position={targetPosition}
        />
      </div>
      {isOpen && (
        <div
          style={{
            top: dialogPosition.top,
            left: dialogPosition.left,
            right: dialogPosition.right,
            bottom: dialogPosition.bottom,
          }}
          className={dialogStyles}
        >
          <Children handleToggle={() => setIsOpen(!isOpen)} />
        </div>
      )}
    </>
  );
};

DialogBox.defaultProps = {
  useDynamicPositioning: false,
  containerClasses: '',
  dialogClasses:
    'w-40 z-10 absolute bg-neutral-lightest shadow-lg rounded-lg p-2',
};
