import React from 'react';
import { CourseOccupancies } from './course-occupanies';
import { SearchCourses } from './search';
import { CourseFiltersDropdown } from '../../common/filters/courses';
import { useDispatch } from 'react-redux';
import * as actions from "./course-occupanies/actions";
export const AdminCoursesData: React.FC = () => {

  const dispatch = useDispatch();

  return (
    <>
      <h2 className={'mb-4 text-black font-normal text-2xl'}>
        Courses & Occupancies
      </h2>
      <div className={"flex items-center justify-between mb-4 w-full"}>
        <div className={"w-1/3"}>
          <SearchCourses />
        </div>
        <div className={"w-1/3"}>
          <CourseFiltersDropdown handleOnApplyFilter={() => dispatch(actions.fetch())} />
        </div>
      </div>
      <CourseOccupancies />
    </>
  );
};
