import { combineReducers } from 'redux';

import { courseInfoReducer as info } from './info/reducer';
import { occupanciesReducer as occupancies } from './occupancies/reducer';
import { studentOccupantsReducer as occupants } from './students/reducer';

export const courseReducer = combineReducers({
  info,
  occupancies,
  occupants,
});
