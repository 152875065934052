import React from 'react';
import { IStudentProgression } from '../../api/students';
import { BiUser } from '@react-icons/all-files/bi/BiUser';
import { StudentProgression } from './progression';
import { termSeasonService } from '../../services/term-season/TermSeasonService';
import { SimpleActionCreator } from 'redux-act';
import { useDispatch } from 'react-redux';
import { ProgressionListSkeleton } from './skeleton';
import { FaRegHandPaper } from '@react-icons/all-files/fa/FaRegHandPaper';
import { AiFillFlag } from '@react-icons/all-files/ai/AiFillFlag';
import { IoHandRight } from '@react-icons/all-files/io5/IoHandRight';

export interface IProgressionListProps {
  list: IStudentProgression[];
  isLoading: boolean;
  onClickAction?: SimpleActionCreator<number>;
}

export const ProgressionList: React.FC<IProgressionListProps> = ({
  list,
  isLoading,
  onClickAction,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <section className={'flex flex-col'}>
        <div className={'flex mt-5'}>
          <div className={'font-medium text-neutral-darkest text-xs w-1/5'}>
            Name
          </div>
          <div className={'font-medium text-neutral-darkest text-xs w-3/5'}>
            Degree Progress
          </div>
          <div
            className={
              'font-medium text-neutral-darkest text-xs w-1/5 text-right'
            }
          >
            Est. Graduation Term
          </div>
        </div>
        {isLoading && <ProgressionListSkeleton />}
        {!isLoading &&
          list.map((student: IStudentProgression) => {
            const progression = student.progression;

            return (
              <div
                className={`flex mt-3 items-center cursor-pointer py-1 px-2 hover:bg-neutral-lightest rounded-lg border border-white`}
                onClick={() =>
                  !!onClickAction && dispatch(onClickAction(student.userId))
                }
              >
                <div className={'w-1/5 flex items-center'}>
                  {!!student.avatarUrl && (
                    <img
                      className={'w-8 h-8 rounded-full mr-3'}
                      src={student.avatarUrl}
                      alt=""
                    />
                  )}
                  {!student.avatarUrl && (
                    <div
                      className={
                        'w-8 h-8 rounded-full bg-neutral-lighter mr-3 flex items-center justify-center relative'
                      }
                    >
                      <BiUser className={'fill-neutral'} />
                      {student.hasFlag && (
                        <div
                          className={
                            'absolute -top-1.5 -right-1.5 bg-white rounded-full w-5 h-5 shadow flex items-center justify-center text-baby-blue text-xs font-semibold'
                          }
                        >
                          {student.flagCount}
                        </div>
                      )}
                    </div>
                  )}
                  <div className={'font-medium text-neutral text-xs'}>
                    {student.name}
                  </div>
                </div>
                <div className={'w-3/5 flex items-center'}>
                  {!!progression && (
                    <div className={'flex-col flex w-full'}>
                      <StudentProgression progression={progression} />
                    </div>
                  )}
                </div>
                <div className={'w-1/5 flex items-center text-right'}>
                  {!!progression &&
                    progression.estimatedGraduationSeason < 0 && (
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        Unavailable
                      </div>
                    )}
                  {!!progression &&
                    progression.estimatedGraduationYear > -1 && (
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        {`${
                          progression.estimatedGraduationSeason > -1 &&
                          termSeasonService.getSeasonAsString(
                            progression.estimatedGraduationSeason
                          )
                        } ${
                          progression.estimatedGraduationYear > 0 &&
                          progression.estimatedGraduationYear
                        }`}
                      </div>
                    )}
                </div>
              </div>
            );
          })}
      </section>
    </>
  );
};

ProgressionList.defaultProps = {
  onClickAction: undefined,
};
