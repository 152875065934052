import { AxiosPromise } from 'axios';
import client from '../../axios.client';

export interface IBlackbaudStudent {
    id: number;
    deceased: boolean;
    display: string;
    email: string;
    emailActive: boolean;
    firstName: string;
    lastName: string;
    lost: boolean;
    maidenName: string;
    nickName: string;
    preferredName: string;
    dateOfBirth: string;
    prefix: string;
    suffix: string;
}

export interface IBlackBaudAPI {
    getStudents(): AxiosPromise<IBlackbaudStudent[]>;
}

class BlackbaudAPI implements IBlackBaudAPI {
    getStudents(): AxiosPromise<IBlackbaudStudent[]> {
        return client.get(`/api/support/blackbaud/students`);
    }

}

export const blackbaud = new BlackbaudAPI();
