import { AxiosPromise } from 'axios';
import client from '../../axios.client';
import { Season } from '../../../constants';
import { ISelectOption } from '../../../ui/select';

export interface ICourseAvailability {
  id: number;
  termYear: number;
  termSeason: Season;
  available: boolean;
}

export interface ICreateCourseAvailabilityPayload {
  courseId: number;
  termYear: number;
  termSeason: Season;
  available: boolean;
}

export interface ICourseAvailabilityOptions {
  terms: ISelectOption[];
  years: ISelectOption[];
}

export interface ICourseAvailabilityAPI {
  getCourseAvailability(courseId: number): AxiosPromise<ICourseAvailability[]>;
  getCourseAvailabilityOptions(courseId: number): AxiosPromise<ICourseAvailabilityOptions>;
  createCourseAvailability(payload: ICreateCourseAvailabilityPayload): AxiosPromise<ICourseAvailability>;
  destroyCourseAvailability(courseId: number, courseAvailabilityId: number): AxiosPromise<any>;
}

class CourseAvailabilityAPI implements ICourseAvailabilityAPI {

  getCourseAvailability(courseId: number): AxiosPromise<ICourseAvailability[]> {
    return client.get(`/api/courses/${courseId}/availability`);
  }

  getCourseAvailabilityOptions(courseId: number): AxiosPromise<ICourseAvailabilityOptions> {
    return client.get(`/api/courses/${courseId}/availability/options`);
  }

  createCourseAvailability(payload: ICreateCourseAvailabilityPayload): AxiosPromise<ICourseAvailability> {
    return client.post(`/api/courses/${payload.courseId}/availability`, {
      termYear: payload.termYear,
      termSeason: payload.termSeason,
      available: payload.available,
    });
  }

  destroyCourseAvailability(courseId: number, courseAvailabilityId: number): AxiosPromise<any> {
    return client.delete(`/api/courses/${courseId}/availability/${courseAvailabilityId}`);
  }

}

export const courseAvailabilityAPI = new CourseAvailabilityAPI();
