import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../types/error';

interface IState {
    isLoading: boolean;
    isOpen: boolean;
    dreamVocationAsKid: string;
    futureVocation: string;
    favoriteThingToDo: string;
    error: IValidationErrorResponse;
}

const initialState: IState = {
    isLoading: false,
    isOpen: false,
    error: {
      errors: {},
      message: ""
    },
    dreamVocationAsKid: "",
    futureVocation: "",
    favoriteThingToDo: "",
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
    ...state,
    isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
    ...state,
    isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
    ...state,
    isLoading: false,
    dreamVocationAsKid: payload.dreamVocationAsKid ?? "",
    futureVocation: payload.futureDreamVocation ?? "",
    favoriteThingToDo: payload.favoriteThingToDo ?? "",
}));

reducer.on(actions.open, (state, payload) => ({
    ...state,
    isOpen: true,
}));

reducer.on(actions.close, (state, payload) => ({
    ...state,
    isOpen: false,
}));

reducer.on(actions.save.start, (state, payload) => ({
    ...state,
    isLoading: true,
    error: {
      errors: {},
      message: ""
    }
}));

reducer.on(actions.save.done, (state, payload) => ({
    ...state,
    isLoading: false,
    isOpen: false
}));

reducer.on(actions.save.error, (state, payload) => ({
    ...state,
    isLoading: false,
    error: payload
}));

reducer.on(actions.onChange, (state, payload) => ({
    ...state,
    [payload.key]: payload.value,
}));

export const UserProfileAboutYouModalReducer = reducer;
