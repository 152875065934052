import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof open>
  | ReturnType<typeof close>
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const onPasswordChange = createAction<string>(
  '[pages/auth/login/modals/reset-password] onPasswordChange'
);
export const onPasswordConfirmChange = createAction<string>(
  '[pages/auth/login/modals/reset-password] onPasswordConfirmChange'
);

export const open = createAction(
  '[pages/auth/login/modals/reset-password] open'
);
export const close = createAction(
  '[pages/auth/login/modals/reset-password] close'
);

export const fetch = createAction(
  '[pages/auth/login/modals/reset-password] fetch'
);

export const load = {
  start: createAction('[pages/auth/login/modals/reset-password] load.start'),
  error: createAction<any>(
    '[pages/auth/login/modals/reset-password] load.error'
  ),
  done: createAction<any>('[pages/auth/login/modals/reset-password] load.done'),
};

export const submit = createAction(
  '[pages/auth/login/modals/reset-password] submit'
);

export const resetPassword = {
  start: createAction(
    '[pages/auth/login/modals/reset-password] resetPassword.start'
  ),
  error: createAction<any>(
    '[pages/auth/login/modals/reset-password] resetPassword.error'
  ),
  done: createAction<any>(
    '[pages/auth/login/modals/reset-password] resetPassword.done'
  ),
};
