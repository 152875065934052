import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { Action, Reducer } from 'redux';
import { Epic } from 'redux-observable';
import { reducers } from '../reducers';

// type ReducerMap = {
//     [key: string]: Reducer<any>;
// };
//
// export type IReducer<R extends ReducerMap> = {
//     [P in keyof R]: ReturnType<R[P]>;
// };

// export type IState = IReducer<typeof reducers>;
export type IState = any;

export type IEpic<T extends Action> = Epic<T, T, IState>;

export const useSelector: TypedUseSelectorHook<IState> = useReduxSelector;
