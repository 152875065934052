import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import { Textarea } from '../../../../../../ui/textarea';
import { Label } from '../../../../../../ui/label';
import * as action from './actions';

export const Message: React.FC = () => {
  const dispatch = useDispatch();

  const { body } = useSelector(
    (state) => ({
      user: state.common.user.info,
      body: state.common.drawers.messages.panels.create.message.body,
    }),
    shallowEqual
  );

  return (
    <>
      <div className="w-full mb-2">
        <Textarea
          name={'body'}
          placeholder={'Message'}
          minHeight={200}
          value={body}
          renderLabel={() => (
            <Label
              name={'Message'}
              required={false}
              classNames={'font-semibold text-baby-blue text-sm mb-2 block'}
            />
          )}
          handleOnChange={(e) => dispatch(action.onChange(e.target.value))}
        />
      </div>
    </>
  );
};
