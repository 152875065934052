import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
import * as actions from './details/actions';
import { Button } from '../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../ui/button';
import { CourseMajors } from './majors';
import { CourseDetails } from './details';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import * as destroyActions from './delete/actions';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../common/routes';
import { Availability } from './availability';

export const AdminSettingsCourse: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, course, schoolConfig } = useSelector(
    (state) => ({
      isLoading: state.pages.admin.settings.course.details.isLoading,
      course: state.pages.admin.settings.course.details.course,
      schoolConfig: state.pages.admin.settings.common.config.school,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!!schoolConfig.availableSeasons.length) {
      dispatch(actions.fetch());
    }
  }, [schoolConfig.gradeLevels.length]);

  if (!course.name) {
    return null;
  }

  return (
    <>
      {/*<ul className={"mb-5"}>*/}
      {/*    <li className={"text-baby-blue text-xs"}>{"Settings > Courses > Edit Course"}</li>*/}
      {/*</ul>*/}
      <div className={'flex justify-between'}>
        <h1 className={'text-neutral-darkest text-lg mb-2'}>
          Edit Course: {course.name} | {course.code}
        </h1>
        <BiTrash
          className={'fill-baby-blue text-lg cursor-pointer'}
          onClick={() => dispatch(destroyActions.destroy.start())}
        />
      </div>
      <hr className={''} />
      <main className={'flex'}>
        <CourseDetails />
        <CourseMajors />
      </main>
      <div className={'mt-6 flex'}>
        <Button
          handleOnClick={() => navigate(Routes.admin.settings.courses.index)}
          classes={'mr-2'}
          size={ButtonSize.Small}
          style={ButtonStyle.Filled}
          variant={ButtonVariant.Neutral}
          isLoading={false}
        >
          Go Back
        </Button>
        <Button
          handleOnClick={() => dispatch(actions.update.start())}
          size={ButtonSize.Small}
          style={ButtonStyle.Filled}
          variant={ButtonVariant.Primary}
          isLoading={isLoading}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};
