import { createAction } from 'redux-act';

export interface IProgressPayload {
  planned: number;
  completed: number;
  unplanned: number;
  unitsRequired: number;
}

export const update = createAction<IProgressPayload>(
  '[pages/dashboard/progress] update'
);
