import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { api } from '../../../../../api';
import { IEpic } from '../../../../../infrastructure/selector';
import { Routes } from '../../../../../common/routes';

const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(() => {
      return from(
        api.admin.courses.deleteCourse(state$.value.common.match.params.id)
      ).pipe(
        mergeMap(({ data }) => {
          window.location.href = Routes.admin.settings.courses.index;
          return of(actions.destroy.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.destroy.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(destroy);
