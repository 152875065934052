import { createAction } from 'redux-act';
import { IDegree } from '../../../../../api/admin/degrees';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/degrees/index] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/index] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/index] load.error'
  ),
  done: createAction<IDegree[]>(
    '[pages/admin/dashboard/settings/degrees/index] load.done'
  ),
};
