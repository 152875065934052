import { MdClose } from '@react-icons/all-files/md/MdClose';
import { SearchInput } from '../../../../../components/search-input';
import { MessageThread } from './thread';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import * as actions from './actions';
import { IMessageThread } from '../../../../../api/messages';
import { MessagesDrawerPanels } from '../../reducer';
import { debounce } from 'lodash';
import { Helpers } from '../../../../../helpers';

export interface IThreadsPanelProps {
  handleClose: () => any;
  handleSetActivePanel: (activePanel: MessagesDrawerPanels) => any;
}

export const ThreadsPanel: React.FC<IThreadsPanelProps> = ({
  handleClose,
  handleSetActivePanel,
}) => {
  const dispatch = useDispatch();

  const { list, user } = useSelector(
    (state) => ({
      user: state.common.user.info,
      list: state.common.drawers.messages.panels.threads.list,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
    const interval = setInterval(() => {
      dispatch(actions.fetch());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const handleSearch = debounce((search: string) => {
    dispatch(actions.onSearch(search));
  }, 300);

  return (
    <>
      <section
        className={'flex items-center justify-between border-b px-5 py-4'}
      >
        <h3 className={'text-baby-blue font-semibold text-md'}>Messages</h3>
        <MdClose
          onClick={() => handleClose()}
          className={'text-lg fill-neutral'}
        />
      </section>
      <section className={'flex items-center justify-between px-5 py-4'}>
        <div className={'w-full mr-4'}>
          <SearchInput
            handleOnChange={(e) => handleSearch(e.target.value)}
            placeholder={'Search'}
            iconClassNames={'fill-baby-blue absolute top-3 left-3'}
            inputClassNames={
              'bg-neutral-lighter rounded-lg pt-3 pb-3 pr-2 pl-8 text-xs w-full text-neutral-dark'
            }
          />
        </div>
        <div
          onClick={() => {
            dispatch(actions.resetSearch());
            handleSetActivePanel(MessagesDrawerPanels.CreateMessageThread);
          }}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 3.00188C0 1.34084 1.342 0 3 0H27C28.658 0 30 1.34084 30 3.00188V20.9991C30 22.6602 28.658 24.001 27 24.001H19.536L15.832 29.5545C15.7407 29.6915 15.617 29.8039 15.4718 29.8816C15.3267 29.9593 15.1646 30 15 30C14.8354 30 14.6733 29.9593 14.5282 29.8816C14.383 29.8039 14.2593 29.6915 14.168 29.5545L10.466 24.001H3C1.342 24.001 0 22.6602 0 20.9991V3.00188ZM14 18.0113V14.0088H10V12.0075H14V8.00501H16V12.0075H20V14.0088H16V18.0113H14Z"
              fill="#007AFF"
            />
          </svg>
        </div>
      </section>
      <section className={'py-2 px-4'}>
        {!list.length && (
          <div>
            <h4 className={'text-neutral-darkest font-semibold text-center'}>
              You do not have any messages yet
            </h4>
          </div>
        )}
        {!!list.length && (
          <ul>
            {list.map((thread: IMessageThread) => (
              <MessageThread
                user={user}
                handleOnClick={(thread: IMessageThread) => {
                  dispatch(actions.resetSearch());
                  dispatch(
                    actions.select({
                      messageThreadId: thread.id,
                      contextualRecipient:
                        user.id == thread.sender.id
                          ? thread.recipient
                          : thread.sender,
                    })
                  );
                  handleSetActivePanel(MessagesDrawerPanels.ThreadMessages);
                }}
                thread={thread}
              />
            ))}
          </ul>
        )}
      </section>
    </>
  );
};
