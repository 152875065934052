import React from 'react';
import { ISVGProps } from '../index';

export const Image: React.FC<ISVGProps> = ({ classes }) => {
    return (
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" stroke={"currentColor"} xmlns="http://www.w3.org/2000/svg" className={classes}>
            <path d="M2.17773 45.0612L18.5656 28.6733C21.3568 25.8822 25.8822 25.8822 28.6733 28.6733L45.0612 45.0612M37.914 37.914L43.581 32.247C46.3721 29.4558 50.8975 29.4558 53.6887 32.247L59.3557 37.914M37.914 16.4722H37.9497M9.32498 59.3557H52.2085C56.1558 59.3557 59.3557 56.1558 59.3557 52.2085V9.32498C59.3557 5.37767 56.1558 2.17773 52.2085 2.17773H9.32498C5.37767 2.17773 2.17773 5.37767 2.17773 9.32498V52.2085C2.17773 56.1558 5.37767 59.3557 9.32498 59.3557Z" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

Image.defaultProps = {
    classes: ''
}
