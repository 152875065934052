import React, { useState } from 'react';
import classNames from 'classnames';

export interface IDropdownRenderTargetProps {
  handleTrigger: () => any;
  isOpen: boolean;
}

export interface IDropdownRenderBodyProps {
  handleTrigger: () => any;
}

export interface IDropdownRenderFooterProps {
  handleTrigger: () => any;
}

export enum DropdownVariant {
  Neutral,
}

export interface IDropdownProps {
  defaultState?: boolean;
  renderTarget: React.FC<IDropdownRenderTargetProps>;
  renderBody: React.FC<IDropdownRenderBodyProps>;
  renderFooter?: React.FC<IDropdownRenderFooterProps>;
  variant?: DropdownVariant;
  maxHeight?: number|null;
}

export const Dropdown: React.FC<IDropdownProps> = ({
  defaultState,
  renderTarget: Target,
  renderBody: Body,
  renderFooter: Footer,
  variant,
  maxHeight
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!defaultState);

  let bodyClasses = classNames('absolute top-10 right-0 w-full rounded-md p-4 z-50');

  let subBodyClasses = classNames({ "overflow-y-scroll hide-scrollbar": maxHeight  });

  if (variant === DropdownVariant.Neutral) {
    bodyClasses += ' bg-neutral-lightest';
  }

  return (
    <>
      <div className={'relative'}>
        <div className={'w-full bg-neutral-lightest p-2 cursor-pointer'}>
          <Target isOpen={isOpen} handleTrigger={() => setIsOpen(!isOpen)} />
        </div>

        {isOpen && (
            <div className={bodyClasses}>
              <div  className={subBodyClasses} style={{maxHeight: maxHeight ? maxHeight : "auto"}}>
                <Body handleTrigger={() => setIsOpen(!isOpen)} />
              </div>
              {Footer && <div className={"pt-4"}>
                <Footer handleTrigger={() => setIsOpen(!isOpen)} />
              </div>}
            </div>

        )}
      </div>
    </>
  );
};

Dropdown.defaultProps = {
  defaultState: false,
  variant: DropdownVariant.Neutral,
  maxHeight: null
};
