import React from 'react';
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import { IoEllipsisHorizontalSharp } from '@react-icons/all-files/io5/IoEllipsisHorizontalSharp';
import classNames from 'classnames';

export interface IPaginationProps {
  handleOnNextClick: (page: number) => any;
  handleOnPreviousClick: (page: number) => any;
  handleOnPageClick: (page: number) => any;
  currentPage: number;
  lastPage: number;
  totalPages: number;
}

export const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  lastPage,
  totalPages,
  handleOnPageClick,
  handleOnPreviousClick,
  handleOnNextClick,
}) => {
  const nextPages = [];

  for (let i = currentPage + 1; i <= currentPage + 2; i++) {
    if (i < lastPage) {
      nextPages.push(i);
    }
  }

  const previousPages = [];

  for (let i = currentPage - 1; i >= currentPage - 2; i--) {
    if (i >= 1) {
      previousPages.unshift(i);
    }
  }

  const leftArrowClasses = classNames([
    'fill-baby-blue text-lg mr-2 cursor-pointer',
    { invisible: currentPage === 1 },
  ]);
  const rightArrowClasses = classNames([
    'fill-baby-blue text-lg mr-2 cursor-pointer',
    { invisible: currentPage === lastPage },
  ]);

  return (
    <div className={'flex w-full justify-end mt-8'}>
      <div
        className={
          'bg-neutral-lighter text-neutral-darkest rounded-lg p-2 w-fit text-xs flex items-center'
        }
      >
        <IoIosArrowBack
          className={leftArrowClasses}
          onClick={() => handleOnPreviousClick(currentPage - 1)}
        />
        {/*{previousPages.map((page: number) => (*/}
        {/*    <span className={"text-neutral text-xs font-semibold mr-2 cursor-pointer hover:text-neutral-darkest"} onClick={() => handleOnPageClick(page)}>{page}</span>*/}
        {/*))}*/}
        {/*<IoEllipsisHorizontalSharp className={"fill-neutral-light mr-2 text-lg"} />*/}
        <span className={'text-neutral-dark text-xs font-semibold mr-2'}>
          Page: {currentPage}
        </span>
        {/*{nextPages.map((page: number) => (*/}
        {/*    <span className={"text-neutral text-xs font-semibold mr-2 cursor-pointer hover:text-neutral-darkest"} onClick={() => handleOnPageClick(page)}>{page}</span>*/}
        {/*))}*/}
        {/*<IoEllipsisHorizontalSharp className={"fill-neutral-light mr-2 text-lg"} />*/}
        {/*<span className={"text-neutral font-semibold text-xs mr-2 cursor-pointer hover:text-neutral-darkest"} onClick={() => handleOnPageClick(lastPage)}>{lastPage}</span>*/}
        <IoIosArrowForward
          className={rightArrowClasses}
          onClick={() => handleOnNextClick(currentPage + 1)}
        />
      </div>
    </div>
  );
};
