import React from 'react';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';

export interface IAddProps {
  handleOnClick: () => any;
}

export const Add: React.FC<IAddProps> = ({ handleOnClick }) => {
  return (
    <div
      onClick={() => handleOnClick()}
      className={
        'cursor-pointer bg-baby-blue-lightest border border-dashed border-baby-blue rounded-full flex items-center justify-center w-5 h-5'
      }
      style={{ padding: '2px' }}
    >
      <HiPlus className={'fill-baby-blue text-2xl'} />
    </div>
  );
};
