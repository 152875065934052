import { combineEpics } from 'redux-observable';
import { epic as details } from './details/epic';
import { epic as concurrency } from './concurrency-requirements/epic';
import { epic as prerequisites } from './prerequisite-requirements/epic';
import { epic as majors } from './majors/epic';
import { epic as create } from './create/epic';
import { epic as destroy } from './delete/epic';
import { epic as requirementCategories } from './majors/requirement-categories/epic';
import { epic as availability } from './availability/epic';

export const epic = combineEpics(
  details,
  concurrency,
  prerequisites,
  majors,
  create,
  destroy,
  requirementCategories,
  availability
);
