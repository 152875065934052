import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../types/error';

interface IState {
  page: number;
  totalPages: number;
  isOpen: boolean;
  isLoading: boolean;
  interests: {
    english: number;
    history: number;
    math: number;
    science: number;
    performingArts: number;
    athletics: number;
    studioArts: number;
  },
  about: {
    dreamVocationAsKid: string,
    dreamFutureVocation: string,
    favoriteThingToDo: string,
  },
  error: IValidationErrorResponse;
}

const initialState: IState = {
  page: 1,
  totalPages: 2,
  isOpen: true,
  isLoading: false,
  interests: {
    english: 2,
    history: 2,
    math: 2,
    science: 2,
    performingArts: 2,
    athletics: 2,
    studioArts: 2,
  },
  about: {
    dreamVocationAsKid: '',
    dreamFutureVocation: '',
    favoriteThingToDo: '',
  },
  error: {
    errors: {},
    message: ''
  }
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.save.start, (state) => ({
  ...state,
  isLoading: true
}));

reducer.on(actions.save.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload
}));

reducer.on(actions.save.done, (state, payload) => ({
  ...state,
  isLoading: false,
  isOpen: false,
}));

reducer.on(actions.open, (state) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
  isLoading: false,
}));

reducer.on(actions.paginate.next, (state) => ({
  ...state,
  page: state.page + 1,
}));

reducer.on(actions.paginate.prev, (state) => ({
  ...state,
  page: state.page - 1,
}));

reducer.on(actions.onInterestChange, (state, payload) => ({
  ...state,
  interests: {
    ...state.interests,
    [payload.key]: payload.value,
  }
}));

reducer.on(actions.onAboutChange, (state, payload) => ({
  ...state,
  about: {
    ...state.about,
    [payload.key]: payload.value,
  }
}));

export const completeProfileReducer = reducer;
