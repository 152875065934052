import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { Season } from '../../constants';
import { IProgression } from '../students';

export interface IOccupancyResponse {
  data: IOccupancy;
}

export interface IOccupancy {
  id: number;
  termSeason: Season;
  termYear: number;
  plannedSlots: number;
  occupants: IOccupant[];
}

export interface IOccupant {
  userId: number;
  name: string;
  progression: IProgression;
}

export interface IOccupancyAPI {
  getOccupancyById(
    courseId: number,
    occupancyId: number
  ): AxiosPromise<IOccupancyResponse>;
}

class CourseOccupancyAPI implements IOccupancyAPI {
  getOccupancyById(
    courseId: number,
    occupancyId: number
  ): AxiosPromise<IOccupancyResponse> {
    return client.get(`/api/courses/${courseId}/occupancies/${occupancyId}`);
  }
}

export const courseOccupancy = new CourseOccupancyAPI();
