import React from 'react';

// Values are expected to be in units / credits
export interface IDegreeProgressBarProps {
  calculate?: boolean;
  unplanned: number;
  planned: number;
  completed: number;
  required?: number;
}

const createPercentage = (numerator: number, denominator: number): string => {
  let decimal = Math.round((numerator / denominator) * 100);

  if (decimal > 100) {
    return '100%';
  }

  return decimal + '%';
};

export const DegreeProgressBar: React.FC<IDegreeProgressBarProps> = ({
  calculate,
  unplanned,
  planned,
  completed,
  required,
}) => {
  let percentageCompleted = '';
  let percentagePlanned = '';
  let percentageUnplanned = '';

  if (calculate && required) {
    percentageCompleted = createPercentage(completed, required);
    percentagePlanned = createPercentage(planned, required);
    percentageUnplanned = createPercentage(unplanned, required);
  } else {
    percentageCompleted = completed.toString() + '%';
    percentageUnplanned = unplanned.toString() + '%';
    percentagePlanned = planned.toString() + '%';
  }

  return (
    <div
      className={
        'bg-white border border-neutral-light w-full h-2 rounded-full relative mt-3'
      }
    >
      {unplanned >= 0 && parseInt(percentagePlanned) <= 90 && (
        <div className={'relative w-full h-full'}>
          <span
            className={
              'font-medium text-sm text-neutral absolute -top-7 right-0 transition-width ease-in-out duration-200'
            }
          >{`${planned > 0 ? percentageUnplanned + " Unplanned" : "0% Planned"} `}</span>
        </div>
      )}
      {planned > 0 && planned > completed && (
        <div
          className={
            'border border-baby-blue-lighter absolute h-2 top-0 left-0 bg-baby-blue-lighter rounded-full transition-width ease-in-out duration-200'
          }
          style={{ width: percentagePlanned }}
        >
          <div className={'relative w-full h-full'}>
            <span
              className={
                'font-medium text-sm text-baby-blue-lighter absolute -top-7 right-0'
              }
            >{`${percentagePlanned} Planned`}</span>
          </div>
        </div>
      )}
      {completed > 0 && (
        <div
          className={
            'border-baby-blue border absolute h-2 top-0 left-0 bg-baby-blue rounded-full transition-width ease-in-out duration-200'
          }
          style={{ width: percentageCompleted }}
        >
          <div className={'relative w-full h-full'}>
            <span
              className={
                'font-medium text-sm text-baby-blue absolute -top-7 right-0'
              }
            >{`${percentageCompleted} Completed`}</span>
          </div>
        </div>
      )}
    </div>
  );
};

DegreeProgressBar.defaultProps = {
  calculate: true,
  required: 0,
};
