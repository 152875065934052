import { createReducer } from 'redux-act';
import * as actions from './actions';
import * as submitActions from '../submit/actions';

interface IState {
  body: string;
}

const initialState: IState = {
  body: '',
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  body: payload,
}));

reducer.on(submitActions.send.done, (state) => {
  return {
    ...state,
    body: '',
  };
});

export const messageReducer = reducer;
