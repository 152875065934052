import React from 'react';
import { UserAvatar } from '../../../../../../components/avatar';
import { Flags } from '../flags';
import { Notes } from '../notes';
import { Button } from '../../../../../../ui';
import {
  ButtonBorderColorVariant,
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant
} from '../../../../../../ui/button';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../common/routes';

export const StudentInfoDetails: React.FC = () => {

  const navigate = useNavigate();

  const {
    studentId,
    name,
    email,
    avatarUrl,
    estimatedGraduation,
    graduationStatus,
    isLoading,
  } = useSelector(
    (state) => ({
      studentId: state.pages.admin.dashboard.student.schedule.schedule?.userId,
      name: state.pages.admin.dashboard.student.info.details.name,
      isLoading: state.pages.admin.dashboard.student.info.details.isLoading,
      avatarUrl: state.pages.admin.dashboard.student.info.details.avatarUrl,
      email: state.pages.admin.dashboard.student.info.details.email,
      graduationStatus:
      state.pages.admin.dashboard.student.info.details.graduationStatus,
      estimatedGraduation:
      state.pages.admin.dashboard.student.info.details.estimatedGraduation,
    }),
    shallowEqual
  );

  if (isLoading) {
    return (
      <>
        <Skeleton height={30} width={'30%'} />
        <Skeleton className={'mt-2'} width={'10%'} />
        <Skeleton width={'10%'} />
        <Skeleton width={'30%'} />
      </>
    );
  }

  return (
    <section className={'flex mb-10'}>
      <div className={''}>
        <UserAvatar imageUrl={avatarUrl} size={95} />
      </div>
      <div className={'ml-4'}>
        <div className={'flex items-center'}>
          <h3 className={'text-2xl text-black mr-2'}>{name}</h3>
          <Flags />
          <Notes />
        </div>
        <span className={'text-neutral text-xs block'}>{email}</span>
        <div
          style={{ backgroundColor: graduationStatus.color }}
          className={'flex items-center py-1 px-2 mt-1 w-fit rounded-md'}
        >
              <span className={'text-black text-xs mr-1'}>
                {estimatedGraduation}
              </span>
          <span className={'text-black text-xs mr-1'}>|</span>
          <span className={'text-black text-xs'}>
                {graduationStatus.label}
              </span>
        </div>
        <div className={"flex items-center mt-4"}>
          <Button
            handleOnClick={() => navigate(generatePath(Routes.admin.dashboard.students.show, {
              id: studentId
            }))}
            classes={"mr-4"}
            size={ButtonSize.XSmall}
            variant={ButtonVariant.White}
            borderColorVariant={ButtonBorderColorVariant.Secondary}
            style={ButtonStyle.Outline}
            radius={ButtonBorderRadius.FULL}
            shadow={true}
          >Schedule</Button>
          <Button
            handleOnClick={() => navigate(generatePath(Routes.admin.dashboard.students.profile, {
              id: studentId
            }))}
            size={ButtonSize.XSmall}
            variant={ButtonVariant.White}
            borderColorVariant={ButtonBorderColorVariant.Secondary}
            style={ButtonStyle.Outline}
            radius={ButtonBorderRadius.FULL}
            shadow={true}
          >Profile</Button>
        </div>
      </div>
    </section>
  )
}
