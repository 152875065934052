import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as updateActions from '../head/actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';

const fetchOnScheduleUpdate: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(updateActions.update.done),
    filter(
      () => !!state$.value.pages.admin.dashboard.student.schedule.schedule
    ),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(
      () => !!state$.value.pages.admin.dashboard.student.schedule.schedule
    ),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.schedules.getScheduleProgress(
          state$.value.pages.admin.dashboard.student.schedule.schedule.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, fetchOnScheduleUpdate);
