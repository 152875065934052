import React, {useEffect} from "react";
import {shallowEqual, useDispatch} from "react-redux";
import {useSelector} from "../../../infrastructure/selector";
import * as actions from "./actions";
import {DropResult} from "react-beautiful-dnd";
import * as deleteTermModalActions from "../modals/delete-term/actions";
import {Routes} from "../../../common/routes";
import {StudentDragAndDropSchedule} from "../../../components/student-schedule";
import {TermTrackHeader} from "../term-track-header";
import * as termsActions from "../../../components/DragAndDropSchedule/term/actions";

export const CurrentTrack: React.FC = () => {
  const dispatch = useDispatch();

    const { schedule, match, configuration, courseBin } = useSelector(
        (state) => ({
            match: state.common.match,
            schedule: state.pages.dashboard.currentTrack.schedule,
            configuration: state.common.configs.configuration,
            courseBin: state.pages.dashboard.courseBin.list
        }),
        shallowEqual
    );

  useEffect(() => {
    if (match.pattern.path == Routes.student.schedule) {
      dispatch(actions.fetch());
    }
  }, [match.params.id]);

  if (!schedule) {
    return null;
  }

    return (
        <>
            <StudentDragAndDropSchedule
                schedule={schedule}
                displayTermCost={configuration.displayTermCostOnSchedule}
                handleOnDragEnd={(result: DropResult) => dispatch(actions.onDragEnd.start(result))}
                handleOnAddTerm={() => dispatch(termsActions.addV2.start())}
                handleOnRemoveTerm={(termId) => dispatch(deleteTermModalActions.open(termId))}
                renderHeader={({ handleOnAddTerm }) => <TermTrackHeader handleOnAddTerm={handleOnAddTerm} />}
                courseBin={courseBin}
            />
        </>
    )
}
