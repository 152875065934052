import React from 'react';
import { Oval } from 'react-loader-spinner';

export enum ButtonVariant {
  Primary,
  Secondary,
  Danger,
  Neutral,
  White,
}

export enum ButtonBorderColorVariant {
  Primary,
  Secondary,
}

export enum ButtonSize {
  Small,
  Medium,
  Large,
  XSmall,
  XXSmall,
  XXXSmall,
}

export enum ButtonStyle {
  Filled,
  Outline,
}

export enum ButtonBorderRadius {
  SM,
  MD,
  LG,
  XL,
  FULL,
}

interface IButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  style?: ButtonStyle;
  radius?: ButtonBorderRadius;
  borderColorVariant?: ButtonBorderColorVariant;
  shadow?: boolean;
  handleOnClick?: () => any;
  children: any;
  isLoading?: boolean;
  classes?: string;
  disabled?: boolean;
  id?: string;
}

export const Button: React.FC<IButtonProps> = ({
  variant,
  size,
  style,
  handleOnClick,
  children,
  isLoading,
  radius,
  disabled,
  classes,
  borderColorVariant,
  shadow
}) => {
  let buttonStyles = 'font-semibold w-fit flex items-center';

  if (disabled) {
    buttonStyles += ' bg-neutral-lighter text-neutral-dark';
  } else {
    if (ButtonStyle.Filled == style) {
      if (variant == ButtonVariant.Primary) {
        buttonStyles += ' bg-blue text-white';
      }

      if (variant === ButtonVariant.Secondary) {
        buttonStyles += ' bg-baby-blue text-white';
      }

      if (variant === ButtonVariant.Danger) {
        buttonStyles += ' bg-red text-white';
      }

      if (variant === ButtonVariant.Neutral) {
        buttonStyles +=
          ' bg-neutral-lightest text-neutral-dark hover:bg-neutral-lighter';
      }
    }
  }

  if (ButtonStyle.Outline === style) {
    if (variant === ButtonVariant.Primary) {
      buttonStyles +=
        ' border border-blue text-blue hover:bg-baby-blue-lightest';
    }

    if (variant === ButtonVariant.Secondary) {
      buttonStyles +=
        ' border border-baby-blue text-baby-blue hover:bg-baby-blue-lightest';
    }

    if (variant === ButtonVariant.Danger) {
      buttonStyles += ' border border-red text-red';
    }

    if(variant === ButtonVariant.White) {
      buttonStyles += ' bg-white text-neutral-darkest';
    }

    if(borderColorVariant === ButtonBorderColorVariant.Primary) {
      buttonStyles += ' border border-baby-blue';
    }

    if(borderColorVariant === ButtonBorderColorVariant.Secondary) {
      buttonStyles += ' border border-baby-blue-lighter';
    }

  }

  if (ButtonSize.XSmall === size) {
    buttonStyles += ' px-3 py-1 text-xxs';
  }

  if (ButtonSize.Small === size) {
    buttonStyles += ' px-3 py-2 text-xs';
  }

  if (ButtonSize.XSmall === size) {
    buttonStyles += ' px-4 py-3 text-sm';
  }

  if (ButtonSize.XXSmall === size) {
    buttonStyles += ' px-3 py-3 text-xs';
  }

  if (ButtonSize.Medium === size) {
    buttonStyles += ' px-2 py-2 text-sm';
  }

  if (ButtonSize.Large === size) {
    buttonStyles += ' px-4 py-3 text-sm';
  }

  if (ButtonSize.XXXSmall === size) {
    buttonStyles += ' px-2 py-1 text-xxs';
  }

  if (ButtonBorderRadius.SM === radius) {
    buttonStyles += ' rounded-sm';
  }

  if (ButtonBorderRadius.MD === radius) {
    buttonStyles += ' rounded-md';
  }

  if (ButtonBorderRadius.LG === radius) {
    buttonStyles += ' rounded-lg';
  }

  if (ButtonBorderRadius.XL === radius) {
    buttonStyles += ' rounded-xl';
  }

  if (ButtonBorderRadius.FULL === radius) {
    buttonStyles += ' rounded-full';
  }

  if(shadow) {
    buttonStyles += ' shadow';
  }

  if (classes) {
    buttonStyles += ` ${classes}`;
  }

  return (
    <button
      disabled={disabled}
      className={buttonStyles}
      onClick={() => handleOnClick && handleOnClick()}
    >
      {isLoading && (
        <div className={'flex items-center justify-center w-full'}>
          <Oval secondaryColor={'#fff'} color={'#fff'} height={18} width={18} />
        </div>
      )}
      {!isLoading && children}
    </button>
  );
};

Button.defaultProps = {
  style: ButtonStyle.Filled,
  isLoading: false,
  variant: ButtonVariant.Primary,
  size: ButtonSize.Medium,
  radius: ButtonBorderRadius.MD,
  disabled: false,
  handleOnClick: () => true,
};
