import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import { api } from '../../../../../api';

const fetchOnSendMessage: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.sendMessage.done),
    map(() => actions.fetch())
  );
const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(
      () =>
        !!state$.value.common.user.info.schoolId &&
        !!state$.value.common.drawers.messages.state.isOpen
    ),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { selectedThreadId } =
        state$.value.common.drawers.messages.panels.thread;

      return from(api.messages.getMessageThreadMessages(selectedThreadId)).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

const sendMessage: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.sendMessage.start),
    switchMap(() => {
      const { selectedThreadId, body } =
        state$.value.common.drawers.messages.panels.thread;

      return from(api.messages.sendMessage(selectedThreadId, body)).pipe(
        mergeMap(({ data }) => {
          return of(actions.sendMessage.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.sendMessage.error(error.response.data)).pipe(
        concat(source$)
      );
    })
  );

export const epic = combineEpics(fetch, load, sendMessage, fetchOnSendMessage);
