import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';
import { Textarea } from '../../../../../../../ui/textarea';
import { Button } from '../../../../../../../ui';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../../ui/button';
import * as actions from '../actions';
import { IValidationErrorResponse } from '../../../../../../../types/error';

export interface IAddNoteProps {
  handleCancel: () => any;
  content: string;
  noteId: number | null;
  error: IValidationErrorResponse;
  isLoading: boolean;
}

export const Note: React.FC<IAddNoteProps> = ({
  handleCancel,
  content,
  noteId,
  error,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const { studentId } = useSelector(
    (state) => ({
      studentId: state.common.match.params.id,
    }),
    shallowEqual
  );

  const [noteContent, setNoteContent] = useState<string>(content);

  return (
    <>
      <div className={''} key={'key1'}>
        <h6 className={'text-neutral-darkest text-sm font-semibold mb-3'}>
          Student Note:
        </h6>
        <Textarea
          name={'content'}
          error={error}
          value={noteContent}
          placeholder={'Add some notes to this student'}
          handleOnChange={(e) => setNoteContent(e.target.value)}
        />
        <div className={'flex justify-end mt-1'}>
          <Button
            classes={'mr-2'}
            style={ButtonStyle.Outline}
            variant={ButtonVariant.Primary}
            handleOnClick={() => handleCancel()}
            radius={ButtonBorderRadius.MD}
            size={ButtonSize.XXXSmall}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
            handleOnClick={() => {
              !noteId
                ? dispatch(
                    actions.create.start({
                      content: noteContent,
                      studentId,
                    })
                  )
                : dispatch(
                    actions.update.start({
                      id: noteId,
                      content: noteContent,
                    })
                  );
            }}
            radius={ButtonBorderRadius.MD}
            size={ButtonSize.XXXSmall}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

Note.defaultProps = {
  content: '',
};
