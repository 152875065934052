import React from 'react';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import * as termsActions from '../../../components/DragAndDropSchedule/term/actions';
import { useDispatch } from 'react-redux';

export interface ITermTrackHeader {
    handleOnAddTerm: () => any;
}
export const TermTrackHeader: React.FC<ITermTrackHeader> = ({ handleOnAddTerm }) => {

    const dispatch = useDispatch();

    return (
        <div className={"mt-5"}>
            <div className={"pb-4 flex items-end justify-between"}>
                <div>
                    <h3 className={"text-xl text-neutral-darkest"}>Your Plan</h3>
                    <span className={"text-xs text-neutral"}>Drag and drop courses between terms</span>
                </div>
            </div>
                <div className="flex items-center">
                    <div className={"grow bg-neutral-lighter mr-4"} style={{ height: "2px" }}></div>
                    <div className={"flex items-center cursor-pointer"} onClick={() => handleOnAddTerm()}>
                        <div
                            className={"bg-baby-blue-lightest border border-dashed border-baby-blue rounded-full flex items-center justify-center w-5 h-5"}
                            style={{padding: "2px"}}>
                            <HiPlus className={"fill-baby-blue text-2xl"}/>
                        </div>
                        <span className={"text-xs ml-2 text-baby-blue font-normal"}>Add Term</span>
                    </div>
                </div>
        </div>
    )

}
