import React from 'react';
import { Students } from './students';
import { Courses } from './courses';
import { Overview } from './overview';

export const AdminDashboardMain: React.FC = () => {
  return (
    <>
      <section className={'border-b pb-4 mb-8'}>
        <h4 className={'text-lg text-neutral-darkest'}>
          You have 0 important items today.
        </h4>
        <span className={'text-xs text-neutral'}>
          This includes messages, flags, and conflicts
        </span>
      </section>
      <Overview />
      <section>
        <div className={''}>
          {/*<Term />*/}
          <Students />
          <Courses />
        </div>
      </section>
    </>
  );
};
