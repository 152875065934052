import React, { useEffect, useState } from 'react';
import { DialogBox } from '../../../../../../ui/dialog-box';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { IStudentFlag } from '../../../../../../api/admin';
import classNames from 'classnames';
import { AddFlag } from './add';
import { FlagList } from './list';
import * as actions from './actions';
import { BiBookmark } from '@react-icons/all-files/bi/BiBookmark';

export const Flags: React.FC = () => {
  const dispatch = useDispatch();
  const [isAddingFlag, setIsAddingFlag] = useState<boolean>(false);

  const { flags } = useSelector(
    (state) => ({
      flags: state.pages.admin.dashboard.student.info.flags.list,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  useEffect(() => {
    setIsAddingFlag(!flags.length);
  }, [flags]);

  return (
    <>
      <DialogBox
        useDynamicPositioning={true}
        dialogClasses="w-80 z-10 bg-neutral-lightest shadow-lg rounded-lg p-2"
        renderTarget={({ handleToggle, isOpen }) => (
          <div
            onClick={() => handleToggle()}
            className={classNames(
              'mr-2 bg-neutral-lighter group cursor-pointer border rounded-full h-8 w-8 flex items-center justify-center hover:bg-baby-blue hover:border-baby-blue',
              { 'bg-baby-blue border-baby-blue': isOpen }
            )}
          >
            <BiBookmark
              className={classNames(
                'fill-neutral-darkest text-md group-hover:fill-white',
                { 'fill-white': isOpen }
              )}
            />
            <span
              className={
                'text-baby-blue font-semibold text-xs absolute -top-2 right-0.5 bg-white shadow h-5 w-5 rounded-full flex items-center justify-center'
              }
            >
              {flags.filter((flag: IStudentFlag) => !flag.resolvedAt).length}
            </span>
          </div>
        )}
      >
        {({ handleToggle }) => {
          return (
            <>
              {isAddingFlag && (
                <AddFlag handleCancel={() => setIsAddingFlag(!isAddingFlag)} />
              )}
              {!isAddingFlag && (
                <FlagList
                  list={flags}
                  handleToggle={handleToggle}
                  handleAddFlag={() => setIsAddingFlag(!isAddingFlag)}
                />
              )}
            </>
          );
        }}
      </DialogBox>
    </>
  );
};
