import React, { useRef, useState } from 'react';
import { UserAvatar } from '../avatar';
import { Image } from '../../icons/image';
import { api } from '../../api';
import { useDispatch } from 'react-redux';

export interface IAvatarUploadProps {
  avatarUrl: string;
  onUploadComplete?: () => any;
}

export const AvatarUpload: React.FC<IAvatarUploadProps> = ({ avatarUrl, onUploadComplete }) => {
  const dispatch = useDispatch();
  const uploadRef = useRef(null);
  const [avatarUploadIsLoading, setAvatarUploadIsLoading] = useState<boolean>(false);

  const handleUploadClick = () => {
    // @ts-ignore
    uploadRef.current.click();
  };
  const handleUploadChange = async () => {
    setAvatarUploadIsLoading(true);
    let inputElement: HTMLInputElement = document.getElementById(
      'avatar'
    ) as HTMLInputElement;
    let files = inputElement.files;
    if (!!files?.length) {
      const file = files[0];
      try {
        let res = await api.user.uploadAvatar(file);
        await api.user.updateAvatarUrl(res.data);

        onUploadComplete && onUploadComplete();
      } catch (error) {
        console.log(error);
      }
      setAvatarUploadIsLoading(false);
    }
  }

  return (
    <>
      <input
        hidden
        type="file"
        id={'avatar'}
        name={'avatar'}
        accept={'image/png, image/jpeg, image/jpg'}
        ref={uploadRef}
        onChange={() => handleUploadChange()}
      />
      <UserAvatar
        handleOnClick={() => handleUploadClick()}
        renderEmptyAvatar={() => {
          return (
            <div onClick={() => handleUploadClick()} className={'cursor-pointer flex items-center justify-center border-neutral-darkest border-2 rounded-full h-32 w-32'}>
              <Image classes={"text-neutral-darkest w-14 h-14"} />
            </div>
          )
        }}
        size={150}
        imageUrl={avatarUrl}
      />
    </>
  )
}
