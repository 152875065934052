import React, { useEffect } from 'react';
import { SelectField } from '../../../../../ui';
import * as actions from './actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import { ICourse } from '../../../../../types/course';

export const CourseConcurrencyRequirementsField: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, list, selected, courseIsLoading } = useSelector(
    (state) => ({
      list: state.pages.admin.settings.course.concurrency.list,
      selected: state.pages.admin.settings.course.concurrency.selected,
      courseIsLoading: state.pages.admin.settings.course.details.isLoading,
      isLoading: state.pages.admin.settings.course.concurrency.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  if (!list.length || courseIsLoading) {
    return null;
  }

  return (
    <div className="w-6/12 mr-5">
      <h3 className={'font-semibold text-neutral-darkest mt-3'}>
        Concurrent Course Requirements
      </h3>
      <span className={'text-neutral text-xs'}>
        These are courses that are required to be taken in parallel
      </span>

      <SelectField
        defaultValue={selected}
        onChange={(options) => dispatch(actions.onChange(options))}
        options={list.map((course: ICourse) => ({
          label: course.code + ' - ' + course.name,
          value: course.id,
        }))}
        error={{ errors: {}, message: '' }}
        multiple
        name={'concurrency'}
      />
    </div>
  );
};
