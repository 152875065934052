import React from 'react';
import { RiMailCheckFill } from '@react-icons/all-files/ri/RiMailCheckFill';

export const RegisterPageThree: React.FC = () => (
  <div className={'mb-10 w-1/3 flex align-items flex-col text-center'}>
    <div className={'mb-10 text-center flex items-center justify-center'}>
      <RiMailCheckFill className={'fill-blue-darkest text-5xl'} />
    </div>
    <h1 className={'text-4xl text-blue-darkest pb-5 font-bold tracking-wide'}>
      You're almost there
    </h1>
    <span className={'text-black font-light'}>
      We've just sent you an email verification, please verify your email before
      logging in.
    </span>
  </div>
);
