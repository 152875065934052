import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from './actions';
import { debounce } from 'lodash';
import { SearchInput } from '../../../../../../components/search-input';
import { StudentFilterDropdown } from '../../../../common/filters/students';

export const Search: React.FC = () => {
  const dispatch = useDispatch();

  const handleSearch = debounce((search: string) => {
    dispatch(actions.onStudentChange(search));
  }, 300);

  return (
    <div className={'flex justify-between'}>
      <div className={'w-1/2'}>
        <SearchInput
          placeholder={'Search by Student Name...'}
          handleOnChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="w-2/12">
        <StudentFilterDropdown />
      </div>
    </div>
  );
};
