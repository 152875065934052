import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourseFutureOccupancy } from '../../../../../api/courses';

interface IState {
  list: ICourseFutureOccupancy[];
  course_id: number;
  isLoading: boolean;
  selectedOccupancyId: number;
}

const initialState: IState = {
  isLoading: false,
  course_id: 29,
  list: [],
  selectedOccupancyId: 0,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    list: payload,
    isLoading: false,
    selectedOccupancyId:
      payload.find((occupancy: ICourseFutureOccupancy) => occupancy.isCurrent)
        ?.id ?? 0,
  };
});

reducer.on(actions.select, (state, payload) => {
  return {
    ...state,
    selectedOccupancyId: payload,
  };
});

export const occupanciesReducer = reducer;
