import { createReducer } from 'redux-act';
import { IStudentAccountInvitation } from '../../../../api/admin/invites';
import * as actions from './actions';

interface IState {
  list: IStudentAccountInvitation[];
  isLoading: boolean;
  showToast: boolean;
}

const initialState: IState = {
  isLoading: false,
  showToast: false,
  list: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: [...payload],
  };
});

reducer.on(actions.resend.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.resend.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.resend.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    showToast: true,
  };
});

export const listReducer = reducer;
