import { createAction } from 'redux-act';
import { MessagesDrawerPanels } from './reducer';
import { IRecipient } from './panels/create/recipient/reducer';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof open>
  | ReturnType<typeof close>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[common/drawers/messages] fetch');

export const open = createAction('[common/drawers/messages] open');
export const close = createAction('[common/drawers/messages] close');

export const toggle = createAction("[common/drawers/messages] toggle");

export const openWithRecipient = createAction<IRecipient>('[common/drawers/messages] openWithRecipient');

export const load = {
  start: createAction('[common/drawers/messages] load.start'),
  error: createAction<any>('[common/drawers/messages] load.error'),
  done: createAction<any>('[common/drawers/messages] load.done'),
};

export const changeActivePanel = createAction<MessagesDrawerPanels>(
  '[common/drawers/messages] changeActivePanel'
);
