import React, { useEffect } from 'react';
import {Edit} from "../../../../icons/edit";
import { AdminAccountSettingsModal } from './modal';
import * as modalActions from './modal/actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
import { UserRoleType } from '../../../../constants';
import { AdminAccountSettingFields } from './fields';

export const AdminAccountSettings: React.FC = () => {

  const dispatch = useDispatch();

  const { user, isLoading, legalName, preferredName, email, role } = useSelector(
    (state) => ({
      isLoading: state.pages.studentProfile.interests.modal.isLoading,
      user: state.common.user.info,
      legalName: state.pages.profile.admin.accountSettings.modal.legalName,
      preferredName: state.pages.profile.admin.accountSettings.modal.preferredName,
      email: state.pages.profile.admin.accountSettings.modal.email,
      role: state.pages.profile.admin.accountSettings.modal.role,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(modalActions.fetch());
  }, []);

  if(isLoading) {
    return null;
  }

  return (
    <>
      <div className={"mt-16"}>
        <div className={"flex items-center"}>
          <h3 className={"text-neutral-darkest text-2xl mr-2 font-medium"}>Account Settings</h3>
          <Edit classes={"text-baby-blue cursor-pointer"} onClick={() => dispatch(modalActions.open())} />
        </div>
        <AdminAccountSettingFields
          values={{
            legalName,
            preferredName,
            email,
            role,
          }}
          isEditable={false}
          isAdmin={user?.roleId == UserRoleType.Administrator}
          isStudent={user?.roleId == UserRoleType.Student}
        />
        <AdminAccountSettingsModal  />
      </div>
    </>
  )
}
