import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import { reducers } from '../infrastructure/reducers';
import { applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
  createRouterMiddleware,
  createRouterReducerMapObject,
} from '@lagunovsky/redux-react-router';
import { createBrowserHistory } from 'history';
import { api } from '../api';
//@ts-ignore
import { rootEpic } from '../infrastructure/epics';

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    api
  }
});
export const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const rootReducer = combineReducers({
  ...reducers,
  ...createRouterReducerMapObject(history),
})
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(routerMiddleware),
  enhancers: [applyMiddleware(epicMiddleware)],
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(routerMiddleware),
    enhancers: [applyMiddleware(epicMiddleware)],
    preloadedState: {
      ...preloadedState
    }
  })
}

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
