import { PageViewAnalytics } from './page-view';
import { ScheduleAnalytics } from './schedule';
import { AuthAnalytics } from './auth';

declare global {
  interface Window {
    dataLayer: { [key: string]: any }[];
  }
}

// TODO: Make this a singleton
class GoogleAnalytics {
  public PageView = new PageViewAnalytics();
  public Schedule = new ScheduleAnalytics();
  public User = new AuthAnalytics();
}

export default new GoogleAnalytics();
