import { createReducer } from 'redux-act';
import * as actions from '../../head/actions';
import { IMajor, IMinor } from '../../../../../../api/majors';
import { IStudentGraduationStatus } from '../../../../../../api/admin';

interface IState {
  id: number;
  name: string;
  avatarUrl: string;
  isLoading: boolean;
  majors: IMajor[];
  minors: IMinor[];
  estimatedGraduation: string;
  graduationStatus: IStudentGraduationStatus;
}

const initialState: IState = {
  id: 0,
  isLoading: false,
  avatarUrl: '',
  name: '',
  majors: [],
  minors: [],
  estimatedGraduation: '',
  graduationStatus: {
    status: 0,
    label: '',
    color: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    name: payload.name,
    avatarUrl: payload.avatarUrl,
    email: payload.email,
    majors: payload.majors,
    minors: payload.minors,
    estimatedGraduation: payload.estimatedGraduation,
    graduationStatus: payload.graduationStatus,
  };
});

export const detailsReducer = reducer;
