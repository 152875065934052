interface IPageViewPayload {
  hostname: string;
  url: string;
  path: string;
  query: string;
}

interface IDataLayerPageView extends IPageViewPayload {
  event: string;
}

export class PageViewAnalytics {
  public addPageViewEvent(data: IPageViewPayload): void {

    if(!window.dataLayer) {
      return;
    }

    this.pushToDataLayer({
      event: 'pageview',
      hostname: data.hostname,
      url: data.url,
      path: data.path,
      query: data.query,
    });
  }

  private pushToDataLayer(data: IDataLayerPageView) {
    window.dataLayer.push({
      event: data.event,
      hostname: data.hostname,
      url: data.url,
      path: data.path,
      path_query: data.query,
    });
  }
}
