import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import { api } from '../../../../../../api';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchOnUpdate: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.done, actions.create.done, actions.destroy.done),
    map(() => actions.fetch())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(api.admin.getStudentFlags(state$.value.common.match.params.id)).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const create: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.create.start),
    switchMap(({ payload }) =>
      from(
        api.admin.createStudentFlag(payload.studentId, payload.context)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.create.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.create.error(error)).pipe(concat(source$))
    )
  );

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(({ payload }) =>
      from(api.admin.updateStudentFlag(payload)).pipe(
        mergeMap(({ data }) => {
          return of(actions.update.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.update.error(error)).pipe(concat(source$))
    )
  );

const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(({ payload }) =>
      from(api.admin.destroyStudentFlag(payload)).pipe(
        mergeMap(({ data }) => {
          return of(actions.destroy.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.destroy.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(
  fetch,
  load,
  create,
  update,
  fetchOnUpdate,
  destroy
);
