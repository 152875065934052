import React, { useState } from 'react';
import { useSelector } from '../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { Drawer } from '../../../components/drawer';
import { ThreadsPanel } from './panels/threads';
import { CreateNewThreadPanel } from './panels/create';
import * as actions from './actions';
import { MessagesDrawerPanels } from './reducer';
import { ThreadMessagesPanel } from './panels/thread';

export const MessagesDrawer: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpen, activePanel } = useSelector(
    (state) => ({
      isOpen: state.common.drawers.messages.state.isOpen,
      activePanel: state.common.drawers.messages.state.activePanel,
    }),
    shallowEqual
  );

  return (
    <>
      <Drawer isOpen={isOpen}>
        {() => (
          <>
            {activePanel === MessagesDrawerPanels.Threads && (
              <ThreadsPanel
                handleClose={() => dispatch(actions.close())}
                handleSetActivePanel={(activePanel) =>
                  dispatch(actions.changeActivePanel(activePanel))
                }
              />
            )}
            {activePanel === MessagesDrawerPanels.CreateMessageThread && (
              <CreateNewThreadPanel
                handleSetActivePanel={(activePanel) =>
                  dispatch(actions.changeActivePanel(activePanel))
                }
              />
            )}
            {activePanel === MessagesDrawerPanels.ThreadMessages && (
              <ThreadMessagesPanel
                handleSetActivePanel={(activePanel) =>
                  dispatch(actions.changeActivePanel(activePanel))
                }
              />
            )}
          </>
        )}
      </Drawer>
    </>
  );
};
