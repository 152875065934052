import React from 'react';
import { BiMinus } from '@react-icons/all-files/bi/BiMinus';
import { BiPlus } from '@react-icons/all-files/bi/BiPlus';
import classNames from 'classnames';

interface IYearPillarProps {
  title: string;
  labels: string[];
  onClick: () => any;
  isCollapsed: boolean;
  isCompleted: boolean;
}

export const YearPillar: React.FC<IYearPillarProps> = ({
  title,
  labels,
  onClick,
  isCollapsed,
  isCompleted,
}) => {
  const pillarStyles = classNames(
    'cursor-pointer bg-white px-4 py-8 shadow-xl rounded-lg bg-shadow flex items-center justify-between mx-3',
    { 'bg-neutral-lighter': isCompleted }
  );

  return (
    <div
      onClick={() => onClick()}
      style={{ writingMode: 'vertical-lr' }}
      className={pillarStyles}
    >
      <div className={'text-black flex items-center'}>
        <span
          className={
            'text-neutral text-xs uppercase mb-4 inline-block font-semibold tracking-widest'
          }
        >
          {title}
        </span>
        {labels.map((label: string, index: number) => (
          <span
            key={index}
            className={
              'text-xxs text-baby-blue font-semibold tracking-widest uppercase mb-2 inline-block'
            }
          >
            {label} {labels.length - 1 != index ? ',' : ''}
          </span>
        ))}
      </div>
      <div>
        {isCollapsed && <BiPlus className={'fill-baby-blue'} />}
        {!isCollapsed && <BiMinus className={'text-baby-blue'} />}
      </div>
    </div>
  );
};
