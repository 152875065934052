import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import { IFilter } from '../../../../../types/filter';
import { SortDirection } from '../../../../../components/sort-by-direction';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(
      () => !!state$.value.common.user.info.schoolId
    ),
    map(() => actions.load.start())
  );

const fetchOnPagination: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.paginate),
    map(() => actions.fetch())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {

      const { keyword } = state$.value.pages.admin.dashboard.courses.search;

      const {
        sort,
        requirementCategories,
        grades,
        availableYears,
        availableTerms
      } = state$.value.pages.admin.common.filters.courses.filters;

      const {
        sortDirection
      } = state$.value.pages.admin.common.filters.courses;

      const {
        currentPage
      } = state$.value.pages.admin.dashboard.courses.courseOccupancies;

        return from(
          api.courses.getCourseOccupanciesBySchoolId(
            {
              page: currentPage,
              filters: {
                name: keyword,
                sort: sort.find((sort: IFilter) => sort.selected)?.value ?? "",
                grades: grades.filter((grade: IFilter) => grade.selected).map((grade: IFilter) => grade.value),
                requirementCategories: requirementCategories.filter((category: IFilter) => category.selected).map((category: IFilter) => category.value),
                availableTerms: availableTerms.filter((category: IFilter) => category.selected).map((category: IFilter) => category.value),
                availableYears: availableYears.filter((category: IFilter) => category.selected).map((category: IFilter) => category.value),
                sortDirection: sortDirection == SortDirection.DESC ? "desc" : "asc"
              },
              schoolId: state$.value.common.user.info.schoolId
            }
          )
        ).pipe(
          mergeMap(({ data }) => {
            return of(actions.load.done(data));
          })
        );
      }
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, fetchOnPagination);
