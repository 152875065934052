import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourse } from '../../../../../../types/course';

interface IState {
  keyword: string;
  selectedCourseId: number;
  list: ICourse[];
  isLoading: boolean;
  dropdownIsOpen: boolean;
}

const initialState: IState = {
  keyword: '',
  isLoading: false,
  selectedCourseId: 37,
  list: [],
  dropdownIsOpen: false,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    list: payload,
  };
});

reducer.on(actions.onCourseChange, (state, payload) => ({
  ...state,
  keyword: payload,
  dropdownIsOpen: true,
}));

reducer.on(actions.select, (state, payload) => ({
  ...state,
  selectedCourseId: payload,
  dropdownIsOpen: false,
}));

export const searchReducer = reducer;
