import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../infrastructure/selector';
import { ProgressionList } from '../../../../../components/progression-list';
import { Add } from '../../../../../ui/add';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../common/routes';
import { SearchInput } from '../../../../../components/search-input';
import * as studentDetailDrawerActions from '../../drawers/student-detail/actions';
import * as studentInvitationModalActions from '../../../student-invitations/modals/invite-students/actions';

export const Students: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, students, user } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isLoading: state.pages.admin.dashboard.overview.students.isLoading,
      students: state.pages.admin.dashboard.overview.students.list,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  return (
    <div className={'mb-8'}>
      <div className="flex justify-between">
        <h5 className={'text-neutral-darkest text-lg font-semibold mb-2'}>
          Students
        </h5>
        <Add
          handleOnClick={() => {
            dispatch(studentInvitationModalActions.open());
            navigate(Routes.admin.invitations.student);
          }}
        />
      </div>
      <SearchInput
        placeholder={'Enter student name...'}
        handleOnChange={(e) => dispatch(actions.onChange(e.target.value))}
      />
      <ProgressionList
        isLoading={isLoading}
        list={students}
        onClickAction={studentDetailDrawerActions.open}
      />
    </div>
  );
};
