import { createAction } from 'redux-act';
import { IValidationErrorResponse } from '../../../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof send.start>
  | ReturnType<typeof send.done>
  | ReturnType<typeof send.error>;

export const send = {
  start: createAction(
    '[common/drawers/messages/panels/create/submit] send.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[common/drawers/messages/panels/create/submit] send.error'
  ),
  done: createAction<any>(
    '[common/drawers/messages/panels/create/submit] send.done'
  ),
};
