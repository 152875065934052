import { createAction } from 'redux-act';
import { IValidationErrorResponse } from '../../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[common/drawers/messages] fetch');

export const load = {
  start: createAction('[common/drawers/messages] load.start'),
  error: createAction<any>('[common/drawers/messages] load.error'),
  done: createAction<any>('[common/drawers/messages] load.done'),
};

export const sendMessage = {
  start: createAction('[common/drawers/panels/thread] sendMessage.start'),
  error: createAction<IValidationErrorResponse>(
    '[common/drawers/panels/thread] sendMessage.error'
  ),
  done: createAction<any>('[common/drawers/panels/thread] sendMessage.done'),
};

export const onChange = createAction<string>(
  '[common/drawers/panels/thread] onChange'
);
