import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { ISelectOption } from '../../ui/select';
import { IAvailableSeason, IGradeLevel } from '../schools';

export interface IConfiguration {
  account: {
    key: string;
    name: string;
    tier: {
      degreeManagementModuleAccess: boolean;
      messagingModuleAccess: boolean;
      termPlanningModuleAccess: boolean;
      registrationModuleAccess: boolean;
    }
  }
  schoolConfigurationId: number;
  schoolId: number;
  multipleStudentSchedulesEnabled: boolean;
  displayTermCostOnSchedule: boolean;
  availableRequirementUnitTypes: ISelectOption[];
  gradeLevels: IGradeLevel[];
  studentInviteTemplateUrl: string;
  availableSeasons: IAvailableSeason[];
}

export interface IConfigurationAPI {
  getConfiguration(): AxiosPromise<IConfiguration>;
}

class ConfigurationAPI implements IConfigurationAPI {

  getConfiguration(): AxiosPromise<IConfiguration> {
    return client.get(`/api/configuration`);
  }
}

export const configuration = new ConfigurationAPI();
