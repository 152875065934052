import { createReducer } from 'redux-act';
import * as actions from './actions';
import {IFilter} from "../../../types/filter";

interface IState {
  search: '';
  filters: ICourseBinListFilter;
}

export interface ICourseBinListFilter {
  grades: IFilter[];
  requirementCategories: IFilter[];
  availableTerms: IFilter[];
  availableYears: IFilter[];
}

const initialState: IState = {
  search: '',
  filters: {
    grades: [],
    requirementCategories: [],
    availableTerms: [],
    availableYears: [],
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.apply, (state, payload) => ({
  ...state,
  filters: {
    ...payload,
  },
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  filters: {
    ...payload,
  },
}));

reducer.on(actions.handleFilter, (state, payload) => {

  let updated = [];

  if(payload.key !== "sort")
  {
    //@ts-ignore
    let isSelected = !!state.filters[payload.key].find((filter: IFilter) => filter.value === payload.filter.value)?.selected;

    //@ts-ignore
    updated = state.filters[payload.key].map((filter: IFilter) => {

      if(filter.value === payload.filter.value)
      {
        return {
          ...filter,
          selected: !isSelected
        }
      }

      return filter;

    })
  } else {
    //@ts-ignore
    updated = state.filters[payload.key].map((filter: IFilter) => ({
      ...filter,
      selected: payload.filter.value === filter.value ? !payload.filter.selected : false
    }))
  }


  return {
    ...state,
    filters: {
      ...state.filters,
      [payload.key]: updated,
    },
  };
});

export const courseBinFilterReducer = reducer;
