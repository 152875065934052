import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from '../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { IUser } from '../../api/user';
import { UserRoleType } from '../../constants';
import { Routes } from '../../common/routes';

interface IPage404State {
  user: IUser;
}

export const Page404: React.FC = () => {
  const { user } = useSelector<IPage404State>(
    (state) => ({
      user: state.common.user.info,
    }),
    shallowEqual
  );

  let link = '';

  if (user.roleId == UserRoleType.Student) {
    link = Routes.student.schedules;
  } else {
    link = Routes.admin.dashboard.students.index;
  }

  return (
    <>
      <section
        className={
          'flex items-center justify-center w-screen h-screen flex-col'
        }
      >
        <h1 className={'text-neutral-darkest text-3xl font-bold'}>
          404 Not Found
        </h1>
        <p className={'text-neutral-dark text-lg font-medium mb-5'}>
          Whoops! We could not find the page you were looking for.
        </p>
        <Link
          className={'font-bold text-sm hover:underline text-baby-blue'}
          to={link}
        >
          Back To Your Dashboard
        </Link>
      </section>
    </>
  );
};
