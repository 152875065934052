import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { IMajor, IMinor } from '../majors';
import { ISchedule } from '../schedules';

export interface IAdvisor {
  id: number;
  name: string;
  avatarUrl: string;
}

export interface IAdvisorAPI {
  getAdvisorsBySchoolId(
    schoolId: number,
    name: string
  ): AxiosPromise<IAdvisor[]>;
}

class AdvisorAPI implements IAdvisorAPI {
  getAdvisorsBySchoolId(
    schoolId: number,
    name: string
  ): AxiosPromise<IAdvisor[]> {
    return client.get(`/api/advisors`, {
      params: {
        schoolId,
      },
    });
  }
}

export const advisor = new AdvisorAPI();
