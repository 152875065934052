import { combineReducers } from 'redux';

import { detailsReducer as details } from './details/reducer';
import { prerequisiteRequirementsReducer as prerequisites } from './prerequisite-requirements/reducer';
import { concurrencyRequirementsReducer as concurrency } from './concurrency-requirements/reducer';
import { majorsReducer as majors } from './majors/reducer';
import { createCourseReducer as create } from './create/reducer';
import { requirementCategoriesReducer as requirementCategories } from './majors/requirement-categories/reducer';
import { courseAvailabilityReducer as availability } from './availability/reducer';

export const courseReducer = combineReducers({
  details,
  prerequisites,
  concurrency,
  majors,
  create,
  requirementCategories,
  availability
});
