import {combineEpics, ofType} from "redux-observable";
import {catchError, concat, map, mergeMap, switchMap} from "rxjs/operators";
import {from, of} from "rxjs";
import * as actions from "./actions";
import {IEpic} from "../../../../../infrastructure/selector";
import {api} from "../../../../../api";

const addTerm: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.addTerm.start),
        switchMap(({ payload: scheduleId }) =>
            from(api.scheduleTerms.addScheduleTerm(scheduleId)).pipe(
                mergeMap(({ data }) => {
                    return of(actions.addTerm.done());
                })
            )
        ),
        catchError((error, source$) =>
            of(actions.addTerm.error(error)).pipe(concat(source$))
        )
    );

const loadOnUpdate: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.addTerm.done, actions.removeTerm.done),
        map(() => actions.load.start())
    );

const load: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.load.start),
        switchMap(() =>
            from(api.schedules.getSchedule(state$.value.pages.admin.dashboard.student.schedule.schedule.id)).pipe(
                mergeMap(({ data }) => {
                    return of(actions.load.done(data));
                })
            )
        ),
        catchError((error, source$) =>
            of(actions.load.error(error)).pipe(concat(source$))
        )
    );

const removeTerm: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.removeTerm.start),
        switchMap(({ payload }) =>
            from(api.scheduleTerms.removeScheduleTerm(state$.value.pages.admin.dashboard.student.schedule.schedule.id, payload)).pipe(
                mergeMap(({ data }) => {
                    return of(actions.removeTerm.done());
                })
            )
        ),
        catchError((error, source$) => {
                return of(actions.removeTerm.error(error.response.data)).pipe(concat(source$))
            }
        )
    );

export const epic = combineEpics(load, addTerm, removeTerm, loadOnUpdate);
