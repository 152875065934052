import React, { useEffect, useState } from 'react';
import { Button, SelectField } from '../../../../../ui';
import {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../ui/button';
import { IoMdAddCircleOutline } from '@react-icons/all-files/io/IoMdAddCircleOutline';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { IMajor } from '../../../../../api/majors';
import * as actions from './actions';
import { Helpers } from '../../../../../helpers';
import { CourseDegreesTitle } from './title';
import { NoDegreesAssigned } from './no-degrees-assigned';
import { AssignedDegreesList } from './list';
import { AssignedToAllDegrees } from './assigned-to-all-degrees';
import { IDegreeRequirement } from '../../../../../api/admin/degrees';
import { Availability } from '../availability';

export const CourseMajors: React.FC = () => {
  const [isAddingMajor, setIsAddingMajor] = useState(false);
  const [isEditingMajor, setIsEditingMajor] = useState(false);
  const dispatch = useDispatch();

  const { isLoading, majors, list, selectedDegree, availableRequirements } =
    useSelector(
      (state) => ({
        majors: state.pages.admin.settings.course.details.majors,
        list: state.pages.admin.settings.course.majors.list,
        selectedDegree: state.pages.admin.settings.course.majors.selectedDegree,
        isLoading: state.pages.admin.settings.course.majors.isLoading,
        availableRequirements:
          state.pages.admin.settings.course.majors.requirements,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (isAddingMajor) {
      dispatch(actions.fetch());
    }
  }, [isAddingMajor]);

  useEffect(() => {
    if (selectedDegree.value != '') {
      dispatch(actions.fetchRequirements());
    }
  }, [selectedDegree]);

  return (
    <div className={"w-4/12 p-4"}>
      <section className={'w-full'}>
        <CourseDegreesTitle />

        {!isAddingMajor && <NoDegreesAssigned />}

        {!isAddingMajor && <AssignedDegreesList />}

        {!list.length && isAddingMajor && <AssignedToAllDegrees />}

        {!isAddingMajor && !majors.length && (
          <Button
            handleOnClick={() => setIsAddingMajor(true)}
            size={ButtonSize.Small}
            style={ButtonStyle.Outline}
            variant={ButtonVariant.Primary}
          >
            <IoMdAddCircleOutline className={'mr-1'} /> Assign to a Degree
          </Button>
        )}

        {isAddingMajor && !!list.length && (
          <>
            <h3 className={'font-semibold text-neutral-darkest mb-2'}>Degrees</h3>

            <SelectField
              error={{ message: '', errors: {} }}
              onChange={(option) => dispatch(actions.onDegreeSelect(option))}
              options={Helpers.mapToSelectOptions<IMajor>(
                list,
                (item: IMajor) => ({
                  label: item.name,
                  value: item.id.toString(),
                })
              )}
              name={'major'}
            />

            {!!selectedDegree.value && !!availableRequirements.length && (
              <>
                <h3 className={'font-semibold text-neutral-darkest mt-5 mb-2'}>
                  Requirement Category
                </h3>
                <SelectField
                  onChange={(option) =>
                    dispatch(actions.onRequirementSelect(option))
                  }
                  options={Helpers.mapToSelectOptions(
                    availableRequirements,
                    (item: IDegreeRequirement) => ({
                      label: item.name,
                      value: item.id.toString(),
                    })
                  )}
                  error={{ errors: {}, message: '' }}
                  name={'requirementCategory'}
                />
              </>
            )}

            <div className={'mt-5 flex justify-between'}>
              <Button
                handleOnClick={() => setIsAddingMajor(false)}
                size={ButtonSize.Small}
                style={ButtonStyle.Outline}
                variant={ButtonVariant.Primary}
              >
                Cancel
              </Button>

              <Button
                handleOnClick={() => {
                  setIsAddingMajor(false);
                  dispatch(actions.assignCourseToDegree.start());
                }}
                size={ButtonSize.Small}
                style={ButtonStyle.Filled}
                variant={ButtonVariant.Primary}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </section>
      <Availability />
    </div>
  );
};
