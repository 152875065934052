import { combineReducers } from 'redux';

import { detailsReducer as details } from './details/reducer';
import { flagsReducer as flags } from './flags/reducer';
import { notesReducer as notes } from './notes/reducer';

export const infoReducer = combineReducers({
  details,
  flags,
  notes,
});
