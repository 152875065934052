import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import {useSelector} from "../../infrastructure/selector";
import {shallowEqual} from "react-redux";
import {Routes} from "../../common/routes";

declare global {
    interface Window {
        Beacon: any;
    }
}
export const HelpScout: React.FC = () => {

    const blackListedUrls = [
        Routes.auth.login,
        Routes.auth.register,
    ];

    const { match, user } = useSelector(
        (state) => ({
            user: state.common.user.info,
            match: state.common.match,
        }),
        shallowEqual
    );

    useEffect(() => {

        if(window.Beacon && !blackListedUrls.includes(match.url) && user?.name) {

            window.Beacon('config', {
                color: "#2563EB",
                display: {
                    style: "iconAndText",
                    text: "Get Help",
                    iconImage: "question"
                }
            });

            window.Beacon('identify', {
                name: user.name,
                email: user.email,
            });

            window.Beacon('prefill', {
                name: user.name,
                email: user.email,
            });

            if(!window.Beacon('info')?.status?.isMounted) {
                window.Beacon('init', '365f907e-6d6d-4299-a0db-c1f90c371d3f')
            }
        }

    }, [window.Beacon]);

    return (
        <>
            <Helmet>
                <script>
                    {`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}
                </script>
            </Helmet>
        </>
    )
}