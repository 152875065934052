import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
import { Modal } from '../../../../components/modal';
import * as courseDetailModalActions from './actions';
import * as termActions from '../../../../components/DragAndDropSchedule/term/actions';
import { Button } from '../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../ui/button';
import * as actions from './actions';
import { ToastVariant, useToasts } from '../../../../hooks/toasts';
import SkeletonModal from '../../../../components/modal/skeleton';

export const DeleteTermModal: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpen, isLoading, termId, error } = useSelector(
    (state) => ({
      isOpen: state.pages.dashboard.modals.deleteTerm.isOpen,
      isLoading: state.pages.dashboard.modals.deleteTerm.isLoading,
      termId: state.pages.dashboard.modals.deleteTerm.termId,
      error: state.pages.dashboard.modals.deleteTerm.error,
    }),
    shallowEqual
  );

  const [addToast] = useToasts();

  useEffect(() => {
    if (!!error.message) {
      addToast({
        variant: ToastVariant.Error,
        message: error.message
      });
    }
  }, [error.message]);

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => dispatch(actions.close())} />;
  }

  return (
    <>
      <Modal
        handleClose={() => dispatch(courseDetailModalActions.close())}
        isOpen={isOpen}
        renderBody={() => <div></div>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-10'}>
              <div className="mr-2">
                <Button
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Secondary}
                  handleOnClick={() => dispatch(actions.close())}
                >
                  Cancel
                </Button>
              </div>
              <Button
                isLoading={isLoading}
                size={ButtonSize.Medium}
                style={ButtonStyle.Filled}
                variant={ButtonVariant.Danger}
                handleOnClick={() =>
                  dispatch(termActions.removeV2.start(termId))
                }
              >
                Delete Term
              </Button>
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
          <div
            className={
              'flex items-center justify-between mb-6 border-b border-neutral-light'
            }
          >
            <div>
              <h2 className={'text-4xl text-black font-semibold mb-2'}>
                Delete Term
              </h2>
            </div>
          </div>
          <div>
            <p className={'text-black text-md'}>
              Are you sure you would like to delete this term? This action can
              not be undone.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};
