import React from 'react';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { IStudentFlag } from '../../../../../../../api/admin';
import classNames from 'classnames';
import { BiCheck } from '@react-icons/all-files/bi/BiCheck';
import * as actions from '../actions';
import { Button } from '../../../../../../../ui';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../../ui/button';
import { useDispatch } from 'react-redux';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

interface IFlagListProps {
  list: IStudentFlag[];
  handleToggle: () => any;
  handleAddFlag: () => any;
}

export const FlagList: React.FC<IFlagListProps> = ({
  list,
  handleToggle,
  handleAddFlag,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className={'w-full'} key={'key1'}>
        <div className={'flex items-center justify-between pb-2'}>
          <h6 className={'text-neutral-darkest text-sm font-semibold'}>
            Student Flags
          </h6>
          <MdClose
            onClick={() => handleToggle()}
            className={'cursor-pointer fill-neutral-darkest'}
          />
        </div>
        <div className={'flex w-full'}>
          <ul className={'w-full'}>
            {list.map((flag: IStudentFlag) => (
              <li
                className={
                  'flex items-center justify-between rounded-md py-2 px-2 hover:bg-neutral-lighter cursor-pointer'
                }
              >
                <span
                  className={classNames(
                    'text-xs',
                    { 'line-through text-neutral italic': !!flag.resolvedAt },
                    { 'text-neutral-darkest': !flag.resolvedAt }
                  )}
                >
                  {flag.context}
                </span>
                <div className={'flex items-center ml-4'}>
                  {!flag.resolvedAt && (
                    <div
                      className={classNames(
                        'w-5 h-5 border rounded-full flex items-center justify-center',
                        { 'bg-baby-blue border-baby-blue': !!flag.resolvedAt },
                        { 'border-baby-blue': !flag.resolvedAt }
                      )}
                    >
                      <BiCheck
                        onClick={() => dispatch(actions.update.start(flag.id))}
                        className={classNames(
                          { 'fill-white': !!flag.resolvedAt },
                          { 'fill-baby-blue': !flag.resolvedAt }
                        )}
                      />
                    </div>
                  )}
                  {!!flag.resolvedAt && (
                    <div
                      className={classNames(
                        'w-5 h-5 border rounded-full flex items-center justify-center',
                        { 'bg-baby-blue border-baby-blue': !!flag.resolvedAt },
                        { 'border-baby-blue': !flag.resolvedAt }
                      )}
                    >
                      <MdClose
                        onClick={() => dispatch(actions.update.start(flag.id))}
                        className={classNames(
                          { 'fill-white': !!flag.resolvedAt },
                          { 'fill-baby-blue': !flag.resolvedAt }
                        )}
                      />
                    </div>
                  )}
                  <BiTrash
                    onClick={() => dispatch(actions.destroy.start(flag.id))}
                    className={'fill-baby-blue ml-2'}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={'flex justify-end pt-2'}>
          <Button
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
            handleOnClick={() => handleAddFlag()}
            radius={ButtonBorderRadius.MD}
            size={ButtonSize.XXXSmall}
          >
            New Flag
          </Button>
        </div>
      </div>
    </>
  );
};
