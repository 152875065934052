import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';
import * as actions from './actions';
import * as destroyActions from '../delete/actions';
import { IDegreeRequirement } from '../../../../../../../api/admin/degrees';
import { CreateRequirement } from '../create';
import { Button } from '../../../../../../../ui';
import {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../../ui/button';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { DegreeRequirementItem } from './item';

export const DegreeRequirementsList: React.FC = () => {
  const dispatch = useDispatch();
  const [isCreatingRequirement, setIsCreatingRequirement] = useState(false);
  const { isLoading, requirements, error } = useSelector(
    (state) => ({
      isLoading:
        state.pages.admin.settings.degrees.update.requirements.list.isLoading,
      requirements:
        state.pages.admin.settings.degrees.update.requirements.list.list,
      error: state.pages.admin.settings.degrees.update.requirements.list.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <section className={'mt-5'}>
        <h2 className={'text-neutral-darkest text-lg mb-2'}>Requirements</h2>
        <hr />

        <div className={'flex items-center border-b pb-2 pt-2'}>
          <h5 className={'text-black font-semibold text-sm w-1/12 mr-2'}>ID</h5>
          <h5 className={'text-black font-semibold text-sm w-3/12 mx-2'}>
            Name
          </h5>
          <h5 className={'text-black font-semibold text-sm w-4/12 mx-2'}>
            Color
          </h5>
          <h5 className={'text-black font-semibold text-sm w-2/12 mx-2'}>
            Number Required
          </h5>
          <h5 className={'text-black font-semibold text-sm w-2/12 mx-2'}>
            Unit
          </h5>
          <h5 className={'text-black font-semibold text-sm w-1/12 ml-2'}></h5>
        </div>
        {requirements.map((requirement: IDegreeRequirement) => (
          <DegreeRequirementItem requirement={requirement} />
        ))}
        {isCreatingRequirement && (
          <CreateRequirement onHide={() => setIsCreatingRequirement(false)} />
        )}
        <div className={'mt-10 flex flex-end'}>
          <Button
            handleOnClick={() => setIsCreatingRequirement(true)}
            size={ButtonSize.Small}
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
          >
            + Add Requirement
          </Button>
        </div>
      </section>
    </>
  );
};
