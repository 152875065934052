import { AxiosPromise } from 'axios';
import client from '../../axios.client';

export enum DegreeType {
  BachelorsOfScience,
  MastersOfScience,
}

export interface IDegree {
  id: number;
  universityId: number;
  schoolId: number;
  name: string;
  type: DegreeType;
  typeAcronym: string;
  abbreviatedName: string;
  totalCreditsRequired: number;
  numberOfRequirements: number;
  numberOfCourses: number;
}

export interface ICreateDegreePayload {
  name: string;
  type: DegreeType;
  abbreviatedName: string;
  totalCreditsRequired: number;
}

export interface IUpdateDegreePayload {
  degreeId: number;
  name: string;
  // type: DegreeType;
  abbreviatedName: string;
  totalCreditsRequired: number;
}

export enum DegreeRequirementUnitType {
  Courses = 1,
  Credits,
}

export interface IDegreeRequirement {
  id: number;
  name: string;
  color: string;
  numberRequired: number;
  type: DegreeRequirementUnitType;
  typeLabel: string;
  isAssigned?: boolean;
}

export interface ICreateDegreeRequirementPayload {
  degreeId: number;
  name: string;
  color: string;
  numberRequired: number;
  type: DegreeRequirementUnitType;
}

export interface IUpdateDegreeRequirementPayload {
  degreeId: number;
  requirementId: number;
  name: string;
  color: string;
  numberRequired: number;
  type: DegreeRequirementUnitType;
}

export interface IDegreeRequirementsResponse {
  available: IDegreeRequirement[];
}

export interface IAssignCourseToDegreePayload {
  degreeId: number;
  requirementId: number;
  courseIds: number[];
}

export interface IRemoveCourseFromDegreePayload {
  degreeId: number;
  courseIds: number[];
}

export interface IDegreesAPI {
  getAllDegrees(): AxiosPromise<IDegree[]>;
  getDegreeById(degreeId: number): AxiosPromise<IDegree>;
  createDegree(payload: ICreateDegreePayload): AxiosPromise<IDegree>;
  updateDegree(payload: IUpdateDegreePayload): AxiosPromise<IDegree>;
  // TODO: Maybe move this to its own api underneath the degrees api?
  getDegreeRequirements(
    degreeId: number
  ): AxiosPromise<IDegreeRequirementsResponse>;
  destroyDegreeRequirement(
    degreeId: number,
    requirementId: number
  ): AxiosPromise<boolean>;
  getAvailableDegreeRequirementsByCourseId(
    degreeId: number,
    courseId: number
  ): AxiosPromise<IDegreeRequirementsResponse>;
  assignCourseToDegree(
    payload: IAssignCourseToDegreePayload
  ): AxiosPromise<IDegreeRequirement>;
  removeCourseFromDegree(
    payload: IRemoveCourseFromDegreePayload
  ): AxiosPromise<IDegreeRequirement>;
}

class DegreesAPI implements IDegreesAPI {
  destroyDegreeRequirement(
    degreeId: number,
    requirementId: number
  ): AxiosPromise<boolean> {
    return client.delete(
      `/api/admin/degrees/${degreeId}/requirements/${requirementId}`
    );
  }

  getAllDegrees(): AxiosPromise<IDegree[]> {
    return client.get('/api/admin/degrees');
  }

  createDegree(payload: ICreateDegreePayload): AxiosPromise<IDegree> {
    return client.post('/api/admin/degrees', payload);
  }

  updateDegree(payload: IUpdateDegreePayload): AxiosPromise<IDegree> {
    return client.put(`/api/admin/degrees/${payload.degreeId}`, payload);
  }

  getDegreeById(degreeId: number): AxiosPromise<IDegree> {
    return client.get(`/api/admin/degrees/${degreeId}`);
  }

  getDegreeRequirements(
    degreeId: number
  ): AxiosPromise<IDegreeRequirementsResponse> {
    return client.get(`/api/admin/degrees/${degreeId}/requirements`);
  }

  createDegreeRequirement(
    payload: ICreateDegreeRequirementPayload
  ): AxiosPromise<IDegreeRequirement> {
    return client.post(`/api/admin/degrees/${payload.degreeId}/requirements`, {
      name: payload.name,
      color: payload.color,
      numberRequired: payload.numberRequired,
      type: payload.type,
    });
  }

  updateDegreeRequirement(
    payload: IUpdateDegreeRequirementPayload
  ): AxiosPromise<IDegreeRequirement> {
    return client.put(
      `/api/admin/degrees/${payload.degreeId}/requirements/${payload.requirementId}`,
      {
        name: payload.name,
        color: payload.color,
        numberRequired: payload.numberRequired,
        type: payload.type,
      }
    );
  }

  getAvailableDegreeRequirementsByCourseId(
    degreeId: number,
    courseId: number
  ): AxiosPromise<IDegreeRequirementsResponse> {
    return client.get(`/api/admin/degrees/${degreeId}/requirements`, {
      params: {
        courseId,
      },
    });
  }

  assignCourseToDegree(
    payload: IAssignCourseToDegreePayload
  ): AxiosPromise<IDegreeRequirement> {
    return client.post(
      `/api/admin/degrees/${payload.degreeId}/requirements/${payload.requirementId}/courses`,
      {
        courseIds: [...payload.courseIds],
      }
    );
  }

  removeCourseFromDegree(
    payload: IRemoveCourseFromDegreePayload
  ): AxiosPromise<IDegreeRequirement> {
    return client.delete(`/api/admin/degrees/${payload.degreeId}/courses`, {
      params: {
        courseIds: [...payload.courseIds],
      },
    });
  }
}

export const degrees = new DegreesAPI();
