import { createReducer } from 'redux-act';
import * as actions from './actions';
import * as uploadStudentActions from './upload-csv/actions';
import { IValidationErrorResponse } from '../../../../../types/error';
import { IUploadedStudent } from '../../../../../api/admin/invites';

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  list: [];
  error: IValidationErrorResponse;
  showToast: boolean;
  uploaded: IUploadedStudent[];
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  showToast: false,
  uploaded: [],
  list: [],
  error: {
    message: '',
    errors: {},
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.open, (state) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
  uploaded: [],
}));

reducer.on(actions.send.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.send.done, (state) => ({
  ...state,
  isLoading: false,
  isOpen: false,
  showToast: true,
}));

reducer.on(actions.send.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(uploadStudentActions.upload.done, (state, payload) => {
  return {
    ...state,
    uploaded: [...payload.filter((o, i) => i != 0)],
  };
});

reducer.on(actions.loadBlackBaudStudents.done, (state, payload) => {
  return {
    ...state,
    // list: payload.map(blackbaudStudent => ({
    //   studentId: blackbaudStudent.id,
    //   name: blackbaudStudent.firstName + " " + blackbaudStudent.lastName,
    //   email: blackbaudStudent.email,
    // })),
  };
});

export const inviteStudentsModalReducer = reducer;
