import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../api';
import { IEpic } from '../../../../../../../infrastructure/selector';

const create: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.create.start),
    switchMap(() => {
      const { name, color, numberRequired, type } =
        state$.value.pages.admin.settings.degrees.update.requirements.create;
      const degreeId =
        state$.value.pages.admin.settings.degrees.update.degree.id;

      return from(
        api.admin.degrees.createDegreeRequirement({
          degreeId,
          name,
          color,
          numberRequired,
          type,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.create.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.create.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(create);
