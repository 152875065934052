import React from 'react';
import { StudentInfoDetails } from './details';

export const StudentInfo: React.FC = () => {

  return (
    <>
      <section className={'flex'}>
        <StudentInfoDetails />
      </section>
    </>
  );
};
