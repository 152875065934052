import React from 'react';
import { StudentStatusData } from './student-status-data';
import { OverviewStats } from './overview-stats';

export const AdminStudentData: React.FC = () => {
  return (
    <>
      <OverviewStats />
      <StudentStatusData />
    </>
  );
};
