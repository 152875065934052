import { createAction } from 'redux-act';
import { IScheduleNotification } from '../../../api/schedule-notifications';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[pages/dashboard/current-track] fetch');

export const load = {
  start: createAction('[pages/dashboard/track-notifications] load.start'),
  error: createAction<any>('[pages/dashboard/track-notifications] load.error'),
  done: createAction<IScheduleNotification[]>(
    '[pages/dashboard/track-notifications] load.done'
  ),
};
