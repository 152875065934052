import moment from 'moment';
import { Season } from '../../constants';
import { IScheduleTerm } from '../../api/schedule-terms';

export interface ITermSeasonService {
  getSeasonFromDate(date: moment.Moment): Season;
  getNextSeason(season: Season): Season;
  applySeasonToTerms(
    terms: IScheduleTerm[],
    currentSeason: Season
  ): IScheduleTerm[];
  applyYearToTerms(
    terms: IScheduleTerm[],
    currentYear: number
  ): IScheduleTerm[];
  applyTermCompletionStatus(
    term: IScheduleTerm,
    currentYear: number
  ): IScheduleTerm;
  getSeasonAsString(season: Season): string;
  getSeasonAsAbbreviatedString(season: Season): string;
}

class TermSeasonService implements ITermSeasonService {
  private seasons = [Season.Spring, Season.Summer, Season.Fall];
  private springMonths = [0, 1, 2, 3];
  private summerMonths = [4, 5, 6, 7];
  private fallMonths = [8, 9, 10, 11];

  public getSeasonFromDate(date: moment.Moment): Season {
    if (this.springMonths.includes(date.month())) {
      return Season.Spring;
    }

    if (this.summerMonths.includes(date.month())) {
      return Season.Summer;
    }

    if (this.fallMonths.includes(date.month())) {
      return Season.Fall;
    }

    return Season.Spring;
  }

  public getNextSeason(season?: Season): Season {
    if (season == Season.Spring) {
      return Season.Summer;
    }
    if (season == Season.Summer) {
      return Season.Fall;
    }
    if (season == Season.Fall) {
      return Season.Spring;
    }

    return Season.Fall;
  }

  applySeasonToTerms(
    terms: IScheduleTerm[],
    currentSeason: Season
  ): IScheduleTerm[] {
    return terms.map((term: IScheduleTerm) => {
      let updatedTerm = {
        ...term,
      };

      // if(this.termHasSeason(updatedTerm)) {
      //     currentSeason = this.getNextSeason(updatedTerm.season);
      //     return updatedTerm;
      // }

      updatedTerm.season = currentSeason;
      currentSeason = this.getNextSeason(currentSeason);
      return updatedTerm;
    });
  }

  getSeasonAsString(season?: Season): string {
    if (season == Season.Spring) {
      return 'Spring';
    }
    if (season == Season.Summer) {
      return 'Summer';
    }
    if (season == Season.Fall) {
      return 'Fall';
    }

    return '';
  }

  getSeasonAsAbbreviatedString(season?: Season): string {
    if (season == Season.Spring) {
      return 'Spr';
    }
    if (season == Season.Winter) {
      return 'Wi';
    }
    if (season == Season.Summer) {
      return 'Sum';
    }
    if (season == Season.Fall) {
      return 'Fa';
    }

    return '';
  }

  // termHasSeason(term: ITerm): boolean {
  //     if(term.season == undefined) {
  //         return false;
  //     }
  //     return !(!term.season && term.season !== Season.Fall);
  // }

  applyYearToTerms(
    terms: IScheduleTerm[],
    currentYear: number
  ): IScheduleTerm[] {
    return terms.map((term: IScheduleTerm) => {
      let updatedTerm = {
        ...term,
      };

      updatedTerm.year = currentYear;

      if (updatedTerm.season == Season.Fall) {
        currentYear++;
      }

      return updatedTerm;
    });
  }

  applyTermCompletionStatus(
    term: IScheduleTerm,
    currentYear: number
  ): IScheduleTerm {
    if (!term.year) {
      term.isCompleted = 0;
      return term;
    }

    if (term.year < currentYear) {
      term.isCompleted = 1;
      return term;
    }

    if (term.year > currentYear) {
      term.isCompleted = 0;
      return term;
    }

    if (term.year == currentYear) {
      let termSeason = term?.season;
      let currentSeason = this.getSeasonFromDate(moment());

      if (termSeason == undefined) {
        term.isCompleted = 0;
        return term;
      }

      if (currentSeason == Season.Summer && termSeason == Season.Fall) {
        term.isCompleted = 1;
        return term;
      }

      term.isCompleted = Number(currentSeason) > Number(termSeason) ? 1 : 0;
    }
    return term;
  }
}

export const termSeasonService = new TermSeasonService();
