import { AxiosPromise } from 'axios';
import client from '../axios.client';

export interface IStudentNote {
  id: number;
  content: string;
  studentId: number;
  creatorId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IStudentNoteAPI {
  createStudentNote(
    studentId: number,
    content: string
  ): AxiosPromise<IStudentNote>;
  updateStudentNote(
    noteId: number,
    content: string
  ): AxiosPromise<IStudentNote>;
  getStudentNotes(studentId: number): AxiosPromise<IStudentNote[]>;
}

class StudentNoteAPI implements IStudentNoteAPI {
  getStudentNotes(studentId: number): AxiosPromise<IStudentNote[]> {
    return client.get(`/api/student/notes`, {
      params: {
        studentId,
      },
    });
  }

  createStudentNote(
    studentId: number,
    content: string
  ): AxiosPromise<IStudentNote> {
    return client.post(`/api/student/notes`, {
      studentId,
      content,
    });
  }

  updateStudentNote(
    noteId: number,
    content: string
  ): AxiosPromise<IStudentNote> {
    return client.put(`/api/student/notes/${noteId}`, {
      content,
    });
  }
}

export const studentNotes = new StudentNoteAPI();
