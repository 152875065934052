import { createAction } from 'redux-act';
import {
  IDegreeRequirement,
  IUpdateDegreeRequirementPayload,
} from '../../../../../../../api/admin/degrees';
import { IValidationErrorResponse } from '../../../../../../../types/error';

export type IActions =
  | ReturnType<typeof update.start>
  | ReturnType<typeof update.done>
  | ReturnType<typeof update.error>;

export const update = {
  start: createAction<IUpdateDegreeRequirementPayload>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/update] update.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/update] update.error'
  ),
  done: createAction<IDegreeRequirement>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/update] update.done'
  ),
};
