import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../infrastructure/selector';
// import * as actions from "./actions";

// const load: IEpic<any> = (action$, state$) =>
//     action$.pipe(
//         ofType(actions.load.start),
//         switchMap(() =>
//             from(api.schedules.getSchedules()).pipe(
//                 mergeMap(({ data }) => {
//                     return of(actions.load.done(data));
//                 })
//             )
//         ),
//         catchError((error, source$) =>
//             of(actions.load.error(error)).pipe(concat(source$))
//         )
//     );
//
// const update: IEpic<any> = (action$, state$) =>
//     action$.pipe(
//         ofType(actions.update.start),
//         switchMap(({ payload }) => {
//                 let currentScheduleId = state$.value.pages.dashboard.currentTrack.list[state$.value.pages.dashboard.currentTrack.currentScheduleIndex].id;
//                 return from(api.schedules.updateSchedule(currentScheduleId, payload)).pipe(
//                     mergeMap(({data}) => {
//                         return of(actions.update.done(data));
//                     })
//                 )
//             }
//         ),
//         catchError((error, source$) =>
//             of(actions.load.error(error)).pipe(concat(source$))
//         )
//     );

export const epic = combineEpics();
