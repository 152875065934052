interface IScheduleEventParams {
  scheduleId: number;
}

export interface IScheduleUpdateEventParams extends IScheduleEventParams {}
export interface IScheduleCreateEventParams extends IScheduleEventParams {}
export interface IViewedStudentScheduleEventParams
  extends IScheduleEventParams {}

interface IScheduleAnalyticsDataLayer {
  event: string;
  parameters: IScheduleEventParams;
}

export class ScheduleAnalytics {
  public addScheduleUpdateEvent(payload: IScheduleUpdateEventParams): void {
    this.pushToDataLayer({
      event: 'updated_schedule',
      parameters: {
        scheduleId: payload.scheduleId,
      },
    });
  }

  public createScheduleEvent(payload: IScheduleCreateEventParams): void {
    this.pushToDataLayer({
      event: 'created_scheduled',
      parameters: {
        scheduleId: payload.scheduleId,
      },
    });
  }

  public viewedStudentSchedule(payload: IViewedStudentScheduleEventParams) {
    this.pushToDataLayer({
      event: 'viewed_student_schedule',
      parameters: {
        scheduleId: payload.scheduleId,
      },
    });
  }

  private pushToDataLayer(data: IScheduleAnalyticsDataLayer) {

    if(!window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: data.event,
      parameters: {
        ...data.parameters,
      },
    });
  }
}
