import * as messagesDrawerActions from '../../../common/drawers/messages/actions';
import * as actions from "./actions";
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { RiMessage2Fill } from '@react-icons/all-files/ri/RiMessage2Fill';
import { useSelector } from '../../../infrastructure/selector';

export const MessagesHeader: React.FC = () => {

  const dispatch = useDispatch();

  const { configuration, hasUnreadMessages } = useSelector(
    (state) => ({
      configuration: state.common.configs.configuration,
      hasUnreadMessages: state.shared.header.messages.hasUnreadMessages,
    }),
    shallowEqual
  );

  useEffect(() => {

    if(!configuration?.account?.tier?.messagingModuleAccess) {
      return;
    }

    dispatch(actions.fetch());

    const interval = setInterval(() => {
      dispatch(actions.fetch());
    }, 10000);
    return () => clearInterval(interval);
  }, [configuration?.account?.key]);

  return (
    <div className='relative' data-cy={"messages-button-header"}>
      <RiMessage2Fill
        className={'cursor-pointer fill-baby-blue text-3xl ml-3'}
        onClick={() => dispatch(messagesDrawerActions.open())}
      />
      {hasUnreadMessages && <div className='-top-0.5 -right-0.5 bg-red h-3 w-3 absolute rounded-full'></div>}
    </div>
  )
}
