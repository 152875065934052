import { IUser } from '../../api/user';
import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IUserState {
  info: IUser;
  isLoading: boolean;
}

const initialState: IUserState = {
  isLoading: false,
  info: {
    id: 0,
    avatarUrl: '',
    schoolId: 0,
    name: '',
    email: '',
    startYear: -1,
    startSeason: -1,
    studentId: '',
    cohortNumber: '',
    roleId: 0,
    createdAt: '',
    updatedAt: '',
    loggedInAt: '',
    emailVerifiedAt: '',
    activeScheduleId: null,
    completedProfile: false,
    isAdmin: false,
    isStudent: false
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  info: {
    ...payload,
  },
}));

export const userReducer = reducer;
