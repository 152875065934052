import React from 'react';
import { Oval } from 'react-loader-spinner';

export interface IButtonProps {
  className: string;
  isLoading?: boolean;
  handleOnClick: (e: any) => any;
  children: any;
}

export const Button: React.FC<IButtonProps> = ({
  className,
  isLoading,
  children,
  handleOnClick,
}) => (
  <button className={className} onClick={(e) => handleOnClick(e)}>
    {isLoading && (
      <div className={'flex items-center justify-center w-full'}>
        <Oval color={'white'} height={18} width={18} />
      </div>
    )}
    {!isLoading && children}
  </button>
);
