import React, { useState } from 'react';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import * as destroyActions from '../../delete/actions';
import {
  DegreeRequirementUnitType,
  IDegreeRequirement,
} from '../../../../../../../../api/admin/degrees';
import { useDispatch } from 'react-redux';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import {
  Input,
  InputBorderVariant,
  InputSize,
} from '../../../../../../../../components/input';
import { REQUIREMENT_COLORS } from '../../../../../../../../constants';
import { BlockPicker } from 'react-color';
import {
  ISelectOption,
  SelectField,
  SelectSize,
} from '../../../../../../../../ui/select';
import { MdCheck } from '@react-icons/all-files/md/MdCheck';
import * as updateActions from '../../update/actions';
import { MdClose } from '@react-icons/all-files/md/MdClose';

export interface IDegreeRequirementItemProps {
  requirement: IDegreeRequirement;
}

export const DegreeRequirementItem: React.FC<IDegreeRequirementItemProps> = ({
  requirement,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [name, setName] = useState<string>(requirement.name);
  const [color, setColor] = useState<string>(requirement.color);
  const [numberRequired, setNumberRequired] = useState<number>(
    requirement.numberRequired
  );
  const [unitType, setUnitType] = useState<number>(requirement.type);
  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(
      updateActions.update.start({
        degreeId: 0,
        requirementId: requirement.id,
        name,
        color,
        numberRequired,
        type: unitType,
      })
    );
    setIsEditing(false);
  };

  let availableTypes: ISelectOption[] = [
    {
      label: 'Courses',
      value: DegreeRequirementUnitType.Courses.toString(),
    },
    {
      label: 'Units',
      value: DegreeRequirementUnitType.Credits.toString(),
    },
  ];

  return (
    <>
      {isEditing && (
        <div
          className={
            'flex items pt-3 border-b pb-3 hover:bg-neutral-lightest cursor-pointer'
          }
        >
          <div className={'text-neutral-darkest text-sm w-1/12'}>
            {requirement.id}
          </div>
          <div className={'text-neutral-darkest text-sm w-3/12'}>
            <Input
              containerStyles={'px-2'}
              size={InputSize.xs}
              borderVariant={InputBorderVariant.Neutral}
              onChange={(e) => setName(e.target.value)}
              type={'text'}
              required={true}
              name={'name'}
              value={name}
            />
          </div>
          <div
            className={
              'text-neutral-darkest text-sm w-4/12 flex items-center relative'
            }
          >
            <div
              className={
                'h-10 w-full rounded-lg flex items-center justify-center cursor-pointer mx-2'
              }
              style={{ backgroundColor: color }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              <div className={'text-sm'}>{color}</div>
            </div>
            {showColorPicker && (
              <div className={'absolute'} style={{ top: 50, left: -23 }}>
                <BlockPicker
                  colors={REQUIREMENT_COLORS}
                  color={color}
                  onChangeComplete={(color) => {
                    setColor(color.hex);
                    setShowColorPicker(!showColorPicker);
                  }}
                />
              </div>
            )}
          </div>
          <div className={'text-neutral-darkest text-sm w-2/12 mx-2'}>
            <Input
              size={InputSize.xs}
              borderVariant={InputBorderVariant.Neutral}
              onChange={(e) => setNumberRequired(e.target.value)}
              type={'number'}
              required={true}
              name={'numberRequired'}
              value={numberRequired.toString()}
            />
          </div>
          <div className={'text-neutral-darkest text-sm w-2/12 mx-2'}>
            <SelectField
              placeholder={'Select Unit Type'}
              defaultValue={availableTypes.filter(
                (type) => parseFloat(type.value) == unitType
              )}
              options={[
                {
                  label: 'Courses',
                  value: DegreeRequirementUnitType.Courses.toString(),
                },
                {
                  label: 'Units',
                  value: DegreeRequirementUnitType.Credits.toString(),
                },
              ]}
              onChange={(option) => setUnitType(Number(option.value))}
              size={SelectSize.xs}
              name={'type'}
              required={true}
              error={{ errors: {}, message: '' }}
            />
          </div>
          <div
            className={
              'text-neutral-darkest text-sm w-1/12 flex items-center justify-center'
            }
          >
            {isEditing && (
              <MdCheck
                className={'mr-2 cursor-pointer fill-baby-blue text-lg'}
                onClick={() => handleSave()}
              />
            )}
            {!isEditing && (
              <AiOutlineEdit
                className={'mr-2 cursor-pointer fill-baby-blue text-lg'}
                onClick={() => setIsEditing(!isEditing)}
              />
            )}
            <MdClose
              className={'cursor-pointer fill-baby-blue text-lg'}
              onClick={() => setIsEditing(!isEditing)}
            />
          </div>
        </div>
      )}

      {!isEditing && (
        <div
          className={
            'flex items pt-3 border-b pb-3 hover:bg-neutral-lightest cursor-pointer'
          }
        >
          <div className={'text-neutral-darkest text-sm w-1/12'}>
            {requirement.id}
          </div>
          <div className={'text-neutral-darkest text-sm w-3/12'}>{name}</div>
          <div
            className={'text-neutral-darkest text-sm w-4/12 flex items-center'}
          >
            <div
              className={'h-6 w-6 p-2 rounded-lg mr-2'}
              style={{ backgroundColor: color }}
            ></div>
            {color}
          </div>
          <div className={'text-neutral-darkest text-sm w-2/12'}>
            {numberRequired}
          </div>
          <div className={'text-neutral-darkest text-sm w-2/12'}>
            {requirement.typeLabel}
          </div>
          <div
            className={
              'text-neutral-darkest text-sm w-1/12 flex items-center justify-center'
            }
          >
            <AiOutlineEdit
              className={'mr-2 cursor-pointer fill-baby-blue text-lg'}
              onClick={() => setIsEditing(!isEditing)}
            />
            <BiTrash
              className={'cursor-pointer fill-baby-blue text-lg'}
              onClick={() =>
                dispatch(destroyActions.destroy.start(requirement.id))
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
