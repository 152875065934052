import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[pages/admin/dashboard/student/info] fetch');

export const load = {
  start: createAction('[pages/admin/dashboard/student/info] load.start'),
  error: createAction<any>('[pages/admin/dashboard/student/info] load.error'),
  done: createAction<any>(
    '[pages/admin/dashboard/student/info] load.done'
  ),
};
