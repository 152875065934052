import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  isLoading: boolean;
  hasUnreadMessages: boolean;
}

const initialState: IState = {
  isLoading: false,
  hasUnreadMessages: false,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    hasUnreadMessages: !!payload?.length
  };
});

export const messagesReducer = reducer;
