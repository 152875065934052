import { combineEpics, ofType } from 'redux-observable';
import { epic as aboutYou } from './about-you/epic';
import { epic as interests } from './interests/epic';
import { epic as settings } from "./account-settings/epic";
import { IEpic } from '../../infrastructure/selector';
import * as actions from './actions';
import { map, switchMap } from 'rxjs/operators';
import * as aboutYouActions from './about-you/modal/actions';
import * as interestsActions from './interests/modal/actions';
import * as settingsActions from "./account-settings/modal/actions";
import { of } from 'rxjs';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    switchMap(() => of(aboutYouActions.fetch(), interestsActions.fetch(), settingsActions.fetch()))
  );

export const epic = combineEpics(fetch, aboutYou, interests, settings);
