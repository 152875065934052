import React from 'react';
import { CourseInfo } from './info';
import { CourseOccupancies } from './occupancies';
import { StudentOccupants } from './students';

export const AdminCourseData: React.FC = () => {
  return (
    <>
      <CourseInfo />
      <CourseOccupancies />
      <StudentOccupants />
    </>
  );
};
