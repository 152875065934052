import { createAction } from 'redux-act';
import {IIntegration} from "../../../api/integrations";

export type IActions =
    | ReturnType<typeof fetch>
    | ReturnType<typeof load.start>
    | ReturnType<typeof load.done>
    | ReturnType<typeof load.error>;

export const fetch = createAction('[integrations] fetch');

export const load = {
    start: createAction('[integrations] load.start'),
    error: createAction<any>('[integrations] load.error'),
    done: createAction<IIntegration[]>('[integrations] load.done'),
};

export const authorize = {
    start: createAction('[integrations] authorize.start'),
    error: createAction<any>('[integrations] authorize.error'),
    done: createAction<any>('[integrations] authorize.done'),
}
