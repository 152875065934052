import { combineReducers } from 'redux';

import { schedulesReducer as schedules } from './schedules/reducer';
import { dashboardReducer as dashboard } from './dashboard/reducer';
import { adminReducer as admin } from './admin/reducer';
import { authReducer as auth } from './auth/reducer';
import { studentProfileReducer as studentProfile } from "./student-profile/reducer";
import { reducers as profile } from "./profile/reducer";

export const reducers = combineReducers({
  schedules,
  dashboard,
  admin,
  auth,
  studentProfile,
  profile
});
