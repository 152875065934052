import React from "react";
import { IValidationErrorResponse } from '../../../../types/error';
import { Input } from '../../../../components/input';

export interface IUserProfileAccountSettingFields {
  isEditable?: boolean;
  errors?: IValidationErrorResponse;
  onChange?: (payload: {key: string; value: string}) => any;
  isStudent: boolean;
  isAdmin: boolean;
  values: {
    legalName: string;
    preferredName: string;
    email: string;
    studentId: string;
    degree: string;
    intendedGraduationSeason: string;
    intendedGraduationYear: string;
  }
}

export const UserProfileAccountSettingFields: React.FC<IUserProfileAccountSettingFields> = ({ values, errors, isStudent, isAdmin, isEditable, onChange }) => {
  return (
    <div className={"mt-4"}>
      <div className={"mb-6"}>
        <div className={"flex items-center justify-between"}>
          <div className={"w-1/2 mr-2"}>
            <Input
              value={values.legalName}
              disabled={!isEditable || isStudent}
              type={"text"}
              required={false}
              name={"legalName"}
              label={"Legal Name"}
              placeholder={"John Doe"}
              onChange={(e) => onChange && onChange({
                key: "legalName",
                value: e.target.value
              })}
            />
          </div>
          <div className={"w-1/2 ml-2"}>
            <Input
              value={values.preferredName}
              disabled={!isEditable}
              type={"text"}
              required={false}
              name={"preferredName"}
              label={"Preferred Name"}
              placeholder={"John Doe"}
              onChange={(e) => onChange && onChange({
                key: "preferredName",
                value: e.target.value
              })}
            />
          </div>
        </div>
      </div>
      <div className={"mb-6"}>
        <div className={"flex items-center justify-between"}>
          <div className={"w-1/2 mr-2"}>
            <Input
              value={values.email}
              disabled={!isEditable || isStudent}
              onChange={(e) => onChange && onChange({
                key: "email",
                value: e.target.value
              })}
              type={"text"}
              required={false}
              name={"email"}
              label={"Email"}
              placeholder={"johndoe@edu.com"}
            />
          </div>
          <div className={"w-1/2 ml-2"}>
            <Input
              value={values.studentId}
              disabled={!isEditable || isStudent}
              onChange={(e) => onChange && onChange({
                key: "studentId",
                value: e.target.value
              })}
              type={"text"}
              required={false}
              name={"studentId"}
              label={"Student ID"}
              placeholder={"XXXXXX"}
            />
          </div>
        </div>
      </div>
      <div className={"mb-6"}>
        <div className={"flex items-center justify-between"}>
          <div className={"w-1/2 mr-2"}>
            <Input
              value={values.degree}
              disabled={!isEditable || isStudent}
              onChange={(e) => onChange && onChange({
                key: "degree",
                value: e.target.value
              })}
              type={"text"}
              required={false}
              name={"degree"}
              label={"Program"}
              placeholder={"High School Diploma"}
            />
          </div>
          {values.intendedGraduationSeason != null && values.intendedGraduationYear != null && <div className={"w-1/2 ml-2"}>
            <span className={"text-sm font-semibold text-neutral-darkest mb-1 block"}>Intended Graduation</span>
            <div className={"flex items-center justify-between"}>
              <div className={"w-1/2 mr-2"}>
                <Input
                  value={values.intendedGraduationSeason}
                  disabled={!isEditable || isStudent}
                  onChange={(e) => onChange && onChange({
                    key: "intendedGraduationSeason",
                    value: e.target.value
                  })}
                  type={"text"}
                  required={false}
                  name={"intendedGraduationSeason"}
                  placeholder={"Spring"}
                />
              </div>
              <div className={"w-1/2 ml-2"}>
                <Input
                  value={values.intendedGraduationYear}
                  disabled={!isEditable || isStudent}
                  onChange={(e) => onChange && onChange({
                    key: "intendedGraduationYear",
                    value: e.target.value
                  })}
                  type={"text"}
                  required={false}
                  name={"intendedGraduationYear"}
                  placeholder={"2023"}
                />
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

UserProfileAccountSettingFields.defaultProps = {
  isEditable: false
}
