import { createAction } from 'redux-act';
import { IPaginatedCourseOccupanciesResponse } from '../../../../../api/courses';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/dashboard/classes/course-occupancies] fetch'
);

export const load = {
  start: createAction(
    '[pages/dashboard/classes/course-occupancies] load.start'
  ),
  error: createAction<any>(
    '[pages/dashboard/classes/course-occupancies] load.error'
  ),
  done: createAction<IPaginatedCourseOccupanciesResponse>(
    '[pages/dashboard/classes/course-occupancies] load.done'
  ),
};

export const paginate = createAction<number>(
  '[pages/dashboard/classes/course-occupancies] paginate'
);
