import {createReducer} from "redux-act";
import * as actions from "./actions";

interface IState {
  english: number;
  history: number;
  math: number;
  science: number;
  athletics: number;
  performingArts: number;
  studioArts: number;
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
  english: 0,
  history: 0,
  math: 0,
  science: 0,
  athletics: 0,
  performingArts: 0,
  studioArts: 0,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    english: payload.english,
    history: payload.history,
    math: payload.math,
    science: payload.science,
    athletics: payload.athletics,
    performingArts: payload.performingArts,
    studioArts: payload.studioArts,
  }
});

export const profileInterestsReducer = reducer;

