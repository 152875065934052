import { combineEpics } from 'redux-observable';
import { epic as createNewTrack } from './create-track/epic';
import { epic as courseDetail } from './course-detail/epic';
import { epic as deleteTerm } from './delete-term/epic';
import { epic as requirementCategory } from './requirement-category-detail/epic';
import { epic as deleteTrack } from './delete-track/epic';
import { epic as editTrack } from './edit-track/epic';

export const epic = combineEpics(
  createNewTrack,
  courseDetail,
  deleteTerm,
  requirementCategory,
  deleteTrack,
  editTrack
);
