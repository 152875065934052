import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, filter, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { api } from '../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../infrastructure/selector';
import * as actions from './actions';

const beforeOpen: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.beforeOpen),
    filter(({ payload }) => {

      let hasNotification = !state$.value.pages.dashboard.trackNotifications.list.filter((notification: any) => 
      notification.course_id === payload.courseId).length;
        return hasNotification;
  }),
    map(({ payload }) => actions.open(payload))
  )

const open: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.open),
    map(({ payload }) => actions.fetch(payload))
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    mergeMap(({ payload }) =>
      of(actions.load.start(payload), actions.loadStatus.start(payload))
    )
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(({ payload }) =>
      from(
        api.courses.getCourseById(
          payload.courseId,
          state$.value.pages.dashboard.currentTrack.schedule.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const loadStatus: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.loadStatus.start),
    switchMap(({ payload }) => {
      return from(
        api.courses.getCourseStatus(payload.courseId, payload.termId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.loadStatus.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.loadStatus.error(error)).pipe(concat(source$))
    )
  );

const addCustomCourseDetails: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.addCustomCourseDetails.start),
    switchMap(({ payload }) => {
      const { name, code, units } =
        state$.value.pages.dashboard.modals.courseDetail.customCourseDetails;
      const courseId =
        state$.value.pages.dashboard.modals.courseDetail.course.id;
      const scheduleId = state$.value.pages.dashboard.currentTrack.schedule.id;

      return from(
        api.courses.createCustomCourseDetails({
          scheduleId,
          courseId,
          name,
          code,
          units: parseInt(units),
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.addCustomCourseDetails.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.addCustomCourseDetails.error(error.response.data)).pipe(
        concat(source$)
      );
    })
  );

const updateCustomCourseDetails: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.updateCustomCourseDetails.start),
    switchMap(({ payload }) => {
      const { name, code, units } =
        state$.value.pages.dashboard.modals.courseDetail.customCourseDetails;
      const courseId =
        state$.value.pages.dashboard.modals.courseDetail.course.id;
      const scheduleId = state$.value.pages.dashboard.currentTrack.schedule.id;

      return from(
        api.courses.updateCustomCourseDetails({
          scheduleId,
          courseId,
          name,
          code,
          units: parseInt(units),
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.updateCustomCourseDetails.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.updateCustomCourseDetails.error(error.response.data)).pipe(
        concat(source$)
      )
    )
  );

export const epic = combineEpics(
  open,
  fetch,
  load,
  loadStatus,
  addCustomCourseDetails,
  updateCustomCourseDetails,
  beforeOpen,
);
