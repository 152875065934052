import React from 'react';
import { useSelector } from '../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';

export interface IRequiresAccessProps {
  children: React.ReactNode;
}

export const RequiresMessagingAccess: React.FC<IRequiresAccessProps> = ({ children }) => {

  const { configuration } =
    useSelector(
      (state) => ({
        configuration: state.common.configs.configuration,
      }),
      shallowEqual
    );

  if(!configuration?.account?.tier?.messagingModuleAccess)
  {
    return null;
  }

  return (
    <>
      {children}
    </>
  )
}

RequiresMessagingAccess.defaultProps = {}
