import { createAction } from 'redux-act';
import { IAvailableCoursesResponse } from '../../../../../api/admin/courses';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/course/concurrency-requirements] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/concurrency-requirements] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/concurrency-requirements] load.error'
  ),
  done: createAction<IAvailableCoursesResponse>(
    '[pages/admin/dashboard/settings/course/concurrency-requirements] load.done'
  ),
};

export const onChange = createAction<{ label: string; value: string }[]>(
  '[pages/admin/dashboard/settings/course/concurrency-requirements] onChange'
);
