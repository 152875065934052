import { createAction } from 'redux-act';
import { IValidationErrorResponse } from '../../../types/error';

export type IActions =
  | ReturnType<typeof open>
  | ReturnType<typeof close>
  | ReturnType<typeof fetch>
  | ReturnType<typeof save.start>
  | ReturnType<typeof save.done>
  | ReturnType<typeof save.error>;

export const paginate = {
  next: createAction('[common/modals/complete-profile] paginate.next'),
  prev: createAction('[common/modals/complete-profile] paginate.prev'),
}
export const open = createAction(
  '[common/modals/complete-profile] open'
);
export const close = createAction(
  '[common/modals/complete-profile] close'
);
export const save = {
  start: createAction(
    '[common/modals/complete-profile] save.start'
  ),
  error: createAction<IValidationErrorResponse>('[common/modals/complete-profile] save.error'),
  done: createAction(
    '[common/modals/complete-profile] save.done'
  ),
};

export const onInterestChange = createAction<{ key: string, value: number }>('[common/modals/complete-profile] onInterestChange');
export const onAboutChange = createAction<{ key: string; value: string; }>('[common/modals/complete-profile] onAboutChange');
