import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import { api } from '../../../../../../api';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchOnUpdate: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.done, actions.create.done),
    map(() => actions.fetch())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.studentNotes.getStudentNotes(state$.value.common.match.params.id)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const create: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.create.start),
    switchMap(({ payload }) =>
      from(
        api.studentNotes.createStudentNote(payload.studentId, payload.content)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.create.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.create.error(error.response.data)).pipe(concat(source$))
    )
  );

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(({ payload }) =>
      from(
        api.studentNotes.updateStudentNote(payload.id, payload.content)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.update.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.update.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, create, update, fetchOnUpdate);
