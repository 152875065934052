import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourseOccupancyInfo } from '../../../../../api/courses';

interface IState {
  currentPage: number;
  lastPage: number;
  headers: { label: string; season: number; year: number; }[];
  list: ICourseOccupancyInfo[];
  isLoading: boolean;
}

const initialState: IState = {
  headers: [],
  isLoading: false,
  list: [],
  currentPage: 1,
  lastPage: 1,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    headers: payload.headers,
    list: payload.list,
    isLoading: false,
    currentPage: payload.currentPage,
    lastPage: payload.lastPage,
  };
});

reducer.on(actions.paginate, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    currentPage: payload,
  };
});

export const courseOccupanciesReducer = reducer;
