import { createAction } from 'redux-act';
import { IUserAccountSettings, IUserProfileInterests } from '../../../../api/user-profile';
import { IValidationErrorResponse } from '../../../../types/error';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const fetch = createAction(
  '[pages/user-profile/account-settings/modal] fetch'
);

export const load = {
  start: createAction(
    '[pages/user-profile/account-settings/modal] load.start'
  ),
  error: createAction<any>(
    '[pages/user-profile/account-settings/modal] load.error'
  ),
  done: createAction<IUserAccountSettings>(
    '[pages/user-profile/account-settings/modal] load.done'
  ),
};

export const save = {
  start: createAction(
    '[pages/user-profile/account-settings/modal] save.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/user-profile/account-settings/modal] save.error'
  ),
  done: createAction<IUserAccountSettings>(
    '[pages/user-profile/account-settings/modal] save.done'
  ),
};

export const open = createAction(
  '[pages/user-profile/account-settings/modal] open'
);
export const close = createAction(
  '[pages/user-profile/account-settings/modal] close'
);

export const onChange = createAction<{ key: string; value: string; }>(
  '[pages/user-profile/account-settings/modal] onChange'
);




