import * as React from 'react';
import { createContext, useState, useCallback, useEffect } from 'react';
import { MdClose } from '@react-icons/all-files/md/MdClose';

const ToastsContext = createContext<any>([]);

export const useToasts = () => {
  return React.useContext(ToastsContext);
};

export default ToastsContext;

interface IToastProviderProps {
  children: React.ReactNode;
}

export enum ToastVariant {
  Success,
  Info,
  Warning,
  Error,
}

export interface IToast {
  message: string;
  variant: ToastVariant;
}

export const ToastProvider: React.FC<IToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  useEffect(() => {
    const timer = setTimeout(
      () => setToasts((toasts) => toasts.slice(1)),
      3000
    );
    return () => clearTimeout(timer);
  }, [toasts]);

  const addToast = useCallback(
    function (toast: IToast) {
      setToasts((toasts) => [...toasts, toast]);
    },
    [setToasts]
  );

  return (
    <ToastsContext.Provider value={[addToast]}>
      {children}
      <>
        {!!toasts.length &&
          toasts.map((toast: IToast, index: number) => {
            let classes =
              'shadow-lg font-semibold text-xs fixed border p-3 rounded-lg top-5 right-5 flex items-center justify-between';

            if (toast.variant == ToastVariant.Success) {
              classes += ' bg-green-lightest border-green text-green';
            }

            if (toast.variant == ToastVariant.Error) {
              classes += ' bg-red-lightest border-red text-red';
            }

            return (
              <div key={index} className={classes} style={{ zIndex: 10000 }}>
                {toast.message}
                {/*<MdClose className={"fill-red text-lg"}/>*/}
              </div>
            );
          })}
      </>
    </ToastsContext.Provider>
  );
};
