import React from 'react';
import { UserProfileInterestFields } from '../../../../pages/student-profile/interests/fields';
import { useSelector } from '../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from "../actions";

export const CompleteProfileInterests: React.FC = () => {

  const dispatch = useDispatch();

  const { english, history, math, science, performingArts, athletics, studioArts } = useSelector(
    (state) => ({
      english: state.common.modals.completeProfile.interests.english,
      history: state.common.modals.completeProfile.interests.history,
      math: state.common.modals.completeProfile.interests.math,
      science: state.common.modals.completeProfile.interests.science,
      performingArts: state.common.modals.completeProfile.interests.performingArts,
      athletics: state.common.modals.completeProfile.interests.athletics,
      studioArts: state.common.modals.completeProfile.interests.studioArts,
    }),
    shallowEqual
  );

  return (
    <>
      <h3 className={"text-neutral-darkest font-semibold text-2xl"}>What are you into?</h3>
      <UserProfileInterestFields
        isScrollable={true}
        isEditable={true}
        // @ts-ignore
        onChange={(value) => dispatch(actions.onInterestChange({
          ...value
        }))}
        values={{
          english,
          history,
          math,
          science,
          performingArts,
          studioArts,
          athletics
        }}
      />
    </>
  )
}
