import { combineReducers } from 'redux';

import { configReducer as configs } from '../configs/reducer';
import { userReducer as user } from '../user/reducer';
import { matchReducer as match } from '../match/reducer';
import { filtersReducer as filters } from '../filters/reducer';
import { drawersReducer as drawers } from '../drawers/reducer';
import { alertsReducer as alerts } from '../alerts/reducer';
import { modalsReducer as modals } from '../modals/reducer';

export const reducers = combineReducers({
  configs,
  user,
  match,
  filters,
  alerts,
  drawers,
  modals
});
