import React from 'react';
import { ISVGProps } from '../index';

export const AddCourse: React.FC<ISVGProps> = ({ classes }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={classes}>
            <path fill={"currentColor"} stroke={"none"} d="M10 14H14V12H10V14ZM10 11H18V9H10V11ZM10 8H18V6H10V8ZM8 18C7.45 18 6.979 17.804 6.587 17.412C6.195 17.02 5.99934 16.5493 6 16V4C6 3.45 6.196 2.979 6.588 2.587C6.98 2.195 7.45067 1.99934 8 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.804 17.021 21.412 17.413C21.02 17.805 20.5493 18.0007 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.979 21.804 2.587 21.412C2.195 21.02 1.99934 20.5493 2 20V6H4V20H18V22H4Z"/>
            <circle stroke={"none"} cx="3" cy="21" r="3" fill="#2563EB"/>
            <path stroke={"none"} fill={"currentColor"} d="M3 18C2.20732 18.0096 1.4498 18.3287 0.889254 18.8893C0.328704 19.4498 0.00956345 20.2073 0 21C0.00956345 21.7927 0.328704 22.5502 0.889254 23.1107C1.4498 23.6713 2.20732 23.9904 3 24C3.79268 23.9904 4.5502 23.6713 5.11075 23.1107C5.6713 22.5502 5.99044 21.7927 6 21C5.99044 20.2073 5.6713 19.4498 5.11075 18.8893C4.5502 18.3287 3.79268 18.0096 3 18ZM4.71429 21.2143H3.21429V22.7143H2.78571V21.2143H1.28571V20.7857H2.78571V19.2857H3.21429V20.7857H4.71429V21.2143Z"/>
        </svg>

    )
}

AddCourse.defaultProps = {
    classes: ''
}
