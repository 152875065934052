import React from 'react';
import { IMessage } from '../../../../../../api/messages';
import { UserAvatar } from '../../../../../../components/avatar';
import moment from 'moment';
import { IUser } from '../../../../../../api/user';

export interface IMessageProps {
  currentUserIsCreator: boolean;
  message: IMessage;
  sender: IUser;
}
export const Message: React.FC<IMessageProps> = ({
  message,
  currentUserIsCreator,
  sender,
}) => {
  if (currentUserIsCreator) {
    return (
      <>
        <div className={'flex w-full justify-end'}>
          <div className={'mb-6 flex flex-col'}>
            <div className={'flex'}>
              <div
                className={
                  'bg-baby-blue rounded-l-xl rounded-br-xl mr-2 flex items-center'
                }
              >
                <p className={'text-white px-4 py-2 text-sm'}>{message.body}</p>
              </div>
              <UserAvatar size={40} imageUrl={sender?.avatarUrl} />
            </div>
            <span className={'text-neutral text-xxs mt-2'}>
              {moment(message.createdAt).fromNow()}
            </span>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={'flex mb-6 w-full flex-col'}>
        <div className={'flex'}>
          <UserAvatar size={40} imageUrl={sender?.avatarUrl} />
          <div className={'bg-blue px-4 py-2 rounded-r-xl rounded-bl-xl ml-2'}>
            <p className={'text-white m-0 p-0 text-sm'}>{message.body}</p>
          </div>
        </div>
        <span className={'text-neutral text-left text-xxs mt-2'}>
          {moment(message.createdAt).fromNow()}
        </span>
      </div>
    </>
  );
};
