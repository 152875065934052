import {combineEpics, ofType} from "redux-observable";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import {EMPTY, from, of} from "rxjs";
import * as actions from "./actions";
import * as adminStudentActions from "../../admin/dashboard/student/head/actions";
import * as adminCourseBinActions from "../../admin/dashboard/student/schedule/course-bin/actions";
import {api} from "../../../api";
import {concat} from "rxjs/operators";
import {IEpic} from "../../../infrastructure/selector";
import * as termActions from "../../../components/DragAndDropSchedule/term/actions";
import * as editTrackModalActions from "../modals/edit-track/actions";
import * as courseDetailModalActions from "../modals/course-detail/actions";
import {IScheduleTermGroup} from "../../../api/schedules";
import {IScheduleTerm} from "../../../api/schedule-terms";
import {Helpers} from "../../../helpers";
import * as courseBinActions from "./course-bin/actions";
import {ICourse} from "../../../types/course";
import GoogleAnalytics from "../../../analytics/index";

const fetchOnEdit: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(editTrackModalActions.update.done),
        map(() => actions.fetch())
    );

const fetchOnCustomCourseDetailCreation: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(courseDetailModalActions.addCustomCourseDetails.done, courseDetailModalActions.updateCustomCourseDetails.done),
        map(() => actions.fetch())
    );

const fetchOnTermAdd: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(termActions.addV2.done),
        map(() => actions.fetch())
    );

const fetchOnRemoveDone: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(termActions.removeV2.done),
        map(() => actions.fetch())
    );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(api.schedules.getSchedule(state$.value.common.match.params.id)).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(({ payload }) => {
      const { schedule } = state$.value.pages.dashboard.currentTrack;

      const seasons = schedule.configuration.availableSeasons;

      return from(
        api.schedules.updateSchedule(
          schedule.id,
          schedule.name,
          schedule.majors,
          payload,
          seasons
        )
      ).pipe(
        mergeMap(({ data }) => {
          GoogleAnalytics.Schedule.addScheduleUpdateEvent({
            scheduleId: data.id,
          });

          return of(actions.update.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.update.error(error)).pipe(concat(source$))
    )
  );

const updateTermGroups: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(({ payload }) => {
      const { schedule } = state$.value.pages.dashboard.currentTrack;

      let termGroups = schedule.termGroups.map((group: IScheduleTermGroup) => {
        group.list = group.list.map((term: IScheduleTerm) => {
          let payloadTerm = payload.find(
            (payloadTerm: IScheduleTerm) => payloadTerm.id == term.id
          );
          return !!payloadTerm ? payloadTerm : term;
        });

        return group;
      });

      return of(actions.updateTermGroups.done(termGroups));
    }),
    catchError((error, source$) =>
      of(actions.update.error(error)).pipe(concat(source$))
    )
  );

const onDragEnd: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onDragEnd.start),
    //@ts-ignore
    switchMap(({ payload }) => {
      const { source, destination } = payload;
      let schedule = state$.value.pages.dashboard.currentTrack.schedule;
      let courseBin = state$.value.pages.dashboard.courseBin.list;

      // TODO Need to not do this!!!!
      if (window.location.href.includes('admin')) {
        schedule = state$.value.pages.admin.dashboard.student.schedule.schedule;
        courseBin = state$.value.pages.admin.dashboard.student.courseBin.list;
      }

      const getList = (id: string): ICourse[] => {
        // console.log("idList", this.id2List);
        // console.log("state", this.state);
        // console.log("id", id);
        // console.log("list", this.state[this.id2List[id]]);

        // console.log(schedule);
        // console.log(id);

        if (id == 'course-bin') {
          return courseBin;
        }

        return schedule.terms.find(
          (term: IScheduleTerm) => term.id.toString() === id
        ).courses;
      };

      console.log('source', source);
      console.log('dest', destination);

      //dropped outside the list
      if (!destination) {
        return EMPTY;
      }

      if (source.droppableId === destination.droppableId) {
        const items = Helpers.reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        );

        let newTerms = schedule.terms.map((term: IScheduleTerm) => {
          if (term.id.toString() === source.droppableId) {
            term.courses = items;
          }
          return term;
        });

      } else {

        const result = Helpers.move(
          [
            ...schedule.terms,
            {
              id: 'course-bin',
              name: '',
              courses: [...courseBin],
              includeInTotal: false,
              creditTotal: 0,
            },
          ],
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        );

        //@ts-ignore
        let terms = result.terms.filter((term) => term.id != 'course-bin');

        // @ts-ignore
        let courseBinCourses = result.terms.find((term) => term.id == 'course-bin').courses;

        let courseBinCourseIds: number[] = [];
        // Remove courses that are already in the course bin, no duplicates, helps with repeatable courses drag and drop
        courseBinCourses = courseBinCourses.filter((course: ICourse) => {
          if(!courseBinCourseIds.includes(course.id)) {
            courseBinCourseIds.push(course.id);
            return course;
          }
        });

        // TODO: Need to completely do this differently, not scalable
        if (window.location.href.includes('admin')) {
          return of(
            adminStudentActions.update.start(terms),
            adminCourseBinActions.update(courseBinCourses)
          );
        } else {
          return of(
            actions.update.start(terms),
            courseBinActions.update(courseBinCourses)
          );
        }
      }
    }),
    catchError((error, source$) =>
      of(actions.update.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(
  fetch,
  load,
  update,
  fetchOnTermAdd,
  fetchOnRemoveDone,
  fetchOnEdit,
  updateTermGroups,
  fetchOnCustomCourseDetailCreation,
  onDragEnd
);
