import React from 'react';
import { Students } from './students';
import { Search } from './search';

export const StudentStatusData: React.FC = () => {
  return (
    <section>
      <h3 className={'text-xl text-neutral-darkest mt-12'}>
        Student Status Data
      </h3>
      <span className={'text-xs text-neutral-light'}>
        Based on completed and planned courses
      </span>
      <Search />
      <Students />
    </section>
  );
};
