import React from 'react';
import { CompleteProfileModal } from './complete-profile';

export const CommonModals: React.FC = () => {
  return (
    <>
      <CompleteProfileModal />
    </>
  )
}
