import React from 'react';
import { SearchInput } from '../../../../../components/search-input';
import { useDispatch } from 'react-redux';
import * as actions from "./actions";
import { debounce } from 'lodash';

export const SearchCourses: React.FC = () => {

  const dispatch = useDispatch();

  const handleOnChange = (e: any) => {
    dispatch(actions.onChange(e.target.value))
  }

  return (
    <div className={"mb-6"}>
      <SearchInput name='search' handleOnChange={debounce(handleOnChange, 250)} placeholder='Search courses by name' />
    </div>
  )
}
