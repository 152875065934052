import {createReducer} from "redux-act";
import * as actions from "./actions";

interface IState {
  dreamVocationAsKid: string;
  futureDreamVocation: string;
  favoriteThingToDo: string;
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
  dreamVocationAsKid: '',
  futureDreamVocation: '',
  favoriteThingToDo: '',
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    dreamVocationAsKid: payload.dreamVocationAsKid,
    futureDreamVocation: payload.futureDreamVocation,
    favoriteThingToDo: payload.favoriteThingToDo,
  }
});

export const profileAboutReducer = reducer;

