import { combineEpics, ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import * as actions from './actions';
import * as listActions from "../list/actions";
import { IEpic } from '../../../../../infrastructure/selector';
import { ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router';

const search: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onChange),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => listActions.fetch())
  );

const reset: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(ROUTER_ON_LOCATION_CHANGED),
    map(() => actions.reset())
  );

export const epic = combineEpics(search, reset);
