import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { map } from 'rxjs/operators';
import * as actions from './actions';
import { IProgressPayload } from './actions';
import * as trackActions from '../current-track/actions';
import { IEpic, IState } from '../../../infrastructure/selector';
import { IScheduleTerm } from '../../../api/schedule-terms';
import { ICourse } from '../../../types/course';

// TODO: @deprecated Progression is already saved in the db this is only and shouldn't be continued to be used
const getProgressPayload = (
  state$: StateObservable<IState>
): IProgressPayload => {
  const { schedule } = state$.value.pages.dashboard.currentTrack;

  if (!schedule) {
    return {
      planned: 0,
      unplanned: 0,
      completed: 0,
      unitsRequired: 38,
    };
  }

  const plannedCredits = schedule.terms
    .map((term: IScheduleTerm) =>
      term.courses
        .map((course: ICourse) => course.credits)
        .reduce((a: number, b: number) => a + b, 0)
    )
    .reduce((a: number, b: number) => a + b, 0);

  const completedCredits = schedule.terms
    .filter((term: IScheduleTerm) => term.isCompleted)
    .map((term: IScheduleTerm) =>
      term.courses
        .map((course: ICourse) => course.credits)
        .reduce((a: number, b: number) => a + b, 0)
    )
    .reduce((a: number, b: number) => a + b, 0);

  const unplannedCredits =
    state$.value.pages.dashboard.progress.unitsRequired - plannedCredits;

  return {
    planned: plannedCredits,
    unplanned: unplannedCredits,
    completed: completedCredits,
    unitsRequired: !!schedule.majors.length
      ? schedule.majors[0].totalCreditsRequired
      : 0,
  };
};

const updateOnLoad: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(trackActions.load.done),
    map(() => {
      let payload = getProgressPayload(state$);
      return actions.update(payload);
    })
  );

const updateOnUpdate: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(trackActions.update.done),
    map(() => {
      let payload = getProgressPayload(state$);
      return actions.update(payload);
    })
  );

export const epic = combineEpics(updateOnLoad, updateOnUpdate);
