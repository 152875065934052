import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  debounce,
} from 'rxjs/operators';
import { from, interval, of } from 'rxjs';
import * as actions from './actions';
import { IEpic } from '../../infrastructure/selector';

const clear: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.trigger),
    debounce(() => interval(3000)),
    map(() => actions.clear())
  );

export const epic = combineEpics(clear);
