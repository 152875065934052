import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../infrastructure/selector';
import { NoSchedules } from '../dashboard/no-schedules';

// TODO: Move this under student dashboard
export const Schedules: React.FC = () => {
  const dispatch = useDispatch();

  const { list, isLoading } = useSelector(
    (state) => ({
      list: state.pages.schedules.list,
      isLoading: state.pages.schedules.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  if (!!list.length) {
    window.location.href = `/student/dashboard/schedules/${list[0].id}`;
  }

  return <>{!list.length && <NoSchedules />}</>;
};
