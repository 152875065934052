import React from 'react';
import { UserProfileInterestFields } from '../../../../../../student-profile/interests/fields';
import { useSelector } from '../../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';

export const ProfilePreview: React.FC = () => {

  const { english, history, math, science, performingArts, athletics, studioArts, isLoading, dreamVocationAsKid, futureVocation, favoriteThingToDo, studentName } = useSelector(
    (state) => ({
      isLoading: state.pages.admin.dashboard.drawers.studentDetail.isLoading,
      studentName: state.pages.admin.dashboard.drawers.studentDetail.name,
      dreamVocationAsKid: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.aboutYou.dreamVocationAsKid,
      futureVocation: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.aboutYou.futureVocation,
      favoriteThingToDo: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.aboutYou.favoriteThingToDo,
      english: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.interests.english,
      history: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.interests.history,
      math: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.interests.math,
      science: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.interests.science,
      performingArts: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.interests.performingArts,
      studioArts: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.interests.studioArts,
      athletics: state.pages.admin.dashboard.drawers.studentDetail.previews.profile.interests.athletics,
    }),
    shallowEqual
  );

  if(isLoading) {
    return null;
  }

  return (
    <>
      <div className={"rounded-md shadow-md p-4 bg-white mt-3 mb-4"}>
          <h4 className={"text-neutral-darkest text-xl"}>About {studentName}</h4>
          <hr className={"border-b border-baby-blue mb-4"}/>

          <div className={"mb-4"}>
            <span className={"mb-2 block text-baby-blue text-sm"}>When I was a kid, I wanted to be a...</span>
            <div className={"border rounded-full border-neutral-darkest px-4 py-1 text-sm text-neutral-darkest"}>{dreamVocationAsKid ?? "N/A"}</div>
          </div>
        <div className={"mb-4"}>
          <span className={"mb-2 block text-baby-blue text-sm"}>When I imagine my future, I see myself as a...</span>
          <div className={"border rounded-full border-neutral-darkest px-4 py-1 text-sm text-neutral-darkest"}>{futureVocation ?? "N/A"}</div>
        </div>
        <div>
          <span className={"mb-2 block text-baby-blue text-sm"}>My favorite thing to do in life is...</span>
          <div className={"border rounded-full border-neutral-darkest px-4 py-1 text-sm text-neutral-darkest"}>{favoriteThingToDo ?? "N/A"}</div>
        </div>
      </div>
      <div className={"rounded-md shadow-md p-4 bg-white pb-10"}>
        <h4 className={"text-neutral-darkest text-xl"}>{studentName}'s Interests</h4>
        <hr className={"border-b border-baby-blue mb-4"}/>

        <UserProfileInterestFields values={{
          english,
          history,
          math,
          science,
          performingArts,
          athletics,
          studioArts
        }} isEditable={false} />
      </div>
    </>
  )
}
