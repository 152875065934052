import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { IEpic } from '../../../../../../infrastructure/selector';
import * as studentActions from '../students/actions';
import { ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router';

const onKeywordSearchChange: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.onStudentChange),
    map(() => studentActions.fetch())
  );

const onLocationChange: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(ROUTER_ON_LOCATION_CHANGED),
    map(() => studentActions.fetch())
  );

export const epic = combineEpics(onKeywordSearchChange, onLocationChange);
