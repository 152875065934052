import { combineEpics, ofType } from 'redux-observable';
import {catchError, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../api';
import { concat } from 'rxjs/operators';
import {IEpic} from "../../../../infrastructure/selector";
import * as alertActions from '../../../../common/alerts/actions';
import { AlertVariant } from '../../../../common/alerts/reducer';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(() => !!state$.value.common.user.info.id),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {

        const userId = state$.value.common.user.info.id;

        return from(api.userProfile.getAccountSettings(userId)).pipe(
          mergeMap(({ data }) => {
            return of(actions.load.done(data));
          })
        )
      }

    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const save: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.start),
    switchMap(() => {

        const userId = state$.value.common.user.info.id;

        const { legalName, preferredName, email, studentId, degree, intendedGraduation  } = state$.value.pages.studentProfile.accountSettings.modal;

        return from(api.userProfile.updateAccountSettings(userId, {
          legalName,
          preferredName,
          email,
          studentId,
          degree,
          intendedGraduation
        })).pipe(
          mergeMap(({ data }) => {
            return of(actions.save.done(data));
          })
        )
      }

    ),
    catchError((error, source$) =>
      of(actions.save.error(error.response.data)).pipe(concat(source$))
    )
  );

const onSave: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.done),
    map(() => alertActions.trigger({
      variant: AlertVariant.Success,
      heading: "Saved!",
      message: "Account Settings updated successfully"
    }))
  );

export const epic = combineEpics(fetch, load, save, onSave);
