export default {
  blueDarkest: '#826205',
  blue: '#9d7910',
  green: '#16A34A',
  'green-lighter': '#BBF7D0',
  'green-lightest': '#F0FFF2',
  'purple-light': '#E5E7F4',
  purple: '#782BD9',
  babyBlueLightest: '#fff7e0',
  babyBlueLighter: '#ddb234',
  babyBlue: '#c2930b',
  red: '#DC2626',
  'red-lighter': '#FECACA',
  'red-lightest': '#FFE6EE',
  'neutral-lightest': '#F8F8F8',
  'neutral-lighter': '#F1F1F1',
  'neutral-light': '#CFCFCF',
  neutral: '#8F8F8F',
  'neutral-dark': '#676767',
  'neutral-darker': '#F1F2F4',
  'neutral-darkest': '#040404',
  'yellow-dark': '#CA8A04',
  yellow: '#FFBF08',
  'yellow-lighter': '#FEF08A',
  'yellow-lightest': '#FFF6DC',
  primary: '#c2930b',
  secondary: '#3c3c3c',
  tertiary: '#333333',
};
