import { createAction } from 'redux-act';
import { IConfiguration } from '../../../api/configuration';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[configuration] fetch');

export const load = {
  start: createAction('[configuration] load.start'),
  error: createAction<any>('[configuration] load.error'),
  done: createAction<IConfiguration>('[configuration] load.done'),
};
