import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
import { Detail, EditableDetailFieldType } from './detail';
import {
  ICourseRequirementGroup,
  IRequiredCourse,
} from '../../../../types/ICourseRequirementGroup';
import { Modal } from '../../../../components/modal';
import * as actions from './actions';
import { Status } from './status';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import { Button } from '../../../../ui';
import { ButtonStyle, ButtonVariant } from '../../../../ui/button';
import { Input } from '../../../../components/input';
import SkeletonModal from '../../../../components/modal/skeleton';

export const CourseDetailModal: React.FC = () => {
  const dispatch = useDispatch();

  const {
    isOpen,
    course,
    isModalLoading,
    isEditMode,
    isCustomContentLoading,
    error
  } = useSelector(
    (state) => ({
      isEditMode: state.pages.dashboard.modals.courseDetail.isEditMode,
      isOpen: state.pages.dashboard.modals.courseDetail.isOpen,
      isModalLoading: state.pages.dashboard.modals.courseDetail.isLoading,
      course: state.pages.dashboard.modals.courseDetail.course,
      isCustomContentLoading:
        state.pages.dashboard.modals.courseDetail.customCourseDetails.isLoading,
      error:
        state.pages.dashboard.modals.courseDetail.customCourseDetails.error,
    }),
    shallowEqual
  );

  console.log('error', error);

  if ( !isOpen ) {
    return null;
  }

  if (isModalLoading) {
    return <SkeletonModal handleClose={() => dispatch(actions.close())} />;
  }

  const requirementCategory =
    !!course.requirementCategories.length && course.requirementCategories[0];
  const coreqs = course.courseConcurrencyGroups
    ?.map((group: ICourseRequirementGroup) =>
      group.list.map((course: IRequiredCourse) => course.code)
    )
    .flat();
  const prereqs = course.coursePrerequisiteGroups
    ?.map((group: ICourseRequirementGroup) =>
      group.list.map((course: IRequiredCourse) => course.code)
    )
    .flat();

  return (
    <>
      <Modal
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderHeader={() => <header></header>}
        renderBody={() => <div></div>}
        renderFooter={() => (
          <footer>
            {isEditMode && (
              <section className={'flex justify-end w-full'}>
                <div className={'mr-2'}>
                  <Button
                    style={ButtonStyle.Outline}
                    variant={ButtonVariant.Secondary}
                    handleOnClick={() => dispatch(actions.toggleEdit())}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    isLoading={isCustomContentLoading}
                    variant={ButtonVariant.Secondary}
                    handleOnClick={() =>
                      dispatch(
                        course.isUserGenerated
                          ? actions.updateCustomCourseDetails.start()
                          : actions.addCustomCourseDetails.start()
                      )
                    }
                  >
                    Save Changes
                  </Button>
                </div>
              </section>
            )}
          </footer>
        )}
      >
        <>
          <div className={'flex items-center justify-between mb-6'}>
            <div>
              <div className={'flex items-baseline'}>
                {isEditMode && (
                  <Input
                    name={'name'}
                    type={'text'}
                    required={true}
                    error={error}
                    onChange={(e) =>
                      dispatch(actions.onNameChange(e.target.value))
                    }
                    className={
                      'w-full bg-transparent border-none text-4xl font-semibold focus:outline-0 placeholder:text-baby-blue-lightest'
                    }
                    style={{ color: requirementCategory.color }}
                    placeholder={'Enter name...'}
                  />
                )}
                {!isEditMode && (
                  <h2
                    className={'text-4xl text-black font-semibold mr-3'}
                    style={{ color: requirementCategory.color }}
                  >
                    {course?.name}
                  </h2>
                )}
                {course.customDetailsEnabled && !isEditMode && (
                  <div className={'cursor-pointer'}>
                    <AiOutlineEdit
                      className={'fill-neutral-darkest text-xl'}
                      onClick={() => dispatch(actions.toggleEdit())}
                    />
                  </div>
                )}
              </div>
              <span className={'text-sm tracking-widest text-neutral-dark'}>
                {requirementCategory.name}
              </span>
            </div>
            <Status />
          </div>
          <hr className={'border border-neutral-lightest w-full'} />
          <section className={'w-10/12 mt-4'}>
            <Detail
              error={error}
              canBeEdited={true}
              fieldType={EditableDetailFieldType.Text}
              isEditMode={isEditMode}
              title={'Course Code'}
              items={[course.code]}
            />
            <Detail
              error={error}
              canBeEdited={true}
              fieldType={EditableDetailFieldType.Select}
              isEditMode={isEditMode}
              title={'Units'}
              items={[course.credits + ' units']}
            />
            <Detail
              error={error}
              isEditMode={isEditMode}
              title={'Pre reqs'}
              items={prereqs}
            />
            <Detail
              error={error}
              isEditMode={isEditMode}
              title={'Co reqs'}
              items={coreqs}
            />
          </section>
        </>
      </Modal>
    </>
  );
};
