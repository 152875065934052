import { combineReducers } from 'redux';

import { createDegreeReducer as create } from './create/reducer';
import { degreesIndexReducer as list } from './list/reducer';
import { editDegreeReducer as update } from './edit/reducer';

export const degreesReducer = combineReducers({
  create,
  list,
  update,
});
