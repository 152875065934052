import { createAction } from 'redux-act';
import { IDegreeRequirement } from '../../../../../../../api/admin/degrees';
import { ISelectOption } from '../../../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof create.done>
  | ReturnType<typeof create.done>
  | ReturnType<typeof create.error>;

export const create = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/create] create.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/create] create.error'
  ),
  done: createAction<IDegreeRequirement>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/create] create.done'
  ),
};

export const onTypeChange = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/create] onTypeChange'
);
export const onChange = createAction<{
  [key: string]: string | boolean | undefined | number;
}>(
  '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/create] onChange'
);
