import {Input} from "../../../../components/input";
import React from "react";
import { IValidationErrorResponse } from '../../../../types/error';

export interface IUserProfileAboutYouFields {
    isEditable?: boolean;
    errors?: IValidationErrorResponse;
    vocationAsKid: string|null;
    futureVocation: string|null;
    favoriteThingToDo: string|null;
    onVocationAsKidChange?: (value: string) => any;
    futureVocationChange?: (value: string) => any;
    favoriteThingToDoChange?: (value: string) => any;
}

export const UserProfileAboutYouFields: React.FC<IUserProfileAboutYouFields> = ({ vocationAsKid, futureVocation, favoriteThingToDo, errors, isEditable, onVocationAsKidChange, futureVocationChange, favoriteThingToDoChange }) => {
    return (
        <div className={"mt-4"}>
            <div className={"mb-6"}>
                <Input
                    value={vocationAsKid ?? ""}
                    error={errors}
                    disabled={!isEditable}
                    onChange={(e) => onVocationAsKidChange && onVocationAsKidChange(e.target.value)}
                    type={"text"}
                    required={false}
                    name={"dreamVocationAsKid"}
                    label={"When I was a kid, I wanted to be a..."}
                    placeholder={"Astronaut"}
                />
            </div>
            <div className={"mb-6"}>
                <Input
                    value={futureVocation ?? ""}
                    error={errors}
                    disabled={!isEditable}
                    onChange={(e) => futureVocationChange && futureVocationChange(e.target.value)}
                    type={"text"}
                    required={false}
                    name={"futureDreamVocation"}
                    label={"When I imagine my future, I see myself as..."}
                    placeholder={"Business owner"}
                />
            </div>
            <div className={"mb-6"}>
                <Input
                    value={favoriteThingToDo ?? ""}
                    error={errors}
                    disabled={!isEditable}
                    onChange={(e) => favoriteThingToDoChange && favoriteThingToDoChange(e.target.value)}
                    type={"text"}
                    required={false}
                    name={"favoriteThingToDo"}
                    label={"My favorite thing to do in life is..."}
                    placeholder={"Dance"}
                />
            </div>
        </div>
    )
}

UserProfileAboutYouFields.defaultProps = {
    isEditable: false
}
