import React from 'react';

export enum PillVariants {
  Primary,
  Secondary,
  White,
}

interface IPillProps {
  variant?: PillVariants;
  isActive: boolean;
  children: any;
}

export const Pill: React.FC<IPillProps> = ({ variant, children, isActive }) => {
  let classes =
    'rounded-full px-5 py-2 text-xs font-normal rounded-full cursor-pointer';

  if (isActive) {
    if (variant == PillVariants.Primary) {
      classes += ' text-white bg-baby-blue';
    }
  } else {
    classes += ' bg-white text-black shadow-md';
  }

  return <div className={classes}>{children}</div>;
};

Pill.defaultProps = {
  variant: PillVariants.White,
  isActive: false,
};
