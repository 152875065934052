import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { IMajor, IMinor } from '../majors';
import { ISchedule } from '../schedules';
import { courses } from './courses';
import { degrees } from './degrees';
import { invites } from './invites';
import { blackbaud } from "./blackbaud";

export interface IAdminOverviewStats {
  averageUnitLoad: number;
  studentsNotFullyPlanned: number;
  plannedToGraduate: number;
  currentSeason: number;
  currentYear: number;
}

export interface IStudentGraduationStatus {
  status: number;
  label: string;
  color: string;
}

export interface IStudentSnapshotResponse {
  id: number;
  name: string;
  avatarUrl: string;
  email: string;
  schoolId: number;
  estimatedGraduation: string;
  majors: IMajor[];
  minors: IMinor[];
  graduationStatus: IStudentGraduationStatus;
  schedule: ISchedule;
}

export interface IAdminOverview {
  activeStudents: number;
  activeCourses: number;
  emptySchedules: number;
  pendingStudentInvitations: number;
  pendingRegistrations: number;
  studentFlags: number;
  courseConflicts: number;
}

export interface IStudentFlag {
  id: number;
  context: string;
  studentId: number;
  resolvedAt?: string;
}

export interface IAdmin {
  getOverview(schoolId: number): AxiosPromise<IAdminOverview>;
  getOverviewStats(schoolId: number): AxiosPromise<IAdminOverviewStats>;
  getStudentSnapshot(studentId: number): AxiosPromise<IStudentSnapshotResponse>;
  getStudentFlags(studentId: number): AxiosPromise<IStudentFlag[]>;
  createStudentFlag(
    studentId: number,
    context: string
  ): AxiosPromise<IStudentFlag>;
  destroyStudentFlag(flagId: number): AxiosPromise<IStudentFlag>;
}

class AdminAPI implements IAdmin {
  public courses = courses;
  public degrees = degrees;
  public invites = invites;
  public blackbaud = blackbaud;

  getOverview(schoolId: number): AxiosPromise<IAdminOverview> {
    return client.get(`/api/admin/overview`, {
      params: {
        schoolId,
      },
    });
  }

  getOverviewStats(schoolId: number): AxiosPromise<IAdminOverviewStats> {
    return client.get('/api/admin/overview/stats', {
      params: {
        schoolId,
      },
    });
  }

  getStudentSnapshot(
    studentId: number
  ): AxiosPromise<IStudentSnapshotResponse> {
    return client.get(`/api/admin/students/${studentId}/schedule`);
  }

  getStudentFlags(studentId: number): AxiosPromise<IStudentFlag[]> {
    return client.get('/api/admin/student/flags', {
      params: {
        studentId,
      },
    });
  }

  createStudentFlag(
    studentId: number,
    context: string
  ): AxiosPromise<IStudentFlag> {
    return client.post(`/api/admin/student/flags`, {
      studentId,
      context,
    });
  }

  updateStudentFlag(flagId: number): AxiosPromise<IStudentFlag> {
    return client.put(`/api/admin/student/flags/${flagId}`);
  }

  destroyStudentFlag(flagId: number): AxiosPromise<IStudentFlag> {
    return client.delete(`/api/admin/student/flags/${flagId}`);
  }
}

export const admin = new AdminAPI();
