import { AxiosPromise } from 'axios';
import client from '../../axios.client';
import { Season } from '../../../constants';
import { ISelectOption } from '../../../ui/select';

export interface IStudentAccountInvitation {
  id: number;
  name: string;
  lastSent: string;
  accepted: boolean;
  email: string;
  degreeName: string;
  gradeLevel: string;
  studentId: string;
  cohort: number;
  startTerm: Season;
  startYear: number;
}

export interface IPreppedStudentAccountInfo {
  name: string;
  studentId: string;
  schoolId: number;
  email: string;
  degree: ISelectOption;
  startTerm: ISelectOption;
  startYear: number;
}

export interface IStudentAccountInfo {
  name: string;
  studentId: string;
  schoolId: number;
  email: string;
  degreeId: number;
  startTerm: number;
  startYear: number;
}

export interface IStudentAccountInvitationsPayload {
  invitations: IStudentAccountInfo[];
}

export interface IUploadedStudent {
  name: string;
  schoolId: number;
  studentId: number;
  email: string;
  startYear: number;
  startTerm: Season;
  degree: ISelectOption;
}

export interface IAdminStudentInvites {
  getStudentAccountInvitations(): AxiosPromise<IStudentAccountInvitation[]>;
  sendStudentInvitations(
    payload: IStudentAccountInvitationsPayload
  ): AxiosPromise<any>;
  resendStudentInvitation(invitationId: number): AxiosPromise<any>;
  destroyStudentInvitation(invitationId: number): AxiosPromise<any>;
  uploadStudentInviteFile(file: File): AxiosPromise<IUploadedStudent[]>;
}

class AdminStudentInvitesAPI implements IAdminStudentInvites {
  getStudentAccountInvitations(): AxiosPromise<IStudentAccountInvitation[]> {
    return client.get(`/api/admin/student/invitations`);
  }

  sendStudentInvitations(
    payload: IStudentAccountInvitationsPayload
  ): AxiosPromise<any> {
    return client.post(`/api/admin/student/invitations`, {
      students: payload.invitations.map((student: IStudentAccountInfo) => ({
        ...student,
      })),
    });
  }

  resendStudentInvitation(invitationId: number): AxiosPromise<any> {
    return client.post(`/api/admin/student/invitations/${invitationId}/resend`);
  }

  destroyStudentInvitation(invitationId: number): AxiosPromise<any> {
    return client.delete(`/api/admin/student/invitations/${invitationId}`);
  }

  uploadStudentInviteFile(file: File): AxiosPromise<IUploadedStudent[]> {
    return client.post(
      '/api/student/invitations/upload',
      {
        file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }
}

export const invites = new AdminStudentInvitesAPI();
