import { createReducer } from 'redux-act';
import * as actions from './actions';
import * as paths from '../routes/paths';
import { matchPath } from 'react-router-dom';
import { parse } from 'query-string';

interface IState {
  params: {
    id: string;
  };
  pathname: string;
  pathnameBase: string;
  pattern: {
    path: string;
    caseSensitive: boolean;
    end: boolean;
  };
  query: {};
  hash: string;
  href: string;
  hostname: string;
}

const location = window.location;

const matches: any = Object.keys(paths)
  .map((key: string) => {
    // @ts-ignore
    let pattern = paths[key];
    return matchPath(pattern, location.pathname);
  })
  .filter(Boolean);

let match = matches[0];

if (!match) {
  try {
    match = {
      params: {},
      url: window.location.pathname,
    };
  } catch {}
}

let hash = window.location.hash;
let href = window.location.href;
let hostname = window.location.hostname;

const initialState: IState = {
  ...match,
  query: parse(location.search),
  hash,
  href,
  hostname,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.route.set, (state, payload) => ({
  ...state,
  ...payload,
}));

export const matchReducer = reducer;
