import { createAction } from 'redux-act';
import {
  IStudentProgression,
} from '../../../../../../api/students';
import { IPaginatedResponse } from '../../../../../../api/common';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/student-status-data/students] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/student-status-data/students] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/student-status-data/students] load.error'
  ),
  done: createAction<IPaginatedResponse<IStudentProgression>>(
    '[pages/admin/dashboard/student-status-data/students] load.done'
  ),
};

export const paginate = createAction<number>(
  '[pages/admin/dashboard/student-status-data/students] paginate'
);
