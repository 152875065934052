import React from 'react';
import { Input } from '../../../../../../components/input';
import * as actions from '../../../actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';

export const NameField: React.FC = () => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { name, error } = register;

  return (
    <div className={'mb-4'}>
      <Input
        placeholder={'enter name'}
        required={true}
        type={'text'}
        label={'Name'}
        name={'name'}
        value={name}
        error={error}
        onChange={(e) =>
          dispatch(
            actions.onChange({
              key: 'name',
              value: e.target.value,
            })
          )
        }
      />
    </div>
  );
};
