import { combineReducers } from 'redux';

import { infoReducer as info } from "./info/reducer";
import { scheduleReducer as schedule } from "./schedule/reducer";
import { requirementsReducer as requirements } from "./requirements-progress/reducer";
import { progressReducer as progress } from "./progress-bar/reducer";
import { courseBinReducer as courseBin } from "./schedule/course-bin/reducer";
import { profileReducer as profile } from "./profile/reducer";

export const adminStudentSnapshotReducer = combineReducers({
  info,
  schedule,
  requirements,
  progress,
  courseBin,
  profile
});
