import React from 'react';
import { Button } from '../../../../../../ui';
import {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../ui/button';

export const AssignedToAllDegrees: React.FC = () => {
  return (
    <>
      <h3 className={'text-neutral-darkest font-semibold mb-2'}>
        This course is currently assigned to all available degrees
      </h3>
      {/*<Button handleOnClick={() => setIsAddingMajor(false)} size={ButtonSize.Small} style={ButtonStyle.Outline} variant={ButtonVariant.Primary}>*/}
      {/*    Go back*/}
      {/*</Button>*/}
    </>
  );
};
