import React from "react";
import {CourseBinFilterDropdown} from "../../../common/filters/course-bin";
import {Droppable} from "react-beautiful-dnd";
import {Helpers} from "../../../helpers";
import {CourseList} from "../../DragAndDropSchedule/term/courses";
import {ICourse} from "../../../types/course";

export interface ICourseBinProps {
    courses: ICourse[];
    termIndex: number;
    groupIndex: number;
    isDraggable?: boolean;
}

// TODO: This can be shared with the other course bin on the student side, just need to solidify where the data will live.
// TODO: Share the html scaffolding and the passing of events but don't share the data store, keep component as dumb as possible.
export const CourseBin: React.FC<ICourseBinProps> = ({ courses, isDraggable, termIndex, groupIndex }) => {
    return (
        <>
            <div className={"flex flex-col w-full mt-8 bg-neutral-darker relative rounded-lg"} style={{ maxWidth: "200px"}}>
                <div className={"mb-2 pt-4 pr-4 pl-4 pb-1"}>
                    <h5 className={"text-black text-xs mb-2 font-semibold"}>Course Bin</h5>
                    <CourseBinFilterDropdown />
                </div>
                <div className={"flex flex-col px-2"}>
                    <Droppable droppableId={"course-bin"}>
                        {(provided, snapshot) => (
                            <div
                                className="semesterBlock"
                                ref={provided.innerRef}
                                style={{...Helpers.getListStyle(snapshot.isDraggingOver)}}
                            >
                                <CourseList groupIndex={groupIndex} termIndex={termIndex} isDraggable={!!isDraggable} isCourseBin={true} termId={10000} list={courses} />
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </>
    )
}

CourseBin.defaultProps = {
    isDraggable: true
}
