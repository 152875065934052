import React, {useState} from "react";
import {RiArrowDownSLine} from "@react-icons/all-files/ri/RiArrowDownSLine";
import {RiArrowUpSLine} from "@react-icons/all-files/ri/RiArrowUpSLine";

export enum SortDirection {
    DESC,
    ASC
}

export interface ISortByDirectionProps {
    onToggle: (direction: SortDirection) => any;
}

export const SortByDirection: React.FC<ISortByDirectionProps> = ({ onToggle }) => {

    const [direction, setDirection] = useState<SortDirection>(SortDirection.ASC)

    const toggle = () => {
        if(direction === SortDirection.ASC) {
            setDirection(SortDirection.DESC);
            onToggle(SortDirection.DESC)
        }
        if(direction === SortDirection.DESC) {
            setDirection(SortDirection.ASC);
            onToggle(SortDirection.ASC)
        }
    }

    return (
        <section role={"button"} className={"flex items-center justify-between bg-neutral-lightest px-2 py-2 cursor-pointer font-semibold text-neutral-dark"} onClick={() => toggle()}>
            {direction === SortDirection.DESC && <span className={"text-neutral text-xs"}>Descending</span>}
            {direction === SortDirection.ASC && <span className={"text-neutral text-xs"}>Ascending</span>}
            {direction === SortDirection.DESC && <RiArrowDownSLine className={'fill-baby-blue text-xl'} />}
            {direction === SortDirection.ASC && <RiArrowUpSLine className={'fill-baby-blue text-xl'} />}
        </section>
    )

}
