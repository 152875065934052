import React, { useEffect } from 'react';
import { AdminStudentProfileAbout } from './about';
import { AdminStudentProfileInterests } from './interests';

export const AdminStudentProfile: React.FC = () => {

  return (
    <>
      <AdminStudentProfileAbout />
      <AdminStudentProfileInterests />
    </>
  )
}
