import { createAction } from 'redux-act';
import { ICourseFutureOccupancy } from '../../../../../../api/courses';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/dashboard/predictive-course-data/timeline] fetch'
);

export const load = {
  start: createAction(
    '[pages/dashboard/predictive-course-data/timeline] load.start'
  ),
  error: createAction<any>(
    '[pages/dashboard/predictive-course-data/timeline] load.error'
  ),
  done: createAction<ICourseFutureOccupancy[]>(
    '[pages/dashboard/predictive-course-data/timeline] load.done'
  ),
};
