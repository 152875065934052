import React from 'react';

interface ILabelProps {
  name: string;
  required: boolean | undefined;
  classNames?: string;
}

export const Label: React.FC<ILabelProps> = ({
  name,
  required,
  classNames,
}) => {
  return (
    <label className={classNames} htmlFor={name}>
      {name}
      {required && '*'}
    </label>
  );
};

Label.defaultProps = {
  required: false,
  name: '',
  classNames: 'block text-blue-darkest font-semibold text-sm mb-1',
};
