import React, { useEffect } from 'react';
import { Button, Checkbox } from '../../../../../../ui';
import { CheckBoxSize } from '../../../../../../ui/checkbox';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../../infrastructure/selector';
import { IDegreeRequirement } from '../../../../../../api/admin/degrees';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonVariant,
} from '../../../../../../ui/button';

export const RequirementCategories: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, majors, requirementCategories, assigned } = useSelector(
    (state) => ({
      majors: state.pages.admin.settings.course.details.majors,
      assigned:
        state.pages.admin.settings.course.requirementCategories.assigned,
      requirementCategories:
        state.pages.admin.settings.course.requirementCategories.list,
      isLoading:
        state.pages.admin.settings.course.requirementCategories.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [majors.length]);

  return (
    <div>
      <h5 className={'text-neutral-darkest text-sm font-semibold mb-2'}>
        Assign to a Requirement Category
      </h5>
      <ul className={'mb-2'}>
        {requirementCategories.map((requirement: IDegreeRequirement) => (
          <li
            className={
              'flex items-center hover:bg-neutral-lightest p-1 cursor-pointer'
            }
            onClick={() => dispatch(actions.onCheck(requirement.id))}
          >
            <Checkbox
              onChange={() => false}
              checked={assigned.includes(requirement.id)}
              containerClassNames={'mr-2'}
              size={CheckBoxSize.md}
            />
            <div
              className={'h-3 w-3 rounded-full mr-2'}
              style={{ backgroundColor: requirement.color }}
            ></div>
            <span className={'text-xs text-neutral-dark mr-3'}>
              {requirement.name}
            </span>
            {/*<span className={"text-xs italic text-baby-blue underline"}>Primary Requirement</span>*/}
          </li>
        ))}
      </ul>
      <Button
        handleOnClick={() => dispatch(actions.sync.start())}
        size={ButtonSize.XSmall}
        variant={ButtonVariant.Primary}
        isLoading={isLoading}
        radius={ButtonBorderRadius.MD}
      >
        Save
      </Button>
    </div>
  );
};
