import React from 'react';
import { ISchedule, IScheduleTermGroup } from '../../api/schedules';
import { TermGroup } from './term-group';

export interface IDragAndDropSchedule {
    schedule: ISchedule;
    isDraggable: boolean;
    displayTermCost?: boolean;
    handleOnRemoveTerm: (termId: number) => any;
}

export const DragAndDropSchedule: React.FC<IDragAndDropSchedule> = ({ schedule, isDraggable, displayTermCost, handleOnRemoveTerm}) => {

    if(!schedule.termGroups.length) return null;

    return (
        <>
            <section className="py-4 flex w-full mr-3 overflow-x-scroll overflow-y-hidden relative" style={{ minHeight: 550 }}>
                <div className="flex h-auto w-full h-full relative bg-white">
                    {schedule.termGroups.map((group: IScheduleTermGroup, index: number) => <TermGroup displayTermCost={!!displayTermCost} isDraggable={isDraggable} groupIndex={index} key={index} group={group} handleOnRemoveTerm={handleOnRemoveTerm} />)}
                </div>
            </section>
        </>
    )
}

DragAndDropSchedule.defaultProps = {
  isDraggable: true,
  displayTermCost: true,
};
