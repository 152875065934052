
export enum Environment {
    Development,
    Staging,
    Demo,
    Production
}

export const getEnvironment = (host: string = ""): Environment => {

    if(host === "")
    {
        host = window.location.host;
    }

    if(host.includes("demo.degreely"))
    {
        return Environment.Demo;
    }

    if(host.includes("staging.degreely"))
    {
        return Environment.Staging;
    }

    if(host.includes("app.degreely") || host.includes("degreely"))
    {
        return Environment.Production;
    }

    return Environment.Development;

}