import React from 'react';
import { FaEllipsisH } from '@react-icons/all-files/fa/FaEllipsisH';
import { IMessageThread } from '../../../../../../api/messages';
import { DialogBox } from '../../../../../../ui/dialog-box';
import { UserAvatar } from '../../../../../../components/avatar';
import moment from 'moment';
import { BiMessageCheck } from '@react-icons/all-files/bi/BiMessageCheck';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';
import { IRecipient } from '../../create/recipient/reducer';

export interface IMessageThreadProps {
  thread: IMessageThread;
  user: IRecipient;
  handleOnClick: (thread: IMessageThread) => any;
}

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export const MessageThread: React.FC<IMessageThreadProps> = ({
  thread,
  user,
  handleOnClick,
}) => {
  const dispatch = useDispatch();

  let avatarUrl = '';
  if (user?.id == thread.sender.id) {
    avatarUrl = thread.recipient.avatarUrl;
  }

  if (user?.id == thread.recipient.id) {
    avatarUrl = thread.sender.avatarUrl;
  }

  return (
    <li className={'p-3'}>
      <div className={'flex items-center'}>
        <UserAvatar
          size={40}
          imageUrl={avatarUrl}
          includeNotificationIdentifier={thread.hasUnreadMessages}
        />
        <div className={'w-full flex items-center justify-between ml-2'}>
          <div
            className={'flex flex-col cursor-pointer'}
            onClick={() => handleOnClick(thread)}
          >
            <span className={'font-semibold text-baby-blue text-sm mb-1'}>
              {thread.name}
            </span>
            <p className={'text-neutral text-xs'}>
              {truncateString(thread.latestMessage.body, 80)}
            </p>
          </div>
          <div className={'flex items-center justify-between relative'}>
            <DialogBox
              position={{
                top: 0,
                right: 0,
                left: 'auto',
                bottom: 'auto',
              }}
              renderTarget={({ handleToggle }) => (
                <div className={'flex items-center'}>
                  <span className={'text-neutral text-xs block mr-2'}>
                    {moment(thread.latestMessage.sentAt).fromNow()}
                  </span>
                  <span onClick={() => handleToggle()}>
                    <FaEllipsisH className={'fill-neutral'} />
                  </span>
                </div>
              )}
            >
              {({ handleToggle }) => {
                return (
                  <>
                    <div
                      className={
                        'bg-white shadow rounded-md p-2 absolute right-0 top-5 w-full hover:bg-baby-blue-lightest group'
                      }
                    >
                      <ul>
                        <li
                          onClick={() =>
                            dispatch(actions.markAsRead.start(thread.id))
                          }
                          className={
                            'text-neutral text-xs flex items-center group-hover:text-baby-blue'
                          }
                        >
                          <BiMessageCheck
                            className={
                              'text-lg fill-neutral mr-1 group-hover:fill-baby-blue'
                            }
                          />
                          Mark as read
                        </li>
                      </ul>
                    </div>
                  </>
                );
              }}
            </DialogBox>
          </div>
        </div>
      </div>
    </li>
  );
};
