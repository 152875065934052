import React from 'react';
import { useDispatch } from 'react-redux';
import { ICourse } from '../../../types/course';
import { CourseListSkeleton } from '../skeleton';

export interface ICourseListProps {
  list: ICourse[];
  isLoading: boolean;
  handleOnClickItem?: (id: number) => any;
  hasRegistrationData?: boolean; // TODO: Remove this once we have a better idea of registration
}

export const CourseList: React.FC<ICourseListProps> = ({
  list,
  isLoading,
  handleOnClickItem,
  hasRegistrationData,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <section className={'flex flex-col'}>
        <div className={'flex mt-5'}>
          {!hasRegistrationData && (
            <>
              <div className={'font-medium text-neutral-darkest text-xs w-1/5'}>
                Code
              </div>
              <div className={'font-medium text-neutral-darkest text-xs w-3/5'}>
                Name
              </div>
              <div
                className={
                  'font-medium text-neutral-darkest text-xs w-1/5 text-right'
                }
              >
                Credits
              </div>
            </>
          )}
          {hasRegistrationData && (
            <>
              <div
                className={'font-medium text-neutral-darkest text-xs w-1/12'}
              >
                Code
              </div>
              <div
                className={'font-medium text-neutral-darkest text-xs w-3/12'}
              >
                Course Name
              </div>
              <div
                className={'font-medium text-neutral-darkest text-xs w-2/12'}
              >
                Level
              </div>
              <div
                className={'font-medium text-neutral-darkest text-xs w-2/12'}
              >
                Registration
              </div>
              <div
                className={'font-medium text-neutral-darkest text-xs w-2/12'}
              >
                Holds
              </div>
              <div
                className={'font-medium text-neutral-darkest text-xs w-2/12'}
              >
                Conflicts
              </div>
            </>
          )}
        </div>
        {isLoading && <CourseListSkeleton />}
        {!isLoading &&
          list.map((course: ICourse) => {
            return (
              <div
                className={`flex mt-3 items-center cursor-pointer py-1 hover:bg-neutral-lightest rounded-lg border border-white`}
                onClick={() =>
                  !!handleOnClickItem && handleOnClickItem(course.id)
                }
              >
                {!hasRegistrationData && (
                  <>
                    <div className={'w-1/5 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        {course.code}
                      </div>
                    </div>
                    <div className={'w-3/5 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        {course.name}
                      </div>
                    </div>
                    <div className={'w-1/5 flex items-center text-right'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        {course.credits}
                      </div>
                    </div>
                  </>
                )}
                {hasRegistrationData && (
                  <>
                    <div className={'w-1/12 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        {course.code}
                      </div>
                    </div>
                    <div className={'w-3/12 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        {course.name}
                      </div>
                    </div>
                    <div className={'w-2/12 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        Junior
                      </div>
                    </div>
                    <div className={'w-2/12 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        10/35
                      </div>
                    </div>
                    <div className={'w-2/12 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        5
                      </div>
                    </div>
                    <div className={'w-2/12 flex items-center'}>
                      <div
                        className={'font-medium text-neutral text-xs w-full'}
                      >
                        2
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
      </section>
    </>
  );
};

CourseList.defaultProps = {
  handleOnClickItem: () => {},
  hasRegistrationData: false,
};
