import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  isExpanded: boolean;
}

const initialState: IState = {
  isExpanded: true,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.open, (state) => ({
  ...state,
  isExpanded: true,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isExpanded: false,
}));

export const sidebarReducer = reducer;
