import React from 'react';
import { UserUniversity } from './user-university';
import { Logo } from '../logo';
import { MessagesHeader } from './messages';
import { RequiresMessagingAccess } from '../../common/guards/messaging-access';
import {StudentOnly} from "../../common/guards/student-only";

export const Header: React.FC = () => {

  return (
    <>
      <header className={'flex items-center p-7 border-b-2 justify-between'}>
        <Logo />
          <div className={"flex items-center justify-end w-1/3"}>
              <UserUniversity />
              <RequiresMessagingAccess>
                  <StudentOnly>
                      <MessagesHeader/>
                  </StudentOnly>
              </RequiresMessagingAccess>
          </div>
      </header>
    </>
  );
};
