import { createAction } from 'redux-act';
import { ISeasonSelect } from './reducer';
import { IMajor, IMinor } from '../../../../api/majors';
import { ISchoolConfiguration } from '../../../../api/schools';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const open = createAction<number>(
  '[pages/dashboard/modals/edit-track] open'
);
export const close = createAction('[pages/dashboard/modals/edit-track] close');

export const set = {
  start: createAction('[pages/dashboard/modals/edit-track] set.start'),
  load: createAction('[pages/dashboard/modals/edit-track] set.load'),
  done: createAction<{
    name: string;
    majors: IMajor[];
    minors: IMinor[];
    seasons: number[];
  }>('[pages/dashboard/modals/edit-track] set.done'),
};

export const fetchConfiguration = createAction(
  '[pages/dashboard/modals/edi-track-modal] fetchConfiguration'
);

export const loadConfiguration = {
  start: createAction(
    '[pages/dashboard/modals/edit-track-modal] loadConfiguration.start'
  ),
  error: createAction<any>(
    '[pages/dashboard/modals/edit-track-modal] loadConfiguration.error'
  ),
  done: createAction<ISchoolConfiguration>(
    '[pages/dashboard/modals/edit-track-modal] loadConfiguration.done'
  ),
};

export const update = {
  start: createAction<number>(
    '[pages/dashboard/modals/edit-track] update.start'
  ),
  error: createAction<any>('[pages/dashboard/modals/edit-track] update.error'),
  done: createAction('[pages/dashboard/modals/edit-track] update.done'),
};

export const onChange = createAction<string>(
  '[pages/dashboard/modals/edit-track] onChange'
);

export const season = {
  select: createAction<number>(
    '[pages/dashboard/modals/edit-track] season.select'
  ),
  set: createAction<ISeasonSelect[]>(
    '[pages/dashboard/modals/edit-track] season.set'
  ),
};
