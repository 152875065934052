import React from 'react';
import { useSelector } from '../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { UserRoleType } from '../../constants';
import { AdminProfile } from './admin';
import { StudentProfile } from '../student-profile';

export const UserProfile: React.FC = () => {

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info
    }),
    shallowEqual
  );

  if(user?.roleId == UserRoleType.Administrator)
  {
   return <AdminProfile />
  } else {
   return <StudentProfile />
  }

}
