import React, { useEffect, useState } from 'react';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { Dropdown } from '../../../../../components/dropdown';
import { Accordion } from '../../../../../ui/accordion';
import { Button, Checkbox } from '../../../../../ui';
import {
  CheckBoxBorder,
  CheckBoxBorderSize,
  CheckboxLabelPosition,
  CheckBoxRounded,
} from '../../../../../ui/checkbox';
import {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../ui/button';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { useSelector } from '../../../../../infrastructure/selector';
import { IFilter } from '../../../../../types/filter';
import {ICourseFilters} from "./reducer";
import {SortByDirection} from "../../../../../components/sort-by-direction";


export interface ICourseFiltersDropdown {
  handleOnApplyFilter: () => void;
}

export const CourseFiltersDropdown: React.FC<ICourseFiltersDropdown> = ({ handleOnApplyFilter }) => {

  const dispatch = useDispatch();

  const { courseFilters, user, selected } = useSelector(
    (state) => ({
      user: state.common.user.info,
      selected: state.pages.admin.common.filters.courses.selected,
      courseFilters: state.pages.admin.common.filters.courses.filters,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  const handleClear = () => {
    dispatch(
      actions.apply({
        sort: [],
        grades: [],
        requirementCategories: [],
        availableTerms: [],
        availableYears: [],
      })
    );
    handleOnApplyFilter();
  };

  const handleApply = () => {
    dispatch(
      actions.apply({
            grades: [...courseFilters.grades],
            sort: [...courseFilters.sort],
            requirementCategories: [...courseFilters.requirementCategories],
            availableTerms: [...courseFilters.availableTerms],
            availableYears: [...courseFilters.availableYears]
      })
    );
    handleOnApplyFilter();
  };

  return (
      <section className={"flex items-center"}>
          <div className={"w-1/3 mr-2"}>
              <Dropdown
                  maxHeight={500}
                  renderTarget={({ isOpen, handleTrigger }) => (
                      <div
                          onClick={() => handleTrigger()}
                          className={'w-full flex items-center justify-between'}
                      >
                          <span className={'text-neutral text-xs'}>Filters</span>
                          {!isOpen && <RiArrowDownSLine className={'fill-baby-blue text-xl'} />}
                          {isOpen && (
                              <MdClose
                                  className={'fill-baby-blue text-xl'}
                                  onClick={() => handleClear()}
                              />
                          )}
                      </div>
                  )}
                  renderBody={({ handleTrigger }) => (
                      <>
                        {courseFilters.grades.length > 0 && (
                              <Accordion
                                defaultState={true}
                                renderTarget={({ isOpen, handleTrigger }) => (
                                  <div
                                    onClick={() => handleTrigger()}
                                    className={
                                      'flex justify-between border-b border-b-neutral-light mb-3'
                                    }
                                  >
                                    <span className={'text-baby-blue text-sm'}>Grade</span>
                                    {!isOpen && (
                                      <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                                    )}
                                    {isOpen && (
                                      <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                                    )}
                                  </div>
                                )}
                                renderBody={() => (
                                  <div className={'w-full'}>
                                    <ul className={'w-full mb-2'}>
                                      {courseFilters.grades.map((grade: IFilter) => (
                                        <li className={'w-full mb-2'}>
                                          <Checkbox
                                            border={CheckBoxBorder.Neutral}
                                            borderSize={CheckBoxBorderSize.TwoPixel}
                                            containerClassNames={
                                              'w-full flex items-center justify-between'
                                            }
                                            labelClassNames={'text-neutral-dark text-xs'}
                                            labelPosition={CheckboxLabelPosition.BeforeBox}
                                            label={grade.label}
                                            rounded={CheckBoxRounded.md}
                                            onChange={() =>
                                              dispatch(
                                                actions.handleFilter({
                                                  key: 'grades',
                                                  filter: grade,
                                                })
                                              )
                                            }
                                            checked={grade.selected}
                                          />
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              />
                        )}

                        <Accordion
                          defaultState={true}
                          renderTarget={({ isOpen, handleTrigger }) => (
                            <div
                              onClick={() => handleTrigger()}
                              className={
                                'flex justify-between border-b border-b-neutral-light mb-3'
                              }
                            >
                              <span className={'text-baby-blue text-sm'}>Requirements</span>
                              {!isOpen && (
                                <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                              )}
                              {isOpen && (
                                <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                              )}
                            </div>
                          )}
                          renderBody={() => (
                            <div className={'w-full'}>
                              <ul className={'w-full mb-2'}>
                                {courseFilters.requirementCategories.map((requirement: IFilter) => (
                                  <li className={'w-full mb-2'}>
                                    <Checkbox
                                      border={CheckBoxBorder.Neutral}
                                      borderSize={CheckBoxBorderSize.TwoPixel}
                                      containerClassNames={
                                        'w-full flex items-center justify-between'
                                      }
                                      labelClassNames={'text-neutral-dark text-xs'}
                                      labelPosition={CheckboxLabelPosition.BeforeBox}
                                      label={requirement.label}
                                      rounded={CheckBoxRounded.md}
                                      onChange={() =>
                                        dispatch(
                                          actions.handleFilter({
                                            key: 'requirementCategories',
                                            filter: requirement,
                                          })
                                        )
                                      }
                                      checked={requirement.selected}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        />


                        <Accordion
                          defaultState={true}
                          renderTarget={({ isOpen, handleTrigger }) => (
                            <div
                              onClick={() => handleTrigger()}
                              className={
                                'flex justify-between border-b border-b-neutral-light mb-3'
                              }
                            >
                              <span className={'text-baby-blue text-sm'}>Available Terms</span>
                              {!isOpen && (
                                <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                              )}
                              {isOpen && (
                                <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                              )}
                            </div>
                          )}
                          renderBody={() => (
                            <div className={'w-full'}>
                              <ul className={'w-full mb-2'}>
                                {courseFilters.availableTerms.map((filter: IFilter) => (
                                  <li className={'w-full mb-2'}>
                                    <Checkbox
                                      border={CheckBoxBorder.Neutral}
                                      borderSize={CheckBoxBorderSize.TwoPixel}
                                      containerClassNames={
                                        'w-full flex items-center justify-between'
                                      }
                                      labelClassNames={'text-neutral-dark text-xs'}
                                      labelPosition={CheckboxLabelPosition.BeforeBox}
                                      label={filter.label}
                                      rounded={CheckBoxRounded.md}
                                      onChange={() =>
                                        dispatch(
                                          actions.handleFilter({
                                            key: 'availableTerms',
                                            filter: filter,
                                          })
                                        )
                                      }
                                      checked={filter.selected}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        />

                        <Accordion
                          defaultState={true}
                          renderTarget={({ isOpen, handleTrigger }) => (
                            <div
                              onClick={() => handleTrigger()}
                              className={
                                'flex justify-between border-b border-b-neutral-light mb-3'
                              }
                            >
                              <span className={'text-baby-blue text-sm'}>Available Years</span>
                              {!isOpen && (
                                <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                              )}
                              {isOpen && (
                                <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                              )}
                            </div>
                          )}
                          renderBody={() => (
                            <div className={'w-full'}>
                              <ul className={'w-full mb-2'}>
                                {courseFilters.availableYears.map((filter: IFilter) => (
                                  <li className={'w-full mb-2'}>
                                    <Checkbox
                                      border={CheckBoxBorder.Neutral}
                                      borderSize={CheckBoxBorderSize.TwoPixel}
                                      containerClassNames={
                                        'w-full flex items-center justify-between'
                                      }
                                      labelClassNames={'text-neutral-dark text-xs'}
                                      labelPosition={CheckboxLabelPosition.BeforeBox}
                                      label={filter.label}
                                      rounded={CheckBoxRounded.md}
                                      onChange={() =>
                                        dispatch(
                                          actions.handleFilter({
                                            key: 'availableYears',
                                            filter: filter,
                                          })
                                        )
                                      }
                                      checked={filter.selected}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        />
                      </>
                  )}
                  renderFooter={({ handleTrigger }) => (
                      <div className={'flex items-center justify-end'}>
                          <Button
                              classes={'mr-2'}
                              style={ButtonStyle.Outline}
                              variant={ButtonVariant.Primary}
                              size={ButtonSize.Small}
                              handleOnClick={() => {
                                  handleTrigger();
                              }}
                          >
                              Cancel
                          </Button>
                          <Button
                              style={ButtonStyle.Filled}
                              variant={ButtonVariant.Primary}
                              size={ButtonSize.Small}
                              handleOnClick={() => {
                                  handleApply();
                                  handleTrigger();
                              }}
                          >
                              Apply
                          </Button>
                      </div>
                  )}
              />
          </div>
          <div className="w-1/3 mr-2">
              <Dropdown
                  renderTarget={({ isOpen, handleTrigger }) => (
                      <div
                          onClick={() => handleTrigger()}
                          className={'w-full flex items-center justify-between'}
                      >
                          {!selected?.sort && <span className={'text-neutral text-xs'}>Sort By</span>}
                          {!!selected?.sort && <span className={"text-neutral-dark font-semibold text-xs"}>Sorting by: {selected.sort.label}</span>}

                          {!!selected?.sort && <MdClose
                              className={'fill-baby-blue text-xl'}
                              onClick={() => handleClear()}
                          />}
                          {!isOpen && !selected?.sort && <RiArrowDownSLine className={'fill-baby-blue text-xl'} />}
                          {isOpen && !selected?.sort && (
                              <MdClose
                                  className={'fill-baby-blue text-xl'}
                                  onClick={() => handleClear()}
                              />
                          )}
                      </div>
                  )}
                  renderBody={({ handleTrigger }) => (
                      <>
                          <div className={'w-full'}>
                              <ul className={'w-full mb-2'}>
                                  {courseFilters.sort.map((sort: IFilter) => (
                                      <li className={'w-full mb-2'}>
                                          <Checkbox
                                              border={CheckBoxBorder.Neutral}
                                              borderSize={CheckBoxBorderSize.TwoPixel}
                                              containerClassNames={
                                                  'w-full flex items-center justify-between'
                                              }
                                              labelClassNames={'text-neutral-dark text-xs'}
                                              labelPosition={CheckboxLabelPosition.BeforeBox}
                                              label={sort.label}
                                              rounded={CheckBoxRounded.md}
                                              onChange={() =>
                                                  dispatch(
                                                      actions.handleFilter({
                                                          key: 'sort',
                                                          filter: sort,
                                                      })
                                                  )
                                              }
                                              checked={sort.selected}
                                          />
                                      </li>
                                  ))}
                              </ul>
                          </div>

                          <div className={'flex items-center justify-end mt-10'}>
                              <Button
                                  classes={'mr-2'}
                                  style={ButtonStyle.Outline}
                                  variant={ButtonVariant.Primary}
                                  size={ButtonSize.Small}
                                  handleOnClick={() => {
                                      handleTrigger();
                                  }}
                              >
                                  Cancel
                              </Button>
                              <Button
                                  style={ButtonStyle.Filled}
                                  variant={ButtonVariant.Primary}
                                  size={ButtonSize.Small}
                                  handleOnClick={() => {
                                      handleApply();
                                      handleTrigger();
                                  }}
                              >
                                  Apply
                              </Button>
                          </div>
                      </>
                  )}
              />
          </div>
          <div className="w-1/3">
              <SortByDirection onToggle={(direction) => {
                dispatch(actions.handleSortDirection(direction));
                handleOnApplyFilter();
              }}/>
          </div>
      </section>
  )
};
