import React, { useState } from 'react';
import { Textarea } from '../../../../../../../ui/textarea';
import { Button } from '../../../../../../../ui';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../../ui/button';
import * as actions from '../actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';

export interface IAddFlagProps {
  handleCancel: () => any;
}

export const AddFlag: React.FC<IAddFlagProps> = ({ handleCancel }) => {
  const dispatch = useDispatch();
  const [context, setContext] = useState<string>('');

  const { studentId } = useSelector(
    (state) => ({
      studentId: state.common.match.params.id,
    }),
    shallowEqual
  );

  return (
    <>
      <div className={''} key={'key1'}>
        <h6 className={'text-neutral-darkest text-sm font-semibold mb-3'}>
          Add a flag to this student
        </h6>
        <Textarea
          name={'content'}
          placeholder={'Add some context to this flag...'}
          handleOnChange={(e) => setContext(e.target.value)}
        />
        <div className={'flex justify-end mt-1'}>
          <Button
            classes={'mr-2'}
            style={ButtonStyle.Outline}
            variant={ButtonVariant.Primary}
            handleOnClick={() => handleCancel()}
            radius={ButtonBorderRadius.MD}
            size={ButtonSize.XXXSmall}
          >
            Cancel
          </Button>
          <Button
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
            handleOnClick={() => {
              dispatch(
                actions.create.start({
                  context,
                  studentId,
                })
              );
              handleCancel();
            }}
            radius={ButtonBorderRadius.MD}
            size={ButtonSize.XXXSmall}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
