import { combineReducers } from 'redux';
import { createTrackModalReducer as createTrack } from '../modals/create-track/reducer';
import { courseDetailModalReducer as courseDetail } from '../modals/course-detail/reducer';
import { deleteTrackModalReducer as deleteTerm } from './delete-term/reducer';
import { requirementCategoryDetailModalReducer as requirementCategory } from './requirement-category-detail/reducer';
import { deleteTrackModalReducer as deleteTrack } from './delete-track/reducer';
import { editTrackModalReducer as editTrack } from './edit-track/reducer';

export const modalsReducer = combineReducers({
  createTrack,
  courseDetail,
  deleteTerm,
  requirementCategory,
  deleteTrack,
  editTrack,
});
