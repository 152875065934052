import React, { useEffect } from 'react';
import { Button } from '../../../../ui';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../ui/button';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
import * as actions from './actions';
import { termSeasonService } from '../../../../services/term-season/TermSeasonService';
import { IStudentAccountInvitation } from '../../../../api/admin/invites';
import * as inviteModalActions from '../modals/invite-students/actions';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

export const InvitationsIndex: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, list, showToast, showResentToast } = useSelector(
    (state) => ({
      list: state.pages.admin.invitations.list.list,
      showToast:
        state.pages.admin.invitations.modals.studentInvitesModal.showToast,
      showResentToast: state.pages.admin.invitations.list.showToast,
      isLoading: state.pages.admin.dashboard.students.overview.isLoading,
    }),
    shallowEqual
  );

  const handleOnDelete = (invitationId: number) => {
    dispatch(actions.destroy.start(invitationId));
  };

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <h4 className={'text-2xl text-neutral-darkest'}>Invited Students</h4>
      <span className={'text-xs text-neutral'}>
        Students you have invited to use Degreely
      </span>
      <div className={'mt-2'}>
        <Button
          classes={'px-10 py-4'}
          variant={ButtonVariant.Primary}
          size={ButtonSize.XSmall}
          style={ButtonStyle.Filled}
          radius={ButtonBorderRadius.XL}
          handleOnClick={() => dispatch(inviteModalActions.open())}
        >
          Invite Students
        </Button>
      </div>

      {/* TODO: Try to make this into a shared component */}
      <section className={'flex items-center mt-10 mb-2'}>
        <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
          Name
        </div>
        <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
          ID Number
        </div>
        <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
          Email
        </div>
        <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
          Program
        </div>
        <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
          Grade
        </div>
        <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
          Start Term
        </div>
        <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
          Invite Sent
        </div>
        <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
          Status
        </div>
        <div
          className={
            'w-1/12 font-medium text-neutral-darkest text-xs text-right'
          }
        ></div>
      </section>

      {/* TODO: Try to make this into a shared component or components */}
      {!!list.length &&
        list.map((invitation: IStudentAccountInvitation) => (
          <section className={'flex items-center'}>
            <div className={'w-1/12 font-medium text-neutral text-xs mt-2'}>
              {invitation.name}
            </div>
            <div className={'w-2/12 font-medium text-neutral text-xs mt-2'}>
              {invitation.studentId}
            </div>
            <div className={'w-2/12 font-medium text-neutral text-xs mt-2'}>
              {invitation.email}
            </div>
            <div className={'w-2/12 font-medium text-neutral text-xs mt-2'}>
              {invitation.degreeName}
            </div>
            <div className={'w-1/12 font-medium text-neutral text-xs mt-2'}>
              {invitation.gradeLevel}
            </div>
            <div className={'w-1/12 font-medium text-neutral text-xs mt-2'}>
              {termSeasonService.getSeasonAsString(invitation.startTerm)}{' '}
              {invitation.startYear}
            </div>
            <div className={'w-1/12 font-medium text-neutral text-xs mt-2'}>
              {invitation.lastSent}
            </div>
            <div className={'w-1/12 font-medium text-neutral text-xs mt-2'}>
              {invitation.accepted ? (
                'Invite Accepted'
              ) : (
                <div className={'cursor-pointer'}>
                  <span
                    onClick={() =>
                      dispatch(actions.resend.start(invitation.id))
                    }
                    className={
                      'font-semibold text-baby-blue border-b border-baby-blue'
                    }
                  >
                    Resend Invite
                  </span>
                </div>
              )}
            </div>
            <div
              className={
                'w-1/12 font-medium text-neutral text-xs mt-2 text-right'
              }
            >
              {!invitation.accepted && (
                <BiTrash
                  className={'fill-baby-blue text-lg cursor-pointer'}
                  onClick={() => handleOnDelete(invitation.id)}
                />
              )}
            </div>
          </section>
        ))}
    </>
  );
};
