import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
import { Modal } from '../../../../components/modal';
import { Button } from '../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../ui/button';
import * as actions from './actions';
import SkeletonModal from '../../../../components/modal/skeleton';

export const DeleteTrackModal: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpen, isLoading, scheduleId } = useSelector(
    (state) => ({
      isOpen: state.pages.dashboard.modals.deleteTrack.isOpen,
      isLoading: state.pages.dashboard.modals.deleteTrack.isLoading,
      scheduleId: state.pages.dashboard.modals.deleteTrack.scheduleId,
    }),
    shallowEqual
  );

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => dispatch(actions.close())} />;
  }

  return (
    <>
      <Modal
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderBody={() => <body></body>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-10'}>
              <div className="mr-2">
                <Button
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Secondary}
                  handleOnClick={() => dispatch(actions.close())}
                >
                  Cancel
                </Button>
              </div>
              <Button
                isLoading={isLoading}
                size={ButtonSize.Medium}
                style={ButtonStyle.Filled}
                variant={ButtonVariant.Danger}
                handleOnClick={() =>
                  dispatch(actions.destroy.start(scheduleId))
                }
              >
                Delete Track
              </Button>
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
          <div
            className={
              'flex items-center justify-between mb-6 border-b border-neutral-light'
            }
          >
            <div>
              <h2 className={'text-4xl text-black font-semibold mb-2'}>
                Delete Track
              </h2>
            </div>
          </div>
          <div>
            <p className={'text-black text-md'}>
              Are you sure you would like to delete this track? This action can
              not be undone.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};
