interface IRollbarConfiguration {
    enabled: boolean;
    accessToken: string;
    captureUncaught: boolean;
    captureUnhandledRejections: boolean;
    payload: {
        client: {
            javascript: {
                code_version: string;
                source_map_enabled: boolean;
            }
        }
    }
}

export const getRollbarConfiguration = (): IRollbarConfiguration  => {
    return {
        enabled: process.env.NODE_ENV === 'production',
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN ?? "",
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            client: {
                javascript: {
                    code_version: '1.0.0',
                    source_map_enabled: true
                }
            }
        }
    };
}
