import React from 'react';
import Select from 'react-select';
import * as actions from '../../../actions';
import { colorStyles, controlErrorStyles, controlStyles } from '../../index';
import { shallowEqual, useDispatch } from 'react-redux';
import { UserRoleType } from '../../../../../../constants';
import { useSelector } from '../../../../../../infrastructure/selector';
import { Helpers } from '../../../../../../helpers';

export const UserRoleField: React.FC = () => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { error } = register;

  const userTypes = [
    {
      value: UserRoleType.Student,
      label: 'Student',
    },
    {
      value: UserRoleType.Administrator,
      label: 'Administrator',
    },
  ];

  let styles = {
    ...colorStyles,
  };

  let hasError = Helpers.hasError(error, 'user_role_type');

  if (hasError) {
    styles = {
      ...colorStyles,
      control: (styles) => ({
        ...styles,
        ...controlStyles,
        ...controlErrorStyles,
      }),
    };
  }

  return (
    <div className={'mb-4 flex flex-col'}>
      <label
        className={'block text-blue-darkest text-sm mb-1 font-semibold'}
        htmlFor="password"
      >
        User Type*
      </label>
      <Select
        styles={styles}
        options={userTypes}
        className={'text-sm text-black mb-1'}
        name={'user_role_type'}
        id={'user_role_type'}
        onChange={(option: any) =>
          dispatch(
            actions.onChange({
              key: 'userRoleType',
              value: option?.value,
            })
          )
        }
      />
      {hasError && (
        <span className={'text-red text-xs'}>
          {error.errors['user_role_type']}
        </span>
      )}
    </div>
  );
};
