import {createReducer} from 'redux-act';
import {IFilter} from '../../../../../types/filter';
import * as actions from './actions';
import {SortDirection} from "../../../../../components/sort-by-direction";

interface IState {
  isLoading: boolean;
  sortBy: number;
  selected: {
    sort?: IFilter,
    filters?: string[]
  },
  filters: ICourseFilters;
  sortDirection: SortDirection;
}

abstract class SortBy {
  public static CREATED_AT = 0;
  public static UPDATED_AT = 1;
}

abstract class CoursesSortBy extends SortBy {
  public static CODE = 2;
  public static NAME = 3;
  public static CREDITS = 4;
}

export interface ICourseFilters {
  grades: IFilter[];
  requirementCategories: IFilter[];
  sort: IFilter[];
  availableTerms: IFilter[];
  availableYears: IFilter[];
}

const initialState: IState = {
  isLoading: false,
  sortBy: CoursesSortBy.NAME,
  selected: {},
  filters: {
    availableTerms: [],
    availableYears: [],
    grades: [],
    requirementCategories: [],
    sort: []
  },
  sortDirection: SortDirection.ASC
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.apply, (state, payload) => ({
  ...state,
  selected: {
    sort: payload.sort.find((sort: IFilter) => sort.selected),
    filters: [
      ...payload.grades.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.label),
      ...payload.requirementCategories.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.label),
      ...payload.availableYears.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.label),
      ...payload.availableTerms.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.label),
    ],
  },
  filters: {
    ...payload,
  },
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  filters: {
    ...payload,
  },
}));

//@ts-ignore
reducer.on(actions.handleFilter, (state, payload) => {

  let updated = [];

  if(payload.key !== "sort")
  {
    //@ts-ignore
    let isSelected = !!state.filters[payload.key].find((filter: IFilter) => filter.value === payload.filter.value)?.selected;

    //@ts-ignore
    updated = state.filters[payload.key].map((filter: IFilter) => {

      if(filter.value === payload.filter.value)
      {
        return {
          ...filter,
          selected: !isSelected
        }
      }

      return filter;

    })
  } else {
    updated = state.filters[payload.key].map((filter: IFilter) => ({
      ...filter,
      selected: payload.filter.value === filter.value ? !payload.filter.selected : false
    }))
  }


  return {
    ...state,
    filters: {
      ...state.filters,
      [payload.key]: updated,
    },
  };
});

reducer.on(actions.handleSortDirection, (state, payload) => ({
  ...state,
  isLoading: false,
  sortDirection: payload
}));

export const courseFilterReducer = reducer;
