import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../api';
import { IEpic } from '../../../../../../../infrastructure/selector';

const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(({ payload }) => {
      const degreeId =
        state$.value.pages.admin.settings.degrees.update.degree.id;

      return from(
        api.admin.degrees.destroyDegreeRequirement(degreeId, payload)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.destroy.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.destroy.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(destroy);
