import { AxiosPromise } from 'axios';
import client from '../axios.client';

export interface IPremadeSchedule {
  id: number;
  school_id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IPremadeSchedules {
  getPremadeSchedules(schoolId: number): AxiosPromise<IPremadeSchedule[]>;
}

class PremadeScheduleAPI implements IPremadeSchedules {
  getPremadeSchedules(schoolId: number): AxiosPromise<IPremadeSchedule[]> {
    return client.get(`/api/schools/${schoolId}/premade-schedules`);
  }
}

export const premadeSchedules = new PremadeScheduleAPI();
