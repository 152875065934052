import { AxiosPromise } from 'axios';
import client from '../axios.client';

export interface IMajor {
  id: number;
  university_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  abbreviatedName: string;
}

export interface IMinor {
  id: number;
  university_id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IMajorsAPI {
  getMajorsByUniversityId(universityId: number): AxiosPromise<IMajor[]>;
  getMajorsBySchoolId(schoolId: number): AxiosPromise<IMajor[]>;
}

class MajorsAPI implements IMajorsAPI {
  getMajorsBySchoolId(schoolId: number): AxiosPromise<IMajor[]> {
    return client.get(`/api/schools/${schoolId}/majors`);
  }
  public getMajorsByUniversityId(universityId: number): AxiosPromise<IMajor[]> {
    return client.get(`/api/universities/${universityId}/majors`);
  }
}

export const majors = new MajorsAPI();
