import React from 'react';
import { ProgressBar } from '../progress-bar';
import { RequirementsProgress } from '../requirements-progress';
import { StudentSchedule } from '../schedule';

export const AdminStudentProgression: React.FC = () => {
  return (
    <>
      <ProgressBar />
      <RequirementsProgress />
      <StudentSchedule />
    </>
  )
}
