import { courses } from './courses';
import { modules } from './modules';
import { user } from './user';
import { auth } from './auth';
import { schedules } from './schedules';
import { schools } from './schools';
import { premadeSchedules } from './premade-schedules';
import { majors } from './majors';
import { students } from './students';
import { scheduleTerms } from './schedule-terms';
import { scheduleNotifications } from './schedule-notifications';
import { requirements } from './requirements';
import { courseOccupancy } from './course-occupancy';
import { admin } from './admin';
import { requirementTypes } from './requirement-types';
import { advisor } from './advisors';
import { messages } from './messages';
import { studentNotes } from './student-notes';
import { configuration} from './configuration';
import { integrations } from "./integrations";
import { userProfile} from "./user-profile";

export const api = {
  auth,
  courses,
  modules,
  user,
  schedules,
  schools,
  premadeSchedules,
  majors,
  students,
  scheduleTerms,
  scheduleNotifications,
  requirements,
  courseOccupancy,
  admin,
  requirementTypes,
  studentNotes,
  advisor,
  messages,
  configuration,
  integrations,
  userProfile
};
