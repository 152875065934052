import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../ui/button';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../../infrastructure/selector';
import { IUploadedStudent } from '../../../../../../api/admin/invites';

export interface IUploadCSVProps {
  handleAddStudents: (uploadedStudents: []) => any;
}

export const UploadCsv: React.FC<IUploadCSVProps> = ({ handleAddStudents }) => {
  const dispatch = useDispatch();
  const uploadRef = useRef<HTMLInputElement>(null);
  const [csvFile, setCsvFile] = useState<File | null>(null);

  const { uploadedStudents, availableDegrees, availableSeasons, schoolId } =
    useSelector(
      (state) => ({
        uploadedStudents:
          state.pages.admin.invitations.modals.studentInvitesModal.uploaded,
        availableDegrees: state.pages.admin.common.config.degrees.list,
        availableSeasons: state.common.configs.configuration.availableSeasons,
        schoolId: state.common.user.info.schoolId,
      }),
      shallowEqual
    );

  useEffect(() => {
    handleAddStudents(
      uploadedStudents.map((student: IUploadedStudent) => ({
        name: student.name,
        schoolId: student.schoolId,
        studentId: student.studentId,
        email: student.email,
        degree: student.degree,
        startTerm: student.startTerm,
        startYear: student.startYear,
      }))
    );
  }, [uploadedStudents.length]);

  const handleOnChange = (files: FileList | null) => {
    setCsvFile(!!files?.length ? files[0] : null);
    dispatch(actions.upload.start(!!files?.length ? files[0] : null));
  };

  return (
    <>
      <Button
        classes={'mr-2'}
        size={ButtonSize.Small}
        style={ButtonStyle.Filled}
        variant={ButtonVariant.Secondary}
        isLoading={false}
        handleOnClick={() => uploadRef.current?.click()}
        radius={ButtonBorderRadius.XL}
      >
        <input
          onChange={(e) => handleOnChange(e.target.files)}
          ref={uploadRef}
          type={'file'}
          hidden
          id={'csv'}
          name={'csv'}
          accept={'text/csv'}
        />
        Upload CSV
      </Button>
    </>
  );
};
