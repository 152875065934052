import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  isLoading: boolean;
  course: {
    name: string;
    code: string;
    units: number;
    prerequisiteRequirements: { id: number; code: string }[];
    concurrentRequirements: { id: number; code: string }[];
  };
  majors: {
    id: number;
    abbreviatedName: string;
    name: string;
  }[];
  requirementCategory: string;
}

const initialState: IState = {
  isLoading: false,
  course: {
    name: '',
    code: '',
    units: 0,
    prerequisiteRequirements: [],
    concurrentRequirements: [],
  },
  majors: [],
  requirementCategory: '',
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    course: {
      ...payload.data.course,
    },
    majors: [...payload.data.majors],
    requirementCategory: payload.data.requirementCategory,
  };
});

export const courseInfoReducer = reducer;
