import React, { useEffect, useState } from 'react';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { IRangeFilter } from './reducer';
import { Dropdown } from '../../../../../components/dropdown';
import { Accordion } from '../../../../../ui/accordion';
import { Button, Checkbox } from '../../../../../ui';
import {
  CheckBoxBorder,
  CheckBoxBorderSize,
  CheckboxLabelPosition,
  CheckBoxRounded,
} from '../../../../../ui/checkbox';
import { RangeSlider } from '../../../../../ui/range-slider';
import {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../ui/button';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { useSelector } from '../../../../../infrastructure/selector';
import { IFilter } from '../../../../../types/filter';

export const StudentFilterDropdown: React.FC = () => {
  const dispatch = useDispatch();

  const { studentFilters, user } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isLoading: state.pages.admin.dashboard.overview.courses.isLoading,
      overview: state.pages.admin.dashboard.overview.overview,
      studentFilters: state.pages.admin.common.filters.students.filters,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  // TODO: Move this all to redux
  const [cohorts, setCohorts] = useState<IFilter[]>(studentFilters.cohorts);
  const [percentPlanned, setPercentPlanned] = useState<IRangeFilter>({
    ...studentFilters.percentPlanned,
  });
  const [percentInProgress, setPercentInProgress] = useState<IRangeFilter>({
    ...studentFilters.percentInProgress,
  });
  const [percentCompleted, setPercentCompleted] = useState<IRangeFilter>({
    ...studentFilters.percentCompleted,
  });

  const handleClear = () => {
    // TODO: Move this all to the reducer once we move to redux
    dispatch(
      actions.apply({
        cohorts: [],
        onlyFlaggedStudents: 0,
        grades: [],
        percentPlanned: {
          min: 0,
          max: 100,
        },
        percentInProgress: {
          min: 0,
          max: 100,
        },
        percentCompleted: {
          min: 0,
          max: 100,
        },
      })
    );
  };

  const handleApply = () => {
    // TODO: Move this to epic once we move everything to redux
    dispatch(
      actions.apply({
        cohorts: [...cohorts],
        onlyFlaggedStudents: 0,
        grades: [...studentFilters.grades],
        percentPlanned: {
          ...percentPlanned,
        },
        percentInProgress: {
          ...percentInProgress,
        },
        percentCompleted: {
          ...percentCompleted,
        },
      })
    );
  };

  return (
    <Dropdown
      renderTarget={({ isOpen, handleTrigger }) => (
        <div
          onClick={() => handleTrigger()}
          className={'w-full flex items-center justify-between'}
        >
          <span className={'text-neutral text-sm'}>Filters</span>
          {!isOpen && <RiArrowDownSLine className={'fill-baby-blue text-xl'} />}
          {isOpen && (
            <MdClose
              className={'fill-baby-blue text-xl'}
              onClick={() => handleClear()}
            />
          )}
        </div>
      )}
      renderBody={({ handleTrigger }) => (
        <>
          {/*TODO: Add this back in once we do the endpoint above*/}
          {/*<Accordion*/}
          {/*    defaultState={true}*/}
          {/*    renderTarget={({ isOpen, handleTrigger }) => (*/}
          {/*        <div onClick={() => handleTrigger()} className={"flex justify-between border-b border-b-neutral-light mb-3"}>*/}
          {/*            <span className={"text-baby-blue text-sm"}>Cohort</span>*/}
          {/*            {!isOpen && <IoIosArrowDown className={"fill-baby-blue text-lg"} />}*/}
          {/*            {isOpen && <IoIosArrowUp className={"fill-baby-blue text-lg"} />}*/}
          {/*        </div>*/}
          {/*    )}*/}
          {/*    renderBody={() => (*/}
          {/*        <div className={"w-full mb-5"}>*/}
          {/*            <ul className={"w-full mb-2"}>*/}
          {/*                <li className={"flex items-center justify-between w-full mb-2"}>*/}
          {/*                    <Checkbox*/}
          {/*                        border={CheckBoxBorder.Neutral}*/}
          {/*                        borderSize={CheckBoxBorderSize.TwoPixel}*/}
          {/*                        containerClassNames={"w-full flex items-center justify-between"}*/}
          {/*                        labelClassNames={"text-neutral-dark text-xs"}*/}
          {/*                        labelPosition={CheckboxLabelPosition.BeforeBox}*/}
          {/*                        label={"Cohort 10"}*/}
          {/*                        rounded={CheckBoxRounded.md}*/}
          {/*                        onChange={() => handleCohortSelect(10)}*/}
          {/*                        checked={cohorts.includes(10)}*/}
          {/*                    />*/}
          {/*                </li>*/}
          {/*                <li className={"flex items-center justify-between mb-2"}>*/}
          {/*                    <Checkbox*/}
          {/*                        border={CheckBoxBorder.Neutral}*/}
          {/*                        borderSize={CheckBoxBorderSize.TwoPixel}*/}
          {/*                        containerClassNames={"w-full flex items-center justify-between"}*/}
          {/*                        labelClassNames={"text-neutral-dark text-xs"}*/}
          {/*                        labelPosition={CheckboxLabelPosition.BeforeBox}*/}
          {/*                        label={"Cohort 11"}*/}
          {/*                        rounded={CheckBoxRounded.md}*/}
          {/*                        onChange={() => handleCohortSelect(11)}*/}
          {/*                        checked={cohorts.includes(11)}*/}
          {/*                    />*/}
          {/*                </li>*/}
          {/*                <li className={"flex items-center justify-between mb-2"}>*/}
          {/*                    <Checkbox*/}
          {/*                        border={CheckBoxBorder.Neutral}*/}
          {/*                        borderSize={CheckBoxBorderSize.TwoPixel}*/}
          {/*                        containerClassNames={"w-full flex items-center justify-between"}*/}
          {/*                        labelClassNames={"text-neutral-dark text-xs"}*/}
          {/*                        labelPosition={CheckboxLabelPosition.BeforeBox}*/}
          {/*                        label={"Cohort 12"}*/}
          {/*                        rounded={CheckBoxRounded.md}*/}
          {/*                        onChange={() => handleCohortSelect(12)}*/}
          {/*                        checked={cohorts.includes(12)}*/}
          {/*                    />*/}
          {/*                </li>*/}
          {/*            </ul>*/}
          {/*        </div>*/}
          {/*    )}*/}
          {/*/>*/}

          <Accordion
            defaultState={true}
            renderTarget={({ isOpen, handleTrigger }) => (
              <div
                onClick={() => handleTrigger()}
                className={
                  'flex justify-between border-b border-b-neutral-light mb-3'
                }
              >
                <span className={'text-baby-blue text-sm'}>Grade</span>
                {!isOpen && (
                  <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                )}
                {isOpen && (
                  <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                )}
              </div>
            )}
            renderBody={() => (
              <div className={'w-full'}>
                <ul className={'w-full mb-2'}>
                  {studentFilters.grades.map((grade: IFilter) => (
                    <li className={'w-full mb-2'}>
                      <Checkbox
                        border={CheckBoxBorder.Neutral}
                        borderSize={CheckBoxBorderSize.TwoPixel}
                        containerClassNames={
                          'w-full flex items-center justify-between'
                        }
                        labelClassNames={'text-neutral-dark text-xs'}
                        labelPosition={CheckboxLabelPosition.BeforeBox}
                        label={grade.label}
                        rounded={CheckBoxRounded.md}
                        onChange={() =>
                          dispatch(
                            actions.handleFilter({
                              key: 'grades',
                              filter: grade,
                            })
                          )
                        }
                        checked={grade.selected}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          />

          <Accordion
            defaultState={true}
            renderTarget={({ handleTrigger, isOpen }) => (
              <div
                onClick={() => handleTrigger()}
                className={
                  'flex justify-between border-b border-b-neutral-light mt-4'
                }
              >
                <span className={'text-baby-blue text-sm'}>Planned (%)</span>
                {!isOpen && (
                  <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                )}
                {isOpen && (
                  <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                )}
              </div>
            )}
            renderBody={() => (
              <div className={'w-full relative h-24 -mb-2'}>
                <div
                  className={'w-full flex items-center justify-center h-full'}
                >
                  <RangeSlider
                    defaultValue={[percentPlanned.min, percentPlanned.max]}
                    onChange={(value) =>
                      setPercentPlanned({
                        min: value[0],
                        max: value[1],
                      })
                    }
                  />
                </div>
              </div>
            )}
          />

          <Accordion
            defaultState={true}
            renderTarget={({ handleTrigger, isOpen }) => (
              <div
                onClick={() => handleTrigger()}
                className={
                  'flex justify-between border-b border-b-neutral-light mt-4'
                }
              >
                <span className={'text-baby-blue text-sm'}>
                  In Progress (%)
                </span>
                {!isOpen && (
                  <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                )}
                {isOpen && (
                  <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                )}
              </div>
            )}
            renderBody={() => (
              <div className={'w-full relative h-24 -mb-2'}>
                <div
                  className={'w-full flex items-center justify-center h-full'}
                >
                  <RangeSlider
                    defaultValue={[
                      percentInProgress.min,
                      percentInProgress.max,
                    ]}
                    onChange={(value) =>
                      setPercentInProgress({
                        min: value[0],
                        max: value[1],
                      })
                    }
                  />
                </div>
              </div>
            )}
          />

          <Accordion
            defaultState={true}
            renderTarget={({ handleTrigger, isOpen }) => (
              <div
                onClick={() => handleTrigger()}
                className={
                  'flex justify-between border-b border-b-neutral-light mt-4'
                }
              >
                <span className={'text-baby-blue text-sm'}>Completed (%)</span>
                {!isOpen && (
                  <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                )}
                {isOpen && (
                  <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                )}
              </div>
            )}
            renderBody={() => (
              <div className={'w-full relative h-24 -mb-2'}>
                <div
                  className={'w-full flex items-center justify-center h-full'}
                >
                  <RangeSlider
                    defaultValue={[percentCompleted.min, percentCompleted.max]}
                    onChange={(value) =>
                      setPercentCompleted({
                        min: value[0],
                        max: value[1],
                      })
                    }
                  />
                </div>
              </div>
            )}
          />

          <div className={'flex items-center justify-end mt-10'}>
            <Button
              classes={'mr-2'}
              style={ButtonStyle.Outline}
              variant={ButtonVariant.Primary}
              size={ButtonSize.Small}
              handleOnClick={() => {
                handleTrigger();
              }}
            >
              Cancel
            </Button>
            <Button
              style={ButtonStyle.Filled}
              variant={ButtonVariant.Primary}
              size={ButtonSize.Small}
              handleOnClick={() => {
                handleApply();
                handleTrigger();
              }}
            >
              Apply
            </Button>
          </div>
        </>
      )}
    />
  );
};
