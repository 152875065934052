import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

export interface IDrawerProps {
  isOpen: boolean;
  children: React.FC;
}

export const Drawer: React.FC<IDrawerProps> = ({
  isOpen,
  children: Children,
}) => {
  const [screenPosition, setScreenPosition] = useState<number>(-1000);

  const animation = useSpring({ right: isOpen ? 0 : screenPosition });

  return (
    <animated.div
      style={animation}
      className={
        'z-50 cursor-pointer fixed top-0 sm:w-10/12 md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:3/12 bg-neutral-lightest shadow-xl rounded-md h-full'
      }
    >
      <Children />
    </animated.div>
  );
};
