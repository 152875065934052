import React from 'react';
import { ISVGProps } from '../index';

export const Messages: React.FC<ISVGProps> = ({ classes }) => {
    return (
        <svg className={classes} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={"currentColor"}>
            <path d="M6 7H6.01M10 7H10.01M14 7H14.01M7 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V11C19 12.1046 18.1046 13 17 13H12L7 18V13Z" stroke="currentColor" fill={"none"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

Messages.defaultProps = {
    classes: ''
}
