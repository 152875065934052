import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../ui/button';
import { Routes } from '../../../../common/routes';
import { SearchCourses } from './search';
import { List } from './list';
import { CourseFiltersDropdown } from '../../common/filters/courses';
import { useDispatch } from 'react-redux';
import * as actions from "./list/actions";

export const AdminSettingsCourses: React.FC = () => {

  const dispatch = useDispatch();

  return (
    <>
      <section>
        <div className={'mb-10 flex flex-end'}>
          <Button
            handleOnClick={() => true}
            size={ButtonSize.Small}
            style={ButtonStyle.Outline}
            variant={ButtonVariant.Secondary}
          >
            <Link to={Routes.admin.settings.courses.create}>+ Create Course</Link>
          </Button>
        </div>
        <div className={"flex items-center justify-between mb-4 w-full"}>
          <div className={"w-1/3"}>
            <SearchCourses />
          </div>
          <div className={"w-1/3"}>
            <CourseFiltersDropdown handleOnApplyFilter={() => dispatch(actions.fetch())} />
          </div>
        </div>
        <List />
      </section>
    </>
  );
};
