import React from 'react';
import { Input, InputSize } from '../../../../../components/input';
import { Button, SelectField } from '../../../../../ui';
import { ISelectOption, SelectSize } from '../../../../../ui/select';
import { ButtonSize, ButtonVariant } from '../../../../../ui/button';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

export interface IAdminCreateDegreeProps {
  onHide: () => any;
}

export const AdminCreateDegree: React.FC<IAdminCreateDegreeProps> = ({
  onHide,
}) => {
  const dispatch = useDispatch();

  const {
    isLoading,
    name,
    abbreviatedName,
    type,
    totalCreditsRequired,
    error,
  } = useSelector(
    (state) => ({
      isLoading: state.pages.admin.settings.degrees.create.isLoading,
      name: state.pages.admin.settings.degrees.create.name,
      abbreviatedName:
        state.pages.admin.settings.degrees.create.abbreviatedName,
      type: state.pages.admin.settings.degrees.create.type,
      totalCreditsRequired:
        state.pages.admin.settings.degrees.create.totalCreditsRequired,
      error: state.pages.admin.settings.degrees.create.error,
    }),
    shallowEqual
  );

  const options: ISelectOption[] = [
    {
      label: 'Masters of Science',
      value: '1',
    },
    {
      label: 'Masters of Business Administration',
      value: '2',
    },
    {
      label: 'Masters of Fine Art',
      value: '3',
    },
  ];

  return (
    <>
      <div className={'flex w-full items-end mt-3'}>
        <div className={'w-3/12 mr-2'}>
          <Input
            label={'Name'}
            placeholder={'name'}
            size={InputSize.xs}
            name={'name'}
            required={true}
            type={'text'}
            error={error}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'name',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="w-3/12 mr-2">
          <Input
            label={'Abbr. Name'}
            placeholder={'Abbreviated Name'}
            size={InputSize.xs}
            name={'abbreviatedName'}
            required={true}
            type={'text'}
            error={error}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'abbreviatedName',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="w-3/12 mr-2">
          <Input
            label={'Total Credits Required'}
            placeholder={'25'}
            size={InputSize.xs}
            name={'totalCreditsRequired'}
            required={true}
            type={'number'}
            error={error}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'totalCreditsRequired',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="w-3/12 mr-2">
          <SelectField
            label={'Type'}
            options={options}
            onChange={(option) => dispatch(actions.onTypeChange(option))}
            size={SelectSize.xs}
            name={'type'}
            required={true}
            error={error}
          />
        </div>
        <div className={'w-2/12 items-center flex flex-1'}>
          <Button
            handleOnClick={() => {
              dispatch(actions.create.start());
            }}
            size={ButtonSize.XXSmall}
            variant={ButtonVariant.Primary}
            isLoading={isLoading}
          >
            Create
          </Button>
          <IoClose
            className={'cursor-pointer ml-2 fill-neutral'}
            onClick={() => onHide()}
          />
        </div>
      </div>
    </>
  );
};
