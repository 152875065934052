import { createAction } from 'redux-act';
import { IUser } from '../../../api/user';
import { IValidationErrorResponse } from '../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof register.start>
  | ReturnType<typeof register.done>
  | ReturnType<typeof register.error>;

export const fetch = createAction('[pages/register] fetch');

export const register = {
  start: createAction('[pages/register] register.start'),
  error: createAction<IValidationErrorResponse>(
    '[pages/register] register.error'
  ),
  done: createAction<IUser>('[pages/register] register.done'),
};

export const onChange = createAction<{
  [key: string]: string | boolean | undefined | number;
}>('[pages/register] onChange');
