import React, { useEffect } from 'react';
import { Modal } from '../../../../../components/modal';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import { AuthHeader } from '../../../shared/header';
import * as actions from './actions';
import { Input } from '../../../../../components/input';
import { Button } from '../../../../../components/button';
import { ResetPasswordSuccessScreen } from './success-screen';
import { ResetPasswordInputs } from './password-input';

export const ResetPasswordModal: React.FC = () => {
  const dispatch = useDispatch();

  const {
    isOpen,
    displaySuccessScreen,
    isLoading,
    password,
    confirmPassword,
    error,
  } = useSelector(
    (state) => ({
      displaySuccessScreen:
        state.pages.auth.modals.resetPassword.displaySuccessScreen,
      error: state.pages.auth.modals.resetPassword.error,
      password: state.pages.auth.modals.resetPassword.password,
      confirmPassword: state.pages.auth.modals.resetPassword.confirmPassword,
      isOpen: state.pages.auth.modals.resetPassword.isOpen,
      isLoading: state.pages.auth.modals.resetPassword.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <Modal
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderHeader={({ handleClose }) => {
          return (
            <header>
              <IoClose
                onClick={() => handleClose()}
                className={
                  'text-3xl absolute top-5 left-5 fill-neutral-dark cursor-pointer'
                }
              />
            </header>
          );
        }}
        renderBody={() => <></>}
        renderFooter={() => <footer></footer>}
      >
        <div className={'w-full'}>
          {displaySuccessScreen && <ResetPasswordSuccessScreen />}
          {!displaySuccessScreen && <ResetPasswordInputs />}
        </div>
      </Modal>
    </>
  );
};
