import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IStudentProgression } from '../../../../../../api/students';

interface IState {
  list: IStudentProgression[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  perPage: number;
}

const initialState: IState = {
  isLoading: false,
  list: [],
  currentPage: 0,
  lastPage: 0,
  perPage: 8,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: payload.list,
    currentPage: payload.currentPage,
    lastPage: payload.lastPage,
  };
});

reducer.on(actions.paginate, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    currentPage: payload,
  };
});

export const studentsReducer = reducer;
