import { createAction } from 'redux-act';
import { IPreppedStudentAccountInfo } from '../../../../../api/admin/invites';
import {IBlackbaudStudent} from "../../../../../api/admin/blackbaud";

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const fetch = createAction(
    '[pages/admin/student-invitations/modals/invite-students/blackbaud] fetch'
);

export const load = {
  start: createAction(
      '[pages/admin/student-invitations/modals/invite-students] load.start'
  ),
  error: createAction<any>(
      '[pages/admin/student-invitations/modals/invite-students] load.error'
  ),
  done: createAction<IBlackbaudStudent[]>(
      '[pages/admin/student-invitations/modals/invite-students] load.done'
  ),
};

export const open = createAction(
  '[pages/admin/student-invitations/modals/invite-students] open'
);
export const close = createAction(
  '[pages/admin/student-invitations/modals/invite-students] close'
);

export const send = {
  start: createAction<IPreppedStudentAccountInfo[]>(
    '[pages/admin/student-invitations/modals/invite-students] send.start'
  ),
  error: createAction<any>(
    '[pages/admin/student-invitations/modals/invite-students] send.error'
  ),
  done: createAction(
    '[pages/admin/student-invitations/modals/invite-students] send.done'
  ),
};

export const loadBlackBaudStudents = {
  start: createAction(
      '[pages/admin/student-invitations/modals/invite-students] loadBlackBaudStudents.start'
  ),
  error: createAction<any>(
      '[pages/admin/student-invitations/modals/invite-students] loadBlackBaudStudents.error'
  ),
  done: createAction<IBlackbaudStudent[]>(
      '[pages/admin/student-invitations/modals/invite-students] loadBlackBaudStudents.done'
  ),
};

// export const onChange = createAction<string>("[pages/dashboard/modals/edit-track] onChange");
