import { createAction } from 'redux-act';
import { IDegree } from '../../../../../../api/admin/degrees';
import { ISelectOption } from '../../../../../../ui/select';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>
  | ReturnType<typeof update.done>
  | ReturnType<typeof update.done>
  | ReturnType<typeof update.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/degrees/edit] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/edit/degree] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/edit/degree] load.error'
  ),
  done: createAction<IDegree>(
    '[pages/admin/dashboard/settings/degrees/edit/degree] load.done'
  ),
};

export const update = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/edit/degree] update.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/edit/degree] update.error'
  ),
  done: createAction<IDegree>(
    '[pages/admin/dashboard/settings/degrees/edit/degree] update.done'
  ),
};

export const onTypeChange = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/degrees/edit/degree] onTypeChange'
);
export const onChange = createAction<{
  [key: string]: string | boolean | undefined | number;
}>('[pages/admin/dashboard/settings/degrees/edit/degree] onChange');
