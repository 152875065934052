import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../api';
import { IEpic } from '../../../../../../infrastructure/selector';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      console.log(state$.value);
      return from(
        api.admin.degrees.getDegreeById(state$.value.common.match.params.id)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(() => {
      const {
        id,
        name,
        abbreviatedName,
        // type,
        totalCreditsRequired,
      } = state$.value.pages.admin.settings.degrees.update.degree;

      return from(
        api.admin.degrees.updateDegree({
          degreeId: id,
          name,
          abbreviatedName,
          // type,
          totalCreditsRequired,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.update.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.update.error(error.response.data)).pipe(
        concat(source$)
      );
    })
  );

export const epic = combineEpics(fetch, load, update);
