import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../../types/error';

interface IState {
  displaySuccessScreen: boolean;
  isOpen: boolean;
  isLoading: boolean;
  password: string;
  confirmPassword: string;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  displaySuccessScreen: false,
  isOpen: false,
  isLoading: false,
  password: '',
  confirmPassword: '',
  error: {
    errors: {},
    message: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
  isOpen: false,
  error: payload,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  isOpen: true,
}));

reducer.on(actions.resetPassword.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.resetPassword.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.resetPassword.done, (state, payload) => ({
  ...state,
  isLoading: false,
  isOpen: true,
  displaySuccessScreen: true,
}));

reducer.on(actions.open, (state) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
}));

reducer.on(actions.onPasswordChange, (state, payload) => ({
  ...state,
  password: payload,
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onPasswordConfirmChange, (state, payload) => ({
  ...state,
  confirmPassword: payload,
  error: {
    message: '',
    errors: {},
  },
}));

export const resetPasswordModalReducer = reducer;
