import React from 'react';

export const Footer: React.FC = () => (
  <footer
    className={'flex items-center justify-between p-4 bg-white border-t-2'}
  >
    <ul className={'flex items-center'}>
      <li className={'ml-2 text-xs text-neutral'}>
        &copy; Copyright Degreely {new Date().getFullYear()}. All Rights
        Reserved.
      </li>
      <li className={'ml-2 text-xs'}>
        <a
          className={'text-neutral underline'}
          target={'_blank'}
          href={'https://degreely.com/terms/'}
        >
          Terms of Service
        </a>
      </li>
      <li className={'ml-2 text-xs'}>
        <a
          className={'text-neutral underline'}
          target={'_blank'}
          href={'https://degreely.com/privacy/'}
        >
          Privacy Policy
        </a>
      </li>
    </ul>
    <p className={'text-neutral text-xs'}>
      Something wrong?{' '}
      <a
        className={'text-xs text-blue-darkest font-semibold hover:underline'}
        href={'https://degreely.atlassian.net/servicedesk/customer/portal/1'}
        target={'_blank'}
      >
        Submit a ticket
      </a>
    </p>
  </footer>
);
