import { createAction } from 'redux-act';
import { IUser } from '../../api/user';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[user] fetch');

export const load = {
  start: createAction('[user] load.start'),
  error: createAction<any>('[user] load.error'),
  done: createAction<IUser>('[user] load.done'),
};

export const logout = {
  start: createAction('[user] logout.start'),
  error: createAction<any>('[user] logout.error'),
  done: createAction('[user] logout.done'),
};
