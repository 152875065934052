import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../api';
import { IEpic } from '../../../../infrastructure/selector';
import { Routes } from '../../../../common/routes';
import * as alertActions from '../../../../common/alerts/actions';
import { AlertVariant } from '../../../../common/alerts/reducer';

const onDestroyError: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.error),
    map(({ payload }) => alertActions.trigger({
      heading: "Something went wrong!",
      message: payload.message,
      variant: AlertVariant.Error
    }))
  );

const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(({ payload }) =>
      from(api.schedules.destroySchedule(payload)).pipe(
        mergeMap(() => {
          return of(actions.destroy.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.destroy.error(error.response.data)).pipe(concat(source$))
    )
  );

const onDestroyComplete: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.done),
    map(() => {
      const schedule = state$.value.pages.dashboard.trackList.list[0];

      window.location.href = Routes.student.schedules + '/' + schedule.id;

      return EMPTY;
    })
  );

export const epic = combineEpics(destroy, onDestroyComplete, onDestroyError);
