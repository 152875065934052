import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../infrastructure/selector';
import {
  ICourseOccupancy,
  ICourseOccupancyInfo,
} from '../../../../../api/courses';
import { Link } from 'react-router-dom';
import {
  buildRoute,
  Routes as DegreelyRoutes,
} from '../../../../../common/routes';
import Skeleton from 'react-loading-skeleton';
import { Pagination } from '../../../../../components/pagination';

export const CourseOccupancies: React.FC = () => {
  const dispatch = useDispatch();

  const { list, isLoading, user, headers, currentPage, lastPage } = useSelector(
    (state) => ({
      currentPage: state.pages.admin.dashboard.courses.courseOccupancies.currentPage,
      lastPage: state.pages.admin.dashboard.courses.courseOccupancies.lastPage,
      user: state.common.user.info,
      headers: state.pages.admin.dashboard.courses.courseOccupancies.headers,
      list: state.pages.admin.dashboard.courses.courseOccupancies.list,
      isLoading:
        state.pages.admin.dashboard.courses.courseOccupancies.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  if (isLoading) {
    return (
      <>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
        <div className={'mt-3'}>
          <Skeleton
            className={'mr-1'}
            width={'50%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
          <Skeleton
            className={'mr-1'}
            width={'16%'}
            height={20}
            inline={true}
          />
        </div>
      </>
    );
  }

  if (!list.length) {
    return null;
  }

  return (
    <>
      <div className={'flex flex-col'}>
        <div className={'flex items-center mb-2'}>
          <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
            Course Code
          </div>
          <div className={'w-5/12 font-medium text-neutral-darkest text-xs'}>
            Course Name
          </div>
          {headers
            .map((header: { label: string; season: number; year: number }) => (
              <div
                className={'w-2/12 font-medium text-neutral-darkest text-xs'}
              >
                { header.label } { header.year }
              </div>
            ))}
        </div>
        {list.map((course: ICourseOccupancyInfo, index: number) => (
          <div
            key={index}
            className={`flex mt-3 items-center cursor-pointer py-1 hover:bg-neutral-lightest rounded-lg border border-white`}
          >
            <Link
              className={'flex items-center w-full'}
              to={buildRoute(
                DegreelyRoutes.admin.dashboard.courses.show,
                course.courseId
              )}
            >
              <div className={'w-1/12 font-medium text-neutral text-xs'}>
                {course.code}
              </div>
              <div className={'w-5/12 font-medium text-neutral text-xs'}>
                {course.name}
              </div>
              {course.occupancies
                .filter((occupancy, index) => index <= 2)
                .map((occupancy: ICourseOccupancy, index: number) => (
                  <div key={index} className={'w-2/12 font-medium text-neutral text-xs'}>
                    {occupancy.slots}{' '}
                    <span className={'font-medium text-neutral text-xs'}>planned</span>
                  </div>
                ))}
            </Link>
          </div>
        ))}
      </div>
      <Pagination
        handleOnNextClick={(page: number) => dispatch(actions.paginate(page))}
        handleOnPreviousClick={(page: number) => dispatch(actions.paginate(page))}
        handleOnPageClick={(page: number) => dispatch(actions.paginate(page))}
        currentPage={currentPage}
        lastPage={lastPage}
        totalPages={lastPage}
      />
    </>
  );
};
