import React from 'react';
import { useSelector } from '../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import {UserRoleType} from "../../../constants";

export interface IAdminOnlyProps {
    children: React.ReactNode;
}

export const StudentOnly: React.FC<IAdminOnlyProps> = ({ children }) => {

    const { user } =
        useSelector(
            (state) => ({
                user: state.common.user.info,
            }),
            shallowEqual
        );

    if(user.roleId !== UserRoleType.Student)
    {
        return null;
    }

    return (
        <>
            {children}
        </>
    )
}

StudentOnly.defaultProps = {}
