import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../infrastructure/selector';
import { api } from '../../../api';
import * as alertActions from '../../alerts/actions';
import { AlertVariant } from '../../alerts/reducer';

// const fetch: IEpic<any> = (action$, state$) =>
//   action$.pipe(
//     ofType(actions.fetch),
//     map(() => actions.save.start())
//   );

const save: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.start),
    switchMap(() => {
      const { interests, about } = state$.value.common.modals.completeProfile;
      const { id } = state$.value.common.user.info;

      return from(
        api.userProfile.completeProfile(id, {
          aboutYou: {
            dreamVocationAsKid: about.dreamVocationAsKid,
            futureVocation: about.dreamFutureVocation,
            favoriteThingToDo: about.favoriteThingToDo,
          },
          interests: {
            ...interests
          }
        })
      ).pipe(
        mergeMap(() => {
          return of(actions.save.done());
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.save.error(error.response.data)).pipe(concat(source$))
    )
  );


const onSave: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.done),
    map(() => alertActions.trigger({
      variant: AlertVariant.Success,
      heading: "Saved!",
      message: "You successfully completed your profile!"
    }))
  );

const onError: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.error),
    map(() => alertActions.trigger({
      variant: AlertVariant.Error,
      heading: "Error!",
      message: "Whoops! Something went wrong, please try again."
    }))
  );

export const epic = combineEpics(save, onSave, onError);
