import React from 'react';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { ICourseStatusInformation } from '../../../../../api/courses';
import { CourseStatus, Season } from '../../../../../constants';
import { Badge } from '../../../../../ui';
import { BadgeVariants } from '../../../../../ui/badge';
import courseStatusService from '../../../../../services/course-status/CourseStatusService';

interface IStatusState {
  course: ICourseStatusInformation;
}

export const Status: React.FC = () => {
  const { course } = useSelector<IStatusState>(
    (state) => ({
      course: state.pages.dashboard.modals.courseDetail.status,
    }),
    shallowEqual
  );

  return (
    <div>
      <Badge
        variant={courseStatusService.getBadgeVariantByCourseStatus(
          course.status
        )}
      >
        {courseStatusService.buildLabel(
          course.status,
          course.termSeason,
          course.termYear
        )}
      </Badge>
      {course.status == CourseStatus.Completed}
    </div>
  );
};
