import { combineEpics } from 'redux-observable';
import { epic as predictiveCourseData } from './predictive-course-data/epic';
import { epic as studentStatusData } from './student-status-data/epic';
import { epic as overview } from './overview-stats/epic';

export const epic = combineEpics(
  predictiveCourseData,
  studentStatusData,
  overview
);
