import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { IMajor, IMinor } from '../majors';
import { IScheduleTerm } from '../schedule-terms';
import { ICourse } from '../../types/course';
import {IFilter} from "../../types/filter";

export interface IScheduleProgression {
  percentInProgress: number;
  percentCompleted: number;
  percentPlanned: number;
  percentUnplanned: number;
  estimatedGraduationSeason: number;
  estimatedGraduationYear: number;
}

export interface IScheduleConfiguration {
  id: number;
  schoolId: number;
  seasons: number[];
}

export interface IScheduleTermGroup {
  name: string;
  isCompleted: boolean;
  list: IScheduleTerm[];
}

export interface ISchedule {
  id: number;
  name: string;
  isFavorite: boolean;
  data: string;
  terms: IScheduleTerm[];
  termGroups: IScheduleTermGroup[];
  userId: number;
  schoolId: number;
  majors: IMajor[];
  minors: IMinor[];
  progression?: IScheduleProgression;
  configuration: IScheduleConfiguration;
  createdAt: string;
  updatedAt: string;
}

export interface ICreateSchedulePayload {
  name: string;
  major_id: number[];
  minor_id: number[];
  available_seasons: number[];
}

export interface IScheduleRequirementProgress {
  id: number;
  name: string;
  required: number;
  completed: number;
  planned: number;
  color: string;
  unit: string;
}

export interface ICourseBinPayload {
  scheduleId: number;
  filters: {
    grades: number[];
    requirementCategories: number[];
    availableTerms: number[];
    availableYears: number[];
  };
}

export interface ICourseBinFiltersResponse {
  grades: IFilter[];
  requirementCategories: IFilter[];
  availableTerms: IFilter[];
  availableYears: IFilter[];
}

export interface ICourseBinFiltersPayload {
  scheduleId: number;
  grades: IFilter[];
  requirementCategories: IFilter[];
  availableTerms: IFilter[];
  availableYears: IFilter[];
}

export interface ISchedules {
  createSchedule(payload: ICreateSchedulePayload): AxiosPromise<ISchedule>;
  getSchedules(): AxiosPromise<ISchedule[]>;
  getSchedule(scheduleId: number | string): AxiosPromise<ISchedule>;
  updateSchedule(
    scheduleId: number | string,
    scheduleName: string,
    majors: IMajor[],
    scheduleData: IScheduleTerm[],
    seasons: number[]
  ): AxiosPromise<ISchedule>;
  getScheduleProgress(
    scheduleId: number | string
  ): AxiosPromise<IScheduleRequirementProgress[]>;
  destroySchedule(scheduleId: number): AxiosPromise<any>;
  favoriteSchedule(scheduleId: number): AxiosPromise<any>;
  getCourseBin(payload: ICourseBinPayload): AxiosPromise<ICourse[]>;
  getCourseBinFilters(
    payload: ICourseBinFiltersPayload
  ): AxiosPromise<ICourseBinFiltersResponse>;
}

class ScheduleAPI implements ISchedules {
  getSchedule(scheduleId: number | string): AxiosPromise<ISchedule> {
    return client.get(`/api/schedules/${scheduleId}`);
  }

  getSchedules(): AxiosPromise<ISchedule[]> {
    return client.get(`/api/schedules`);
  }

  destroySchedule(scheduleId: number): AxiosPromise<any> {
    return client.delete(`/api/schedules/${scheduleId}`);
  }

  public createSchedule(
    payload: ICreateSchedulePayload
  ): AxiosPromise<ISchedule> {
    return client.post(`/api/schedules`, {
      name: payload.name,
      major_id: payload.major_id,
      minor_id: payload.minor_id,
      available_seasons: payload.available_seasons,
    });
  }

  updateSchedule(
    scheduleId: number | string,
    scheduleName: string,
    majors: IMajor[],
    scheduleData: IScheduleTerm[],
    seasons: number[]
  ): AxiosPromise<ISchedule> {
    return client.put(`/api/schedules/${scheduleId}`, {
      name: scheduleName,
      major_id: majors.map((m: IMajor) => m.id.toString()),
      terms: scheduleData.map((term: IScheduleTerm) => ({
        ...term,
        courses: term.courses.map((course: ICourse) => course.id),
      })),
      seasons: seasons,
    });
  }

  getScheduleProgress(
    scheduleId: number | string
  ): AxiosPromise<IScheduleRequirementProgress[]> {
    return client.get(`/api/schedules/${scheduleId}/progress`);
  }

  favoriteSchedule(scheduleId: number | string): AxiosPromise {
    return client.post(`/api/schedules/${scheduleId}/activate`);
  }

  getCourseBin(payload: ICourseBinPayload): AxiosPromise<ICourse[]> {
    return client.get(`/api/schedules/${payload.scheduleId}/course-bin`, {
      params: {
        grades: payload.filters.grades,
        requirementCategories: payload.filters.requirementCategories,
        availableTerms: payload.filters.availableTerms,
        availableYears: payload.filters.availableYears,
      },
    });
  }

  getCourseBinFilters(
    payload: ICourseBinFiltersPayload
  ): AxiosPromise<ICourseBinFiltersResponse> {
    return client.post(
      `/api/schedules/${payload.scheduleId}/course-bin/filters`,
      {
        grades: payload.grades.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.value),
        requirementCategories: payload.requirementCategories.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.value),
        availableTerms: payload.availableTerms.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.value),
        availableYears: payload.availableYears.filter((filter: IFilter) => filter.selected).map((filter: IFilter) => filter.value),
      }
    );
  }
}

export const schedules = new ScheduleAPI();
