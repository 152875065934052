import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

export interface ModalProps {
  handleClose: () => any;
}

const SkeletonModal: React.FC<ModalProps> = ({ handleClose }) => {
  return (
    <>
      <div
        className={
          'fixed bg-black top-0 left-0 h-screen w-screen flex items-center justify-center z-10 opacity-60'
        }
      ></div>
      <div
        className={
          'w-screen h-screen fixed flex items-center justify-center z-50 top-0 left-0'
        }
      >
        <div
          className={
            'relative sm:10/12 md:8/12 lg:6/12 xl:w-5/12 bg-white rounded-lg shadow-lg p-10'
          }
          style={{ minHeight: 500 }}
        >
          <IoClose
            onClick={() => handleClose()}
            className={'text-3xl fill-neutral-dark cursor-pointer mb-3'}
          />
          <SkeletonTheme baseColor="#e3e3e3" highlightColor="#d1d1d1">
            <Skeleton count={1} height={70} />
            <hr
              style={{
                background: '#e3e3e3',
                height: '2px',
                border: 'none',
                marginTop: '10px',
                marginBottom: '3px',
              }}
            />
            <Skeleton
              count={2}
              width={'80%'}
              height={35}
              className={'my-2 text-center'}
            />
          </SkeletonTheme>
        </div>
      </div>
    </>
  );
};

export default SkeletonModal;
