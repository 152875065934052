import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../types/error';

interface IState {
  isLoading: boolean;
  isOpen: boolean;
  error: IValidationErrorResponse;
  legalName: string;
  preferredName: string;
  email: string;
  phone: string;
  studentId: string;
  degree: {
    label: string;
    value: number;
  }
  intendedGraduation: {
    season: number;
    year: number;
  }
}

const initialState: IState = {
  isLoading: false,
  isOpen: false,
  error: {
    errors: {},
    message: ""
  },
  legalName: "",
  preferredName: "",
  email: "",
  phone: "",
  studentId: "",
  degree: {
    label: "",
    value: 0
  },
  intendedGraduation: {
    season: 0,
    year: 0
  }
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  legalName: payload.legalName,
  preferredName: payload.preferredName,
  email: payload.email,
  studentId: payload.studentId,
  degree: {
    label: payload.degree.label,
    value: payload.degree.value
  },
  intendedGraduation: {
    season: payload.intendedGraduation.season,
    year: payload.intendedGraduation.year
  }
}));

reducer.on(actions.open, (state, payload) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state, payload) => ({
  ...state,
  isOpen: false,
}));

reducer.on(actions.save.start, (state, payload) => ({
  ...state,
  isLoading: true,
  error: {
    errors: {},
    message: ""
  }
}));

reducer.on(actions.save.done, (state, payload) => ({
  ...state,
  isLoading: false,
  isOpen: false,
}));

reducer.on(actions.save.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload
}));

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  [payload.key]: payload.value,
}));

export const UserProfileAccountSettingsReducer = reducer;
