import { createReducer } from 'redux-act';
import * as actions from './actions';

export enum AlertVariant {
  Success,
  Info,
  Warning,
  Error,
}
export interface IAlert {
  heading?: string;
  message: string;
  variant: AlertVariant;
}

interface IState {
  list: IAlert[];
}
const initialState: IState = {
  list: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.trigger, (state, payload) => ({
  ...state,
  list: [...state.list, payload],
}));

reducer.on(actions.clear, (state, payload) => ({
  ...state,
  list: [],
}));

export const alertsReducer = reducer;
