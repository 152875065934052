import { createAction } from "redux-act";
import { ICourse } from "../../../../../../types/course";

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction("[pages/admin/dashboard/student/schedule/course-bin] fetch");

export const load = {
    start: createAction("[pages/admin/dashboard/student/schedule/course-bin] load.start"),
    error: createAction<any>("[pages/admin/dashboard/student/schedule/course-bin] load.error"),
    done: createAction<ICourse[]>("[pages/admin/dashboard/student/schedule/course-bin] load.done"),
};

export const update = createAction<ICourse[]>("[pages/admin/dashboard/student/schedule/course-bin] update")
