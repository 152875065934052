import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../types/error';

interface IState {
  isLoading: boolean;
  isOpen: boolean;
  error: IValidationErrorResponse;
  athletics: number;
  english: number;
  history: number;
  math: number;
  performingArts: number;
  science: number;
  studioArts: number;
}

const initialState: IState = {
  isLoading: false,
  isOpen: false,
  error: {
    errors: {},
    message: ""
  },
  athletics: 0,
  english: 0,
  history: 0,
  math: 0,
  performingArts: 0,
  science: 0,
  studioArts: 0
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  athletics: payload.athletics,
  english: payload.english,
  history: payload.history,
  math: payload.math,
  performingArts: payload.performingArts,
  science: payload.science,
  studioArts: payload.studioArts,
}));

reducer.on(actions.open, (state, payload) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state, payload) => ({
  ...state,
  isOpen: false,
}));

reducer.on(actions.save.start, (state, payload) => ({
  ...state,
  isLoading: true,
  error: {
    errors: {},
    message: ""
  }
}));

reducer.on(actions.save.done, (state, payload) => ({
  ...state,
  isLoading: false,
  isOpen: false,
}));

reducer.on(actions.save.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload
}));

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  [payload.key]: payload.value,
}));

export const UserProfileInterestsReducer = reducer;
