import { createAction } from 'redux-act';
import { IStudentSnapshotResponse } from '../../../../../api/admin';
import { ISchedule } from '../../../../../api/schedules';
import { IScheduleTerm } from '../../../../../api/schedule-terms';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[pages/admin/dashboard/student/head] fetch');

export const load = {
  start: createAction('[pages/admin/dashboard/student/head] load.start'),
  error: createAction<any>('[pages/admin/dashboard/student/head] load.error'),
  done: createAction<IStudentSnapshotResponse>(
    '[pages/admin/dashboard/student/head] load.done'
  ),
};

export const update = {
  start: createAction<IScheduleTerm[]>(
    '[pages/admin/dashboard/student/head] update.start'
  ),
  error: createAction<any>('[pages/admin/dashboard/student/head] update.error'),
  done: createAction<ISchedule>(
    '[pages/admin/dashboard/student/head] update.done'
  ),
};
