import React from 'react';
import { Modal } from '../../../../../components/modal';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../../types/error';
import { ForgotPasswordEmailInput } from './email-input';
import { ForgotPasswordSuccessScreen } from './success-screen';

interface IForgotPasswordModalState {
  displaySuccessScreen: boolean;
  error: IValidationErrorResponse;
  email: string;
  isOpen: boolean;
  isLoading: boolean;
}

export const ForgotPasswordModal: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpen, isLoading, email, error, displaySuccessScreen } =
    useSelector<IForgotPasswordModalState>(
      (state) => ({
        displaySuccessScreen:
          state.pages.auth.modals.forgotPassword.displaySuccessScreen,
        error: state.pages.auth.modals.forgotPassword.error,
        email: state.pages.auth.modals.forgotPassword.email,
        isOpen: state.pages.auth.modals.forgotPassword.isOpen,
        isLoading: state.pages.auth.modals.forgotPassword.isLoading,
      }),
      shallowEqual
    );

  return (
    <>
      <Modal
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderHeader={({ handleClose }) => {
          return (
            <header>
              <IoClose
                onClick={() => handleClose()}
                className={
                  'text-3xl absolute top-5 left-5 fill-neutral-dark cursor-pointer'
                }
              />
            </header>
          );
        }}
        renderBody={() => {
          return <></>;
        }}
        renderFooter={() => <footer></footer>}
      >
        <div className={'w-full'}>
          {!displaySuccessScreen && <ForgotPasswordEmailInput />}
          {displaySuccessScreen && <ForgotPasswordSuccessScreen />}
        </div>
      </Modal>
    </>
  );
};
