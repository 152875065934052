import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { IUser } from '../user';
import { IScheduleTerm } from '../schedule-terms';
import { studentFiltersAPI } from './filters';
import { IRecipient } from '../../common/drawers/messages/panels/create/recipient/reducer';
import { IPaginatedResponse } from '../common';

export interface IStudentProgression extends IUser {
  id: number;
  userId: number;
  name: string;
  avatarUrl: string;
  progression: IProgression;
  hasFlag: boolean;
  flagCount: number;
}

export interface IProgression {
  percentPlanned: number;
  percentCompleted: number;
  percentInProgress: number;
  percentUnplanned: number;
  estimatedGraduationSeason: number;
  estimatedGraduationYear: number;
}

export interface IStudentDetail {
  id: number;
  name: string;
  email: string;
  major: string;
  hasActiveSchedule: boolean;
  progression: IStudentProgression;
  currentTerm: IScheduleTerm;
  nextTerm: IScheduleTerm;
  flags: number;
  about: {
    dreamVocationAsKid: string;
    futureVocation: string;
    favoriteThingToDo: string;
  },
  interests: {
    english: number;
    history: number;
    math: number;
    science: number;
    performingArts: number;
    athletics: number;
    studioArts: number;
  }
}

export interface IStudentDetailResponse {
  data: IStudentDetail;
}

export interface IStudentProgressionsParams {
  schoolId: number;
  search?: string;
  page?: number;
  limit?: number;
  filters?: IStudentAPIFilters;
}

export interface IStudents {
  getStudentProgressions(
    params: IStudentProgressionsParams
  ): AxiosPromise<IPaginatedResponse<IStudentProgression>>;
  getStudentDetail(studentId: number): AxiosPromise<IStudentDetailResponse>;
  getStudentsBySchoolId(schoolId: number): AxiosPromise<IRecipient[]>;
}

export interface IStudentAPIFilters {
  grade: number[];
  cohort: number[];
  flags: number;
  percentInProgressMin: number;
  percentInProgressMax: number;
  percentPlannedMin: number;
  percentPlannedMax: number;
  percentCompletedMin: number;
  percentCompletedMax: number;
}

class StudentAPI implements IStudents {
  filters = studentFiltersAPI;

  // TODO: this should change to /api/student/progressions
  getStudentProgressions(
    params: IStudentProgressionsParams
  ): AxiosPromise<IPaginatedResponse<IStudentProgression>> {
    return client.get(`/api/students`, {
      params: {
        schoolId: params.schoolId,
        name: params.search,
        limit: params.limit,
        page: params.page ?? 1,
        grade: params?.filters?.grade,
        flags: params?.filters?.flags ?? 0,
        percentInProgressMin: params.filters?.percentInProgressMin,
        percentInProgressMax: params.filters?.percentInProgressMax,
        percentPlannedMax: params.filters?.percentPlannedMax,
        percentPlannedMin: params.filters?.percentPlannedMin,
        percentCompletedMax: params.filters?.percentCompletedMax,
        percentCompletedMin: params.filters?.percentCompletedMin,
      },
    });
  }

  getStudentDetail(studentId: number): AxiosPromise<IStudentDetailResponse> {
    return client.get(`/api/students/${studentId}/detail`);
  }

  getStudentsBySchoolId(schoolId: number): AxiosPromise<IRecipient[]> {
    return client.get(`/api/schools/${schoolId}/students`);
  }
}

export const students = new StudentAPI();
