import React from 'react';
import { Input } from '../input';
import { BiSearch } from '@react-icons/all-files/bi/BiSearch';
import { UserAvatar } from '../avatar';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { IValidationErrorResponse } from '../../types/error';

export interface ISelected {
  id: number;
  imageUrl: string;
  label: string;
}

export interface ISearchInputProps {
  placeholder: string;
  handleOnChange: (e: any) => any;
  inputClassNames?: string;
  iconClassNames?: string;
  renderLabel?: React.FC;
  selected?: ISelected | null;
  handleClearSelection?: () => any;
  error?: IValidationErrorResponse;
  name?: string;
  handleOnFocus?: () => any;
  value?: string;
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  handleOnFocus,
  name,
  handleOnChange,
  placeholder,
  inputClassNames,
  iconClassNames,
  renderLabel: Label,
  selected,
  handleClearSelection,
  error,
}) => {
  return (
    <>
      {!!Label && <Label />}
      <div className={'relative'}>
        {selected?.label != null && (
          <div
            className={inputClassNames + ' flex items-center justify-between'}
          >
            <div className={'flex items-center'}>
              <UserAvatar size={20} imageUrl={selected?.imageUrl} />
              <span>{selected?.label}</span>
            </div>
            <MdClose
              onClick={() => handleClearSelection && handleClearSelection()}
              className={'fill-neutral-dark text-lg'}
            />
          </div>
        )}

        {selected?.label == null && <BiSearch className={iconClassNames} />}
        {selected?.label == null && (
          <Input
            error={error}
            className={inputClassNames}
            onChange={(e) => handleOnChange(e)}
            type={'text'}
            required={false}
            name={name ?? 'search'}
            placeholder={placeholder}
            handleOnFocus={handleOnFocus}
          />
        )}
      </div>
    </>
  );
};

SearchInput.defaultProps = {
  inputClassNames:
    'bg-neutral-lightest p-2 pl-8 text-xs w-full text-neutral-dark',
  iconClassNames: 'fill-baby-blue absolute top-2 left-2',
  error: { errors: {}, message: '' },
};
