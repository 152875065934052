import React, { useEffect } from 'react';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';

export const AdminStudentProfileAbout: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, dreamVocationAsKid, futureDreamVocation, favoriteThingToDo, studentName } = useSelector(
    (state) => ({
      isLoading: state.pages.admin.dashboard.student.profile.about.isLoading,
      studentName: state.pages.admin.dashboard.student.info.details.name,
      dreamVocationAsKid: state.pages.admin.dashboard.student.profile.about.dreamVocationAsKid,
      futureDreamVocation: state.pages.admin.dashboard.student.profile.about.futureDreamVocation,
      favoriteThingToDo: state.pages.admin.dashboard.student.profile.about.favoriteThingToDo,
    }),
    shallowEqual
  );


  useEffect(() => {
    dispatch(actions.fetch());
  }, [studentName]);

  if(isLoading) {
    return null;
  }

  return (
    <div>
      <h2 className={"text-2xl text-neutral-dark font-semibold mb-1"}>About {studentName}</h2>
      <hr className={"border-b border-baby-blue mb-4"} />

      <div className={"mb-4"}>
        <span className={"mb-2 block text-baby-blue text-sm"}>When I was a kid, I wanted to be a...</span>
        <div className={"border rounded-full border-neutral-darkest px-4 py-1 text-sm text-neutral-darkest"}>{dreamVocationAsKid ?? "N/A"}</div>
      </div>
      <div className={"mb-4"}>
        <span className={"mb-2 block text-baby-blue text-sm"}>When I imagine my future, I see myself as a...</span>
        <div className={"border rounded-full border-neutral-darkest px-4 py-1 text-sm text-neutral-darkest"}>{futureDreamVocation ?? "N/A"}</div>
      </div>
      <div>
        <span className={"mb-2 block text-baby-blue text-sm"}>My favorite thing to do in life is...</span>
        <div className={"border rounded-full border-neutral-darkest px-4 py-1 text-sm text-neutral-darkest"}>{favoriteThingToDo ?? "N/A"}</div>
      </div>
    </div>
  )
}
