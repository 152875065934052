import { combineReducers } from 'redux';

import { createPanelReducer as create } from './create/reducer';
import { threadsReducer as threads } from './threads/reducer';
import { threadReducer as thread } from './thread/reducer';

export const panelsReducer = combineReducers({
  create,
  threads,
  thread,
});
