import React, {useEffect} from "react";
import {useSelector} from "../../../../../infrastructure/selector";
import {shallowEqual, useDispatch} from "react-redux";
import {DropResult} from "react-beautiful-dnd";
import * as actions from "../../../../dashboard/current-track/actions";
import {StudentDragAndDropSchedule} from "../../../../../components/student-schedule";
import * as termsActions from "./actions";
import {TrackHeader} from "../track-header";
import * as courseBinActions from "./course-bin/actions";
export const StudentSchedule: React.FC = () => {

    const dispatch = useDispatch();
    const { schedule, isLoading, configuration, courseBinCourses } = useSelector(
        (state) => ({
            schedule: state.pages.admin.dashboard.student.schedule.schedule,
            isLoading: state.pages.admin.dashboard.student.schedule.isLoading,
            configuration: state.common.configs.configuration,
            courseBinCourses: state.pages.admin.dashboard.student.courseBin.list
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(courseBinActions.fetch())
    }, []);

    if(!schedule) {
        return null;
    }

    return (
            <StudentDragAndDropSchedule
                courseBin={courseBinCourses}
                schedule={schedule}
                displayTermCost={configuration.displayTermCostOnSchedule}
                handleOnAddTerm={() => dispatch(termsActions.addTerm.start(schedule.id))}
                handleOnRemoveTerm={(termId: number) => dispatch(termsActions.removeTerm.start(termId))}
                handleOnDragEnd={(result: DropResult) => dispatch(actions.onDragEnd.start(result))}
                renderHeader={({ handleOnAddTerm }) => <TrackHeader handleOnAddTerm={handleOnAddTerm} />}
            />
    )
}
