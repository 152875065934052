import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  keyword: string;
}

const initialState: IState = {
  keyword: '',
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.onStudentChange, (state, payload) => ({
  ...state,
  keyword: payload,
}));

export const searchReducer = reducer;
