import { createAction } from 'redux-act';
import { IMessageThread } from '../../../../../api/messages';
import { IUser } from '../../../../../api/user';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export interface ISelectMessageThreadPayload {
  messageThreadId: number;
  contextualRecipient: IUser;
}

export const fetch = createAction(
  '[common/drawers/messages/panels/threads] fetch'
);
export const select = createAction<ISelectMessageThreadPayload>(
  '[common/drawers/messages/panels/threads] select'
);

export const onSearch = createAction<string>(
  '[common/drawers/messages/panels/threads] onSearch'
);

export const resetSearch = createAction(
  '[common/drawers/messages/panels/threads] resetSearch'
);

export const load = {
  start: createAction('[common/drawers/messages/panels/threads] load.start'),
  error: createAction<any>(
    '[common/drawers/messages/panels/threads] load.error'
  ),
  done: createAction<IMessageThread[]>(
    '[common/drawers/messages/panels/threads] load.done'
  ),
};

export const markAsRead = {
  start: createAction<number>(
    '[common/drawers/messages/panels/threads] markAsRead.start'
  ),
  error: createAction<any>(
    '[common/drawers/messages/panels/threads] markAsRead.error'
  ),
  done: createAction<IMessageThread>(
    '[common/drawers/messages/panels/threads] markAsRead.done'
  ),
};
