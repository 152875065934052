import { createAction } from 'redux-act';
import { IValidationErrorResponse } from '../../../types/error';

export type IActions =
  | ReturnType<typeof add>
  | ReturnType<typeof addV2.start>
  | ReturnType<typeof addV2.error>
  | ReturnType<typeof addV2.done>
  | ReturnType<typeof removeV2.start>
  | ReturnType<typeof removeV2.error>
  | ReturnType<typeof removeV2.done>
  | ReturnType<typeof remove>;

export const add = createAction('[drag-and-drop-schedule/term] add');
export const remove = createAction<number>(
  '[drag-and-drop-schedule/term] remove'
);

export const addV2 = {
  start: createAction('[drag-and-drop-schedule/term] add.start'),
  error: createAction<any>('[drag-and-drop-schedule/term] add.error'),
  done: createAction('[pdrag-and-drop-schedule/term] add.done'),
};

export const removeV2 = {
  start: createAction<number | string>(
    '[drag-and-drop-schedule/term] removeV2.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[drag-and-drop-schedule/term] removeV2.error'
  ),
  done: createAction('[drag-and-drop-schedule/term] removeV2.done'),
};
