import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const ProgressionListSkeleton: React.FC = () => {
  return (
    <>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
      <div className={'mt-3'}>
        <Skeleton className={'mr-5'} width={'19%'} height={20} inline={true} />
        <Skeleton className={'mr-5'} width={'60%'} height={20} inline={true} />
        <Skeleton width={'18%'} height={20} inline={true} />
      </div>
    </>
  );
};
