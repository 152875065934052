import React, { useEffect } from 'react';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { useSelector } from '../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { ISchedule } from '../../../api/schedules';
import { Link } from 'react-router-dom';
import * as trackActions from '../current-track/actions';
import * as modalActions from '../modals/create-track/actions';
import { Routes } from '../../../common/routes';
import * as actions from './actions';
import { HiOutlineTrash } from '@react-icons/all-files/hi/HiOutlineTrash';
import * as deleteTrackModalActions from '../modals/delete-track/actions';
import * as editTrackModalActions from '../modals/edit-track/actions';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import { ScheduleFavorite } from './favorite';

export const TrackList: React.FC = () => {
  const dispatch = useDispatch();

  const { list, activeTrackId, isLoading, multipleStudentSchedulesEnabled } = useSelector(
    (state) => ({
      activeTrackId: state.common.match.params.id,
      list: state.pages.dashboard.trackList.list,
      isLoading: state.pages.dashboard.trackList.isLoading,
      multipleStudentSchedulesEnabled: state.common.configs.configuration.multipleStudentSchedulesEnabled
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  if (!list.length) {
    return null;
  }

  if(!multipleStudentSchedulesEnabled) {
    return null;
  }

  return (
    <ul className={'flex items-center mb-6'}>
      {!!list.length &&
        list.map((track: ISchedule) => {
          return (
            <li key={track.id} className={'flex items-center mr-3'}>
              <div
                className={`${
                  activeTrackId == track.id
                    ? 'text-white bg-baby-blue'
                    : 'bg-white text-black shadow-md'
                } flex items-center rounded-full px-5 py-2 text-xs font-normal rounded-full cursor-pointer`}
              >
                <Link
                  onClick={() =>
                    dispatch(trackActions.setCurrentTrackIndex(track.id))
                  }
                  to={`${Routes.student.schedules}/${track.id}`}
                >
                  {track.name}
                </Link>
                <ScheduleFavorite
                  scheduleId={track.id}
                  isSelected={activeTrackId == track.id}
                  isFavorite={track.isFavorite}
                />
              </div>
              {activeTrackId == track.id && multipleStudentSchedulesEnabled && (
                <div className={'ml-2 flex items-center'}>
                  <AiOutlineEdit
                    className={'mr-2 text-xl fill-black cursor-pointer'}
                    onClick={() => dispatch(editTrackModalActions.open(track.id))}
                  />
                  <HiOutlineTrash
                    className={'mr-2 text-xl stroke-black cursor-pointer'}
                    onClick={() =>
                      dispatch(deleteTrackModalActions.open(track.id))
                    }
                  />
                </div>
              )}
            </li>
          );
        })}
      {multipleStudentSchedulesEnabled && (
        <li className={'flex items-center'}>
          <div
            className={
              'bg-purple-light border border-dashed border-baby-blue rounded-full flex items-center justify-center w-5 h-5'
            }
            style={{ padding: '2px' }}
          >
            <HiPlus className={'fill-baby-blue text-2xl'} />
          </div>
          <span
            onClick={() => dispatch(modalActions.open())}
            className={'text-xs ml-2 text-baby-blue font-normal cursor-pointer'}
          >
          Create new track
        </span>
        </li>
      )}
    </ul>
  );
};
