import React, { useEffect } from 'react';
import { Greeting } from '../../../shared/greeting';
import { Layout } from '../../../shared/layout';
import { Outlet } from 'react-router-dom';
import { AdminPageTabs } from './page-tabs';
import { StudentDetailDrawer } from './drawers/student-detail';

export const AdminDashboard: React.FC = () => {
  return (
    <>
      <Layout>
        <div className={'flex w-full'}>
          <section className={'w-full py-8 px-16'}>
            <Greeting />
            {/*<AdminPageTabs />*/}
            <section className={'bg-white rounded-sm p-10'}>
              <Outlet />
            </section>
          </section>
          <StudentDetailDrawer />
        </div>
      </Layout>
    </>
  );
};
