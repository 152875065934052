import { BiMessageDots } from '@react-icons/all-files/bi/BiMessageDots';
import { FiSettings } from '@react-icons/all-files/fi/FiSettings';
import { IoLogOutOutline } from '@react-icons/all-files/io5/IoLogOutOutline';
import React from 'react';
import { UserAvatar } from '../../../components/avatar';
import {Messages} from "../../../icons/messages";
import {Cog} from "../../../icons/cog";
import {InviteStudent} from "../../../icons/invite-student";
import {NavLink} from "react-router-dom";
import {Logout} from "../../../icons/logout";
import {shallowEqual, useDispatch} from "react-redux";
import * as messagesDrawerActions from "../../../common/drawers/messages/actions";
import {useSelector} from "../../../infrastructure/selector";
import classNames from "classnames";
import {Helpers} from "../../../helpers";
import {RequiresMessagingAccess} from "../../../common/guards/messaging-access";
import { Routes} from '../../../common/routes';

export interface ISidebarNavigationFooter {
  isExpanded: boolean;
}
export const SidebarNavigationFooter: React.FC<ISidebarNavigationFooter> = ({ isExpanded }) => {

  const dispatch = useDispatch();

  const { messageDrawerIsOpen, user, configuration } = useSelector(
      (state) => ({
        user: state.common.user.info,
        messageDrawerIsOpen: state.common.drawers.messages.state.isOpen,
        configuration: state.common.configs.configuration,
      }),
      shallowEqual
  );

  const itemClasses = "flex items-center mb-4 pt-3 cursor-pointer w-full";
  const textClasses = "text-sm pt-1 ml-2 font-medium cursor-pointer";

  const collapsedMessageClasses = classNames({
    "p-1.5 bg-white flex items-center rounded-md group is-active fill-baby-blue text-baby-blue stroke-baby-blue": messageDrawerIsOpen,
    "fill-white p-1.5 bg-baby-blue flex items-center text-white": !messageDrawerIsOpen
  });

  const expandedMessageClasses = classNames({
    textClasses,
    "p-1.5 bg-white flex items-center rounded-md group is-active fill-baby-blue text-baby-blue stroke-baby-blue": messageDrawerIsOpen,
    "p-1.5 fill-white bg-baby-blue flex items-center text-white": !messageDrawerIsOpen
  })

  if(!isExpanded) {
    return (
      <section>
        <ul className={"flex flex-col"}>
          <li className={"flex items-center flex-col mb-8 cursor-pointer justify-center"}>
            <UserAvatar imageUrl={user?.avatarUrl} size={40}  />
          </li>
          <RequiresMessagingAccess>
            <li className={"cursor-pointer flex items-center flex-col mb-4"} onClick={() => dispatch(messagesDrawerActions.toggle())}>
              <div className={collapsedMessageClasses}>
                <Messages classes={"text-2xl"}/>
              </div>
            </li>
          </RequiresMessagingAccess>
          <li className={"flex items-center flex-col mb-3"}>
            <NavLink to={Routes.user.profile} className={({ isActive}) => isActive ? "p-1.5 bg-white flex items-center mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "fill-white p-1.5 bg-baby-blue flex items-center mb-4 text-white"}>
              <Cog classes={"text-2xl"}/>
            </NavLink>
          </li>
          <li className={"flex items-center flex-col cursor-pointer mb-6"} onClick={() => Helpers.handleLogout()}>
            <Logout classes={"text-2xl"}/>
          </li>
        </ul>
      </section>
    )
  }

  return (
      <section className={"px-5"}>
        <ul className={"flex flex-col"}>
          <li className={"flex mb-8 items-center"}>
            <UserAvatar imageUrl={user?.avatarUrl} size={40}  />
            <div className={"flex flex-col ml-2"}>
              <span className={"text-white font-medium text-xs"}>{user?.name}</span>
              <span className={"text-white font-medium text-xs"}>{user?.email}</span>
            </div>
          </li>
          <RequiresMessagingAccess>
            <li className={expandedMessageClasses} onClick={() => dispatch(messagesDrawerActions.toggle())}>
              <Messages classes={"text-2xl"}/>
              <span className={textClasses}>Messages</span>
            </li>
          </RequiresMessagingAccess>
          <li className={itemClasses}>
            <NavLink to={Routes.user.profile} className={({ isActive}) => isActive ? "p-1.5 bg-white flex items-center rounded-md group is-active fill-baby-blue text-baby-blue w-full" : "fill-white p-1.5 bg-baby-blue flex items-center text-white w-full"}>
              <Cog classes={"text-2xl"}/>
              <span className={"text-sm pt-1 ml-2 font-medium"}>Settings</span>
            </NavLink>
          </li>
          <li className={"flex items-center mb-4 cursor-pointer w-full p-1.5"} onClick={() => Helpers.handleLogout()}>
            <Logout classes={"text-2xl"} />
            <span className={"text-sm ml-2 font-medium cursor-pointer"}>Logout</span>
          </li>
        </ul>
      </section>
  )
}
