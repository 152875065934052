import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof onStudentChange>;

export const onStudentChange = createAction<string>(
  '[pages/admin/dashboard/student-status-data/search] onStudentChange'
);
export const onFilterChange = createAction<{ key: string; value: any }>(
  '[pages/admin/dashboard/student-status-data/search] onFilterChange'
);
