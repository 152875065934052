import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IMessageThread } from '../../../../../api/messages';

interface IState {
  isLoading: boolean;
  list: IMessageThread[];
  search: string;
}

const initialState: IState = {
  isLoading: false,
  list: [],
  search: '',
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: [...payload],
  };
});

reducer.on(actions.onSearch, (state, payload) => {
  return {
    ...state,
    search: payload,
  };
});

reducer.on(actions.resetSearch, (state, payload) => {
  return {
    ...state,
    search: "",
  };
});

export const threadsReducer = reducer;
