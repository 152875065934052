import React from "react";
import {Edit} from "../../../icons/edit";
import { UserProfileAccountSettingFields } from './fields';
import { AccountSettingsModal } from './modal';
import * as modalActions from './modal/actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../infrastructure/selector';
import { UserRoleType } from '../../../constants';

export const UserProfileAccountSettings: React.FC = () => {

    const dispatch = useDispatch();

    const { user, isLoading, legalName, preferredName, email, studentId, degree, intendedGraduationSeason, intendedGraduationYear } = useSelector(
      (state) => ({
          isLoading: state.pages.studentProfile.interests.modal.isLoading,
          user: state.common.user.info,
          legalName: state.pages.studentProfile.accountSettings.modal.legalName,
          preferredName: state.pages.studentProfile.accountSettings.modal.preferredName,
          email: state.pages.studentProfile.accountSettings.modal.email,
          studentId: state.pages.studentProfile.accountSettings.modal.studentId,
          degree: state.pages.studentProfile.accountSettings.modal.degree,
          intendedGraduationSeason: state.pages.studentProfile.accountSettings.modal.intendedGraduation.season,
          intendedGraduationYear: state.pages.studentProfile.accountSettings.modal.intendedGraduation.year,
      }),
      shallowEqual
    );

    if(isLoading) {
      return null;
    }

    return (
        <>
            <div className={"mt-16"}>
                <div className={"flex items-center"}>
                    <h3 className={"text-neutral-darkest text-2xl mr-2 font-medium"}>Account Settings</h3>
                    <Edit classes={"text-baby-blue cursor-pointer"} onClick={() => dispatch(modalActions.open())} />
                </div>
                <UserProfileAccountSettingFields
                  values={{
                    legalName,
                    preferredName,
                    email,
                    studentId,
                    degree: degree.label,
                    intendedGraduationSeason,
                    intendedGraduationYear,
                  }}
                  isEditable={false}
                  isAdmin={user?.roleId == UserRoleType.Administrator}
                  isStudent={user?.roleId == UserRoleType.Student}
                />
                <AccountSettingsModal  />
            </div>
        </>
    )
}
