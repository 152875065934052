import { combineEpics } from 'redux-observable';
import { epic as students } from './students/epic';
import { epic as courses } from './courses/epic';
import { epic as course } from './course/epic';
import { epic as student } from './student/epic';
import { epic as main } from './main/epic';
import { epic as drawers } from './drawers/epic';

export const epic = combineEpics(
  students,
  courses,
  course,
  student,
  main,
  drawers
);
