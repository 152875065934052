import React from 'react';
import { DegreeProgressBar } from '../../../../../components/degree-progress-bar';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';

export const ProgressBar: React.FC = () => {
  const { planned, unplanned, completed } = useSelector(
    (state) => ({
      planned: state.pages.admin.dashboard.student.progress.planned,
      unplanned: state.pages.admin.dashboard.student.progress.unplanned,
      completed: state.pages.admin.dashboard.student.progress.completed,
    }),
    shallowEqual
  );

  return (
    <section className={'mb-10'}>
      <DegreeProgressBar
        unplanned={unplanned}
        planned={planned}
        completed={completed}
      />
    </section>
  );
};
