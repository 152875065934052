import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../infrastructure/selector';
import { ICourseFutureOccupancy } from '../../../../../api/courses';
import { DataPoint } from '../../students/predictive-course-data/timeline/data-point';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const CourseOccupancies: React.FC = () => {
  const dispatch = useDispatch();
  const [isNewYear, setIsNewYear] = useState(true);
  let currentYear = 0;

  const { isLoading, url, list, selectedOccupancyId } = useSelector(
    (state) => ({
      url: state.common.match.url,
      list: state.pages.admin.dashboard.course.occupancies.list,
      isLoading: state.pages.admin.dashboard.course.occupancies.isLoading,
      selectedOccupancyId:
        state.pages.admin.dashboard.course.occupancies.selectedOccupancyId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [url]);

  return (
    <section className={'mt-4'}>
      <h2 className={'text-2xl text-neutral-darkest'}>
        Course Occupancy Timeline
      </h2>
      <span className={'text-xs text-neutral-light'}>
        Based on historical enrollment and student plans
      </span>

      {isLoading && <Skeleton height={300} width={'100%'} className={'mt-4'} />}

      {!isLoading && (
        <div
          className={'border border-neutral-light mt-4 py-4 px-8 rounded-md'}
        >
          <div
            className={'flex relative justify-around'}
            style={{ height: 200 }}
          >
            {!!list.length &&
              list.map((occupancy: ICourseFutureOccupancy, index: number) => {
                let isNewYear = false;
                if (index == 0) {
                  currentYear = occupancy.termYear;
                  isNewYear = true;
                } else {
                  if (currentYear != occupancy.termYear) {
                    isNewYear = true;
                    currentYear = occupancy.termYear;
                  } else {
                    isNewYear = false;
                  }
                }

                // TODO: This threshold number should be configurable
                let threshold = 25;
                let percentage =
                  Math.round((occupancy.plannedSlots / threshold) * 100) + '%';

                return (
                  <DataPoint
                    showYear={isNewYear}
                    isCurrent={occupancy.isCurrent}
                    selected={selectedOccupancyId}
                    occupancyId={occupancy.id}
                    onClick={() => dispatch(actions.select(occupancy.id))}
                    percentage={percentage}
                    termSeason={occupancy.termSeason}
                    termYear={occupancy.termYear}
                    slots={occupancy.plannedSlots}
                  />
                );
              })}
          </div>
        </div>
      )}
    </section>
  );
};
