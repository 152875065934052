import { createAction } from 'redux-act';
import { IStudentDetailResponse } from '../../../../../api/students';
import { IRecipient } from '../../../../../common/drawers/messages/panels/create/recipient/reducer';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof open>
  | ReturnType<typeof close>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/drawers/student-detail] fetch'
);

export const open = createAction<number>(
  '[pages/admin/dashboard/drawers/student-detail] open'
);
export const close = createAction(
  '[pages/admin/dashboard/drawers/student-detail] close'
);

export const openMessageDrawerWithRecipient = createAction<IRecipient>(
  '[pages/admin/dashboard/drawers/student-detail] openMessageDrawerWithRecipient'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/drawers/student-detail] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/drawers/student-detail] load.error'
  ),
  done: createAction<IStudentDetailResponse>(
    '[pages/admin/dashboard/drawers/student-detail] load.done'
  ),
};

export const preview = {
  select: createAction<number>(
    '[pages/admin/dashboard/drawers/student-detail] preview.select'
  ),
};
