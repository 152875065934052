import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { Season } from '../../constants';
import { ICourse } from '../../types/course';

export interface IScheduleTerm {
  id: number;
  name: string;
  season: Season;
  year: number;
  isCompleted: number;
  inProgress: number;
  createdAt: string;
  updatedAt: string;
  courses: ICourse[];
  creditTotal?: number;
  includeInTotal?: boolean;
}

export interface IScheduleTerms {
  addScheduleTerm(scheduleId: number | string): AxiosPromise<IScheduleTerm>;
  removeScheduleTerm(
    scheduleId: number | string,
    scheduleTermId: number | string
  ): AxiosPromise<IScheduleTerm>;
}

class ScheduleTermAPI implements IScheduleTerms {
  addScheduleTerm(scheduleId: number | string): AxiosPromise<IScheduleTerm> {
    return client.post(`/api/schedules/${scheduleId}/terms`);
  }

  removeScheduleTerm(
    scheduleId: number | string,
    scheduleTermId: number | string
  ): AxiosPromise<IScheduleTerm> {
    return client.delete(
      `/api/schedules/${scheduleId}/terms/${scheduleTermId}`
    );
  }
}

export const scheduleTerms = new ScheduleTermAPI();
