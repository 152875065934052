class PhoneNumberFormatter {
  format(number: string): string {
    let phoneArr: string[] = [];

    let separator = '-';

    let phoneNumberArray = number.split('');

    phoneNumberArray.forEach((number: string, index: number) => {
      if (
        phoneNumberArray.length === 4 &&
        phoneNumberArray[index] !== separator
      ) {
        if (index === 3) {
          phoneArr.push('-');
        }
      }

      if (phoneNumberArray.length === 8) {
        if (index === 7 && phoneNumberArray[index] !== separator) {
          phoneArr.push(separator);
        }
      }

      phoneArr.push(number);
    });

    return phoneArr.join('');
  }
}

export default new PhoneNumberFormatter();
