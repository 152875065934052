import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { ISelectOption } from '../../ui/select';
import { IRecipient } from '../../common/drawers/messages/panels/create/recipient/reducer';

export interface ISchool {
  id: number;
  university_id: number;
  name: string;
  abbreviated_name: string;
  created_at: string;
  updated_at: string;
}

export interface IAvailableSeason {
  label: string;
  value: number;
}

export interface IGradeLevel {
  label: string;
  value: number;
}

export interface ISchoolConfiguration {
  id: number;
  schoolId: number;
  availableSeasons: IAvailableSeason[];
  displayTermCostOnSchedule: boolean;
  gradeLevels: IGradeLevel[];
  availableRequirementUnitTypes: ISelectOption[];
  studentInviteTemplateUrl: string;
}

export interface ISchools {
  getSchools(): AxiosPromise<ISchool[]>;
  getSchool(schoolId: number | string): AxiosPromise<ISchool>;
  getSchoolConfiguration(schoolId: number): AxiosPromise<ISchoolConfiguration>;
  getStudentsBySchoolId(
    schoolId: number,
    search: string
  ): AxiosPromise<IRecipient[]>;
}

class SchoolsAPI implements ISchools {
  getSchool(schoolId: number | string): AxiosPromise<ISchool> {
    return client.get(`/api/schools/${schoolId}`);
  }

  getSchools(): AxiosPromise<ISchool[]> {
    return client.get('/api/schools');
  }

  getSchoolConfiguration(schoolId: number): AxiosPromise<ISchoolConfiguration> {
    return client.get(`/api/schools/${schoolId}/configuration`);
  }

  getStudentsBySchoolId(
    schoolId: number,
    search: string
  ): AxiosPromise<IRecipient[]> {
    return client.get(`/api/schools/${schoolId}/students`, {
      params: {
        name: search,
      },
    });
  }
}

export const schools = new SchoolsAPI();
