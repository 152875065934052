import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Greeting } from '../../shared/greeting';
import { TrackList } from './track-list';
import { Layout } from '../../shared/layout';
import { CreateNewTrackModal } from './modals/create-track';
import { CourseDetailModal } from './modals/course-detail';
import { DeleteTrackModal } from './modals/delete-track';
import { RequirementCategoryDetailModal } from './modals/requirement-category-detail';
import { DeleteTermModal } from './modals/delete-term';
import { EditTrackModal } from './modals/edit-track';

export const DashboardPage: React.FC = () => {
  return (
    <>
      <Layout>
        <>
          <section className={'px-8 py-6 relative'}>
            <Greeting />
            <TrackList />
            <section className={'bg-white rounded-sm p-5'}>
              <Outlet />
            </section>
          </section>
          <CreateNewTrackModal />
          <CourseDetailModal />
          <DeleteTermModal />
          <RequirementCategoryDetailModal />
          <DeleteTrackModal />
          <EditTrackModal />
        </>
      </Layout>
    </>
  );
};
