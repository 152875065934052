import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { IMajor } from '../../../../../../api/majors';
import { colorStyles, controlErrorStyles, controlStyles } from '../../index';
import { Helpers } from '../../../../../../helpers';
import { api } from '../../../../../../api';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../../../actions';

export const ProgramField: React.FC = () => {
  const dispatch = useDispatch();

  const [programs, setPrograms] = useState<IMajor[]>([]);

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { schoolId, majorId, error } = register;

  useEffect(() => {
    api.majors
      .getMajorsBySchoolId(schoolId)
      .then((res) => {
        setPrograms(res.data);
      })
      .catch((error) => console.log(error));
  }, [schoolId]);

  if (!programs.length) {
    return null;
  }

  const errorKey = 'major_id';
  let hasError =
    !!Object.keys(error).length && error.errors.hasOwnProperty(errorKey);

  let styles = {
    ...colorStyles,
  };

  if (hasError) {
    styles = {
      ...colorStyles,
      control: (styles) => ({
        ...styles,
        ...controlStyles,
        ...controlErrorStyles,
      }),
    };
  }

  return (
    <div className={'mb-4'}>
      <label
        className={'block text-blue-darkest text-sm mb-1 font-semibold'}
        htmlFor="password"
      >
        Program*
      </label>
      <Select
        placeholder={'select program'}
        name={'majorId'}
        styles={styles}
        className={'text-sm text-black'}
        onChange={(option: any) =>
          dispatch(
            actions.onChange({
              key: 'majorId',
              value: option?.value,
            })
          )
        }
        options={Helpers.mapToSelectOptions<IMajor>(
          programs,
          (item: IMajor) => ({ label: item.name, value: item.id.toString() })
        )}
      />
      {Helpers.hasError(error, 'major_id') && (
        <span className={'text-red text-xs'}>{error.errors['major_id']}</span>
      )}
    </div>
  );
};
