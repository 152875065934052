import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof favorite.start>
  | ReturnType<typeof favorite.done>
  | ReturnType<typeof favorite.error>;

export const favorite = {
  start: createAction<number>(
    '[pages/dashboard/track-list/favorite] favorite.start'
  ),
  error: createAction<any>(
    '[pages/dashboard/track-list/favorite] favorite.error'
  ),
  done: createAction('[pages/dashboard/track-list/favorite] favorite.done'),
};
