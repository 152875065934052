import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { ICourseStatusInformation } from '../courses';

export interface IRequirements {
  getRequirementStatus(
    payload: IRequirementStatusRequest
  ): AxiosPromise<IRequirementStatusResponse>;
}

export interface ICourseInfo {
  id: number;
  name: string;
  code: string;
  status: ICourseStatusInformation;
}

export interface IRequirementCategoryStatus {
  id: number;
  name: string;
  completed: number;
  required: number;
  color: string;
  unit: string;
}

export interface IRequirementStatusResponse {
  category: IRequirementCategoryStatus;
  courses: ICourseInfo[];
}

export interface IRequirementStatusRequest {
  requirementId: number;
  scheduleId: number;
}

class RequirementsAPI implements IRequirements {
  getRequirementStatus(payload: IRequirementStatusRequest): AxiosPromise<any> {
    return client.get(`/api/requirement-category/status`, {
      params: {
        scheduleId: payload.scheduleId,
        requirementId: payload.requirementId,
      },
    });
  }
}

export const requirements = new RequirementsAPI();
