import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../infrastructure/selector';
import { Modal } from '../../../components/modal';
import { Button } from '../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../ui/button';
import * as actions from './actions';
import SkeletonModal from '../../../components/modal/skeleton';
import { CompleteProfileAbout } from './about';
import { CompleteProfileInterests } from './interests';

export const CompleteProfileModal: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpen, isLoading, page, totalPages, completedProfile, isStudent } = useSelector(
    (state) => ({
      completedProfile: state.common.user.info.completedProfile,
      isStudent: state.common.user.info.isStudent,
      isOpen: state.common.modals.completeProfile.isOpen,
      totalPages: state.common.modals.completeProfile.totalPages,
      page: state.common.modals.completeProfile.page,
      isLoading: state.common.modals.completeProfile.isLoading,
    }),
    shallowEqual
  );

  const pages = [
    CompleteProfileInterests,
    CompleteProfileAbout,
  ];

  if (!isOpen || completedProfile || !isStudent) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => dispatch(actions.close())} />;
  }

  return (
    <>
      <Modal
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderBody={() => <body></body>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-10'}>
              <div className="mr-2">
                <Button
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Secondary}
                  handleOnClick={() => page == totalPages ? dispatch(actions.paginate.prev()) : dispatch(actions.paginate.next())}
                >
                  {page == totalPages ? "Back" : "Skip"}
                </Button>
              </div>
              <Button
                isLoading={isLoading}
                size={ButtonSize.Medium}
                style={ButtonStyle.Filled}
                variant={ButtonVariant.Secondary}
                handleOnClick={() => page == totalPages ? dispatch(actions.save.start()) : dispatch(actions.paginate.next())}
              >
                {page == totalPages ? "Save" : "Next"}
              </Button>
            </div>
          </>
        )}
      >
        <div>
          <div
            className={
              'flex items-center justify-between mb-6 border-b border-neutral-light'
            }
          >
            <div className={"flex items-center justify-between w-full"}>
              <h2 className={'text-4xl font-semibold mb-2 text-baby-blue'}>
                Complete Your Profile
              </h2>
              <div className={"flex items-baseline"}>
                <span className={"text-4xl font-bold text-baby-blue mr-2"}>{page}</span>
                <span className={"text-md uppercase text-neutral-light mr-1"}>/</span>
                <span className={"text-md text-neutral-light uppercase ml-1"}>{totalPages} questions</span>
              </div>
            </div>
          </div>
          <div>
            {pages.map((Component, index) => index + 1 == page && <Component />)}
          </div>
        </div>
      </Modal>
    </>
  );
};
