import { createAction } from 'redux-act';
import { ISchedule } from '../../api/schedules';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[pages/schedules] fetch');

export const load = {
  start: createAction('[pages/schedules] load.start'),
  error: createAction<any>('[pages/schedules] load.error'),
  done: createAction<ISchedule[]>('[pages/schedules] load.done'),
};
