import React, { useEffect } from 'react';
import { Greeting } from '../../../shared/greeting';
import { Outlet } from 'react-router-dom';
import { Layout } from '../../../shared/layout';
import { AdminSettingsPageTabs } from './page-tabs';
import { shallowEqual, useDispatch } from 'react-redux';
import * as configActions from './common/config/actions';
import { useSelector } from '../../../infrastructure/selector';

export const AdminSettings: React.FC = () => {
  const dispatch = useDispatch();

  const { schoolId } = useSelector(
    (state) => ({
      schoolId: state.common.user.info.schoolId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(configActions.fetch());
  }, [schoolId]);

  return (
    <>
      <Layout>
        <div className={'flex w-full'}>
          <section className={'w-full py-8 px-16'}>
            <Greeting />
            <div className='flex items-center justify-between'>
              {/*<AdminSettingsPageTabs />*/}
            </div>
            <section className={'bg-white rounded-sm p-10'}>
              <Outlet />
            </section>
          </section>
        </div>
      </Layout>
    </>
  );
};
