import { createReducer } from 'redux-act';
import * as actions from '../head/actions';

interface IState {
  hasLoaded: boolean;
  isLoading: boolean;
  planned: number;
  unplanned: number;
  completed: number;
}

const initialState: IState = {
  hasLoaded: false,
  isLoading: false,
  planned: 0,
  unplanned: 0,
  completed: 0,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    hasLoaded: true,
    planned: payload.schedule.progression?.percentPlanned ?? 0,
    unplanned: payload.schedule.progression?.percentUnplanned ?? 0,
    completed: payload.schedule.progression?.percentCompleted ?? 0,
  };
});

export const progressReducer = reducer;
