import { combineReducers } from 'redux';

import { coursesReducer as courses } from './courses/reducer';
import { courseReducer as course } from './course/reducer';
import { commonReducer as common } from './common/reducer';
import { degreesReducer as degrees } from './degrees/reducer';

export const adminSettingsReducer = combineReducers({
  courses,
  course,
  common,
  degrees,
});
