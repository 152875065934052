import React, { useState } from 'react';
import { useSelector } from '../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import {
  IScheduleNotification,
  ScheduleNotificationType,
} from '../../../../api/schedule-notifications';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { IoMdInformationCircle } from '@react-icons/all-files/io/IoMdInformationCircle';

interface ITermRequirements {
  termId: number;
}

export const TermRequirements: React.FC<ITermRequirements> = ({ termId }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const { list } = useSelector(
    (state) => ({
      list: state.pages.dashboard.trackNotifications.list,
    }),
    shallowEqual
  );
  const notifications = list
    .filter((l: IScheduleNotification) => l.term_id === termId)
    .filter(
      (l: IScheduleNotification) => l.type === ScheduleNotificationType.Term
    );

  if (!notifications.length) {
    return null;
  }

  return (
    <>
      {!isExpanded && (
        <div
          className={'absolute top-1 right-1 pointer'}
          onClick={() => setIsExpanded(true)}
        >
          <IoMdInformationCircle className={'fill-red text-lg'} />
        </div>
      )}
      {isExpanded &&
        notifications.map(
          (notification: IScheduleNotification, index: number) => (
            <div
              key={index}
              className={
                'flex items-center justify-between mt-4 text-red font-medium bg-neutral-lightest px-3 py-2 text-xs rounded-full'
              }
            >
              <span>{notification.message}</span>
              <MdClose
                className={'fill-neutral-dark text-lg'}
                onClick={() => setIsExpanded(false)}
              />
            </div>
          )
        )}
    </>
  );
};
