import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { termSeasonService } from '../../../../../../../services/term-season/TermSeasonService';

export interface IDataPointProps {
  showYear: boolean;
  onClick?: () => any;
  isCurrent: boolean;
  occupancyId: number;
  selected: number;
  percentage: string;
  termSeason: number;
  termYear: number;
  slots: number;
}

export const DataPoint: React.FC<IDataPointProps> = ({
  percentage,
  termSeason,
  termYear,
  slots,
  onClick,
  selected,
  occupancyId,
  isCurrent,
  showYear,
}) => {
  let message = '';
  if (slots > 1) {
    message = `${slots} Students Planned`;
  } else {
    message = `${slots} Student Planned`;
  }

  const classes = classNames(
    { 'bg-baby-blue': selected == occupancyId },
    { 'bg-neutral-lighter': selected != occupancyId },
    'mb-5 w-2 hover:bg-baby-blue cursor-pointer transition ease-in-out'
  );
  const isCurrentClasses = classNames(
    { 'font-semibold text-neutral-darkest': isCurrent },
    { 'font-normal text-neutral-light': !isCurrent },
    'text-xxs font-semibold absolute -bottom-1'
  );

  return (
    <div
      className={'flex flex-col items-center justify-end'}
      style={{ height: 200 }}
      onClick={() => !!onClick && onClick()}
    >
      <div
        className={classes}
        style={{ height: percentage }}
        data-tip={message}
      >
        <ReactTooltip
          backgroundColor={'#007AFF'}
          className={'!rounded-full !text-xs !px-2 !py-1 !bg-baby-blue'}
        />
      </div>
      {/*{selected == occupancyId && <div className={"absolute -bottom-3 w-2 h-2 rounded-lg bg-baby-blue"}></div>}*/}
      {/*<div className={isCurrentClasses}>|</div>*/}
      {selected == occupancyId && (
        <div
          className={
            'font-semibold absolute -top-2 text-xxs bg-baby-blue text-white rounded-full px-2 py-1 uppercase'
          }
        >
          {termSeasonService.getSeasonAsString(termSeason) + ' ' + termYear}
        </div>
      )}
      {showYear && (
        <div className={isCurrentClasses}>
          <div>{termYear}</div>
        </div>
      )}
    </div>
  );
};

DataPoint.defaultProps = {
  onClick: () => {},
  showYear: false,
};
