import { createAction } from 'redux-act';
import { IDegreeRequirement } from '../../../../../../../api/admin/degrees';
import { IValidationErrorResponse } from '../../../../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof destroy.done>
  | ReturnType<typeof destroy.done>
  | ReturnType<typeof destroy.error>;

export const destroy = {
  start: createAction<number>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/destroy] destroy.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/destroy] destroy.error'
  ),
  done: createAction<boolean>(
    '[pages/admin/dashboard/settings/degrees/edits/degree/requirements/destroy] destroy.done'
  ),
};
