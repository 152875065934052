import { createAction } from 'redux-act';
import {IUserProfileAboutYou} from "../../../../api/user-profile";
import { IValidationErrorResponse } from '../../../../types/error';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const fetch = createAction(
    '[pages/user-profile/about-you/modal] fetch'
);

export const load = {
    start: createAction(
        '[pages/user-profile/about-you/modal] load.start'
    ),
    error: createAction<any>(
        '[pages/user-profile/about-you/modal] load.error'
    ),
    done: createAction<IUserProfileAboutYou>(
        '[pages/user-profile/about-you/modal] load.done'
    ),
};

export const save = {
    start: createAction(
      '[pages/user-profile/about-you/modal] save.start'
    ),
    error: createAction<IValidationErrorResponse>(
      '[pages/user-profile/about-you/modal] save.error'
    ),
    done: createAction<IUserProfileAboutYou>(
      '[pages/user-profile/about-you/modal] save.done'
    ),
};

export const open = createAction(
    '[pages/user-profile/about-you/modal] open'
);
export const close = createAction(
    '[pages/user-profile/about-you/modal] close'
);

export const onChange = createAction<{ key: string; value: string; }>(
  '[pages/user-profile/about-you/modal] onChange'
);




