import React from 'react';
import classNames from 'classnames';
import { IValidationErrorResponse } from '../../types/error';

export enum InputBorderVariant {
  Primary,
  Secondary,
  Tertiary,
  Neutral,
  None,
}

export enum InputVariant {
  Primary,
  Transparent,
  Neutral,
}

export enum InputSize {
  xs,
  sm,
  md,
  lg,
  xl,
  xxs,
}

export interface IInputProps {
  type: string;
  label?: string;
  name: string;
  value?: string;
  borderVariant?: InputBorderVariant;
  required: boolean;
  placeholder?: string;
  maxLength?: number;
  error?: IValidationErrorResponse;
  // TODO: Type the event
  onChange: (e: any) => any;
  disabled?: boolean;
  variant?: InputVariant;
  size?: InputSize;
  className?: string;
  containerStyles?: string;
  style?: React.CSSProperties;
  handleOnFocus?: () => false;
}

export const Input: React.FC<IInputProps> = ({
  name,
  type,
  error,
  label,
  onChange,
  value,
  required,
  size,
  variant,
  className,
  style,
  containerStyles,
  borderVariant,
  handleOnFocus,
  disabled,
  ...props
}) => {
  const hasError = () => {
    return (
      !!error &&
      !!Object.keys(error).length &&
      error?.errors.hasOwnProperty(name)
    );
  };

  let inputStyles =
    'text-sm text-neutral-darkest p-3 border border-black rounded-xl w-full placeholder:text-neutral';

  if (hasError()) {
    inputStyles =
      'text-sm text-neutral-darkest p-3 border border-red rounded-xl w-full';
  }

  let styles: string;

  if (className) {
    styles = className;
  } else {
    styles = classNames(
      'text-sm text-neutral-darkest border rounded-xl w-full placeholder:text-neutral',
      { 'py-2 px-3': size == InputSize.xs },
      { 'p-3': size == InputSize.md },
      { 'py-1 px-2': size == InputSize.xxs },
      { 'bg-transparent': variant == InputVariant.Transparent },
      { 'bg-white': variant == InputVariant.Primary },
      { 'bg-neutral-lighter': variant == InputVariant.Neutral }
    );
  }

  if(disabled) {
    styles += ' text-neutral-light';
  }

  if (borderVariant === InputBorderVariant.Primary) {
    styles += ' border-baby-blue';
  }

  if (borderVariant === InputBorderVariant.Neutral) {
    styles += ' border-black';
  }

  return (
    <>
      <div className={containerStyles}>
        {!!label && (
          <label
            className={'block text-blue-darkest font-semibold text-sm mb-1'}
            htmlFor={name}
          >
            {label}
            {required && '*'}
          </label>
        )}
        <input
          onFocus={handleOnFocus}
          {...props}
          name={name}
          disabled={disabled}
          style={style}
          className={styles}
          type={type}
          onChange={(e) => onChange(e)}
          value={value}
        />
        {hasError() && (
          <span className={'text-red text-xs'}>{error?.errors[name]}</span>
        )}
      </div>
    </>
  );
};

Input.defaultProps = {
  variant: InputVariant.Primary,
  size: InputSize.md,
  label: '',
  placeholder: '',
  borderVariant: InputBorderVariant.Neutral,
  handleOnFocus: () => false,
};
