import React from 'react';
import { AlertVariant } from '../../common/alerts/reducer';

export enum ToastVariant {
  Success,
  Warning,
  Info,
  Error,
}

export interface IToastProps {
  variant: AlertVariant;
  children: any;
}

export const Toast: React.FC<IToastProps> = ({ children, variant }) => {
  let classNames = 'p-4 fixed bottom-5 left-5 rounded-lg text-sm z-50';

  if (variant === AlertVariant.Info) {
    classNames +=
      ' bg-baby-blue-lightest border-baby-blue border text-baby-blue';
  }

  if (variant === AlertVariant.Success) {
    classNames += ' bg-green-lightest border-green border text-green';
  }

  if (variant === AlertVariant.Error) {
    classNames += ' bg-red-lightest border-red border text-red';
  }

  return <div className={classNames}>{children}</div>;
};
