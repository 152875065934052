import { createAction } from 'redux-act';
import { IAdminAccountSettings } from '../../../../../api/user-profile';
import { IValidationErrorResponse } from '../../../../../types/error';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const fetch = createAction(
  '[pages/profile/admin/account-settings/modal] fetch'
);

export const load = {
  start: createAction(
    '[pages/profile/admin/account-settings/modal] load.start'
  ),
  error: createAction<any>(
    '[pages/profile/admin/account-settings/modal] load.error'
  ),
  done: createAction<IAdminAccountSettings>(
    '[pages/profile/admin/account-settings/modal] load.done'
  ),
};

export const save = {
  start: createAction(
    '[pages/profile/admin/account-settings/modal] save.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/profile/admin/account-settings/modal] save.error'
  ),
  done: createAction<IAdminAccountSettings>(
    '[pages/profile/admin/account-settings/modal] save.done'
  ),
};

export const open = createAction(
  '[pages/profile/admin/account-settings/modal] open'
);
export const close = createAction(
  '[pages/profile/admin/account-settings/modal] close'
);

export const onChange = createAction<{ key: string; value: string; }>(
  '[pages/profile/admin/account-settings/modal] onChange'
);




