import React, { useEffect } from 'react';
import * as actions from './actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { MessagesDrawerPanels } from '../../reducer';
import { IMessage } from '../../../../../api/messages';
import { useSelector } from '../../../../../infrastructure/selector';
import { Message } from './message';
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack';
import { UserAvatar } from '../../../../../components/avatar';
import {
  Input,
  InputBorderVariant,
  InputSize,
  InputVariant,
} from '../../../../../components/input';
import { Button } from '../../../../../ui';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../ui/button';

export interface IThreadMessagesPanelProps {
  handleSetActivePanel: (activePanel: MessagesDrawerPanels) => any;
}
export const ThreadMessagesPanel: React.FC<IThreadMessagesPanelProps> = ({
  handleSetActivePanel,
}) => {
  const dispatch = useDispatch();

  const { messages, recipient, user, body, error, isLoading } = useSelector(
    (state) => ({
      user: state.common.user.info,
      messages: state.common.drawers.messages.panels.thread.messages,
      isLoading: state.common.drawers.messages.panels.thread.isLoading,
      recipient: state.common.drawers.messages.panels.thread.recipient,
      body: state.common.drawers.messages.panels.thread.body,
      error: state.common.drawers.messages.panels.thread.error,
    }),
    shallowEqual
  );

  const handleOnEnter = (e: KeyboardEvent) => {
    if(e.key === "Enter") {
      dispatch(actions.sendMessage.start());
    }
  }

  useEffect(() => {
    document.addEventListener("keypress", handleOnEnter)

    return () => document.removeEventListener("keypress", handleOnEnter);
  }, []);

  useEffect(() => {
    dispatch(actions.fetch());
    const interval = setInterval(() => {
      dispatch(actions.fetch());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  if (!user?.name) {
    return null;
  }

  return (
    <div className={'flex flex-col h-full'}>
      <section
        className={'flex items-center justify-between border-b px-5 py-4'}
      >
        <div className={'w-1/3'}>
          <IoIosArrowBack
            onClick={() => handleSetActivePanel(MessagesDrawerPanels.Threads)}
            className={'text-xl fill-neutral'}
          />
        </div>
        <div className={'flex-1 flex items-center'}>
          <UserAvatar size={30} imageUrl={recipient.avatarUrl} />
          <div className={'flex flex-col ml-2'}>
            <h6 className={'text-baby-blue font-semibold text-sm'}>
              {recipient.name}
            </h6>
            {recipient.studentId && (
              <span className={'text-xxs text-neutral'}>
                ID # {recipient.studentId}
              </span>
            )}
            {recipient.email && (
              <span className={'text-xxs text-neutral'}>{recipient.email}</span>
            )}
          </div>
        </div>
      </section>
      <section className={'py-2 px-4 flex-1 flex flex-col-reverse mt-4 overflow-auto hide-scrollbar'}>
        {!!messages.length && (
          <ul>
            {messages.map((message: IMessage) => (
              <Message
                sender={message.sender}
                currentUserIsCreator={user.id == message.sender.id}
                message={message}
              />
            ))}
          </ul>
        )}
      </section>
      <section className={'py-2 px-4 flex items-center mb-4'}>
        <Input
          placeholder={'Message'}
          containerStyles={'w-full'}
          value={body}
          size={InputSize.md}
          variant={InputVariant.Neutral}
          borderVariant={InputBorderVariant.None}
          onChange={(e) => dispatch(actions.onChange(e.target.value))}
          type={'text'}
          required={false}
          name={'body'}
          error={error}
        />
        <Button
          isLoading={isLoading}
          classes={'ml-3'}
          style={ButtonStyle.Filled}
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Large}
          radius={ButtonBorderRadius.MD}
          handleOnClick={() => dispatch(actions.sendMessage.start())}
        >
          Send
        </Button>
      </section>
    </div>
  );
};
