import React, { useEffect } from 'react';
import * as actions from './actions';
import * as studentDetailDrawerActions from '../../../drawers/student-detail/actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import { ProgressionList } from '../../../../../../components/progression-list';
import { Pagination } from '../../../../../../components/pagination';

export const Students: React.FC = () => {
  const dispatch = useDispatch();

  const { list, isLoading, user, currentPage, lastPage } = useSelector(
    (state) => ({
      list: state.pages.admin.dashboard.students.studentStatusData.students
        .list,
      currentPage:
        state.pages.admin.dashboard.students.studentStatusData.students
          .currentPage,
      lastPage:
        state.pages.admin.dashboard.students.studentStatusData.students
          .lastPage,
      isLoading:
        state.pages.admin.dashboard.students.studentStatusData.students
          .isLoading,
      user: state.common.user.info,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  if (!list.length) {
    return null;
  }

  return (
    <>
      <ProgressionList
        isLoading={isLoading}
        list={list}
        onClickAction={studentDetailDrawerActions.open}
      />
      <Pagination
        handleOnNextClick={(page: number) => dispatch(actions.paginate(page))}
        handleOnPageClick={(page: number) => dispatch(actions.paginate(page))}
        handleOnPreviousClick={(page: number) =>
          dispatch(actions.paginate(page))
        }
        totalPages={50}
        currentPage={currentPage}
        lastPage={lastPage}
      />
    </>
  );
};
