import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';

const fetchOnSearch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onChange),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { search } = state$.value.pages.admin.dashboard.overview.students;

      return from(
        api.students.getStudentProgressions({
          schoolId: state$.value.common.user.info.schoolId,
          search: search,
          limit: 5,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, fetchOnSearch);
