import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as baseMessagingDrawerActions from '../../../actions';
import { api } from '../../../../../../api';
import { IEpic } from '../../../../../../infrastructure/selector';
import { UserRoleType } from '../../../../../../constants';
import { MessagesDrawerPanels } from '../../../reducer';

const onOpenWithRecipient: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(baseMessagingDrawerActions.openWithRecipient),
    switchMap(({ payload }) => of(actions.select(payload), baseMessagingDrawerActions.changeActivePanel(MessagesDrawerPanels.CreateMessageThread)))
  );
const fetchOnSearch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onSearch),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => actions.load.start())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { search } =
        state$.value.common.drawers.messages.panels.create.recipient;
      const user = state$.value.common.user.info;

      if (user.roleId == UserRoleType.Student) {
        return from(
          api.advisor.getAdvisorsBySchoolId(
            state$.value.common.user.info.schoolId,
            search
          )
        ).pipe(
          mergeMap(({ data }) => {
            return of(
              actions.load.done(
                data.map((advisor) => ({
                  ...advisor,
                }))
              )
            );
          })
        );
      } else {
        return from(
          api.schools.getStudentsBySchoolId(user.schoolId, search)
        ).pipe(
          mergeMap(({ data }) => {
            return of(
              actions.load.done(
                data.map((student) => ({
                  ...student,
                }))
              )
            );
          })
        );
      }
    }),
    catchError((error, source$) => {
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(fetch, load, fetchOnSearch, onOpenWithRecipient);
