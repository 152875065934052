import { createAction } from 'redux-act';
import { IStudentNote } from '../../../../../../api/student-notes';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>
  | ReturnType<typeof create.start>
  | ReturnType<typeof create.done>
  | ReturnType<typeof create.error>
  | ReturnType<typeof update.start>
  | ReturnType<typeof update.done>
  | ReturnType<typeof update.error>;

export interface ICreateNotePayload {
  content: string;
  studentId: string;
}

export interface IUpdateNotePayload {
  id: number;
  content: string;
}

export const fetch = createAction(
  '[pages/admin/dashboard/student/info/notes] fetch'
);

export const load = {
  start: createAction('[pages/admin/dashboard/student/info/notes] load.start'),
  error: createAction<any>(
    '[pages/admin/dashboard/student/info/notes] load.error'
  ),
  done: createAction<IStudentNote[]>(
    '[pages/admin/dashboard/student/info/notes] load.done'
  ),
};

export const create = {
  start: createAction<ICreateNotePayload>(
    '[pages/admin/dashboard/student/info/notes] create.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/student/info/notes] create.error'
  ),
  done: createAction<IStudentNote>(
    '[pages/admin/dashboard/student/info/notes] create.done'
  ),
};

export const update = {
  start: createAction<IUpdateNotePayload>(
    '[pages/admin/dashboard/student/info/notes] update.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/student/info/notes] update.error'
  ),
  done: createAction<IStudentNote>(
    '[pages/admin/dashboard/student/info/notes] update.done'
  ),
};
