import React from 'react';
import { useSelector } from '../../infrastructure/selector';
import { shallowEqual } from 'react-redux';

export const Greeting: React.FC = () => {
  const { user, isLoading } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isLoading: state.common.user.isLoading,
    }),
    shallowEqual
  );

  return (
    <h1 className={'text-neutral-darkest text-3xl font-medium mb-5'}>
      Welcome back, {user?.name}.
    </h1>
  );
};
