import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IStudentNote } from '../../../../../../api/student-notes';
import { IValidationErrorResponse } from '../../../../../../types/error';

interface IState {
  isLoading: boolean;
  list: IStudentNote[];
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  list: [],
  error: { errors: {}, message: '' },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  error: { errors: {}, message: '' },
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    error: { errors: {}, message: '' },
    list: [...payload],
  };
});

reducer.on(actions.create.start, (state, payload) => ({
  ...state,
  error: { errors: {}, message: '' },
  isLoading: true,
}));

reducer.on(actions.create.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.create.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    error: { errors: {}, message: '' },
  };
});

reducer.on(actions.update.start, (state, payload) => ({
  ...state,
  isLoading: true,
  error: { errors: {}, message: '' },
}));

reducer.on(actions.update.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.update.done, (state, payload) => {
  return {
    ...state,
    error: { errors: {}, message: '' },
    isLoading: false,
  };
});

export const notesReducer = reducer;
