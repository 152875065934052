import {createReducer} from "redux-act";
import * as headActions from "../head/actions";
import * as scheduleActions from "../schedule/actions";
import {ISchedule} from "../../../../../api/schedules";

interface IState {
  schedule: ISchedule | null;
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
  schedule: null,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(headActions.load.start, (state, payload) => ({
    ...state,
    isLoading: true,
}));

reducer.on(headActions.load.error, (state, payload) => ({
    ...state,
    isLoading: false,
}));

reducer.on(headActions.load.done, (state, payload) => {
    return {
        ...state,
        isLoading: false,
        schedule: payload.schedule,
    }
});

reducer.on(headActions.update.done, (state, payload) => {
    return {
        ...state,
        isLoading: false,
        schedule: payload,
    }
});

reducer.on(scheduleActions.load.done, (state, payload) => {
    return {
        ...state,
        isLoading: false,
        schedule: payload,
    }
});

export const scheduleReducer = reducer;

