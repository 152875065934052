import React from 'react';
import { Toast } from '../../ui/toast';
import { useSelector } from '../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { IAlert } from './reducer';

export const AlertToast: React.FC = () => {
  const { alerts } = useSelector(
    (state) => ({
      alerts: state.common.alerts.list,
    }),
    shallowEqual
  );

  if (!alerts.length) {
    return null;
  }

  return (
    <>
      {alerts.map((alert: IAlert) => (
        <Toast variant={alert.variant}>
          <>
            {alert.heading && (
              <h6 className={'font-semibold text-xs'}>{alert.heading}</h6>
            )}
            <span className={'text-xs'}>{alert.message}</span>
          </>
        </Toast>
      ))}
    </>
  );
};
