import { createAction } from 'redux-act';
import {
  IDegreeRequirement,
  IDegreeRequirementsResponse,
} from '../../../../../../api/admin/degrees';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/course/majors/requirement-categories] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/majors/requirement-categories] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/majors/requirement-categories] load.error'
  ),
  done: createAction<IDegreeRequirementsResponse>(
    '[pages/admin/dashboard/settings/course/majors/requirement-categories] load.done'
  ),
};

export const sync = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/majors/requirement-categories] sync.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/majors/requirement-categories] sync.error'
  ),
  done: createAction<any>(
    '[pages/admin/dashboard/settings/course/majors/requirement-categories] sync.done'
  ),
};

export const onCheck = createAction<number>(
  '[pages/admin/dashboard/settings/course/majors/requirement-categories] onCheck'
);
