import React from 'react';
import { DegreeRequirementsList } from './requirements/list';
import { Degree } from './degree';

export const AdminEditDegreePage: React.FC = () => {
  return (
    <>
      <Degree />
      <DegreeRequirementsList />
    </>
  );
};
