import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourse } from '../../../../../types/course';

interface IState {
  list: ICourse[];
  perPage: number;
  currentPage: number;
  lastPage: number|null;
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
  perPage: 25,
  currentPage: 1,
  lastPage: null,
  list: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    list: payload.list,
    perPage: payload.perPage,
    currentPage: payload.currentPage,
    lastPage: payload.lastPage,
    isLoading: false,
  };
});

reducer.on(actions.paginate, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    currentPage: payload,
  };
});

export const listReducer = reducer;
