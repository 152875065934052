import { createAction } from 'redux-act';
import { IAdvisor } from '../../../../../../api/advisors';
import { IRecipient } from './reducer';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[common/drawers/messages/panels/create/recipient] fetch'
);

export const load = {
  start: createAction(
    '[common/drawers/messages/panels/create/recipient] load.start'
  ),
  error: createAction<any>(
    '[common/drawers/messages/panels/create/recipient] load.error'
  ),
  done: createAction<IRecipient[]>(
    '[common/drawers/messages/panels/create/recipient] load.done'
  ),
};

export const onSearch = createAction<string>(
  '[common/drawers/messages/panels/create/recipient] onSearch'
);

export const select = createAction<IAdvisor>(
  '[common/drawers/messages/panels/create/recipient] select'
);

export const clear = createAction(
  '[common/drawers/messages/panels/create/recipient] clear'
);
