import React from 'react';
import { HiMenuAlt1 } from '@react-icons/all-files/hi/HiMenuAlt1';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import classNames from 'classnames';
import { CollapsedSidebarNavigation } from './collapsed';
import { ExpandedSidebarNavigation } from './expanded';
import { SidebarNavigationFooter } from './footer';

export const SidebarNavigation: React.FC = () => {

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const sidebarClasses = classNames("flex flex-col w-full bg-baby-blue py-3 px-1 fixed top-0 z-10 h-full", {
    'w-72': isExpanded,
    'w-18': !isExpanded
  })

  return (
    <div className={sidebarClasses}>
      <div className={"pb-8"}>
        {!isExpanded && <HiMenuAlt1 onClick={() => setIsExpanded(!isExpanded)} className={"fill-white text-2xl cursor-pointer"}/>}
        {isExpanded && <IoClose onClick={() => setIsExpanded(!isExpanded)} className={"fill-white text-2xl cursor-pointer"}/>}
      </div>
      <div className={'flex flex-col justify-between h-full'}>
        {!isExpanded && <CollapsedSidebarNavigation />}
        {isExpanded && <ExpandedSidebarNavigation />}
      </div>
      <SidebarNavigationFooter isExpanded={isExpanded} />
    </div>
  )
};
