import React from 'react';
import { AiFillStar } from '@react-icons/all-files/ai/AiFillStar';
import classNames from 'classnames';
import { AiOutlineStar } from '@react-icons/all-files/ai/AiOutlineStar';
import { useDispatch } from 'react-redux';
import * as actions from './actions';

interface IScheduleFavoriteProps {
  scheduleId: number;
  isFavorite: boolean;
  isSelected: boolean;
}

export const ScheduleFavorite: React.FC<IScheduleFavoriteProps> = ({
  isFavorite,
  isSelected,
  scheduleId,
}) => {
  const dispatch = useDispatch();

  const activeClasses = classNames(
    'ml-1 text-lg',
    { 'fill-yellow-lighter': isFavorite && isSelected },
    { 'fill-black': isFavorite && !isSelected }
  );
  const inActiveClasses = 'ml-1 text-lg stroke-white';

  if (!isSelected) {
    return null;
  }

  return (
    <>
      {!isFavorite && (
        <AiOutlineStar
          className={inActiveClasses}
          onClick={() => dispatch(actions.favorite.start(scheduleId))}
        />
      )}
      {isFavorite && <AiFillStar className={activeClasses} />}
    </>
  );
};
