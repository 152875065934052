import { createAction } from 'redux-act';
import { IStudentFlag } from '../../../../../../api/admin';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>
  | ReturnType<typeof create.start>
  | ReturnType<typeof create.done>
  | ReturnType<typeof create.error>;

export interface ICreateFlagPayload {
  context: string;
  studentId: string;
}

export const fetch = createAction('[pages/admin/dashboard/student/info] fetch');

export const load = {
  start: createAction('[pages/admin/dashboard/student/info/flags] load.start'),
  error: createAction<any>(
    '[pages/admin/dashboard/student/info/flags] load.error'
  ),
  done: createAction<IStudentFlag[]>(
    '[pages/admin/dashboard/student/info/flags] load.done'
  ),
};

export const create = {
  start: createAction<ICreateFlagPayload>(
    '[pages/admin/dashboard/student/info/flags] create.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/student/info/flags] create.error'
  ),
  done: createAction<IStudentFlag>(
    '[pages/admin/dashboard/student/info/flags] create.done'
  ),
};

export const update = {
  start: createAction<number>(
    '[pages/admin/dashboard/student/info/flags] update.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/student/info/flags] update.error'
  ),
  done: createAction<IStudentFlag>(
    '[pages/admin/dashboard/student/info/flags] update.done'
  ),
};

export const destroy = {
  start: createAction<number>(
    '[pages/admin/dashboard/student/info] destroy.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/student/info] destroy.error'
  ),
  done: createAction<IStudentFlag>(
    '[pages/admin/dashboard/student/info] destroy.done'
  ),
};
