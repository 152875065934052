import React from 'react';
import Select from 'react-select';
import * as actions from '../../../actions';
import { colorStyles, controlErrorStyles, controlStyles } from '../../index';
import { shallowEqual, useDispatch } from 'react-redux';
import { Season, UserRoleType } from '../../../../../../constants';
import moment from 'moment/moment';
import { Helpers } from '../../../../../../helpers';
import { useSelector } from '../../../../../../infrastructure/selector';

const getGraduationYearsList = () => {
  let yearsList: number[] = [];
  let currentYear = moment().year();

  for (let i = currentYear; i <= currentYear + 8; i++) {
    yearsList.push(i);
  }

  return yearsList.map((year) => ({
    value: year,
    label: year,
  }));
};

export const IntendedGraduationFields: React.FC = () => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { error, userRoleType } = register;

  const availableGraduationYears = getGraduationYearsList();

  const availableSeasons = [
    {
      value: Season.Fall.toString(),
      label: 'Fall',
    },
    {
      value: Season.Spring.toString(),
      label: 'Spring',
    },
    {
      value: Season.Summer.toString(),
      label: 'Summer',
    },
  ];

  let intendedGraduationSeasonStyles = {
    ...colorStyles,
  };
  let intendedGraduationYearStyles = {
    ...colorStyles,
  };

  let intendedGraduationSeasonHasError = Helpers.hasError(
    error,
    'intended_graduation_season'
  );
  let intendedGraduationYearHasError = Helpers.hasError(
    error,
    'intended_graduation_season'
  );

  if (intendedGraduationSeasonHasError) {
    intendedGraduationSeasonStyles = {
      ...colorStyles,
      control: (styles) => ({
        ...styles,
        ...controlStyles,
        ...controlErrorStyles,
      }),
    };
  }

  if (intendedGraduationYearHasError) {
    intendedGraduationYearStyles = {
      ...colorStyles,
      control: (styles) => ({
        ...styles,
        ...controlStyles,
        ...controlErrorStyles,
      }),
    };
  }

  if (userRoleType == UserRoleType.Administrator) {
    return null;
  }

  return (
    <div className={'flex justify-between mb-4'}>
      <div className={'w-full'}>
        <label
          className={
            'block text-neutral-darkest text-sm mb-1 font-semibold text-blue-darkest'
          }
          htmlFor="password"
        >
          Intended Graduation*
        </label>
        <div className={'w-full flex justify-between'}>
          <div className={'w-full mr-4'}>
            <Select
              styles={intendedGraduationSeasonStyles}
              options={availableSeasons}
              className={'text-sm w-full text-black'}
              name={'intended_graduation_season'}
              placeholder={'select season'}
              id={'intended_graduation_season'}
              onChange={(option: any) =>
                dispatch(
                  actions.onChange({
                    key: 'intendedGraduationSeason',
                    value: option?.value,
                  })
                )
              }
            />
            {intendedGraduationSeasonHasError && (
              <span className={'text-red text-xs'}>
                {error.errors['intended_graduation_season']}
              </span>
            )}
          </div>
          <div className={'w-full ml-4'}>
            <Select
              styles={intendedGraduationYearStyles}
              options={availableGraduationYears}
              className={'text-sm w-full text-black'}
              name={'intended_graduation_year'}
              placeholder={'select year'}
              id={'intended_graduation_year'}
              onChange={(option: any) =>
                dispatch(
                  actions.onChange({
                    key: 'intendedGraduationYear',
                    value: option?.value,
                  })
                )
              }
            />
            {intendedGraduationYearHasError && (
              <span className={'text-red text-xs'}>
                {error.errors['intended_graduation_year']}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
