import { CourseStatus, Season } from '../../constants';
import { BadgeVariants } from '../../ui/badge';

class CourseStatusService {
  constructor() {}

  public getBadgeVariantByCourseStatus(status: CourseStatus) {
    if (status == CourseStatus.Completed) {
      return BadgeVariants.Success;
    }
    if (status == CourseStatus.Planned) {
      return BadgeVariants.Warning;
    }
    if (status == CourseStatus.NotPlanned) {
      return BadgeVariants.Danger;
    }
    if (status == CourseStatus.InProgress) {
      return BadgeVariants.Info;
    }
    if (status == CourseStatus.NotRequired) {
      return BadgeVariants.Neutral;
    }

    return BadgeVariants.Neutral;
  }

  public buildLabel(
    status: CourseStatus,
    season: Season,
    year: number
  ): string {
    let label = '';

    if (
      status == CourseStatus.Planned ||
      status == CourseStatus.Completed ||
      status == CourseStatus.InProgress
    ) {
      label += this.getStatusLabel(status);
      label += ' ' + this.getSeasonLabel(season);
      label = label + ' ' + year.toString();
    }

    if (
      status == CourseStatus.NotRequired ||
      status == CourseStatus.NotPlanned
    ) {
      label += this.getStatusLabel(status);
    }

    return label;
  }

  private getStatusLabel(status: CourseStatus): string {
    if (status == CourseStatus.Completed) {
      return 'Completed';
    }
    if (status == CourseStatus.InProgress) {
      return 'In Progress';
    }
    if (status == CourseStatus.Planned) {
      return 'Planned';
    }
    if (status == CourseStatus.NotPlanned) {
      return 'Not Planned';
    }
    if (status == CourseStatus.NotRequired) {
      return 'Not Required';
    }

    return '';
  }

  private getSeasonLabel(season: Season): string {
    if (season == Season.Fall) {
      return 'Fall';
    }
    if (season == Season.Summer) {
      return 'Sum';
    }
    if (season == Season.Winter) {
      return 'Win';
    }
    if (season == Season.Spring) {
      return 'Spr';
    }

    return '';
  }
}

export default new CourseStatusService();
