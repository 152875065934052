import React from 'react';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';

export const NoDegreesAssigned: React.FC = () => {
  const { isLoading, degrees } = useSelector(
    (state) => ({
      degrees: state.pages.admin.settings.course.details.majors,
      isLoading: state.pages.admin.settings.course.majors.isLoading,
    }),
    shallowEqual
  );

  if (isLoading || degrees.length) {
    return null;
  }

  return (
    <>
      <h3 className={'text-neutral-darkest font-semibold'}>
        This course is currently not assigned to any degrees
      </h3>
      <span className={'text-neutral text-xs mb-4 block'}>
        Assign this course to a degree to be able to specify its requirement for
        that degree
      </span>
    </>
  );
};
