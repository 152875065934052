import { combineReducers } from 'redux';
import { Reducer } from 'redux-act';
import { reducers as common } from '../../common/reducers/index';
import { reducers as pages } from '../../pages/reducer';
import { reducers as shared } from '../../shared/reducer';

export const reducers = {
  shared,
  common,
  pages,
  //@ts-ignore
  // router: null as Reducer<RouterState<any>>
};

// export const createRootReducer = (history: any) => combineReducers({
//     ...reducers,
//     router: connectRouter(history),
// })

export const createRootReducer = () =>
  combineReducers({
    // ...reducers
    // router: connectRouter(history),
  });
