import { Progression } from '../../progression';
import { Terms } from '../../terms';
import React from 'react';

export const ProgressPreview: React.FC = () => {
  return (
    <>
      <Progression />
      <Terms />
    </>
  )
}
