import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  scheduleId: number;
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  scheduleId: 0,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.open, (state, payload) => ({
  ...state,
  isOpen: true,
  scheduleId: payload,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
}));

reducer.on(actions.destroy.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.destroy.done, (state) => ({
  ...state,
  isLoading: false,
  isOpen: false,
}));

reducer.on(actions.destroy.error, (state) => ({
  ...state,
  isLoading: false,
}));

export const deleteTrackModalReducer = reducer;
