import React, { useEffect } from 'react';
import { Modal, ModalPosition, ModalVariant } from '../../../../components/modal';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../infrastructure/selector';
import { Button } from '../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../ui/button';
import { UserProfileAboutYouFields } from '../fields';

export const AboutYouModal: React.FC = () => {

    const dispatch = useDispatch();

    const { isOpen, isLoading, error, dreamVocationAsKid, futureVocation, favoriteThingToDo } = useSelector(
        (state) => ({
            dreamVocationAsKid: state.pages.studentProfile.aboutYou.modal.dreamVocationAsKid,
            futureVocation: state.pages.studentProfile.aboutYou.modal.futureVocation,
            favoriteThingToDo: state.pages.studentProfile.aboutYou.modal.favoriteThingToDo,
            isOpen: state.pages.studentProfile.aboutYou.modal.isOpen,
            error: state.pages.studentProfile.aboutYou.modal.error,
            isLoading:
            state.pages.studentProfile.aboutYou.modal.isLoading,
        }),
        shallowEqual
    );

    useEffect(() => {
        if(isOpen) {
            dispatch(actions.fetch());
        }
    }, [isOpen]);

    return (
        <Modal
            position={ModalPosition.Centered}
            variant={ModalVariant.Centered}
            handleClose={() => dispatch(actions.close())}
            isOpen={isOpen}
            renderBody={() => <></>}
            renderFooter={() => <>
                <div className={"flex items-center justify-end mt-16"}>
                    <Button
                        classes={"mr-2"}
                        size={ButtonSize.Large}
                        variant={ButtonVariant.Primary}
                        style={ButtonStyle.Outline}
                        handleOnClick={() => dispatch(actions.close())}
                    >
                        Cancel
                    </Button>
                    <Button
                      size={ButtonSize.Large}
                      variant={ButtonVariant.Secondary}
                      isLoading={isLoading}
                      handleOnClick={() => dispatch(actions.save.start())}
                    >
                        Save
                    </Button>
                </div>
            </>}
        >
            <div>
                <h2 className={"text-3xl pb-4 text-baby-blue border-b border-b-neutral-light font-semibold"}>Update Your About You</h2>
            </div>

            <UserProfileAboutYouFields
              vocationAsKid={dreamVocationAsKid}
              futureVocation={futureVocation}
              favoriteThingToDo={favoriteThingToDo}
              errors={error}
              onVocationAsKidChange={(value: string) => dispatch(actions.onChange({
                key: "dreamVocationAsKid",
                value
              }))}
              futureVocationChange={(value: string) => dispatch(actions.onChange({
                key: "futureVocation",
                value
              }))}
              favoriteThingToDoChange={(value: string) => dispatch(actions.onChange({
                key: "favoriteThingToDo",
                value
              }))}
              isEditable={true} />
        </Modal>
    )
}
