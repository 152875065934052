import React from "react";
import { IValidationErrorResponse } from '../../../../types/error';
import { RangeSlider } from '../../../../ui/range-slider';
import classNames from 'classnames';

export interface IUserProfileInterestFields {
  isEditable?: boolean;
  isScrollable?: boolean;
  errors?: IValidationErrorResponse;
  onChange?: (payload: {key: string; value: number[]}) => any;
  values: {
    english: number;
    history: number;
    math: number;
    science: number;
    performingArts: number;
    athletics: number;
    studioArts: number;
  }
}

export const UserProfileInterestFields: React.FC<IUserProfileInterestFields> = ({ isScrollable, values, errors, isEditable, onChange }) => {

  const classes = classNames({
    "hide-scrollbar pb-4": isScrollable
  });

  let styles = {};

  if(isScrollable) {
    styles = {
      overflow: "scroll",
      maxHeight: "330px"
    }
  }

  return (
    <>
      <div className={"flex justify-between items-center mt-6"}>
        <span className={"text-neutral-dark font-light text-sm w-1/3 text-left"}>👎 Not for me.</span>
        <span className={"text-neutral-dark font-light text-sm w-1/3 text-center"}>🤷 It's okay.</span>
        <span className={"text-neutral-dark font-light text-sm w-1/3 text-right"}>😍 Love.</span>
      </div>
      <div className={classes} style={styles}>
        <div className={"mt-8"}>
          <span className={"font-semibold text-neutral-darkest text-sm block mb-4"}>English / Reading</span>
          <RangeSlider
            disabled={!isEditable}
            showValue={false}
            min={0}
            max={4}
            defaultValue={[values.english]}
            onChange={(value) => onChange && onChange({
              key: "english",
              value: value
            })}
          />
        </div>
        <div className={"mb-2 mt-14"}>
          <span className={"font-semibold text-neutral-darkest text-sm block mb-4"}>History</span>
          <RangeSlider
            disabled={!isEditable}
            showValue={false}
            min={0}
            max={4}
            defaultValue={[values.history]}
            onChange={(value) => onChange && onChange({
              key: "history",
              value: value
            })}
          />
        </div>
        <div className={"mb-2 mt-14"}>
          <span className={"font-semibold text-neutral-darkest text-sm block mb-4"}>Math</span>
          <RangeSlider
            disabled={!isEditable}
            showValue={false}
            min={0}
            max={4}
            defaultValue={[values.math]}
            onChange={(value) => onChange && onChange({
              key: "math",
              value: value
            })}
          />
        </div>
        <div className={"mb-2 mt-14"}>
          <span className={"font-semibold text-neutral-darkest text-sm block mb-4"}>Science</span>
          <RangeSlider
            disabled={!isEditable}
            showValue={false}
            min={0}
            max={4}
            defaultValue={[values.science]}
            onChange={(value) => onChange && onChange({
              key: "science",
              value: value
            })}
          />
        </div>
        <div className={"mb-2 mt-14"}>
          <span className={"font-semibold text-neutral-darkest text-sm block mb-4"}>Performing Arts</span>
          <RangeSlider
            disabled={!isEditable}
            showValue={false}
            min={0}
            max={4}
            defaultValue={[values.performingArts]}
            onChange={(value) => onChange && onChange({
              key: "performingArts",
              value: value
            })}
          />
        </div>
        <div className={"mb-2 mt-14"}>
          <span className={"font-semibold text-neutral-darkest text-sm block mb-4"}>Athletics</span>
          <RangeSlider
            disabled={!isEditable}
            showValue={false}
            min={0}
            max={4}
            defaultValue={[values.athletics]}
            onChange={(value) => onChange && onChange({
              key: "athletics",
              value: value
            })}
          />
        </div>
        <div className={"mb-2 mt-14"}>
          <span className={"font-semibold text-neutral-darkest text-sm block mb-4"}>Studio Arts</span>
          <RangeSlider
            disabled={!isEditable}
            showValue={false}
            min={0}
            max={4}
            defaultValue={[values.studioArts]}
            onChange={(value) => onChange && onChange({
              key: "studioArts",
              value: value
            })}
          />
        </div>
      </div>
    </>
  )
}

UserProfileInterestFields.defaultProps = {
  isEditable: false,
  isScrollable: false
}
