import { combineEpics } from 'redux-observable';
import { epic as configs } from '../configs/epic';
import { epic as user } from '../user/epic';
import { epic as match } from '../match/epic';
import { epic as drawers } from '../drawers/epic';
import { epic as filters } from '../filters/epic';
import { epic as alerts } from '../alerts/epic';
import { epic as modals } from "../modals/epic";

export default combineEpics(user, match, configs, filters, alerts, drawers, modals);
