import { combineEpics, ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import * as actions from './actions';
import * as courseOccupancyActions from "../course-occupanies/actions";
import { IEpic } from '../../../../../infrastructure/selector';

const search: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onChange),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => courseOccupancyActions.fetch())
  );

export const epic = combineEpics(search);
