import { createAction } from 'redux-act';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[common/drawers/messages/panels/create/track] fetch'
);

export const load = {
  start: createAction(
    '[common/drawers/messages/panels/create/track] load.start'
  ),
  error: createAction<any>(
    '[common/drawers/messages/panels/create/track] load.error'
  ),
  done: createAction<any>(
    '[common/drawers/messages/panels/create/track] load.done'
  ),
};

export const select = createAction<number>(
  '[common/drawers/messages/panels/create/track] select'
);
