import React from 'react';
import { SearchInput } from '../../../../../components/search-input';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from "./actions";
import { debounce } from 'lodash';
import { useSelector } from '../../../../../infrastructure/selector';

export const SearchCourses: React.FC = () => {

  const { keyword } = useSelector(
    (state) => ({
      keyword: state.pages.admin.settings.courses.search.keyword,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleOnChange = (e: any) => {
    dispatch(actions.onChange(e.target.value))
  }

  return (
    <>
      <SearchInput name='search' handleOnChange={debounce(handleOnChange, 250)} placeholder='Search courses by name'  />
    </>
  )
}
