import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../types/error';

interface IState {
  showEmailVerificationMessage: boolean;
  isLoading: boolean;
  name: string;
  studentId: string;
  phoneNumber: string;
  phone: string;
  avatarUrl: string;
  pronouns: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  schoolId: number | null;
  startYear: string | null;
  startSeason: string | null;
  marketingEmailConsent: boolean;
  marketingSMSConsent: boolean;
  legalConsent: boolean;
  userRoleType: number | null;
  intendedGraduationYear: number | null;
  intendedGraduationSeason: number | null;
  majorId: number | null;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  showEmailVerificationMessage: false,
  isLoading: false,
  avatarUrl: '',
  name: '',
  phoneNumber: '',
  studentId: '',
  phone: '',
  pronouns: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  schoolId: null,
  startYear: null,
  startSeason: null,
  marketingEmailConsent: true,
  marketingSMSConsent: true,
  legalConsent: true,
  userRoleType: null,
  intendedGraduationSeason: null,
  intendedGraduationYear: null,
  majorId: null,
  error: {
    message: '',
    errors: {},
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.fetch, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.register.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.register.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.register.done, (state, payload) => ({
  ...state,
  showEmailVerificationMessage: true,
  isLoading: false,
}));

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  //@ts-ignore
  [payload.key]: payload.value,
  error: {
    message: '',
    errors: {},
  },
}));

export const registerReducer = reducer;
