import { createAction } from 'redux-act';
import {
  IDegree,
  IDegreeRequirement,
  IDegreeRequirementsResponse,
} from '../../../../../api/admin/degrees';
import { ISelectOption } from '../../../../../ui/select';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>
  | ReturnType<typeof loadRequirements.start>
  | ReturnType<typeof loadRequirements.done>
  | ReturnType<typeof loadRequirements.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/course/majors] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/majors] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/majors] load.error'
  ),
  done: createAction<IDegree[]>(
    '[pages/admin/dashboard/settings/course/majors] load.done'
  ),
};

export const fetchRequirements = createAction(
  '[pages/admin/dashboard/settings/course/majors] fetchRequirements'
);

export const loadRequirements = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/majors] loadRequirements.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/majors] loadRequirements.error'
  ),
  done: createAction<IDegreeRequirementsResponse>(
    '[pages/admin/dashboard/settings/course/majors] loadRequirements.done'
  ),
};

export const onDegreeSelect = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/course/majors] onDegreeSelect'
);

export const onRequirementSelect = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/course/majors] onRequirementSelect'
);

export const assignCourseToDegree = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/majors] assignCourseToDegree.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/majors] assignCourseToDegree.error'
  ),
  done: createAction<IDegreeRequirement>(
    '[pages/admin/dashboard/settings/course/majors] assignCourseToDegree.done'
  ),
};

export const removeCourseFromDegree = {
  start: createAction<number>(
    '[pages/admin/dashboard/settings/course/majors] removeCourseFromDegree.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/majors] removeCourseFromDegree.error'
  ),
  done: createAction<IDegreeRequirement>(
    '[pages/admin/dashboard/settings/course/majors] removeCourseFromDegree.done'
  ),
};
