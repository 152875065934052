import React, { useEffect } from 'react';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import * as studentDetailDrawerActions from '../../drawers/student-detail/actions';
import { ProgressionList } from '../../../../../components/progression-list';

export const StudentOccupants: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, list, selectedOccupancyId } = useSelector(
    (state) => ({
      list: state.pages.admin.dashboard.course.occupants.list,
      isLoading: state.pages.admin.dashboard.course.occupants.isLoading,
      selectedOccupancyId:
        state.pages.admin.dashboard.course.occupancies.selectedOccupancyId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [selectedOccupancyId]);

  return (
    <section className={'mt-8'}>
      <h2 className={'text-2xl text-neutral-darkest'}>Students in Plan</h2>
      <span className={'text-xs text-neutral-light'}>
        Based on completed and planned courses
      </span>
      <ProgressionList
        onClickAction={studentDetailDrawerActions.open}
        list={list}
        isLoading={isLoading}
      />
    </section>
  );
};
