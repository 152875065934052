import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as studentFilterActions from '../../../../common/filters/students/actions';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';

const fetchOnFilterUpdate: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(studentFilterActions.apply),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => actions.fetch())
  );

const fetchOnPagination: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.paginate),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(() => !!state$.value.common.user.info.schoolId),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { keyword } =
        state$.value.pages.admin.dashboard.students.studentStatusData.search;
      const { filters } = state$.value.pages.admin.common.filters.students;

      const { perPage, currentPage } =
        state$.value.pages.admin.dashboard.students.studentStatusData.students;

      const { schoolId } = state$.value.common.user.info;

      return from(
        api.students.getStudentProgressions({
          schoolId,
          search: keyword,
          limit: perPage,
          page: currentPage,
          filters: {
            cohort: filters.cohort,
            grade: filters.grade,
            flags: filters.onlyFlaggedStudents,
            percentCompletedMin: filters.percentCompleted.min,
            percentCompletedMax: filters.percentCompleted.max,
            percentPlannedMin: filters.percentPlanned.min,
            percentPlannedMax: filters.percentPlanned.max,
            percentInProgressMin: filters.percentInProgress.min,
            percentInProgressMax: filters.percentInProgress.max,
          },
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(
  fetch,
  load,
  fetchOnFilterUpdate,
  fetchOnPagination
);
