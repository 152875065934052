import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import * as messageDrawerActions from "../../../../../common/drawers/messages/actions";

const fetchOnOpen: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.open),
    map(() => actions.fetch())
  );

const onMessageDrawerOpen: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.openMessageDrawerWithRecipient),
    switchMap(({ payload }) => of(actions.close(), messageDrawerActions.openWithRecipient(payload)))
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(() => state$.value.pages.admin.dashboard.drawers.studentDetail.selectedId !== null),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(({ payload }) => {
      return from(
        api.students.getStudentDetail(
          state$.value.pages.admin.dashboard.drawers.studentDetail.selectedId
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetchOnOpen, fetch, load, onMessageDrawerOpen);
