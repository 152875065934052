import { createAction } from 'redux-act';
import { IStudentListFilters } from './reducer';
import {IFilter} from "../../../../../types/filter";

export type IActions = ReturnType<typeof apply>;

export const apply = createAction<IStudentListFilters>(
  '[pages/admin/common/filters/students] apply'
);

export const fetch = createAction(
  '[pages/admin/common/filters/students] fetch'
);

export const load = {
  start: createAction('[pages/admin/common/filters/students] load.start'),
  error: createAction<any>('[pages/admin/common/filters/students] load.error'),
  done: createAction<any>('[pages/admin/common/filters/students] load.done'),
};

export const handleFilter = createAction<{ key: string; filter: IFilter }>(
  '[pages/admin/common/filters/students] handleFilter'
);
