// ****** THEMEABLE ***** //
// Primary (1)
// Secondary (2)
// Tertiary (3)
// Alternative (4)

// *** NOT THEMEABLE **
// Greys
// neutral100
// neutral200
// neutral300
// neutral400
// neutral500
// neutral600
// neutral700
// neutral800
// neutral900

// White
// Black
// Success Greens
// Info Blues
// Danger Reds
// Warning Yellows

export default {
  blueDarkest: '#0F2D4D',
  blue: '#144FC2',
  green: '#069697',
  greenLighter: '#9BD5D5',
  greenLightest: '#EBF7F7',
  'purple-light': '#E5E7F4',
  purple: '#782BD9',
  babyBlueLightest: '#E4F0FD',
  babyBlueLighter: '#7EB6F3',
  babyBlue: '#2563EB',
  red: '#FE2B52',
  redLighter: '#FECACA',
  redLightest: '#FFE6EE',
  'neutral-lightest': '#F8F8F8',
  'neutral-lighter': '#F1F1F1',
  'neutral-light': '#CFCFCF',
  neutral: '#8F8F8F',
  'neutral-dark': '#676767',
  'neutral-darker': '#F1F2F4',
  'neutral-darkest': '#040404',
  yellowDark: '#CA8A04',
  yellow: '#FFBF23',
  yellowLighter: '#FEF08A',
  yellowLightest: '#FFF6DC',
  primary: '#F05F26',
  secondary: '#0F2D4D',
  tertiary: '#DC2626',
};
