interface IAuthEventParams {
  userId: number;
  roleId: number;
}

export interface ILoggedInEvent extends IAuthEventParams {}
export interface IRegisteredEvent extends IAuthEventParams {}

interface IAuthAnalyticsDataLayer {
  event: string;
  parameters: IAuthEventParams;
}

export class AuthAnalytics {
  public addLoginEvent(payload: ILoggedInEvent): void {
    this.pushToDataLayer({
      event: 'login',
      parameters: {
        roleId: payload.roleId,
        userId: payload.userId,
      },
    });
  }

  public addRegisteredEvent(payload: IRegisteredEvent): void {
    this.pushToDataLayer({
      event: 'registered',
      parameters: {
        userId: payload.userId,
        roleId: payload.roleId,
      },
    });
  }

  private pushToDataLayer(data: IAuthAnalyticsDataLayer) {

    if(!window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: data.event,
      parameters: {
        ...data.parameters,
      },
    });
  }
}
