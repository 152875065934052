import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ISchoolConfiguration } from '../../../../../api/schools';
import { ISelectOption } from '../../../../../ui/select';

// TODO: DO NOT USE THIS REDUCER
interface IState {
  school: ISchoolConfiguration;
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
  school: {
    availableSeasons: [],
    id: 0,
    schoolId: 0,
    displayTermCostOnSchedule: false,
    gradeLevels: [],
    availableRequirementUnitTypes: [],
    studentInviteTemplateUrl: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    school: {
      ...payload,
    },
  };
});

export const configReducer = reducer;
