import { AxiosPromise } from 'axios';
import client from '../axios.client';

export enum ScheduleNotificationType {
  Course,
  Term,
}

export interface IScheduleNotification {
  course_id: number;
  message: string;
  term_id: number;
  type: ScheduleNotificationType;
}

export interface IScheduleNotifications {
  getScheduleNotifications(
    scheduleId: number | string
  ): AxiosPromise<IScheduleNotification[]>;
}

class ScheduleNotificationsAPI implements IScheduleNotifications {
  getScheduleNotifications(
    scheduleId: number | string
  ): AxiosPromise<IScheduleNotification[]> {
    return client.get(`/api/schedules/${scheduleId}/notifications`);
  }
}

export const scheduleNotifications = new ScheduleNotificationsAPI();
