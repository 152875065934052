import React, { useEffect, useState } from 'react';
import { DialogBox } from '../../../../../../ui/dialog-box';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { BiNote } from '@react-icons/all-files/bi/BiNote';
import { Note } from './note';
import * as actions from './actions';

export const Notes: React.FC = () => {
  const dispatch = useDispatch();
  const { notes, error, isLoading } = useSelector(
    (state) => ({
      notes: state.pages.admin.dashboard.student.info.notes.list,
      isLoading: state.pages.admin.dashboard.student.info.notes.isLoading,
      error: state.pages.admin.dashboard.student.info.notes.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <DialogBox
        useDynamicPositioning={true}
        dialogClasses="w-80 z-10 bg-neutral-lightest shadow-lg rounded-lg p-2"
        renderTarget={({ handleToggle, isOpen }) => (
          <div
            onClick={() => handleToggle()}
            className={classNames(
              'mr-2 bg-neutral-lighter group cursor-pointer border rounded-full h-8 w-8 flex items-center justify-center hover:bg-baby-blue hover:border-baby-blue',
              { 'bg-baby-blue border-baby-blue': isOpen }
            )}
          >
            <BiNote
              className={classNames(
                'fill-neutral-darkest text-md group-hover:fill-white',
                { 'fill-white': isOpen }
              )}
            />
          </div>
        )}
      >
        {({ handleToggle }) => {
          return (
            <>
              <Note
                isLoading={isLoading}
                error={error}
                noteId={notes.length ? notes[0]?.id : null}
                content={notes[0]?.content ?? ''}
                handleCancel={() => handleToggle()}
              />
            </>
          );
        }}
      </DialogBox>
    </>
  );
};
