import { combineEpics, ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../infrastructure/selector';
import * as userActions from '../../../common/user/actions';
import { UserRoleType } from '../../../constants';
import { Routes } from '../../../common/routes';
import GoogleAnalytics from '../../../analytics';
import { Helpers } from '../../../helpers';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    switchMap(() => {
      return from(api.auth.setSession()).pipe(
        mergeMap(() => {
          return of(actions.login.start());
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.login.error(error.response.data)).pipe(concat(source$))
    )
  );

const login: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.login.start),
    switchMap(() => {
      const { email, password } = state$.value.pages.auth.login;

      return from(api.auth.login(email, password)).pipe(
        switchMap(({ data }) => {
          return from(api.user.getUser()).pipe(
            mergeMap(({ data }) => {
              GoogleAnalytics.User.addLoginEvent({
                userId: data.id,
                roleId: data.roleId,
              });

              Helpers.redirectToDashboard(data);

              return of(actions.login.done(data), userActions.load.done(data));
            })
          );
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.login.error(error?.response?.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(login, fetch);
