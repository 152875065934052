import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IMajor, IMinor } from '../../../../api/majors';
import { IValidationErrorResponse } from '../../../../types/error';
import { Season } from '../../../../constants';
import { termSeasonService } from '../../../../services/term-season/TermSeasonService';
import { IAvailableSeason } from '../../../../api/schools';

export interface ISeasonSelect {
  value: number;
  label: string;
  display: boolean;
  checked: boolean;
}

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  scheduleId: number;
  name: string;
  majors: IMajor[];
  minors: IMinor[];
  error: IValidationErrorResponse;
  seasons: ISeasonSelect[];
  availableSeasons: ISeasonSelect[];
  form: {
    name: string;
    majors: number[];
    minors: number[];
    seasons: ISeasonSelect[];
  };
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  scheduleId: 0,
  name: '',
  majors: [],
  minors: [],
  availableSeasons: [],
  error: {
    message: '',
    errors: {},
  },
  form: {
    name: '',
    majors: [],
    minors: [],
    seasons: [],
  },
  seasons: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.open, (state, payload) => ({
  ...state,
  isOpen: true,
  scheduleId: payload,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
}));

reducer.on(actions.update.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.update.done, (state) => ({
  ...state,
  isLoading: false,
  isOpen: false,
}));

reducer.on(actions.update.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  name: payload,
}));

reducer.on(actions.loadConfiguration.done, (state, payload) => {
  const selectedSeasonIds = state.seasons.map(
    (season: ISeasonSelect) => season.value
  );
  const availableSeasons = payload.availableSeasons.map(
    (s: IAvailableSeason) => ({
      checked: false,
      label: s.label,
      value: s.value,
      display: true,
    })
  );
  const nonSelectedAvailableSeasons = availableSeasons.filter(
    (season: ISeasonSelect) => !selectedSeasonIds.includes(season.value)
  );

  const seasons = [...state.seasons, ...nonSelectedAvailableSeasons];

  return {
    ...state,
    seasons,
    availableSeasons,
  };
});

reducer.on(actions.season.set, (state, payload) => ({
  ...state,
  seasons: [...payload],
}));

reducer.on(actions.set.done, (state, payload) => ({
  ...state,
  name: payload.name,
  majors: payload.majors,
  minors: payload.minors,
  seasons: payload.seasons.map((season: number) => ({
    value: season,
    label: termSeasonService.getSeasonAsString(season),
    checked: true,
    display: true,
  })),
}));

export const editTrackModalReducer = reducer;
