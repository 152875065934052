import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import { api } from '../../../../../api';

const fetchOnSearch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onSearch),
    map(() => actions.fetch())
  );

const fetchOnMarkAsRead: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.markAsRead.done),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(
      () =>
        !!state$.value.common.user.info.schoolId &&
        !!state$.value.common.drawers.messages.state.isOpen
    ),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { search } = state$.value.common.drawers.messages.panels.threads;

      return from(api.messages.getAllMessageThreads(search)).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

const markAsRead: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.markAsRead.start),
    switchMap(({ payload }) => {
      return from(api.messages.markMessageThreadAsRead(payload)).pipe(
        mergeMap(({ data }) => {
          return of(actions.markAsRead.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.markAsRead.error(error)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(fetch, load, markAsRead, fetchOnMarkAsRead, fetchOnSearch);
