import React from 'react';

export const CourseDegreesTitle: React.FC = () => {
  return (
    <>
      <h2 className={'text-neutral-darkest font-semibold'}>Degrees</h2>
      <span className={'block mb-2 text-neutral text-xxs border-b pb-2'}>
        Degrees this course is currently assigned to
      </span>
    </>
  );
};
