import React from 'react';
import { Course } from './course';
import { ICourse } from '../../../../types/course';

interface ICoursesProps {
  groupIndex: number;
  termIndex: number;
  list: ICourse[];
  termId: number;
  isCourseBin?: boolean;
  isDraggable: boolean;
}

export const CourseList: React.FC<ICoursesProps> = ({
  groupIndex,
  termIndex,
  list,
  termId,
  isCourseBin,
  isDraggable,
}) => {
  if (!list.length) return null;

  return (
    <React.Fragment>
      <div
        className="hide-scrollbar overflow-y-scroll"
        style={{ maxHeight: isCourseBin ? '425px' : '400px' }}
      >
        {list.map((course, index) => (
          <Course
            isDraggable={isDraggable}
            termIndex={termIndex}
            groupIndex={groupIndex}
            termId={termId}
            key={course.id.toString()}
            course={course}
            index={index}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

CourseList.defaultProps = {
  isCourseBin: false,
};
