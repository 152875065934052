import { combineEpics, ofType } from 'redux-observable';
import {catchError, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import {IEpic} from "../../../../../infrastructure/selector";

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(() => !!state$.value.common.user.info.id),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {

        const userId = state$.value.common.user.info.id;

        return from(api.userProfile.getAdminAccountSettings(userId)).pipe(
          mergeMap(({ data }) => {
            return of(actions.load.done(data));
          })
        )
      }

    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const save: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.start),
    switchMap(() => {

        const userId = state$.value.common.user.info.id;

        const { legalName, preferredName, email, role  } = state$.value.pages.profile.admin.accountSettings.modal;

        return from(api.userProfile.updateAdminAccountSettings(userId, {
          legalName,
          preferredName,
          email,
          role
        })).pipe(
          mergeMap(({ data }) => {
            return of(actions.save.done(data));
          })
        )
      }

    ),
    catchError((error, source$) =>
      of(actions.save.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, save);
