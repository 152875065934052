import { combineReducers } from 'redux';

import { reducers as aboutYou } from './about-you/reducer';
import { reducers as interests } from './interests/reducer';
import { reducers as accountSettings } from './account-settings/reducer';

export const studentProfileReducer = combineReducers({
    aboutYou,
    interests,
    accountSettings
});
