import React, { useState } from 'react';

export interface IAccordionRenderTargetProps {
  handleTrigger: () => any;
  isOpen: boolean;
}

export interface IAccordionRenderBodyProps {
  handleTrigger: () => any;
}

export interface IAccordionProps {
  defaultState?: boolean;
  renderTarget: React.FC<IAccordionRenderTargetProps>;
  renderBody: React.FC<IAccordionRenderBodyProps>;
}

export const Accordion: React.FC<IAccordionProps> = ({
  defaultState,
  renderTarget: Target,
  renderBody: Body,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!defaultState);

  return (
    <>
      <Target isOpen={isOpen} handleTrigger={() => setIsOpen(!isOpen)} />
      {isOpen && <Body handleTrigger={() => setIsOpen(!isOpen)} />}
    </>
  );
};

Accordion.defaultProps = {
  defaultState: false,
};
