import { AxiosPromise } from 'axios';
import client from '../axios.client';

export enum RequirementUnitType {
  Courses,
  Credits,
}

export interface IRequirementType {
  id: number;
  name: string;
  unit: RequirementUnitType;
}

export interface IRequirementTypesAPI {
  getAllRequirementTypes(): AxiosPromise<IRequirementType[]>;
}

class RequirementTypes implements IRequirementTypesAPI {
  getAllRequirementTypes(): AxiosPromise<IRequirementType[]> {
    return client.get('/api/admin/requirement-types');
  }
}

export const requirementTypes = new RequirementTypes();
