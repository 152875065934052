import axios from 'axios';
import {Environment, getEnvironment} from "../utilities/environment";

const getBaseUrl = ():string => {

    let host = window.location.host;

    let environment = getEnvironment(host);

    if(environment === Environment.Demo)
    {
        return "https://demo.api.degreely.com";
    }

    if(environment === Environment.Staging)
    {
        return "https://staging.api.degreely.com";
    }

    if(environment === Environment.Production)
    {
        return "https://api.degreely.com";
    }

    return "http://localhost:8000"
}

const client = axios.create({
  withCredentials: true,
  baseURL: getBaseUrl(),
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      if (
        window.location.pathname != '/login' &&
        window.location.pathname != '/register'
      ) {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default client;
