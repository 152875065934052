import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as registerActions from '../../../pages/auth/register/actions';
import { api } from '../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../infrastructure/selector';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      // TODO: This can be shared with theme detection
      let domain = 'app';

      let location = window.location;

      let host = location.host;

      let splitHost = host.split('.');

      if (splitHost[0].includes('localhost')) {
        return of(actions.load.done(domain));
      }

      domain = splitHost[0];

      return of(actions.load.done(domain));
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load);
