import React from 'react';
import { CourseRequirements } from '../DragAndDropSchedule/term/courses/course/requirements';

export interface ICourseCardProps {
  name: string;
  code: string;
  courseId: number;
  termId?: number;
  credits: number;
  borderColor: string;
}

export const CourseCard: React.FC<ICourseCardProps> = ({
  name,
  code,
  courseId,
  termId,
  credits,
  borderColor,
}) => {
  return (
    // TODO: Use a card component for this wrapper
    <section
      className={'bg-white shadow-lg border-l-6 rounded-md py-2 px-2 mb-4'}
      style={{ borderColor }}
    >
      <div>
        <h6 className={'text-neutral-darkest font-normal text-md'}>{name}</h6>
        <span className="className text-neutral font-light text-xxs -mb-2">
          {code}
        </span>
      </div>
      <div>
        {termId && <CourseRequirements courseId={courseId} termId={termId} />}
        <span
          className="creditNum flex items-center text-xs text-black uppercase font-medium tracking-widest block mt-2"
          style={{ fontSize: 9 }}
        >
          {credits} units
        </span>
      </div>
    </section>
  );
};

CourseCard.defaultProps = {};
