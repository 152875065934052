import React from 'react';
import { IMajor } from '../../../../../../api/majors';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import * as actions from '../actions';
import { Checkbox } from '../../../../../../ui';
import { CheckBoxSize } from '../../../../../../ui/checkbox';
import { RequirementCategories } from '../requirement-categories';

export const AssignedDegreesList: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, majors } = useSelector(
    (state) => ({
      majors: state.pages.admin.settings.course.details.majors,
      isLoading: state.pages.admin.settings.course.majors.isLoading,
    }),
    shallowEqual
  );

  if (!majors.length || isLoading) {
    return null;
  }

  return (
    <>
      {majors.map((major: IMajor) => (
        <div className={'border-b mb-2 flex justify-between items-center'}>
          <div>
            <h5 className={'text-xs text-neutral-darkest font-semibold'}>
              Name: {major.name}
            </h5>
            <span className={'text-xs text-neutral font-medium'}>
              {major.abbreviatedName}
            </span>
          </div>
          <div
            className={'flex items-center'}
            onClick={() =>
              dispatch(actions.removeCourseFromDegree.start(major.id))
            }
          >
            <BiTrash className={'fill-baby-blue cursor-pointer'} />
          </div>
        </div>
      ))}
      <RequirementCategories />
    </>
  );
};
