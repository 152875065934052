import React from "react";
import {DegreeProgress} from "../progress";
import {CurrentTrack} from "../current-track";
import {CourseProgress} from "../course-progress";

export const DashboardTracks: React.FC = () => (
    <>
        <DegreeProgress/>
        <CourseProgress />
        <CurrentTrack/>
    </>
)
