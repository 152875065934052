import { createAction } from 'redux-act';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const open = createAction<number>(
  '[pages/dashboard/modals/delete-track] open'
);
export const close = createAction(
  '[pages/dashboard/modals/delete-track] close'
);

export const destroy = {
  start: createAction<number>(
    '[pages/dashboard/modals/delete-track] destroy.start'
  ),
  error: createAction<{ message: string }>(
    '[pages/dashboard/modals/delete-track] destroy.error'
  ),
  done: createAction('[pages/dashboard/modals/delete-track] destroy.done'),
};
