import { AxiosPromise } from 'axios';
import { ICourse } from '../../../types/course';
import client from '../../axios.client';
import { IMajor } from '../../majors';
import { IDegree } from '../degrees';
import { IPaginatedRequestParams, IPaginatedResponse } from '../../common';

export interface ICourseResponse {
  course: ICourse;
  majors: IMajor[];
}

export interface IAvailableCoursesResponse {
  list: ICourse[];
}

export interface IUpdateCoursePayload {
  id: number;
  name: string;
  code: string;
  credits: number;
  termsOffered: number[];
  difficulty: number;
  customDetailsEnabled: boolean;
  prerequisites: number[];
  concurrency: number[];
  gradeLevel: number[] | null;
  isRepeatable: boolean;
}

export interface ICreateCoursePayload {
  schoolId: number;
  name: string;
  code: string;
  content: string;
  category: string;
  credits: number;
  requiredTerm: number;
  termsOffered: number[];
  difficulty: number;
  isLocked: boolean;
  type: number;
  customDetailsEnabled: boolean;
  prerequisites: number[];
  concurrency: number[];
  gradeLevel: number[] | null;
  isRepeatable: boolean;
}

export interface ICoursesRequestParams extends IPaginatedRequestParams {
  name?: string;
  grades?: number[];
  requirementCategories?: number[];
  availableTerms?: number[];
  availableYears?: number[];
  sort?: string;
  sortDirection?: string;
  limit?: number;
}

export interface IAdminCourses {
  getCourses(params: ICoursesRequestParams): AxiosPromise<IPaginatedResponse<ICourse>>;
  getCourseById(courseId: number): AxiosPromise<ICourseResponse>;
  getAvailablePrerequisiteCourses(
    courseId: number
  ): AxiosPromise<IAvailableCoursesResponse>;
  getAvailableConcurrentCourses(
    courseId: number
  ): AxiosPromise<IAvailableCoursesResponse>;
  updateCourse(data: IUpdateCoursePayload): AxiosPromise<ICourseResponse>;
  getAvailableDegreesByCourseId(courseId: number): AxiosPromise<IDegree[]>;
  createCourse(payload: ICreateCoursePayload): AxiosPromise<ICourseResponse>;
  deleteCourse(courseId: number): AxiosPromise<boolean>;
  syncCourseDegreeRequirementCategories(
    courseId: number,
    requirementCategoryIds: number[]
  ): AxiosPromise<any>;
}

class AdminCoursesAPI implements IAdminCourses {
  public getCourses(params: ICoursesRequestParams): AxiosPromise<IPaginatedResponse<ICourse>> {

    let defaultParams: ICoursesRequestParams = {
      name: "",
      grades: [],
      requirementCategories: [],
      availableTerms: [],
      availableYears: [],
      sort: "",
      sortDirection: "asc",
    }

    return client.get(`/api/admin/courses`, {
      params: {
        ...defaultParams,
        ...params,
      }
    });
  }

  public updateCourse(
    data: IUpdateCoursePayload
  ): AxiosPromise<ICourseResponse> {
    return client.put(`/api/admin/courses/${data.id}`, {
      ...data,
      content: data.code,
      category: 'category',
      type: 0,
      isLocked: false,
      requiredTerm: -1,
    });
  }

  getCourseById(courseId: number): AxiosPromise<ICourseResponse> {
    return client.get(`/api/admin/courses/${courseId}`);
  }

  getAvailablePrerequisiteCourses(
    courseId: number
  ): AxiosPromise<IAvailableCoursesResponse> {
    return client.get(
      `/api/admin/courses/${courseId}/available-prerequisite-courses`
    );
  }

  getAvailableConcurrentCourses(
    courseId: number
  ): AxiosPromise<IAvailableCoursesResponse> {
    return client.get(
      `/api/admin/courses/${courseId}/available-concurrent-courses`
    );
  }

  getAvailableDegreesByCourseId(courseId: number): AxiosPromise<IDegree[]> {
    return client.get(`/api/admin/courses/${courseId}/degrees`);
  }

  syncCourseDegreeRequirementCategories(
    courseId: number,
    requirementCategoryIds: number[]
  ): AxiosPromise<any> {
    return client.put(`/api/admin/courses/${courseId}/requirement-categories`, {
      requirementCategoryIds: [...requirementCategoryIds],
    });
  }

  createCourse(payload: ICreateCoursePayload): AxiosPromise<ICourseResponse> {
    return client.post('/api/admin/courses', payload);
  }

  deleteCourse(courseId: number): AxiosPromise<boolean> {
    return client.delete(`/api/admin/courses/${courseId}`);
  }
}

export const courses = new AdminCoursesAPI();
