import React from 'react';
import { AuthHeader } from '../../../../shared/header';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';

export const ResetPasswordSuccessScreen: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className={'flex items-center justify-center mb-8'}>
        <img src="/assets/stamp-icon.svg" alt="check stamp icon" />
      </div>
      <AuthHeader title={"You're all set"} />
      <div className={'mt-20'}>
        <p className={'text-black text-xs text-center'}>
          Your password has been reset. You can now{' '}
          <a
            className={'text-blue'}
            href={'#'}
            onClick={() => dispatch(actions.close())}
          >
            login
          </a>{' '}
          with your new password
        </p>
      </div>
    </div>
  );
};
