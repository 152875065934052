import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { api } from '../../../../../../api';
import { IEpic } from '../../../../../../infrastructure/selector';
import { IMessageThread } from '../../../../../../api/messages';

const send: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.send.start),
    switchMap(() => {
      const { message, recipient, track } =
        state$.value.common.drawers.messages.panels.create;

      const threads = state$.value.common.drawers.messages.panels.threads.list;

      const currentThread = threads.find((thread: IMessageThread) => thread.recipient.id === recipient.selected?.id);

      if(!!currentThread) {
        return from(
          api.messages.sendMessage(currentThread.id, message.body)
        ).pipe(
          mergeMap(({ data }) => {
            return of(actions.send.done(data));
          })
        );
      }

      return from(
        api.messages.createMessageThread({
          scheduleId: track.selected,
          recipientId: recipient.selected?.id,
          body: message.body,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.send.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.send.error(error.response.data)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(send);
