import React, { useEffect } from 'react';
import { Layout } from '../../../shared/layout';
import { shallowEqual, useDispatch } from 'react-redux';
// import {UserProfileAvatar} from "./avatar";
// import {UserProfileName} from "./name";
// import {UserProfileAccountSettings} from "./account-settings";
// import * as actions from "./actions";
import { useSelector } from '../../../infrastructure/selector';
import { UserProfileName } from '../shared/name';
import { UserProfileAvatar } from '../shared/avatar';
import { AdminAccountSettings } from './account-settings';
import { AdminAccountSettingsModal } from './account-settings/modal';
export const AdminProfile: React.FC = () => {

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info
    }),
    shallowEqual
  );

  return (
    <>
      <Layout>
        <section className={'py-8 px-16 relative '}>
          <div className={'bg-white rounded-sm p-10'}>
            <h3 className={'text-2xl text-neutral-darkest font-semibold mb-3'}>
              Settings
            </h3>
            <hr />
            <UserProfileAvatar />
            <UserProfileName />
            <div className={"w-2/3 mt-8 mx-auto"}>
              <AdminAccountSettings />
              <AdminAccountSettingsModal />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
