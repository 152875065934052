import { combineReducers } from 'redux';

import { recipientReducer as recipient } from './recipient/reducer';
import { trackReducer as track } from './track/reducer';
import { messageReducer as message } from './message/reducer';

export const createPanelReducer = combineReducers({
  recipient,
  track,
  message,
});
