import React from 'react';
import { Input } from '../../../../../../components/input';
import * as actions from '../../../actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import { UserRoleType } from '../../../../../../constants';

export const StudentIdField: React.FC = () => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { studentId, phone, userRoleType, error } = register;

  if (!userRoleType || userRoleType == UserRoleType.Administrator) {
    return null;
  }

  return (
    <div className={'mb-4'}>
      <Input
        placeholder={'enter your student id'}
        required={true}
        type={'text'}
        label={'Student ID'}
        name={'student_id'}
        value={studentId}
        error={error}
        onChange={(e) =>
          dispatch(
            actions.onChange({
              key: 'studentId',
              value: e.target.value,
            })
          )
        }
      />
    </div>
  );
};
