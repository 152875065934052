import { createReducer } from 'redux-act';
import * as actions from './actions';
import {IIntegration} from "../../../api/integrations";

interface IState {
    blackbaud: IIntegration;
}

const initialState: IState = {
    blackbaud: {
        name: "",
        key: "",
        logoUrl: "",
        authorizationUrl: "",
    }
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
    ...state,
    isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
    ...state,
    isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
    return {
        ...state,
        blackbaud: payload[0],
    };
});

export const integrationsReducer = reducer;
