import React from 'react';
import { Term } from '../../term';
import { TermType } from '../../../../constants';
import * as deleteTermModalActions from '../../../../pages/dashboard/modals/delete-term/actions';
import { useDispatch } from 'react-redux';
import { IScheduleTerm } from '../../../../api/schedule-terms';

interface IGroupProps {
    groupIndex: number;
    termIndex: number;
    isLastInGroup: boolean;
    term: IScheduleTerm;
    isDraggable: boolean;
    displayTermCost: boolean;
    handleOnRemoveTerm: (termId: number) => any;
}

export const Group: React.FC<IGroupProps> = ({ groupIndex, termIndex, isLastInGroup, term, isDraggable, displayTermCost, handleOnRemoveTerm }) => {
    return (
            <Term
                groupIndex={groupIndex}
                displayTermCost={displayTermCost}
                isDraggable={isDraggable}
                isLastInGroup={isLastInGroup}
                key={termIndex}
                termIndex={termIndex}
                type={TermType.Term}
                term={term}
                onDeleteTerm={handleOnRemoveTerm}
            />
    )
}
