import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as trackActions from '../../../pages/dashboard/current-track/actions';
import { IEpic } from '../../../infrastructure/selector';
import { ISchedule } from '../../../api/schedules';
import { termSeasonService } from '../../../services/term-season/TermSeasonService';
import moment from 'moment';
import { api } from '../../../api';
import { IScheduleTerm } from '../../../api/schedule-terms';

const add: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.add),
    mergeMap(() => {
      const { list, currentScheduleIndex } =
        state$.value.pages.dashboard.currentTrack;

      const { start_year, start_season } = state$.value.common.user.info;

      let currentTerms = list.find(
        (schedule: ISchedule) => schedule.id.toString() == currentScheduleIndex
      ).data;
      let currentTermCount = currentTerms.length;
      let newTermId = currentTermCount + 1;

      let terms = [
        ...currentTerms,
        {
          id: 'term' + newTermId,
          name: 'Term ' + newTermId,
          courses: [],
          creditTotal: 0,
          includeInTotal: true,
          isCompleted: true,
        },
      ];

      terms = termSeasonService.applySeasonToTerms(terms, start_season);
      terms = termSeasonService.applyYearToTerms(terms, start_year);
      terms = terms.map((term: IScheduleTerm) =>
        termSeasonService.applyTermCompletionStatus(term, moment().year())
      );

      return of(trackActions.update.start(terms));
    })
  );

const remove: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.remove),
    mergeMap(({ payload }) => {
      const { schedule } = state$.value.pages.dashboard.currentTrack;

      const { start_year, start_season } = state$.value.common.user.info;

      let currentTerms = schedule.data;

      let terms = currentTerms
        .filter((term: IScheduleTerm) => term.id !== payload)
        .map((term: IScheduleTerm, index: number) => ({
          ...term,
          id: index + 1,
          name: 'Term ' + (index + 1),
        }));

      terms = termSeasonService.applySeasonToTerms(terms, start_season);
      terms = termSeasonService.applyYearToTerms(terms, start_year);
      terms = terms.map((term: IScheduleTerm) =>
        termSeasonService.applyTermCompletionStatus(term, moment().year())
      );

      return of(trackActions.update.start(terms));
    })
  );

const addV2: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.addV2.start),
    switchMap(() =>
      from(
        api.scheduleTerms.addScheduleTerm(
          state$.value.pages.dashboard.currentTrack.schedule.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.addV2.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.addV2.error(error)).pipe(concat(source$))
    )
  );

const removeV2: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.removeV2.start),
    switchMap(({ payload }) =>
      from(
        api.scheduleTerms.removeScheduleTerm(
          state$.value.pages.dashboard.currentTrack.schedule.id,
          payload
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.removeV2.done());
        })
      )
    ),
    catchError((error, source$) => {
      return of(actions.removeV2.error(error.response.data)).pipe(
        concat(source$)
      );
    })
  );

export const epic = combineEpics(add, remove, addV2, removeV2);
