import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IMessage } from '../../../../../api/messages';
import * as messageThreadsActions from '../threads/actions';
import { IValidationErrorResponse } from '../../../../../types/error';

interface IState {
  selectedThreadId: number;
  isLoading: boolean;
  recipient: {
    id: number;
    name: string;
    studentId: string;
    avatarUrl: string;
    email: string;
  };
  messages: IMessage[];
  body: string;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  selectedThreadId: -1,
  isLoading: false,
  recipient: {
    id: -1,
    name: '',
    studentId: '',
    avatarUrl: '',
    email: '',
  },
  messages: [],
  body: '',
  error: {
    errors: {},
    message: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(messageThreadsActions.select, (state, payload) => ({
  ...state,
  selectedThreadId: payload.messageThreadId,
  recipient: {
    id: payload.contextualRecipient.id,
    name: payload.contextualRecipient.name,
    studentId: payload.contextualRecipient.studentId,
    avatarUrl: payload.contextualRecipient.avatarUrl,
    email: payload.contextualRecipient.email,
  },
}));

reducer.on(actions.sendMessage.done, (state, payload) => ({
  ...state,
  body: '',
}));

reducer.on(actions.sendMessage.error, (state, payload) => ({
  ...state,
  error: payload,
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    messages: [...payload],
  };
});

reducer.on(actions.onChange, (state, payload) => {
  return {
    ...state,
    body: payload,
    error: {
      errors: {},
      message: '',
    },
  };
});

export const threadReducer = reducer;
