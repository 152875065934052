import { combineReducers } from 'redux';

import { currentTrackReducer as currentTrack } from './current-track/reducer';
import { modalsReducer as modals } from './modals/reducer';
import { courseBinReducer as courseBin } from './current-track/course-bin/reducer';
import { progressReducer as progress } from './progress/reducer';
import { courseProgressReducer as courseProgress } from './course-progress/reducer';
import { trackNotificationsReducer as trackNotifications } from './track-notifications/reducer';
import { trackListReducer as trackList } from './track-list/reducer';

export const dashboardReducer = combineReducers({
  currentTrack,
  courseBin,
  modals,
  progress,
  courseProgress,
  trackNotifications,
  trackList,
});
