import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import * as actions from './actions';
import * as submitActions from './panels/create/submit/actions';
import { panelsReducer as panels } from './panels/reducer';

export enum MessagesDrawerPanels {
  Threads,
  CreateMessageThread,
  ThreadMessages,
}

interface IState {
  isLoading: boolean;
  isOpen: boolean;
  activePanel: MessagesDrawerPanels;
}

const initialState: IState = {
  isLoading: false,
  isOpen: false,
  activePanel: MessagesDrawerPanels.Threads,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
}));

reducer.on(actions.open, (state, payload) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.toggle, (state) => ({
  ...state,
  isOpen: !state.isOpen,
}));

reducer.on(actions.openWithRecipient, (state, payload) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
  };
});

reducer.on(actions.changeActivePanel, (state, payload) => {
  return {
    ...state,
    activePanel: payload,
  };
});

reducer.on(submitActions.send.done, (state) => {
  return {
    ...state,
    activePanel: MessagesDrawerPanels.Threads,
  };
});

export const messagesDrawerReducer = combineReducers({
  panels,
  state: reducer,
});
