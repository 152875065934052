import React from "react";
import { AvatarUpload } from '../../../../components/avatar-upload';
import { useSelector } from '../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from "../../../../common/user/actions";

export const UserProfileAvatar: React.FC = () => {

  const dispatch = useDispatch();

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info
    }),
    shallowEqual
  );

    return (
        <div className={"w-full flex items-center justify-center mt-10"}>
            <AvatarUpload onUploadComplete={() => dispatch(actions.fetch())} avatarUrl={user?.avatarUrl} />
        </div>
    )
}
