import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ISelectOption } from '../../../../../../ui/select';
import { Season } from '../../../../../../constants';
import { IValidationErrorResponse } from '../../../../../../types/error';

interface IState {
  isLoading: boolean;
  availableTerms: ISelectOption[];
  availableYears: ISelectOption [];
  selectedTerm: ISelectOption|null;
  selectedYear: ISelectOption|null;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  selectedTerm: null,
  selectedYear: null,
  availableTerms: [],
  availableYears: [],
  error: { errors: {}, message: "" },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    availableYears: payload.years,
    availableTerms: payload.terms,
    selectedTerm: null,
    selectedYear: null,
  };
});

reducer.on(actions.onTermChange, (state, payload) => {
  return {
    ...state,
    selectedTerm: payload,
    error: { errors: {}, message: "" },
  };
});

reducer.on(actions.onYearChange, (state, payload) => {
  return {
    ...state,
    selectedYear: payload,
    error: { errors: {}, message: "" },
  };
});

reducer.on(actions.create.error, (state, payload) => {
  return {
    ...state,
    error: payload,
  };
});

export const createAvailabilityReducer = reducer;
