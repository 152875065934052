import { createAction } from 'redux-act';
import { ICourseBinListFilter } from './reducer';
import { IValidationErrorResponse } from '../../../types/error';
import {IFilter} from "../../../types/filter";

export type IActions = ReturnType<typeof apply>;

export const apply = createAction<ICourseBinListFilter>(
  '[common/filters/course-bin] apply'
);

export const fetch = createAction('[common/filters/course-bin] fetch');

export const load = {
  start: createAction('[common/filters/course-bin] load.start'),
  error: createAction<IValidationErrorResponse>(
    '[common/filters/course-bin] load.error'
  ),
  done: createAction<any>('[common/filters/course-bin] load.done'),
};

export const handleFilter = createAction<{ key: string; filter: IFilter }>(
  '[common/filters/course-bin] handleFilter'
);
