import { createReducer } from 'redux-act';
import * as actions from './actions';
import * as submitActions from '../submit/actions';
import { ISchedule } from '../../../../../../api/schedules';
import { IValidationErrorResponse } from '../../../../../../types/error';

interface IState {
  isLoading: boolean;
  search: string;
  list: ISchedule[];
  selected: number|null;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  search: '',
  list: [],
  selected: null,
  error: { errors: {}, message: '' },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: [...payload],
  };
});

reducer.on(actions.select, (state, payload) => {
  return {
    ...state,
    selected: payload,
  };
});

reducer.on(submitActions.send.done, (state) => {
  return {
    ...state,
    selected: null,
  };
});

reducer.on(submitActions.send.error, (state, payload) => {
  return {
    ...state,
    selected: null,
    error: payload,
  };
});

export const trackReducer = reducer;
