import React from 'react';
import { Label } from '../../../../../ui/label';
import { Input } from '../../../../../components/input';
import * as actions from './actions';
import { Checkbox, SelectField } from '../../../../../ui';
import { CoursePrerequisiteRequirementsField } from '../prerequisite-requirements';
import { CourseConcurrencyRequirementsField } from '../concurrency-requirements';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import {
  CheckBoxBorder,
  CheckBoxBorderSize,
  CheckboxLabelPosition,
  CheckBoxRounded,
} from '../../../../../ui/checkbox';
import { IGradeLevel } from '../../../../../api/schools';
import { Availability } from '../availability';

export const CourseDetails: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, errors, course, schoolConfig } = useSelector(
    (state) => ({
      course: state.pages.admin.settings.course.details.course,
      errors: state.pages.admin.settings.course.details.errors,
      isLoading: state.pages.admin.settings.course.details.isLoading,
      schoolConfig: state.pages.admin.settings.common.config.school,
    }),
    shallowEqual
  );

  if (!course.name) {
    return null;
  }

  return (
    <section className={'flex w-8/12 flex-col border-r pt-4 pr-4'}>
      <h3 className={'font-semibold text-neutral-darkest'}>Details</h3>

      <div className={'flex mt-6 w-full'}>
        <div className="w-6/12 mr-5">
          <Label name={'Course Name'} required={true} />
          <Input
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'name',
                  value: e.target.value,
                })
              )
            }
            value={course.name}
            name={'name'}
            type={'text'}
            required={true}
          />
        </div>
        <div className="w-6/12">
          <Label name={'Course Code'} required={true} />
          <Input
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'code',
                  value: e.target.value,
                })
              )
            }
            value={course.code}
            name={'code'}
            type={'text'}
            required={true}
          />
        </div>
      </div>

      <div className={'flex mt-8 w-full'}>
        <div className="w-4/12 mr-5">
          <Label name={'Credits'} required={true} />
          <Input
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'credits',
                  value: e.target.value,
                })
              )
            }
            value={course.credits}
            name={'credits'}
            type={'number'}
            required={true}
          />
        </div>
        <div className="w-4/12 mr-5">
          <Label name={'Terms Offered'} required={true} />
          <SelectField
            defaultValue={course.termsOffered}
            onChange={(options) =>
              dispatch(actions.onTermsOfferedChange(options))
            }
            options={schoolConfig.availableSeasons}
            error={{ errors: {}, message: '' }}
            multiple
            name={'terms_offered'}
          />
        </div>
        <div className="w-4/12">
          <Label name={'Difficulty'} required={true} />
          <Input
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'difficulty',
                  value: e.target.value,
                })
              )
            }
            value={course.difficulty}
            name={'difficulty'}
            type={'number'}
            maxLength={5}
            required={true}
          />
        </div>
      </div>

      <div className={'flex mt-8 mb-8'}>
        <div className="w-1/3 flex flex-col">
          <Checkbox
            border={CheckBoxBorder.Neutral}
            borderSize={CheckBoxBorderSize.TwoPixel}
            containerClassNames={'w-full flex items-center'}
            labelClassNames={'text-blue-darkest text-sm font-semibold ml-2'}
            labelPosition={CheckboxLabelPosition.AfterBox}
            label={'Allow Students to Customize'}
            rounded={CheckBoxRounded.md}
            checked={course.customDetailsEnabled}
            onChange={() =>
              dispatch(
                actions.onChange({
                  key: 'customDetailsEnabled',
                  value: !course.customDetailsEnabled,
                })
              )
            }
          />
          <span className={'mt-2 text-neutral text-xs'}>
            These are courses that can be edited by the student to specify their
            credits, name and code of the course
          </span>
        </div>
        <div className="w-1/3 flex flex-col">
          <Checkbox
            border={CheckBoxBorder.Neutral}
            borderSize={CheckBoxBorderSize.TwoPixel}
            containerClassNames={'w-full flex items-center'}
            labelClassNames={'text-blue-darkest text-sm font-semibold ml-2'}
            labelPosition={CheckboxLabelPosition.AfterBox}
            label={'Is Repeatable'}
            rounded={CheckBoxRounded.md}
            checked={course.isRepeatable}
            onChange={() =>
              dispatch(
                actions.onChange({
                  key: 'isRepeatable',
                  value: !course.isRepeatable,
                })
              )
            }
          />
          <span className={'mt-2 text-neutral text-xs'}>
            Setting a course to repeatable will allow students to take the same course twice and it will count towards the same requirement twice
          </span>
        </div>
        <div className="w-1/3">
          <Label name={'Grade Level'} required={false} />
          <SelectField
            multiple={true}
            defaultValue={course.gradeLevel}
            onChange={(option) => dispatch(actions.onGradeLevelChange(option))}
            options={schoolConfig.gradeLevels}
            error={{ errors: {}, message: '' }}
            name={'grade_level'}
          />
        </div>
      </div>

      <hr />

      <div className={'flex mt-4 w-full'}>
        <CoursePrerequisiteRequirementsField />
        <CourseConcurrencyRequirementsField />
      </div>
    </section>
  );
};
