import React, { useEffect } from 'react';
import { RequirementProgress } from '../../../../../components/requirement-progress';
import * as actions from './actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import { IScheduleRequirementProgress } from '../../../../../api/schedules';
import Skeleton from 'react-loading-skeleton';

export const RequirementsProgress: React.FC = () => {
  const dispatch = useDispatch();

  const { list, isLoading, schedule, hasLoaded } = useSelector(
    (state) => ({
      schedule: state.pages.admin.dashboard.student.schedule.schedule,
      isLoading: state.pages.admin.dashboard.student.requirements.isLoading,
      hasLoaded: state.pages.admin.dashboard.student.requirements.hasLoaded,
      list: state.pages.admin.dashboard.student.requirements.list,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [schedule?.id]);

  if (isLoading && !hasLoaded) {
    return (
      <>
        <Skeleton
          height={110}
          width={'12%'}
          className={'rounded-md mr-8'}
          inline={true}
        />
        <Skeleton
          height={110}
          width={'12%'}
          className={'rounded-md mr-8'}
          inline={true}
        />
        <Skeleton
          height={110}
          width={'12%'}
          className={'rounded-md mr-8'}
          inline={true}
        />
        <Skeleton
          height={110}
          width={'12%'}
          className={'rounded-md mr-8'}
          inline={true}
        />
        <Skeleton
          height={110}
          width={'12%'}
          className={'rounded-md mr-8'}
          inline={true}
        />
        <Skeleton
          height={110}
          width={'12%'}
          className={'rounded-md mr-8'}
          inline={true}
        />
      </>
    );
  }

  if (!list.length) {
    return null;
  }

  return (
    <section className={'flex items-center mb-10'}>
      {list.map((requirement: IScheduleRequirementProgress) => (
        <RequirementProgress
          id={requirement.id}
          title={requirement.name}
          unit={requirement.unit}
          completed={requirement.completed}
          planned={requirement.planned}
          total={requirement.required}
          color={requirement.color}
        />
      ))}
    </section>
  );
};
