import React from 'react';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

export interface IModalProps {
  isOpen: boolean;
  handleClose: () => any;
  children?: any;
}

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  handleClose,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div
        className={
          'fixed bg-black top-0 left-0 h-screen w-screen flex items-center justify-center z-10 opacity-60'
        }
      ></div>
      <div
        className={
          'w-screen h-screen fixed flex items-center justify-center z-50 top-0 left-0'
        }
      >
        <div
          className={
            'relative sm:10/12 md:8/12 lg:6/12 xl:w-5/12 bg-white rounded-lg shadow-lg p-10'
          }
          style={{ minHeight: 500 }}
        >
          <header>
            <IoClose
              aria-label={'close'}
              role={'close'}
              onClick={() => handleClose()}
              className={'text-3xl fill-neutral-dark cursor-pointer'}
            />
          </header>
          <div className={'w-full my-8 w-11/12 mx-auto'}>{children}</div>
        </div>
      </div>
    </>
  );
};

Modal.defaultProps = {};
