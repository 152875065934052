import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Helpers } from '../../../helpers';
import { TermType } from '../../../constants';
import { CourseList } from './courses';
import { CreditTotalAndPrice } from './credit-total';
import { termSeasonService } from '../../../services/term-season/TermSeasonService';
import { IScheduleTerm } from '../../../api/schedule-terms';
import classNames from 'classnames';
import { ICourse } from '../../../types/course';
import { TermRequirements } from './requirements';

interface ITermProps {
    groupIndex: number;
    termIndex: number;
    isLastInGroup: boolean;
    type: TermType;
    term: IScheduleTerm;
    onDeleteTerm: (termId: number) => any;
    isDraggable: boolean;
    displayTermCost: boolean;
}

export const Term: React.FC<ITermProps> = ({ displayTermCost, isLastInGroup, term, type, onDeleteTerm = () => {}, isDraggable, termIndex, groupIndex }) => {

    const size = "225px";

    let defaultStyles = "py-3 px-3 flex flex-col rounded-md grow relative bg-neutral-lightest"

  let termBodyStyles = classNames(defaultStyles.split(' '), {
    'border-2 border-baby-blue': term.inProgress,
  });

  let termContainerStyles = classNames('w-full h-full flex flex-col', {
    'mr-3': !isLastInGroup,
  });

  const [showCompletedTermOverlay, setShowCompletedTermOverlay] =
    useState<boolean>(true);

  const creditTotal = term.courses
    .map((course: ICourse) => course.credits)
    .reduce((a, b) => a + b, 0);

  return (
    <div
      className={termContainerStyles}
      style={{ minWidth: size, maxWidth: size }}
      onMouseEnter={() => setShowCompletedTermOverlay(false)}
      onMouseLeave={() => setShowCompletedTermOverlay(!!term.isCompleted)}
    >
      <div className={'flex items-center justify-between'}>
        <div className={'relative pl-4 pt-4 w-full'}>
          <TermRequirements termId={term.id} />
          <h5 className={'text-neutral-light text-sm mb-0 -mb-2'}>
            {term.name}
          </h5>
          <span
            style={{ fontSize: '8px' }}
            className={'text-baby-blue uppercase font-bold tracking-widest'}
          >
            {termSeasonService.getSeasonAsString(term?.season)} {term?.year}
          </span>
        </div>
      </div>

            <div className={termBodyStyles}>
                {term.isCompleted && showCompletedTermOverlay && <div className={"rounded-md bg-neutral opacity-20 absolute top-0 left-0 w-full h-full z-10"}/>}
                <Droppable droppableId={term.id.toString()}>
                    {(provided, snapshot) => (
                        <div
                            className="min-h-3"
                            ref={provided.innerRef}
                            style={Helpers.getListStyle(snapshot.isDraggingOver, displayTermCost)}
                        >
                            {provided.placeholder}
                            <CourseList groupIndex={groupIndex} termIndex={termIndex} isDraggable={isDraggable} list={term.courses} termId={term.id}/>
                        </div>
                    )}
                </Droppable>
                <CreditTotalAndPrice
                    onDeleteTerm={onDeleteTerm}
                    displayTermCost={displayTermCost}
                    term={term}
                    total={creditTotal}
                />
            </div>
        </div>
        )
}
