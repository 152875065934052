import { combineReducers } from 'redux';
import { domainConfigReducer as domain } from './domain';
import { configurationReducer as configuration } from './configuration/reducer';
import { integrationsReducer as integrations } from './integrations/reducer';

export const configReducer = combineReducers({
  domain,
  configuration,
  integrations
});
