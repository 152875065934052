import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IProgression } from '../../../../../api/students';
import { IScheduleTerm } from '../../../../../api/schedule-terms';
import { Season } from '../../../../../constants';

interface IState {
  activePreview: number;
  selectedId: number|null;
  isLoading: boolean;
  isOpen: boolean;
  avatarUrl: string;
  studentId: number;
  hasActiveSchedule: boolean;
  name: string;
  email: string;
  major: string;
  progression: IProgression;
  currentTerm: IScheduleTerm | null;
  nextTerm: IScheduleTerm | null;
  flags: number;
  previews: {
    profile: {
      aboutYou: {
        dreamVocationAsKid: string;
        futureVocation: string;
        favoriteThingToDo: string;
      },
      interests: {
        english: number;
        history: number;
        math: number;
        science: number;
        performingArts: number;
        athletics: number;
        studioArts: number;
      }
    }
  }
}

const initialState: IState = {
  activePreview: 0,
  selectedId: null,
  isLoading: false,
  isOpen: false,
  name: '',
  studentId: 0,
  email: '',
  avatarUrl: '',
  major: '',
  flags: 0,
  hasActiveSchedule: false,
  progression: {
    percentPlanned: 0,
    percentCompleted: 0,
    percentInProgress: 0,
    percentUnplanned: 0,
    estimatedGraduationSeason: 0,
    estimatedGraduationYear: 0,
  },
  currentTerm: {
    id: 0,
    name: '',
    season: Season.Summer,
    year: 0,
    isCompleted: 0,
    inProgress: 0,
    createdAt: '',
    updatedAt: '',
    courses: [],
    creditTotal: 0,
    includeInTotal: false,
  },
  nextTerm: {
    id: 0,
    name: '',
    season: Season.Summer,
    year: 0,
    isCompleted: 0,
    inProgress: 0,
    createdAt: '',
    updatedAt: '',
    courses: [],
    creditTotal: 0,
    includeInTotal: false,
  },
  previews: {
    profile: {
      aboutYou: {
        dreamVocationAsKid: "",
        futureVocation: "",
        favoriteThingToDo: "",
      },
      interests: {
        english: 0,
        history: 0,
        math: 0,
        science: 0,
        performingArts: 0,
        athletics: 0,
        studioArts: 0,
      }
    }
  }
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.close, (state) => ({
  ...state,
  activePreview: 0,
  isOpen: false,
  name: '',
  studentId: 0,
  email: '',
  avatarUrl: '',
  major: '',
  currentTerm: {
    id: 0,
    name: '',
    season: Season.Summer,
    year: 0,
    isCompleted: 0,
    inProgress: 0,
    createdAt: '',
    updatedAt: '',
    courses: [],
    creditTotal: 0,
    includeInTotal: false,
  },
  nextTerm: {
    id: 0,
    name: '',
    season: Season.Summer,
    year: 0,
    isCompleted: 0,
    inProgress: 0,
    createdAt: '',
    updatedAt: '',
    courses: [],
    creditTotal: 0,
    includeInTotal: false,
  },
}));

reducer.on(actions.open, (state, payload) => ({
  ...state,
  selectedId: payload,
  isOpen: true,
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    studentId: payload.data.id,
    avatarUrl: payload.data.progression.avatarUrl,
    name: payload.data.name,
    email: payload.data.email,
    major: payload.data.major,
    currentTerm: payload.data.currentTerm,
    nextTerm: payload.data.nextTerm,
    progression: payload.data.progression.progression,
    flags: payload.data.flags,
    hasActiveSchedule: payload.data.hasActiveSchedule,
    previews: {
      profile: {
        aboutYou: {
          dreamVocationAsKid: payload.data.about.dreamVocationAsKid,
          futureVocation: payload.data.about.futureVocation,
          favoriteThingToDo: payload.data.about.favoriteThingToDo,
        },
        interests: {
          english: payload.data.interests.english,
          history: payload.data.interests.history,
          math: payload.data.interests.math,
          science: payload.data.interests.science,
          performingArts: payload.data.interests.performingArts,
          athletics: payload.data.interests.athletics,
          studioArts: payload.data.interests.studioArts,
        }
      }
    }
  };
});

reducer.on(actions.preview.select, (state, payload) => ({
  ...state,
  isLoading: false,
  activePreview: payload
}));

export const studentDetailDrawerReducer = reducer;
