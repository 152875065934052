import React from 'react';
import {
  Input,
  InputSize,
  InputVariant,
} from '../../../../../components/input';
import { SelectField } from '../../../../../ui';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';
import { SelectSize, SelectVariant } from '../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../types/error';

export enum EditableDetailFieldType {
  Text,
  Number,
  Select,
}

interface IDetailProps {
  title: string;
  items: string[];
  isEditMode: boolean;
  canBeEdited?: boolean;
  fieldType?: EditableDetailFieldType;
  error: IValidationErrorResponse;
}

export const Detail: React.FC<IDetailProps> = ({
  title,
  items,
  isEditMode,
  fieldType,
  canBeEdited,
  error,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className={
        'flex items-center justify-between rounded-md bg-neutral-lightest py-2 px-4 w-full mb-4'
      }
    >
      <span className={'text-black font-normal text-lg w-1/2'}>{title}</span>
      <div className={'flex items-center justify-end w-1/2'}>
        {isEditMode && canBeEdited && (
          <div className={'w-full'}>
            {fieldType == EditableDetailFieldType.Text && (
              <Input
                size={InputSize.xs}
                variant={InputVariant.Transparent}
                onChange={(e) =>
                  dispatch(actions.onCourseCodeChange(e.target.value))
                }
                name={'code'}
                type={'text'}
                error={error}
                required={false}
              />
            )}
            {fieldType == EditableDetailFieldType.Select && (
              <SelectField
                variant={SelectVariant.Transparent}
                size={SelectSize.xs}
                onChange={(option) =>
                  dispatch(actions.onUnitChange(option.value))
                }
                options={[
                  { label: '1 Unit', value: '1' },
                  { label: '2 Units', value: '2' },
                  { label: '3 Units', value: '3' },
                  { label: '4 Units', value: '4' },
                  { label: '5 Units', value: '5' },
                ]}
                error={{ errors: {}, message: '' }}
              />
            )}
          </div>
        )}
        {((isEditMode && !canBeEdited) || !isEditMode) &&
          !!items &&
          !!items.length &&
          items.map((item: string) => (
            <span className={'text-sm bg-neutral px-2 py-1 rounded-md mr-2'}>
              {item}
            </span>
          ))}
        {((isEditMode && !canBeEdited) || !isEditMode) &&
          !!items &&
          !items.length && (
            <span className={'text-md bg-neutral px-2 py-1 rounded-md mr-2'}>
              N/A
            </span>
          )}
      </div>
    </div>
  );
};

Detail.defaultProps = {
  fieldType: EditableDetailFieldType.Text,
  canBeEdited: false,
};
