import React from "react";
import {Edit} from "../../../icons/edit";
import {AboutYouModal} from "./modal";
import * as aboutYouModalActions from "./modal/actions";
import { shallowEqual, useDispatch } from 'react-redux';
import {UserProfileAboutYouFields} from "./fields";
import { useSelector } from '../../../infrastructure/selector';

export const UserProfileAboutYou: React.FC = () => {

    const dispatch = useDispatch();

    const { isOpen, isLoading, error, dreamVocationAsKid, futureVocation, favoriteThingToDo } = useSelector(
      (state) => ({
        dreamVocationAsKid: state.pages.studentProfile.aboutYou.modal.dreamVocationAsKid,
        futureVocation: state.pages.studentProfile.aboutYou.modal.futureVocation,
        favoriteThingToDo: state.pages.studentProfile.aboutYou.modal.favoriteThingToDo,
        isOpen: state.pages.studentProfile.aboutYou.modal.isOpen,
        error: state.pages.studentProfile.aboutYou.modal.error,
        isLoading:
        state.pages.studentProfile.aboutYou.modal.isLoading,
      }),
      shallowEqual
    );

    return (
        <div className={""}>
            <div className={"flex items-center"}>
                <h3 className={"text-neutral-darkest text-2xl mr-2 font-medium"}>About You</h3>
                <Edit classes={"text-baby-blue cursor-pointer"} onClick={() => dispatch(aboutYouModalActions.open())}/>
            </div>
            <UserProfileAboutYouFields vocationAsKid={dreamVocationAsKid} favoriteThingToDo={favoriteThingToDo} futureVocation={futureVocation} />
            <AboutYouModal />
        </div>
    )
}
