import { createAction } from 'redux-act';
import { IOccupancyResponse } from '../../../../../api/course-occupancy';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/course/students] fetch'
);

export const load = {
  start: createAction('[pages/admin/dashboard/course/students] load.start'),
  error: createAction<any>(
    '[pages/admin/dashboard/course/students] load.error'
  ),
  done: createAction<IOccupancyResponse>(
    '[pages/admin/dashboard/course/students] load.done'
  ),
};
