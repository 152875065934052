import React, {useEffect} from "react";
import {StudentInfo} from "./info";
import {useDispatch} from "react-redux";
import * as actions from "./head/actions";
import { Outlet } from 'react-router-dom';

export const AdminStudent: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <StudentInfo />
      <Outlet />
    </>
  );
};
