import { createAction } from 'redux-act';
import { IScheduleRequirementProgress } from '../../../../../api/schedules';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/student/requirement-progress] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/student/requirement-progress] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/student/requirement-progress] load.error'
  ),
  done: createAction<IScheduleRequirementProgress[]>(
    '[pages/admin/dashboard/student/requirement-progress] load.done'
  ),
};
