import React from 'react';
import { Helpers } from '../../../../../helpers';
import { Draggable } from 'react-beautiful-dnd';
import { FaLock } from '@react-icons/all-files/fa/FaLock';
import { CourseRequirements } from './requirements';
import * as courseDetailModalActions from '../../../../../pages/dashboard/modals/course-detail/actions';
import { useDispatch } from 'react-redux';
import { ICourse } from '../../../../../types/course';

interface ICourseProps {
  course: ICourse;
  index: number;
  groupIndex: number;
  termId: number;
  termIndex: number;
  key: string | number;
  isDraggable: boolean;
}

export const Course: React.FC<ICourseProps> = ({
  termId,
  groupIndex,
  termIndex,
  course,
  index,
  isDraggable,
}) => {
  const dispatch = useDispatch();
  return (
    <Draggable
      isDragDisabled={course.isLocked || !isDraggable}
      key={course.id.toString() + "_" + groupIndex.toString() + "_" + termIndex.toString() + "_" + index.toString()}
      draggableId={course.id.toString() + "_" + groupIndex.toString() + "_" + termIndex.toString() + "_" + index.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          onClick={() =>
            dispatch(
              courseDetailModalActions.beforeOpen({
                courseId: course.id,
                termId: termId,
              })
            )
          }
          className={[
            'relative',
            'courseBlock',
            'bg-white',
            'shadow-md border-l-6',
          ].join(' ')}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            borderLeftColor: course.requirementCategory?.color ?? '',
            ...Helpers.getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            ),
          }}
        >
          <div>
            <h6 className={'text-neutral-darkest font-medium text-sm'}>
              {course.name}
            </h6>
            <span className="className text-neutral font-light text-xxs -mb-2">
              {course.isUserGenerated ? course.code : course.content}
            </span>
          </div>
          <div>
            <CourseRequirements courseId={course.id} termId={termId} />
            <span
              className="creditNum flex items-center text-xss text-black uppercase font-semibold tracking-widest mt-2"
              style={{ fontSize: 9 }}
            >
              {/*{course.isLocked && <span className={"locked"} style={{ fontSize: 10, marginRight: 5}}><FaLock/></span>}*/}
              {course.credits} units
            </span>
          </div>
        </div>
      )}
    </Draggable>
  );
};