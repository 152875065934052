import React from 'react';
import { AuthHeader } from '../../../../shared/header';
import { SiMinutemailer } from '@react-icons/all-files/si/SiMinutemailer';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';

export const ForgotPasswordSuccessScreen: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className={'flex items-center justify-center mb-8'}>
        <img src="/assets/flyer.svg" alt="mail flyer icon" />
      </div>
      <AuthHeader
        title={'Check your email'}
        subtitle={'We have sent password recover instructions to your email'}
      />
      <div className={'mt-20'}>
        <p className={'text-black text-xs text-center'}>
          Didn’t recieve the email? Check your spam folder, or{' '}
          <a
            className={'text-blue'}
            href={'#'}
            onClick={() => dispatch(actions.reset())}
          >
            try another email address
          </a>
        </p>
      </div>
    </>
  );
};
