import React from 'react';
import ReactSlider from 'react-slider';
import classNames from 'classnames';

interface IRangeSliderProps {
  defaultValue: number[];
  min?: number;
  max?: number;
  onChange: (value: number[]) => any;
  showValue?: boolean;
  disabled?: boolean;
}

export const RangeSlider: React.FC<IRangeSliderProps> = ({
  onChange,
  defaultValue,
  min,
  max,
  showValue,
  disabled
}) => {

  let numberOfThumbs = defaultValue.length;

  const track1Classes = classNames([
    '[&_.track-0]:bg-neutral [&_.track-0]:h-1 [&_.track-0]:rounded-full w-full',
    {
      '[&_.track-0]:bg-baby-blue': numberOfThumbs === 1,
      '[&_.track-0]:bg-neutral-lighter': numberOfThumbs === 2,
    }
  ]);

  const track2Classes = classNames([
    '[&_.track-1]:h-1 [&_.track-1]:rounded-full w-full',
    {
        '[&_.track-1]:bg-neutral-light': numberOfThumbs === 1,
        '[&_.track-1]:bg-baby-blue': numberOfThumbs === 2,
    }
  ]);

  const track3Classes = classNames([
    '[&_.track-2]:h-1 [&_.track-2]:bg-neutral [&_.track-2]:rounded-full w-full',
  ]);

  const thumb1Classes = classNames([
    '[&_.thumb-0]:border [&_.thumb-0]:border-neutral-lighter [&_.thumb-0]:cursor-grab [&_.thumb-0]:active:cursor-grabbing [&_.thumb-0]:bg-white [&_.thumb-0]:rounded-full [&_.thumb-0]:w-5 [&_.thumb-0]:h-5 [&_.thumb-0]:shadow-lg [&_.thumb-0]:-top-2',
  ]);
  const thumb2Classes = classNames([
    '[&_.thumb-1]:border [&_.thumb-1]:border-neutral-lighter [&_.thumb-1]:cursor-grab [&_.thumb-1]:active:cursor-grabbing [&_.thumb-1]:bg-white [&_.thumb-1]:rounded-full [&_.thumb-1]:w-5 [&_.thumb-1]:h-5 [&_.thumb-1]:shadow-lg [&_.thumb-1]:-top-2',
  ]);

  const classes = classNames([
    track1Classes,
    track2Classes,
    track3Classes,
    thumb1Classes,
    thumb2Classes,
  ]);

  return (
    <>
      <ReactSlider
        onAfterChange={(value) => onChange(value)}
        className={classes}
        min={min}
        disabled={disabled}
        max={max}
        defaultValue={defaultValue}
        renderThumb={(props, state) => {
          return (
              <div {...props} style={{ ...props.style, display: disabled ? "none" : "static" }} >
                  {showValue && (
                      <div
                          className={
                              'relative bg-blue -top-8 -left-2 rounded-md flex items-center h-7 w-9 justify-center'
                          }
                      >
                          <span className={'text-white block text-xs'}>
                            {state.valueNow}%
                          </span>
                      </div>
                  )}
              </div>
          )
        }}
        minDistance={1}
      />
    </>
  );
};

RangeSlider.defaultProps = {
  min: 0,
  max: 100,
  showValue: true,
  disabled: false,
};
