import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  isLoading: boolean;
  studentsNotFullyPlanned: number;
  averageUnitLoad: number;
  plannedToGraduate: number;
  currentYear: number;
  currentSeason: number;
}

const initialState: IState = {
  isLoading: false,
  studentsNotFullyPlanned: 0,
  averageUnitLoad: 0,
  plannedToGraduate: 0,
  currentSeason: -1,
  currentYear: -1,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    ...payload,
  };
});

export const overviewReducer = reducer;
