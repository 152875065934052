import { createAction } from 'redux-act';
import {
  IStudentProgression,
} from '../../../../../api/students';
import { IPaginatedResponse } from '../../../../../api/common';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/main/students] fetch'
);

export const onChange = createAction<string>(
  '[pages/admin/dashboard/main/students] onChange'
);

export const load = {
  start: createAction('[pages/admin/dashboard/main/students] load.start'),
  error: createAction<any>('[pages/admin/dashboard/main/students] load.error'),
  done: createAction<IPaginatedResponse<IStudentProgression>>(
    '[pages/admin/dashboard/main/students] load.done'
  ),
};
