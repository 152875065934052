import React from 'react';
import Select from 'react-select';
import * as actions from '../../../actions';
import { useDispatch } from 'react-redux';
import { colorStyles } from '../../index';

export const PronounsField: React.FC = () => {
  const dispatch = useDispatch();

  const pronounOptions = [
    {
      label: 'he/him/his',
      value: 'he/him/his',
    },
    {
      label: 'she/her/hers',
      value: 'she/her/hers',
    },
    {
      label: 'ze/hir/hir',
      value: 'ze/hir/hir',
    },
    {
      label: 'they/them/their',
      value: 'they/them/their',
    },
  ];

  return (
    <div className={'mb-4'}>
      <label
        className={'block text-blue-darkest text-sm mb-1 font-semibold'}
        htmlFor="password"
      >
        Pronouns
      </label>
      <Select
        styles={colorStyles}
        options={pronounOptions}
        className={'text-sm w-full text-black'}
        placeholder={'select pronouns'}
        onChange={(option: any) =>
          dispatch(
            actions.onChange({
              key: 'pronouns',
              value: option?.value,
            })
          )
        }
      />
    </div>
  );
};
