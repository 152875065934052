import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(
      () =>
        !!state$.value.pages.admin.dashboard.course.occupancies
          .selectedOccupancyId
    ),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      let str = state$.value.common.match.url;
      let n = str.lastIndexOf('/');
      let courseId = str.substring(n + 1);
      const occupancyId =
        state$.value.pages.admin.dashboard.course.occupancies
          .selectedOccupancyId;
      return from(
        api.courseOccupancy.getOccupancyById(courseId, occupancyId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load);
