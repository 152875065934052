import React from 'react';
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack';
import { Button } from '../../../../../ui';
import { ButtonSize, ButtonVariant } from '../../../../../ui/button';
import { Recipient } from './recipient';
import { TrackSelect } from './track';
import { Message } from './message';
import { useDispatch } from 'react-redux';
import * as actions from './submit/actions';
import { MessagesDrawerPanels } from '../../reducer';

export interface ICreateNewThreadPanelProps {
  handleSetActivePanel: (activePanel: MessagesDrawerPanels) => any;
}

export const CreateNewThreadPanel: React.FC<ICreateNewThreadPanelProps> = ({
  handleSetActivePanel,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <section
        className={
          'flex items-center justify-center border-b px-5 py-4 relative'
        }
      >
        <IoIosArrowBack
          onClick={() => handleSetActivePanel(MessagesDrawerPanels.Threads)}
          className={'absolute top-5 left-6 text-xl fill-neutral'}
        />
        <h3 className={'text-baby-blue font-semibold text-md'}>New Message</h3>
      </section>
      <section className={'px-5 py-4 w-full'}>
        <Recipient />
        {/*<TrackSelect />*/}
        <Message />
        <div className="w-full flex items-center justify-end">
          <Button
            isLoading={false}
            handleOnClick={() => dispatch(actions.send.start())}
            variant={ButtonVariant.Primary}
            size={ButtonSize.XXSmall}
          >
            Send
          </Button>
        </div>
      </section>
    </>
  );
};
