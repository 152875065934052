import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../infrastructure/selector';
import { api } from '../../../api';
import querystring from "query-string";
const fetch: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(actions.fetch),
        map(() => actions.load.start())
    );

const load: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.load.start),
        switchMap(() => {
            return from(
                api.integrations.getIntegrations()
            ).pipe(
                mergeMap(({ data }) => {
                    return of(actions.load.done(data));
                })
            )
        }),
        catchError((error, source$) =>
            of(actions.load.error(error)).pipe(concat(source$))
        )
    );


const authorize: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.authorize.start),
        map(() => {
            const { integrations } = state$.value.common.configs;
            const { blackbaud } = integrations;
            let params = querystring.parse(blackbaud.authorizeUrl);

            //@ts-ignore
            window.localStorage.setItem(params.state, window.location.href + "?import=students");
            window.location.href = blackbaud.authorizeUrl;
        }),
        catchError((error, source$) =>
            of(actions.load.error(error)).pipe(concat(source$))
        )
    );



export const epic = combineEpics(fetch, load, authorize);
